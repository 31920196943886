import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["formCard", "formTitle", "formCardBody", "nameDropdown", "nameDropdownBtn", "nameInput", "nameList",
                    "statusDropdown", "statusDropdownBtn", "statusInput", "statusList",
                    "kindDropdown", "kindDropdownBtn", "kindInput", "kindList", "numberForm",
                    "medInput", "iuguIdInput", "iuguEmailInput", "pixInput", "saveBtn", "nameModal"]


  connect() {
    this.controllerName = `financials--books--clients--accounts--save`
  }

  disconnect() {
    this.stopRefreshing()
  }

  cancelSave() {
    this.stopRefreshing()
    this.getControllerByIdentifier("financials--books--clients--accounts--index").doIndexHtml()
  }

  saveAccount() {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal({ message: `Em processamento, aguarde!` })

    this.send_data = { current_user: {}, account: {}, tracker: { manual: true } }
    this.saveBtnTarget.disabled = true
    this.stopRefreshing()

    if (this.actionMode == `edit`) {
      this.send_data.account.id = this.current_account.id

      this.send_data.tracker.action = `updated`
      this.send_data.tracker.observations = `Atualizou a Conta ${this.iuguIdInputTarget.value.trim()} | ${this.iuguEmailInputTarget.value.trim()}`
    } else {
      this.send_data.tracker.action = `created`
      this.send_data.tracker.observations = `Adicionou a Conta ${this.iuguIdInputTarget.value.trim()} | ${this.iuguEmailInputTarget.value.trim()}`
    }

    this.send_data.account.client_id = this.current_client.id
    this.send_data.account.med_name = this.medInputTarget.value
    this.send_data.account.med_id = this.medInputTarget.dataset.medId
    this.send_data.account.med_iugu_id = this.medInputTarget.dataset.medIuguId
    this.send_data.account.iugu_id = this.iuguIdInputTarget.value.trim()
    this.send_data.account.iugu_email = this.iuguEmailInputTarget.value.trim()
    // this.send_data.account.iugu_name = this.numberInputTarget.value
    // this.send_data.account.status = this.statusInputTarget.dataset.status
    // this.send_data.account.kind = this.kindInputTarget.dataset.kind

    this.send_data.tracker.record_id = this.current_client.id
    this.send_data.tracker.record_type = `financial_client_entities`
    this.send_data.tracker.obj_id = ``
    this.send_data.tracker.obj_type = ``

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = this.permission

    this.requestSave()
  }

  requestSave() {
    if (this.actionMode == `new`) {
      var url = "/financials/books/clients/accounts/create"
      var method = "POST"
    } else if (this.actionMode == `edit`) {
      var url = "/financials/books/clients/accounts/update"
      var method = "PUT"
    } else if (this.actionMode == `delete`) {
      var url = "/financials/books/clients/accounts/destroy"
      var method = "DELETE"
    }

    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.stopRefreshing()
        if (response.save) {

          var client = response.data.cln.client
          var account = response.data.cln.account

          controller.current_client = client
          controller.getControllerByIdentifier("financials--books--clients--accounts--index").current_client = client

          controller.application.clients.forEach((element, i) => {
            if (element.id == client.id) {
              controller.application.clients.splice(controller.application.clients.indexOf(element), 1, client)
            }
          })

          // if (controller.actionMode == `new`) {
          //   controller.current_client.accounts[controller.current_client.accounts.length] = account
          // } else if (controller.actionMode == `edit`) {
          //   controller.current_client.accounts.forEach((element, i) => {
          //     if (element.id == account.id) {
          //       controller.current_client.accounts.splice(controller.current_client.accounts.indexOf(element), 1, account)
          //     }
          //   })
          // }
        }

        controller.cancelSave()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  doFormHtml() {
    this.stopRefreshing()

    if (this.application.permissions[this.permission].can_create) {
      var saveBtnHtml = `<button disabled type="button" class="btn btn-primary btn-sm py-2 btn-table f-065" data-action="click->${this.controllerName}#saveAccount" data-${this.controllerName}-target="saveBtn">Salvar</button>`
    } else {
      var saveBtnHtml = ``
    }

    var deleteBtnHtml = ``

    var html = `<div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="formCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 py-3" style="font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="formTitle"></h6>
                  </div>

                  <div class="card-body py-0" style="overflow:auto;" data-${this.controllerName}-target="formCardBody">
  
                    <div class="row my-2 d-flex align-items-center">
                      <div class="col-1 text-right px-0">
                        <button data-action="click->${this.controllerName}#showMeds" data-channel-setter="true" data-element="medInput" data-${this.controllerName}-target="medModal" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                          <span class="material-icons md-sm md-dark">help_center</span>
                          <span class="mc-tooltiptext mc-tooltiptext-right">Clique para ver as Meds</span>
                        </button>
                      </div>
                      <div class="col-3">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="medForm">MedCapital</label>
                            <input readonly="readonly" class="form-control f-075 pt-1 pb-1" data-${this.controllerName}-target="medInput" type="text" placeholder="MedCapital" required>
                          </div>
                        </div>
                      </div>

                      <div class="col-4">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="iuguIdForm">ID Iugu</label>
                            <input aria-describedby="iuguIdFormHelp" class="form-control form-valid-control" id="iuguIdForm" data-${this.controllerName}-target="iuguIdInput" placeholder="ID Iugu" type="text" required>
                          </div>
                        </div>
                      </div>

                      <div class="col-4">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="iuguEmailForm">Email Iugu</label>
                            <input aria-describedby="iuguEmailFormHelp" class="form-control form-valid-control" id="iuguEmailForm" data-${this.controllerName}-target="iuguEmailInput" placeholder="Email Iugu" type="text" required>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row my-2">
                    
                      

                    </div>

                    <div class="row my-2 d-none">
                      <div class="col-6">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label>Status</label>
                            <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="statusDropdown">
                              <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="statusDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="statusInput"></span></button>
                              <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                <ul class="ul-select" data-${this.controllerName}-target="statusList" data-app--helpers--search-target="searchList">
                                  <li data-status="active" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Aberta</li>
                                  <li data-status="inactive" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Encerrada</li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label>Tipo</label>
                            <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="kindDropdown">
                              <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="kindDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="kindInput"></span></button>
                              <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                <ul class="ul-select" data-${this.controllerName}-target="kindList" data-app--helpers--search-target="searchList">
                                  <li data-kind="main" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Principal</li>
                                  <li data-kind="secondary" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Secundária</li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="card-footer border-top text-right py-1">
                    <button type="button" class="btn btn-secondary btn-sm py-2 btn-table f-065" data-dismiss="modal" data-action="click->${this.controllerName}#cancelSave">Fechar</button>
                    ${deleteBtnHtml}
                    ${saveBtnHtml}
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.gridElement.innerHTML = html)
    }).then(() => {
      controller.formCardTarget.style.height = ($(window).height() * 0.5) + "px"


      if (controller.actionMode == `new`) {
        controller.formTitleTarget.innerText = `Nova Conta`

        // controller.statusDropdownBtnTarget.disabled = true
        // controller.statusDropdownTarget.value = `active`
        // controller.statusInputTarget.innerText = `Aberta`
        // controller.statusInputTarget.dataset.status = `active`

      } else if (controller.actionMode == `edit`) {
        controller.formTitleTarget.innerText = `Editando Conta`

        controller.medInputTarget.value = controller.current_account.med_name
        controller.iuguIdInputTarget.value = controller.current_account.iugu_id
        controller.iuguEmailInputTarget.value = controller.current_account.iugu_email

      }

      controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
      controller.refreshSaveBtn()
    })

  }

  showMeds(ev) {
    var element = ev.currentTarget.dataset.element

    var html = `<div class="modal fade" data-controller="financials--books--dashboards--meds--modal" data-financials--books--dashboards--meds--modal-target="modal" id="connectionModal" tabindex="-1" role="dialog" aria-labelledby="connectionModalTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div class="modal-content">
                      <div class="modal-body p-0" data-financials--books--dashboards--meds--modal-target="body"></div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.getControllerByIdentifier(`financials--books--dashboards--meds--modal`).controllerForm = controller
      controller.getControllerByIdentifier(`financials--books--dashboards--meds--modal`).inputElement = this.nameTarget(element)
      // controller.getControllerByIdentifier(`financials--books--dashboards--meds--modal`).channelSetter = channelSetter
      controller.getControllerByIdentifier(`financials--books--dashboards--meds--modal`).open()
    })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0

      if (controller.medInputTarget.value == ``) {
        len += 1
      }

      // if (controller.statusInputTarget.innerText == ``) {
      //   len += 1
      // }

      // if (controller.kindInputTarget.innerText == ``) {
      //   len += 1
      // }

      if (controller.iuguIdInputTarget.value == ``) {
        len += 1
      }

      if (controller.iuguEmailInputTarget.value == ``) {
        len += 1
      }

      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

}
