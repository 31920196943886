import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["body", "mainDashboard", "bankCodeFilter", "branchCode", "accountNumber", "installments", "debtCheckbox", "darfCheckbox",
                    "paymentForm", "bankData", "installmentData", "approvedDraftCard", "bankCode", "saveTicketBtn",
                    "bodyEdit", "bodyEditInput", "xxxx", "xxxx", "xxxx", "xxxx",
                    "refundForm", "bankCheckbox", "pixCheckbox", "pixData", "pixCode", "xxxx"]

  connect() {
    this.controllerName = `mobile--operations--products--tax-filings--management--journeys`
  }

  goToDashboard() {
    this.getControllerByIdentifier("mobile--operations--products--tax-filings--show--dashboard").reconnect()
  }

  setCurrentSummary() {
    this.application.summaries.forEach(element => {
      if (element.date_id == this.application.current_date.id) {
        this.current_summary = element
      }
    })
  }

  goBackIndex() {
    this.doJourneyDashboard()
  }

  goBackDraft() {
    this.doDraftHtml()
  }

  doJourneyDashboard() {
    this.setCurrentSummary()

    var html = `<div class="row d-flex align-items-center my-3" data-${this.controllerName}-target="mainDashboard">
                  <div class="col-2 text-center px-0">
                    <span class="material-icons md-primary md-lg" data-action="click->${this.controllerName}#goToDashboard">dashboard</span>
                    <p class="mb-0 f-065">Dashboard</p>
                  </div>
                  <div class="col-10 text-center">
                    <h3><strong>Jornada IRPF ${this.application.current_date.year + 1}</strong></h3>
                    <hr class="mb-0">
                  </div>
                </div>
                <div class="row my-3" data-${this.controllerName}-target="body"></div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("mobile--operations--products--tax-filings--show--main").contentTarget.innerHTML = html)
    }).then(() => {
      controller.doDataTable()
    })
  }

  doDataTable() {
    this.current_journeys = []
    this.application.journeys.forEach(element => {
      if (element.date_id == this.application.current_date.id) {
        this.current_journeys[this.current_journeys.length] = element
      }
    })

    var journeys = this.getControllerByIdentifier("app--helpers--data").sortByKeyDesc(this.current_journeys, `date_to_time`)
    
    this.listData(journeys)
  }

  listData(data) {
    this.bodyTarget.innerHTML = ""
    if (data == undefined || data == [] || data.length == 0) {
      var noData = `<div class="col-10 offset-1 text-center">
                      <div class="card my-3">
                        <div class="media d-flex align-items-center py-3">
                          <span class="material-icons md-lg md-primary mx-3">description</span>
                          <div class="media-body">
                            <h5 class="mb-0 f-1p25">Ainda não começamos a Jornada!</h5>
                          </div>
                        </div>
                      </div>
                    </div>`

      this.bodyTarget.innerHTML = noData
    } else {
      
      var rowHtml = ``
      var i = 0
      data.forEach(element => {

        if ((element.has_file && i == 0) || (element.has_file && element.status == `transmitted`) || (element.has_file && element.status == `enquiry`) || (element.has_file && element.status == `rectification`)) {
          var fileBtn = `<div class="media d-flex align-items-center py-3" data-action="click->${this.controllerName}#doCheckHtml">
                          <span class="material-icons md-lg md-primary mx-3">description</span>`
        } else {
          var fileBtn = `<div class="media d-flex align-items-center py-3">`
        }

        if (i == 0) {
          rowHtml += `<div class="col-10 offset-1 text-center">
                        <div class="card my-3 cardItem" data-id="${element.id}">
                            ${fileBtn}
                            <div class="media-body">
                              <h5 class="mb-0 f-1p5 f-bolder">${element.status_pretty}</h5>
                              <span class="text-black-secondary">${element.date_pretty} ${element.date_time}</span>
                            </div>
                          </div>
                        </div>
                      </div>`
        } else {
          rowHtml += `<div class="col-10 offset-1 text-center">
                        <div class="card my-3 cardItem" data-id="${element.id}">
                            ${fileBtn}
                            <div class="media-body">
                              <h5 class="mb-0 f-1">${element.status_pretty}</h5>
                              <span class="text-black-secondary">${element.date_pretty}  ${element.date_time}</span>
                            </div>
                          </div>
                        </div>
                      </div>`
        }
        i += 1
      })

      this.bodyTarget.innerHTML = rowHtml
    }
  }

  doCheckHtml(ev) {
    var journeyId = ev.currentTarget.closest(".cardItem").dataset.id
    

    this.current_journeys.forEach(element => {
      if (element.id == journeyId) {
        this.current_journey = element
      }
    })

    if (this.current_journey.status == `draft_sent`) {
      this.doDraftHtml()
    } else if (this.current_journey.status == `awaiting_transmission`) {
    } else if (this.current_journey.status == `transmitted`) {
      this.doTransmittedHtml()
    } else if (this.current_journey.status == `rectification`) {
      this.doRectificationHtml()
    } else if (this.current_journey.status == `enquiry`) {
      this.doEnquiryHtml()
    }
  }

  doTransmittedHtml() {

    // `<button data-action="click->${this.controllerName}#goToURL" data-url="${this.current_journey.file_filing_url}" type="button" class="btn btn-sm btn-table p-0 mc-tooltip ml-auto mr-1">`

    var html = `<div class="row d-flex align-items-center w-100">
                  <div class="col-2 px-2 text-center">
                    <button data-action="click->${this.controllerName}#goBackIndex" type="button" class="btn btn-sm btn-table p-0 mc-tooltip ml-auto mr-1">
                      <span class="material-icons md-lg md-dark">arrow_back</span>
                      <span class="mc-tooltiptext">Voltar</span>
                    </button>
                  </div>
                  <div class="col-8 mb-4">
                    <div class="card card-no-shadow my-3">
                      <h5 class="mb-0 f-1p1">Declaração ${this.application.current_date.financial_year}: </h5>
                    </div>
                  </div>
                  <div class="col-2 px-2 text-center">
                    <button data-action="click->${this.controllerName}#goToURL" data-url="${this.current_journey.file_filing_url}" type="button" class="btn btn-sm btn-table p-0 mc-tooltip ml-auto mr-1">
                      <span class="material-icons md-lg md-dark">launch</span>
                      <span class="mc-tooltiptext">Ver Declaração</span>
                    </button>
                  </div>
                </div>
                <div class="row d-flex align-items-center w-100">
                  <div class="col-8 offset-2 my-4">
                    <div class="card card-no-shadow my-3">
                      <h5 class="mb-0 f-1p1">Recibo Declaração ${this.application.current_date.financial_year}: </h5>
                    </div>
                  </div>
                  <div class="col-2 px-2 my-4 text-center">
                    <button data-action="click->${this.controllerName}#goToURL" data-url="${this.current_journey.file_receipt_url}" type="button" class="btn btn-sm btn-table p-0 mc-tooltip ml-auto mr-1">
                      <span class="material-icons md-lg md-dark">launch</span>
                      <span class="mc-tooltiptext">Ver Recibo</span>
                    </button>
                  </div>
                </div>`

    if (this.current_journey.has_darf) {
      html += `<div class="row d-flex align-items-center w-100">
                  <div class="col-8 offset-2 my-4">
                    <div class="card card-no-shadow my-3">
                      <h5 class="mb-0 f-1p1">1ª Darf ${this.application.current_date.financial_year}: </h5>
                    </div>
                  </div>
                  <div class="col-2 px-2 my-4 text-center">
                    <button data-action="click->${this.controllerName}#goToURL" data-url="${this.current_journey.file_darf_url}" type="button" class="btn btn-sm btn-table p-0 mc-tooltip ml-auto mr-1">
                      <span class="material-icons md-lg md-dark">launch</span>
                      <span class="mc-tooltiptext">Ver Darf</span>
                    </button>
                  </div>
                </div>`
    }

    if (this.current_journey.has_booking) {
      html += `<div class="row d-flex align-items-center w-100">
                  <div class="col-8 offset-2 my-4">
                    <div class="card card-no-shadow my-3">
                      <h5 class="mb-0 f-1p1">Livro-Caixa ${this.application.current_date.financial_year}: </h5>
                    </div>
                  </div>
                  <div class="col-2 px-2 my-4 text-center">
                    <button data-action="click->${this.controllerName}#goToURL" data-url="${this.current_journey.file_booking_url}" type="button" class="btn btn-sm btn-table p-0 mc-tooltip ml-auto mr-1">
                      <span class="material-icons md-lg md-dark">launch</span>
                      <span class="mc-tooltiptext">Ver Livro-Caixa</span>
                    </button>
                  </div>
                </div>`
    }

    if (this.current_journey.has_senior_darf) {
      html += `<div class="row d-flex align-items-center w-100">
                  <div class="col-8 offset-2 my-4">
                    <div class="card card-no-shadow my-3">
                      <h5 class="mb-0 f-1p1">Darf Doação Idoso ${this.application.current_date.financial_year}: </h5>
                    </div>
                  </div>
                  <div class="col-2 px-2 my-4 text-center">
                    <button data-action="click->${this.controllerName}#goToURL" data-url="${this.current_journey.file_senior_darf_url}" type="button" class="btn btn-sm btn-table p-0 mc-tooltip ml-auto mr-1">
                      <span class="material-icons md-lg md-dark">launch</span>
                      <span class="mc-tooltiptext">Ver Darf Doação Idoso</span>
                    </button>
                  </div>
                </div>`
    }

    if (this.current_journey.has_child_darf) {
      html += `<div class="row d-flex align-items-center w-100">
                  <div class="col-8 offset-2 my-4">
                    <div class="card card-no-shadow my-3">
                      <h5 class="mb-0 f-1p1">Darf Doação ECA ${this.application.current_date.financial_year}: </h5>
                    </div>
                  </div>
                  <div class="col-2 px-2 my-4 text-center">
                    <button data-action="click->${this.controllerName}#goToURL" data-url="${this.current_journey.file_child_darf_url}" type="button" class="btn btn-sm btn-table p-0 mc-tooltip ml-auto mr-1">
                      <span class="material-icons md-lg md-dark">launch</span>
                      <span class="mc-tooltiptext">Ver Darf Doação ECA</span>
                    </button>
                  </div>
                </div>`
    }

    this.bodyTarget.innerHTML = html
  }

  doRectificationHtml() {
    var html = `<div class="row d-flex align-items-center w-100">
                  <div class="col-2 px-2 text-center">
                    <button data-action="click->${this.controllerName}#goBackIndex" type="button" class="btn btn-sm btn-table p-0 mc-tooltip ml-auto mr-1">
                      <span class="material-icons md-lg md-dark">arrow_back</span>
                      <span class="mc-tooltiptext">Voltar</span>
                    </button>
                  </div>
                  <div class="col-8">
                    <div class="card card-no-shadow my-3">
                      <h5 class="mb-0 f-1p1">Declaração Retificadora ${this.application.current_date.year + 1}: </h5>
                    </div>
                  </div>
                  <div class="col-2 px-2 text-center">
                    <button data-action="click->${this.controllerName}#goToURL" data-url="${this.current_journey.file_filing_url}" type="button" class="btn btn-sm btn-table p-0 mc-tooltip ml-auto mr-1">
                      <span class="material-icons md-lg md-dark">launch</span>
                      <span class="mc-tooltiptext">Ver Declaração Retificadora</span>
                    </button>
                  </div>
                </div>
                <div class="row d-flex align-items-center w-100">
                  <div class="col-8 offset-2 my-5">
                    <div class="card card-no-shadow my-3">
                      <h5 class="mb-0 f-1p1">Recibo Declaração Retificadora ${this.application.current_date.year + 1}: </h5>
                    </div>
                  </div>
                  <div class="col-2 px-2 my-5 text-center">
                    <button data-action="click->${this.controllerName}#goToURL" data-url="${this.current_journey.file_receipt_url}" type="button" class="btn btn-sm btn-table p-0 mc-tooltip ml-auto mr-1">
                      <span class="material-icons md-lg md-dark">launch</span>
                      <span class="mc-tooltiptext">Ver Recibo Retificador</span>
                    </button>
                  </div>
                </div>`

    this.bodyTarget.innerHTML = html
  }

  doEnquiryHtml() {
    var html = `<div class="row d-flex align-items-center w-100">
                  <div class="col-2 px-2 text-center">
                    <button data-action="click->${this.controllerName}#goBackIndex" type="button" class="btn btn-sm btn-table p-0 mc-tooltip ml-auto mr-1">
                      <span class="material-icons md-lg md-dark">arrow_back</span>
                      <span class="mc-tooltiptext">Voltar</span>
                    </button>
                  </div>
                  <div class="col-8">
                    <div class="card card-no-shadow my-3">
                      <h5 class="mb-0 f-1p1">Consulta Processamento: </h5>
                    </div>
                  </div>
                  <div class="col-2 px-2 text-center">
                    <button data-action="click->${this.controllerName}#goToURL" data-url="${this.current_journey.file_enquiry_url}" type="button" class="btn btn-sm btn-table p-0 mc-tooltip ml-auto mr-1">
                      <span class="material-icons md-lg md-dark">launch</span>
                      <span class="mc-tooltiptext">Ver Consulta</span>
                    </button>
                  </div>
                </div>`

    this.bodyTarget.innerHTML = html
  }

  doDraftHtml() {
    var html = `<div class="row d-flex align-items-center w-100">
                  <div class="col-2 px-2 text-center">
                    <button data-action="click->${this.controllerName}#goBackIndex" type="button" class="btn btn-sm btn-table p-0 mc-tooltip ml-auto mr-1">
                      <span class="material-icons md-lg md-dark">arrow_back</span>
                      <span class="mc-tooltiptext">Voltar</span>
                    </button>
                  </div>
                  <div class="col-8 text-center">
                    <div class="card card-no-shadow my-3">
                      <h5 class="mb-0 f-1p1">Veja o seu Rascunho:</h5>
                    </div>
                  </div>
                  <div class="col-2 px-2  text-center">
                    <button data-action="click->${this.controllerName}#goToURL" data-url="${this.current_journey.file_draft_url}" type="button" class="btn btn-sm btn-table p-0 mc-tooltip ml-auto mr-1">
                      <span class="material-icons md-lg md-dark">launch</span>
                      <span class="mc-tooltiptext">Ver Rascunho</span>
                    </button>
                  </div>
                </div>
                <div class="row d-flex align-items-center w-100">
                  <div class="col-5 offset-1 text-center px-2 my-5">
                    <div class="card my-3 py-3 w-100 h-100 bg-primary" data-action="click->${this.controllerName}#approvedDraftHtml">
                      <h5 class="my-auto f-1p1 f-bold">Aprovar Rascunho</h5>
                    </div>
                  </div>
                  <div class="col-5 text-center px-2 my-5">
                    <div class="card my-3 py-3 w-100 h-100" data-action="click->${this.controllerName}#ticketDraftHtml">
                      <h5 class="my-auto f-1">Relatar Pendências</h5>
                    </div>
                  </div>
                </div>
                <div class="row d-flex align-items-center w-100">
									<div class="col-10 offset-1 px-1 f-1p25">
										<p>O Rascunho da sua <strong>Declaração de Imposto de Renda ${this.application.current_date.year + 1}</strong> está pronto!</p>
										<p>É indispensável os seguintes items:</p>
										<ol>
											<li>Verificar as <strong>fontes pagadoras</strong> e o <strong>pagamentos dedutíveis</strong>;</li>
											<li>Verificar a <strong>evolução patrimonial</strong> no campo de <strong>bens e direitos</strong>;</li>
											<li>Conferir os <strong>dados cadastrais</strong> na primeira página.</li>
										</ol>
										<p>Após a conferência, caso tenha algum ajuste a ser feito, nos notifique.</p>
										<p>Ao contrário, aguardamos sua autorização para transmitir clicando no botão acima.</p>
									</div>
								</div>`

    this.bodyTarget.innerHTML = html
  }

  draftInfo() {
    var html = `<div class="row">
									<div class="col-12 px-1">
										<p>O Rascunho da sua <strong>Declaração de Imposto de Renda ${this.application.current_date.year + 1}</strong> está pronto!</p>
										<p>É indispensável os seguintes items:</p>
										<ol>
											<li>Verificar as <strong>fontes pagadoras</strong> e o <strong>pagamentos dedutíveis</strong>;</li>
											<li>Verificar a <strong>evolução patrimonial</strong> no campo de <strong>bens e direitos</strong>;</li>
											<li>Conferir os <strong>dados cadastrais</strong> na primeira página.</li>
										</ol>
										<p>Após a conferência, caso tenha algum ajuste a ser feito, nos notifique.</p>
										<p>Ao contrário, aguardamos sua autorização para transmitir clicando no botão ao lado.</p>
									</div>
								</div>`

    this.actionAreaTarget.innerHTML = html
  }

  approvedDraftHtml() {

    var html = `<div class="col-2 px-2 text-center">
                  <button data-action="click->${this.controllerName}#goBackDraft" type="button" class="btn btn-sm btn-table p-0 mc-tooltip ml-auto mr-1">
                    <span class="material-icons md-lg md-dark">arrow_back</span>
                    <span class="mc-tooltiptext">Voltar</span>
                  </button>
                </div>
                <div class="col-8 text-center">
                  <div class="card card-no-shadow my-3">
                    <h5 class="mb-0 f-1p25">Dados para ${this.current_summary.statement_pretty}</h5>
                  </div>
                </div>
                <div class="w-100 d-none" data-${this.controllerName}-target="paymentForm">
                  <div class="col-9 offset-2 d-flex my-3">
                    <div class="custom-control custom-checkbox pr-0 pl-2 w-50 my-0 d-flex">
                      <input type="checkbox" class="custom-control-input" id="paymentDebtCheck" data-form="debt" data-${this.controllerName}-target="debtCheckbox" data-action="click->${this.controllerName}#formCheck">
                      <label class="custom-control-label f-1p25 ml-3" for="paymentDebtCheck">Débito</label>
                    </div>
                    <div class="custom-control custom-checkbox pr-0 pl-2 w-50 my-0 d-flex">
                      <input type="checkbox" class="custom-control-input" id="paymentDarfCheck" data-form="darf" data-${this.controllerName}-target="darfCheckbox" data-action="click->${this.controllerName}#formCheck">
                      <label class="custom-control-label f-1p25 ml-3" for="paymentDarfCheck">Darf</label>
                    </div>
                  </div>
                </div>
                <div class="w-100 d-none" data-${this.controllerName}-target="refundForm">
                  <div class="col-12 d-flex my-3 px-0 justify-content-center">
                    <div class="custom-control custom-checkbox pr-0 pl-2 my-0 d-flex mx-5">
                      <input type="checkbox" class="custom-control-input" id="refundDebtCheck" data-form="bank" data-${this.controllerName}-target="bankCheckbox" data-action="click->${this.controllerName}#refundCheck">
                      <label class="custom-control-label f-085 ml-3" for="refundDebtCheck">Débito</label>
                    </div>
                    <div class="custom-control custom-checkbox pr-0 pl-2 my-0 d-flex mx-5">
                      <input type="checkbox" class="custom-control-input" id="refundPixCheck" data-form="pix" data-${this.controllerName}-target="pixCheckbox" data-action="click->${this.controllerName}#refundCheck">
                      <label class="custom-control-label f-085 ml-3" for="refundPixCheck">PIX</label>
                    </div>
                  </div>
                </div>
                <div class="w-100 d-none" data-${this.controllerName}-target="bankData">
                  <div class="col-10 offset-1 text-center px-0">
                    <div class="form-group my-4">
                      <div class="floating-label floating-label-md">
                        <label class="f-1p25" for="bankCode">Banco</label>
                        <input aria-describedby="bankCodeHelp" class="form-control f-1p5 w-100" id="bankCode" type="text" data-filter-mode="simple" data-${this.controllerName}-target="bankCode" data-action="focus->${this.controllerName}#bankFilter keyup->${this.controllerName}#bankFilter keyup->${this.controllerName}#enterBank blur->${this.controllerName}#hideList">
                        <ul class="ul-filter filter-list d-none w-100" style="z-index:1" data-${this.controllerName}-target="bankCodeFilter">
                      </div>
                    </div>
                  </div>
                  <div class="col-10 offset-1 text-center px-0">
                    <div class="form-group my-4">
                      <div class="floating-label floating-label-md">
                        <label class="f-1p25" for="branchCode">Agência sem DV</label>
                        <input aria-describedby="branchCodeHelp" class="form-control f-1p5 w-100" id="branchCode" type="tel" data-${this.controllerName}-target="branchCode">
                      </div>
                    </div>
                  </div>
                  <div class="col-10 offset-1 text-center px-0">
                    <div class="form-group my-4">
                      <div class="floating-label floating-label-md">
                        <label class="f-1p25" for="accountNumber">Conta Corrente com DV</label>
                        <input aria-describedby="accountNumberHelp" class="form-control f-1p5 w-100" id="accountNumber" type="text" data-${this.controllerName}-target="accountNumber">
                      </div>
                    </div>
                  </div>
                </div>
                <div class="w-100 d-none" data-${this.controllerName}-target="pixData">
                  <div class="col-10 offset-1 text-center px-0">
                    <div class="form-group my-2">
                      <div class="floating-label floating-label-md">
                        <label class="f-085" for="pixCode">PIX</label>
                        <input disabled aria-describedby="pixCodeHelp" class="form-control f-085 w-100" id="pixCode" type="tel" data-${this.controllerName}-target="pixCode">
                      </div>
                    </div>
                  </div>
                </div>
                <div class="w-100 d-none" data-${this.controllerName}-target="installmentData">
                  <div class="col-10 offset-1 text-center">
                    <div class="form-group my-4">
                      <div class="floating-label floating-label-md">
                        <label class="f-1p25" for="installments">Parcelas (máximo 8 parcelas)</label>
                        <input aria-describedby="installmentsHelp" class="form-control f-1p5 w-100" id="installments" type="tel" data-${this.controllerName}-target="installments">
                      </div>
                    </div>
                  </div>
                </div>
                <div class="w-100 d-none" data-${this.controllerName}-target="approvedDraftCard">
                  <div class="col-10 offset-1 text-center px-2 my-5">
                    <div class="card my-3 w-100 h-100 bg-primary py-3" data-action="click->${this.controllerName}#approvedDraft">
                      <h5 class="my-auto f-1p25 f-bold">Enviar Dados</h5>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.bodyTarget.innerHTML = html)
    }).then(() => {
      controller.listBanks()
      if (controller.current_summary.statement == `to_pay`) {
        controller.paymentFormTarget.classList.remove("d-none")
      } else if (controller.current_summary.statement == `to_refund`) {
        controller.refundFormTarget.classList.remove("d-none")
        // controller.bankDataTarget.classList.remove("d-none")
        // controller.approvedDraftCardTarget.classList.remove("d-none")
      } else if (controller.current_summary.statement == `do_nothing`) {
        controller.approvedDraftCardTarget.classList.remove("d-none")
      }
    }) 
  }

  refundCheck(ev) {
    var form = ev.currentTarget.dataset.form

    if (form == `bank`) {
      this.pixCheckboxTarget.checked = false
      this.bankCheckboxTarget.checked = true
      this.bankDataTarget.classList.remove("d-none")
      this.pixDataTarget.classList.add("d-none")
    } else if (form == `pix`) {
      this.pixCheckboxTarget.checked = true
      this.bankCheckboxTarget.checked = false
      this.bankDataTarget.classList.add("d-none")
      this.pixDataTarget.classList.remove("d-none")

      this.pixCodeTarget.value = this.application.tax_filing.account_cpf_pretty
    }

    this.approvedDraftCardTarget.classList.remove("d-none")
    this.getControllerByIdentifier("app--helpers--forms").floatingLabel()
  }

  goToFile(ev) {
    this.getControllerByIdentifier(`app--helpers--read-file`).feature_name = this.application.files_permission
    this.getControllerByIdentifier(`app--helpers--read-file`).goToFile(ev)
  }

  formCheck(ev) {
    var form = ev.currentTarget.dataset.form

    if (form == `debt`) {
      this.darfCheckboxTarget.checked = false
      this.debtCheckboxTarget.checked = true
      this.bankDataTarget.classList.remove("d-none")
    } else if (form == `darf`) {
      this.darfCheckboxTarget.checked = true
      this.debtCheckboxTarget.checked = false
      this.bankDataTarget.classList.add("d-none")
    }
    this.installmentDataTarget.classList.remove("d-none")
    this.installmentsTarget.value = 1
    this.approvedDraftCardTarget.classList.remove("d-none")
    this.getControllerByIdentifier("app--helpers--forms").floatingLabel()
  }

  listBanks() {
    var html = `<li class="li-selector dark f-1p25 text-left" data-action="click->${this.controllerName}#selectBank" data-text="001 - Banco do Brasil" data-filter="001 - Banco do Brasil">001 - Banco do Brasil</li>
                <li class="li-selector dark f-1p25 text-left" data-action="click->${this.controllerName}#selectBank" data-text="237 - Bradesco" data-filter="237 - Bradesco">237 - Bradesco</li>
                <li class="li-selector dark f-1p25 text-left" data-action="click->${this.controllerName}#selectBank" data-text="104 - Caixa Econômica Federal" data-filter="04 - Caixa Econômica Federal" class="li-selector dark f-065 text-left">104 - Caixa Econômica Federal</li>
                <li class="li-selector dark f-1p25 text-left" data-action="click->${this.controllerName}#selectBank" data-text="341 - Itaú" data-filter="341 - Itaú">341 - Itaú</li>
                <li class="li-selector dark f-1p25 text-left" data-action="click->${this.controllerName}#selectBank" data-text="033 - Santander" data-filter="033 - Santander">033 - Santander</li>
                <li class="li-selector dark f-1p25 text-left" data-action="click->${this.controllerName}#selectBank" data-text="260 - Nubank" data-filter="260 - Nubank">260 - Nubank</li>
                <li class="li-selector dark f-1p25 text-left" data-action="click->${this.controllerName}#selectBank" data-text="077 - Inter" data-filter="077 - Inter">077 - Inter</li>
                <li class="li-selector dark f-1p25 text-left" data-action="click->${this.controllerName}#selectBank" data-text="756 - Sicoob" data-filter="756 - Sicoob">756 - Sicoob</li>
                <li class="li-selector dark f-1p25 text-left" data-action="click->${this.controllerName}#selectBank" data-text="212 - Original" data-filter="212 - Original">212 - Original</li>
                <li class="li-selector dark f-1p25 text-left" data-action="click->${this.controllerName}#selectBank" data-text="Outro Informar por Email" data-filter="Outro Informar por Email">Outro Informar por Email</li>`

    this.bankCodeFilterTarget.innerHTML = html
  }

  bankFilter(ev) {
    this.getControllerByIdentifier("app--helpers--input").filterList(ev, 2)
  }

  selectBank(ev) {
    this.getControllerByIdentifier("app--helpers--input").selectItem(ev)
  }

  enterBank(ev) {
    if (ev.type == "keyup" && ev.key == "Enter") {
      this.getControllerByIdentifier("app--helpers--input").enterItem(ev)
    }
  }

  hideList(ev) {
    // this.getControllerByIdentifier("app--helpers--input").hideList(ev)
  }

  onlyNumbers(ev) {
    this.getControllerByIdentifier("app--helpers--numbers").onlyNumbers(ev)
  }

  approvedDraft() {
    if (this.current_summary.statement == `to_pay`) {
      if (this.debtCheckboxTarget.checked) {
        if (this.bankCodeTarget.value == ``) {
          alert(`O Banco não pode ficar em branco.`)
        } else if (this.branchCodeTarget.value == ``) {
          alert(`A Agência não pode ficar em branco.`)
        } else if (this.accountNumberTarget.value == ``) {
          alert(`O Número de Conta não pode ficar em branco.`)
        } else {
          var r = confirm(`Você confirma a aprovação do Rascunho da Declaração IRPF ${this.application.current_date.financial_year} e confirma os dados?`)
          if (r) {
            this.saveSummary()
          }
        }
      } else if (this.darfCheckboxTarget.checked) {
        if (this.installmentsTarget.value == ``) {
          alert(`A quantidade de parcelas não pode ficar em branco.`)
        } else {
          var r = confirm(`Você confirma a aprovação do Rascunho da Declaração IRPF ${this.application.current_date.financial_year} e confirma os dados?`)
          if (r) {
            this.saveSummary()
          }
        }
      }
      
    } else if (this.current_summary.statement == `to_refund`) {

      if (this.bankCheckboxTarget.checked) {
        if (this.bankCodeTarget.value == ``) {
          alert(`O Banco não pode ficar em branco.`)
        } else if (this.branchCodeTarget.value == ``) {
          alert(`A Agência não pode ficar em branco.`)
        } else if (this.accountNumberTarget.value == ``) {
          alert(`O Número de Conta não pode ficar em branco.`)
        } else {
          var r = confirm(`Você confirma a aprovação do Rascunho da Declaração IRPF ${this.application.current_date.financial_year} e confirma os dados?`)
          if (r) {
            this.saveSummary()
          }
        }
      } else if (this.pixCheckboxTarget.checked) {
        if (this.pixCodeTarget.value == ``) {
          alert(`O PIX não pode ficar em branco.`)
        } else {
          var r = confirm(`Você confirma a aprovação do Rascunho da Declaração IRPF ${this.application.current_date.financial_year} e confirma os dados?`)
          if (r) {
            this.saveSummary()
          }
        }
      }

    } else if (this.current_summary.statement == `do_nothing`) {
      var r = confirm(`Você confirma a aprovação do Rascunho da Declaração IRPF ${this.application.current_date.financial_year} e confirma os dados?`)
      if (r) {
        this.saveSummary()
      }
    }
    
  }

  saveSummary() {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal({ message: `Em processamento, aguarde!` })
    this.send_summary_data = { summary: {}, current_user: {}, tracker: { manual: true } }

    this.send_summary_data.tracker.record_id = this.application.tax_filing.id
    this.send_summary_data.tracker.record_type = `operation_products`
    this.send_summary_data.tracker.obj_id = this.application.current_date.id
    this.send_summary_data.tracker.obj_type = `operation_dates`
    this.send_summary_data.tracker.action = `updated`

    this.send_summary_data.summary.id = this.current_summary.id
    if (this.current_summary.statement == `to_pay`) {
      if (this.debtCheckboxTarget.checked) {
        this.send_summary_data.summary.form = `debt`
        this.send_summary_data.summary.bank_code = this.bankCodeTarget.value
        this.send_summary_data.summary.branch_code = this.branchCodeTarget.value
        this.send_summary_data.summary.account_number = this.accountNumberTarget.value
      } else if (this.darfCheckboxTarget.checked) {
        this.send_summary_data.summary.form = `darf`
      }
      this.send_summary_data.summary.installments = this.installmentsTarget.value

      this.send_summary_data.tracker.observations = `Aprovou pelo celular o Rascunho e atualizou a forma de Pagamento do IRPF ${this.application.current_date.financial_year}`
    } else if (this.current_summary.statement == `to_refund`) {

      if (this.bankCheckboxTarget.checked) {
        this.send_summary_data.summary.form = `debt`
        this.send_summary_data.summary.bank_code = this.bankCodeTarget.value
        this.send_summary_data.summary.branch_code = this.branchCodeTarget.value
        this.send_summary_data.summary.account_number = this.accountNumberTarget.value
      } else if (this.pixCheckboxTarget.checked) {
        this.send_summary_data.summary.form = `pix`
        this.send_summary_data.summary.account_number = this.getControllerByIdentifier(`app--helpers--numbers`).fromCpfToNumber(this.pixCodeTarget.value)
      }

      this.send_summary_data.tracker.observations = `Aprovou pelo celular o Rascunho e atualizou a forma de Restituição do IRPF ${this.application.current_date.financial_year}`
    } else if (this.current_summary.statement == `do_nothing`) {
      this.send_summary_data.summary.form = `none`
      this.send_summary_data.summary.bank_code = `Não se Aplica`
      this.send_summary_data.summary.branch_code = `Não se Aplica`
      this.send_summary_data.summary.account_number = `Não se Aplica`

      this.send_summary_data.tracker.observations = `Aprovou pelo celular o Rascunho do IRPF ${this.application.current_date.financial_year}`
    }

    this.send_summary_data.current_user.current_user_id = this.application.current_user_id
    this.send_summary_data.current_user.feature = this.application.summary_permission

    const url = "/operations/products/tax_filings/summaries/update"
    const init = { method: "PUT", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_summary_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          var summary = response.data.cln
          controller.application.current_summary = summary
          controller.application.summaries.forEach(element => {
            if (element.id == summary.id) {
              controller.application.summaries.splice(controller.application.summaries.indexOf(element), 1, summary)
            }
          })

          controller.createNewJourney()
        }
        
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  createNewJourney() {
    this.actionMode = `new`
    this.send_data = { current_user: {}, journey: {}, tracker: { manual: true } }
    
    this.send_data.journey.tax_filing_id = this.application.tax_filing.id
    this.send_data.journey.date_id = this.application.current_date.id
    this.send_data.journey.status = `awaiting_transmission`
    this.send_data.journey.date = new Date()
    
    var newStatus = this.getControllerByIdentifier(`mobile--operations--products--tax-filings--show--dashboard`).translateJourney(this.send_data.journey.status)
    this.send_data.tracker.record_id = this.application.tax_filing.id
    this.send_data.tracker.record_type = `operation_products`
    this.send_data.tracker.obj_id = this.application.current_date.id
    this.send_data.tracker.obj_type = `operation_dates`
    this.send_data.tracker.action = `changed`
    this.send_data.tracker.observations = `Mudou a Jornada do IRPF ${this.application.current_date.financial_year} para ${newStatus}`

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = this.application.journey_permission

    this.requestSaveJourney()
  }

  requestSaveJourney() {
    var url = "/operations/products/tax_filings/journeys/create"
    var method = "POST"

    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          var journey = response.data.cln
          if (controller.actionMode == `new`) {
            controller.application.journeys[controller.application.journeys.length] = journey
          }

          controller.sendJourneyEmailNotification(journey)
        }
        controller.doJourneyDashboard()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  sendJourneyEmailNotification(journey) {
    this.send_data = { current_user: {}, notification: {} }

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = this.application.journey_permission

    this.send_data.notification.journey_id = journey.id
    this.send_data.notification.product_id = this.application.tax_filing.id
    this.send_data.notification.product_token = this.application.tax_filing.token
    this.send_data.notification.product_name = this.application.tax_filing.product_name
    this.send_data.notification.date_id = this.application.current_date.id
    this.send_data.notification.date_type = `operation_dates`
    this.send_data.notification.receiver_id = this.application.current_agent.account_id
    this.send_data.notification.receiver_name = this.application.current_agent.account_name
    this.send_data.notification.receiver_kind = `team`
    this.send_data.notification.sender_id = this.application.current_user.account_id
    this.send_data.notification.sender_name = `${this.getControllerByIdentifier("app--visits--users").getFullNameTratment(this.application.current_user)}`
    this.send_data.notification.action = `mudou o status da sua Declaração IRPF ${this.application.current_date.financial_year} para`
    this.send_data.notification.body = journey.status_pretty

    var url = "/operations/products/tax_filings/journeys/send_email_notification"
    var method = "POST"

    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  ticketDraftHtml() {
    var html = `<div class="col-2 px-2 text-center">
                  <button data-action="click->${this.controllerName}#goBackDraft" type="button" class="btn btn-sm btn-table p-0 mc-tooltip ml-auto mr-1">
                    <span class="material-icons md-lg md-dark">arrow_back</span>
                    <span class="mc-tooltiptext">Voltar</span>
                  </button>
                </div>
                <div class="col-8 text-center">
                  <div class="card card-no-shadow my-3 mb-5">
                    <h5 class="mb-0 f-1p25">Pendências do Rascunho</h5>
                  </div>
                </div>
                <div class="w-100">
                  <div class="col-10 offset-1 d-flex align-items-center" data-${this.controllerName}-target="bodyEdit">
                    <textarea rows="10" autofocus data-${this.controllerName}-target="bodyEditInput" class="form-control p-1 f-1p25" type="text" required></textarea>
                  </div>
                  <div class="col-10 offset-1 text-center px-2 my-5">
                    <div class="card my-3 w-100 h-100 bg-primary py-3" data-${this.controllerName}-target="saveTicketBtn" data-action="click->${this.controllerName}#saveTicket">
                      <h5 class="my-auto f-1p25 f-bold">Salvar Pendência</h5>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.bodyTarget.innerHTML = html)
    }).then(() => {
      // if (this.current_summary.statement == `to_pay`) {
      //   controller.paymentFormTarget.classList.remove("d-none")
      // } else {
      //   controller.bankDataTarget.classList.remove("d-none")
      //   controller.approvedDraftCardTarget.classList.remove("d-none")
      // }
    })
  }

  saveTicket() {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal({ message: `Em processamento, aguarde!` })
    this.actionMode = `new`
    this.saveTicketBtnTarget.classList.add("d-none")
    this.send_data = { current_user: {}, ticket: {}, tracker: { manual: true } }

    this.send_data.ticket.board_id = this.application.tax_filing.id
    this.send_data.ticket.board_type = `operation_products`
    this.send_data.ticket.board_path = window.location.pathname
    this.send_data.ticket.board_name = `${this.application.current_tax_filing_calculation.name} ${this.application.tax_filing.account_name}`
    this.send_data.ticket.date_id = this.application.current_date.id
    this.send_data.ticket.date_type = `operation_dates`
    this.send_data.ticket.owner_id = this.application.current_user.account_id
    this.send_data.ticket.owner_name = `${this.application.current_user.name.split(" ").shift()} ${this.application.current_user.name.split(" ").pop()}`
    this.send_data.ticket.performer_id = this.application.current_agent.account_id
    this.send_data.ticket.performer_name = this.application.current_agent.account_name
    this.send_data.ticket.stage = `in_process`
    this.send_data.ticket.status = `in_time`
    this.send_data.ticket.sharing = `external`
    this.send_data.ticket.term = `quick`
    this.send_data.ticket.priority = `maximum`
    this.send_data.ticket.body = this.bodyEditInputTarget.value
    this.send_data.ticket.due_at = new Date()
    this.send_data.ticket.started_at = new Date()
    this.send_data.ticket.flag = `blue`
    this.send_data.ticket.kind = `task`

    this.send_data.tracker.record_id = this.application.tax_filing.id
    this.send_data.tracker.record_type = `operation_products`
    this.send_data.tracker.obj_id = this.application.current_date.id
    this.send_data.tracker.obj_type = `operation_dates`
    this.send_data.tracker.action = `created`
    this.send_data.tracker.observations = `Adicionou Ticket ${this.bodyEditInputTarget.value}`

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = this.application.ticket_permission

    this.requestSaveTicket()
  }

  requestSaveTicket() {
    var url = `/users/works/activities/tickets/create`
    var method = "POST"

    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          var ticket = response.data.cln
          controller.application.tickets[controller.application.tickets.length] = ticket
          controller.sendTicketEmailNotification(ticket.id)
        }

        controller.goBackDraft()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  sendTicketEmailNotification(ticket_id) {
    this.send_data = { current_user: {}, notification: {} }

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = this.application.ticket_permission

    this.send_data.notification.ticket_id = ticket_id
    this.send_data.notification.product_id = this.application.tax_filing.id
    this.send_data.notification.product_token = this.application.tax_filing.token
    this.send_data.notification.product_name = this.application.tax_filing.product_name
    this.send_data.notification.receiver_id = this.application.current_agent.account_id
    this.send_data.notification.receiver_name = this.application.current_agent.account_name
    this.send_data.notification.receiver_kind = `team`
    this.send_data.notification.sender_id = this.application.current_user.account_id
    this.send_data.notification.sender_name = `${this.getControllerByIdentifier("app--visits--users").getFullNameTratment(this.application.current_user)}`
    this.send_data.notification.action = `adicionou a seguinte pendência no IRPF ${this.application.current_date.financial_year}`
    this.send_data.notification.body = this.bodyEditInputTarget.value

    var url = "/users/works/activities/tickets/send_email_notification"
    var method = "POST"

    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0
      controller.validGroupTargets.forEach(element => {
        if (this.fromStringToBoolean(element.dataset.valid) == false) {
          len += 1
        }
      });
      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  goToURL(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_blank`)
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    });
    this.getControllerByIdentifier("app--helpers--console").console(results)
    return results
  }

  // var controller = this
  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
