import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "content", "mainCard", "cardBody", "footerTable", "checkboxProcess", "cardTitle",
    "formCard", "formCardBody", "labelAgreementUpload", "fileAgreementName", "labelSummaryUpload",
    "fileSummaryName", "fileUpload", "progressUpload", "progressUploadBar",
    "progressUploadCounter", "errorMessage", "formTitle", "saveBtn",
    "hasContractRow", "uploadContractRow", "hasSummaryRow", "uploadSummaryRow"]

  connect() {
    this.controllerName = `financials--books--clients--contracts--upload`
    this.upload_files = []
    this.filesPermittedTypes = ["pdf", "png", "jpeg", "DEC", "REC", ".DEC", ".REC", "PDF", "PNG", "JPEG"]
    this.uploadReady = false
  }

  cancelSave() {
    this.stopRefreshing()

    if (this.controllerDashboard.context.identifier == `financials--books--clients--contracts--upload-modal`) {
      this.getControllerByIdentifier(`financials--books--clients--contracts--upload-modal`).close()
    }
  }

  editContract() {
    this.hasContractRowTarget.classList.add(`d-none`)
    this.uploadContractRowTarget.classList.remove(`d-none`)
  }

  editSummary() {
    this.hasSummaryRowTarget.classList.add(`d-none`)
    this.uploadSummaryRowTarget.classList.remove(`d-none`)
  }

  saveUpload() {
    this.progressCount(0)
    this.requestUpload(this.upload_files)
  }

  requestUpload(files) {
    var i = 1
    this.requests = []
    files.forEach(element => {
      var formData = new FormData()
      formData.append('file', element.file)
      formData.append('field', element.field)
      formData.append('contract_id', this.current_contract.id)
      formData.append('current_user_id', this.application.current_user.id)
      formData.append('feature', this.permission)

      formData.append('tracker_manual', true)
      formData.append('tracker_action', `uploaded`)
      formData.append('tracker_record_id', this.current_contract.client_id)
      formData.append('tracker_record_type', `financial_client_entities`)
      formData.append('tracker_obj_id', this.current_contract.id)
      formData.append('tracker_obj_type', `financial_client_contracts`)
      formData.append('tracker_observations', `Upload do Arquivo ${element.field_pretty} do Contrato ${this.current_contract.name}`)

      var init = { method: "PUT", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token }, body: formData }
      this.requests[this.requests.length] = init
    })

    this.requestNext()
  }

  requestNext() {
    var url = "/financials/books/clients/contracts/save_upload"
    var controller = this

    if (this.requests.length) {
      fetch(url, this.requests.shift())
        .then(response => response.json())
        .then(response => {
          if (response.save) {
            var contract = response.data.cln

            controller.application.contracts.forEach(element => {
              if (element.id == contract.id) {
                controller.application.contracts.splice(controller.application.contracts.indexOf(element), 1, contract)
              }
            })

            if (controller.controllerDashboard.context.identifier == `financials--books--clients--contracts--upload-modal`) {

              if (controller.getControllerByIdentifier(`financials--books--clients--addons--list`)) {

                controller.getControllerByIdentifier(`financials--books--clients--addons--list`).current_contract = contract
                controller.getControllerByIdentifier(`financials--books--clients--addons--list`).doAddonsList()

              }
              
            }

          }

          controller.requestNext(contract)
          controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
        })
        .catch(error => {
          controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
        })
    } else {
      this.progressCount(100)
      this.stopRefreshing()

      var response = { type: `success`, message: `Upload finalizado com sucesso!` }
      this.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      this.cancelSave()
    }
  }

  doUploadHtml() {
    this.stopRefreshing()

    if (this.application.permissions[this.permission].can_upload) {
      var saveBtnHtml = `<button disabled type="button" class="btn btn-primary btn-sm py-2 btn-table f-065" data-action="click->${this.controllerName}#saveUpload" data-${this.controllerName}-target="saveBtn">Salvar</button>`
    } else {
      var saveBtnHtml = ``
    }

    var uploadTitle = ``
    uploadTitle = `Upload Contrato e Sumário`

    var html = `<div class="row">
                  <div class="col-12 px-0">
                    <div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="formCard" data-action="resize@window->${this.controllerName}#layout">
                      <div class="card-header d-flex align-items-center card-header-table-list f-065">
                        <h6 class="card-title display-4 py-3" style="font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="formTitle"></h6>
                      </div>
                      <div class="card-body py-0 px-2" style="overflow:auto;" data-${this.controllerName}-target="formCardBody">
                        <div class="row w-100 d-flex align-items-center my-3">
                          <div class="col-12 text-center">
                            <h6>${uploadTitle}</h6>
                          </div>
                        </div>
                        <div class="row d-flex align-items-center rowFile mb-3" data-${this.controllerName}-target="hasContractRow">
                          <div class="col-12 d-flex">
                            <span class="f-085 text-bold mr-auto">Contrato</span>
                            <button data-action="click->${this.controllerName}#goToURL" data-url="${this.current_contract.agreement_url}" type="button" class="btn btn-sm btn-table p-0 mc-tooltip ml-auto">
                              <span class="material-icons md-sm md-dark">launch</span>
                              <span class="mc-tooltiptext">Ver Contrato</span>
                            </button>
                            <button data-action="click->${this.controllerName}#editContract" type="button" class="btn btn-sm btn-table p-0 mc-tooltip ml-2">
                              <span class="material-icons md-sm md-dark">edit</span>
                              <span class="mc-tooltiptext">Editar Contrato</span>
                            </button>
                          </div>
                        </div>

                        <div class="row d-flex align-items-center rowFile mb-3" data-field="agreement" data-field-pretty="Contrato" data-${this.controllerName}-target="uploadContractRow">
                          <div class="col-6 offset-3">
                            <span class="f-065">Contrato</span>
                            <label class="drop-area copy" for="agreementDropzoneArea" data-${this.controllerName}-target="labelAgreementUpload" data-action="dragover->${this.controllerName}#dragOverHandler drop->${this.controllerName}#dropHandler">
                              <div class="row my-2">
                                <div class="col p-0 d-flex align-items-center justify-content-center">
                                  <span class="material-icons md-300 md-dark mx-3">add_a_photo</span>
                                </div>
                              </div>
                            </label>
                            <input class="file-input mt-0" type="file" id="agreementDropzoneArea" data-action="change->${this.controllerName}#dropHandler"></input>
                          </div>
                          <div class="col-12">
                            <span class="f-065 f-bold" data-${this.controllerName}-target="fileAgreementName"></span>
                          </div>
                        </div>

                        <div class="row d-flex align-items-center rowFile mb-3" data-${this.controllerName}-target="hasSummaryRow">
                          <div class="col-12 d-flex">
                            <span class="f-085 text-bold mr-auto">Sumário</span>
                            <button data-action="click->${this.controllerName}#goToURL" data-url="${this.current_contract.summary_url}" type="button" class="btn btn-sm btn-table p-0 mc-tooltip ml-auto">
                              <span class="material-icons md-sm md-dark">launch</span>
                              <span class="mc-tooltiptext">Ver Sumário</span>
                            </button>
                            <button data-action="click->${this.controllerName}#editSummary" type="button" class="btn btn-sm btn-table p-0 mc-tooltip ml-2">
                              <span class="material-icons md-sm md-dark">edit</span>
                              <span class="mc-tooltiptext">Editar Sumário</span>
                            </button>
                          </div>
                        </div>

                        <div class="row d-flex align-items-center rowFile mb-3" data-field="summary" data-field-pretty="Sumário" data-${this.controllerName}-target="uploadSummaryRow">
                          <div class="col-6 offset-3">
                          <span class="f-065">Sumário</span>
                            <label class="drop-area copy" for="summaryDropzoneArea" data-${this.controllerName}-target="labelSummaryUpload" data-action="dragover->${this.controllerName}#dragOverHandler drop->${this.controllerName}#dropHandler">
                              <div class="row my-2">
                                <div class="col p-0 d-flex align-items-center justify-content-center">
                                  <span class="material-icons md-300 md-dark mx-3">add_a_photo</span>
                                </div>
                              </div>
                            </label>
                            <input class="file-input mt-0" type="file" id="summaryDropzoneArea" data-action="change->${this.controllerName}#dropHandler"></input>
                          </div>
                          <div class="col-12">
                            <span class="f-065 f-bold" data-${this.controllerName}-target="fileSummaryName"></span>
                          </div>
                        </div>

                        <div class="row w-100 d-flex align-items-end d-none" data-${this.controllerName}-target="fileUpload">
                          <div class="col-12">
                            <div class="form-group my-0 text-center w-100">
                              <h7 class="progress">
                                <span class="progress_count"></span>
                              </h7>
                              <div class="progress" style="height: 6px;overflow: inherit;" data-${this.controllerName}-target="progressUpload">
                                <div class="progress-bar" role="progressbar" style="width:0%;border-bottom:0.5rem solid #053B5E;" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100" data-${this.controllerName}-target="progressUploadBar"></div>
                              </div>
                              <span data-${this.controllerName}-target="progressUploadCounter" class="d-block"></span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="card-footer border-top text-right py-1">
                        <span class="mr-auto text-left f-danger my-auto" data-${this.controllerName}-target="errorMessage"></span>
                        <button type="button" class="btn btn-secondary btn-sm py-2 btn-table f-065" data-dismiss="modal" data-action="click->${this.controllerName}#cancelSave">Fechar</button>
                        ${saveBtnHtml}
                      </div>
                    </div>
                  </div>
                </div>`


    var controller = this
    new Promise(function (resolve) {
      resolve(controller.gridElement.innerHTML = html)
    }).then(() => {
      if (controller.getControllerByIdentifier(`financials--books--receivables--conciliations--modal--contracts`)) {
        controller.formCardTarget.classList.add(`mt-4`)
      } else {
        controller.formCardTarget.style.height = ($(window).height() * 0.65) + "px"
      }

      controller.formTitleTarget.innerText = `Upload`

      if (controller.current_contract.has_agreement) {
        controller.hasContractRowTarget.classList.remove(`d-none`)
        controller.uploadContractRowTarget.classList.add(`d-none`)
      } else {
        controller.hasContractRowTarget.classList.add(`d-none`)
        controller.uploadContractRowTarget.classList.remove(`d-none`)
      }

      if (controller.current_contract.has_summary) {
        controller.hasSummaryRowTarget.classList.remove(`d-none`)
        controller.uploadSummaryRowTarget.classList.add(`d-none`)
      } else {
        controller.hasSummaryRowTarget.classList.add(`d-none`)
        controller.uploadSummaryRowTarget.classList.remove(`d-none`)
      }

      controller.refreshSaveBtn()
      controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
    })
  }

  dragOverHandler(ev) {
    ev.preventDefault();
  }

  dropHandler(ev) {
    ev.preventDefault();

    if (ev.target.files.length > 0) {
      if (ev.type == "change") {
        this.file = ev.target.files[0]

        var element = this.file
        var obj = {}
        var date = new Date()
        obj.file_name = element.name
        obj.file_size = element.size
        obj.file_type = element.type
        obj.file_last_modified = element.lastModified
        obj.field = ev.currentTarget.closest(".rowFile").dataset.field
        obj.field_pretty = ev.currentTarget.closest(".rowFile").dataset.fieldPretty
        obj.uploaded = false
        obj.message_erro = ""
        obj.created_at_time = parseInt(date.getTime() / 1000)
        if ((element.size / 1000000).toFixed(2) <= 5 && (this.filesPermittedTypes.includes(element.type.split("/")[1]) || this.filesPermittedTypes.includes(element.name.substr(element.name.lastIndexOf('.') + 1)))) {
          obj.can_upload = true
          obj.file = element
          this.uploadReady = true
          this.fileUploadTarget.classList.remove("d-none")
          if (obj.field == `agreement`) {
            this.fileAgreementNameTarget.innerText = obj.file_name
          } else if (obj.field == `summary`) {
            this.fileSummaryNameTarget.innerText = obj.file_name
          }

          // this.upload_files[this.upload_files.length] = obj
          this.upload_files = [obj]
        } else {
          if ((element.size / 1000000).toFixed(2) > 5) {
            obj.message_erro += "Arquivo deve ser menor que 5MB.\n"
          }

          if (!this.filesPermittedTypes.includes(element.type.split("/")[1])) {
            obj.message_erro += "Formato do arquivo não é permitido."
          }
          obj.can_upload = false
          this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", obj.message_erro, 5000)
        }
      }
    } else {
      this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", `O Upload de Arquivos está desabilitado`, 3000)
    }
  }

  progressCount(value) {
    var controller = this
    this.progressUploadBarTarget.style.width = value + `%`
    var i = 0
    if (value != 100) {
      this.progressTimer = setInterval(function () {
        controller.progressUploadBarTarget.style.width = Math.floor(i + 1) + `%`
        controller.progressUploadCounterTarget.innerText = Math.floor(i + 1) + `%`
        i++
        if (i == 95) {
          i = 94
        }
      }, 500);
    } else {
      this.stopRefreshing()
      this.progressUploadCounterTarget.innerText = value + `%`
    }
  }

  goToURL(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_blank`)
  }

  refreshSaveBtn() {
    var controller = this
    if (this.hasSaveBtnTarget) {
      this.refreshTimer = setInterval(function () {
        var len = 0

        if (controller.fileAgreementNameTarget.innerText == `` && controller.fileSummaryNameTarget.innerText == ``) {
          len += 1
        }

        if (controller.uploadReady == false) {
          len += 1
        }

        if (len == 0) {
          controller.saveBtnTarget.disabled = false
        } else {
          controller.saveBtnTarget.disabled = true
        }
      }, 200);
    }
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }

    if (this.progressTimer) {
      clearInterval(this.progressTimer)
    }
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
