import { Controller } from "stimulus"
import createChannel from "cables/cable";

export default class extends Controller {
  static targets = ["main", "2222", "3333", "4444", "5555", "6666", "7777", "8888", "9999"]

  connect() {
    this.application.tax_return_socket = new WebSocket(this.mainTarget.dataset.webSocketUrl)
  }

  broadcastUpdateChannel() {
    let controller = this;
    this.taxReturnUpdateChannel = createChannel({ channel: 'Operations::Products::TaxReturnChannel', id: this.update_room.id }, {
      connected() {
        controller.updateListen()
        controller.getControllerByIdentifier("app--navigation--desktop--navbar").connectionOn()
      },
      received(data) {
        if (data.kind == "tax_return_calculation") {
          controller.application.calculations.forEach(element => {
            if (element.id == data.cln.id) {
              controller.application.calculations.splice(controller.application.calculations.indexOf(element), 1, data.cln)
            }
          })  
        } else if (data.kind == "tax_return_achievement") {
          controller.application.achievements.forEach(element => {
            if (element.id == data.cln.id) {
              controller.application.achievements.splice(controller.application.achievements.indexOf(element), 1, data.cln)
            }
          })
        }
        
        if (controller.getControllerByIdentifier("operations--products--tax-returns--entities--dashboard").hasMainDashboardTarget) {
          controller.getControllerByIdentifier("operations--products--tax-returns--entities--dashboard").reconnect()
        }
        if (controller.getControllerByIdentifier("operations--products--tax-returns--achievements--dashboard").hasMainDashboardTarget) {
          controller.getControllerByIdentifier("operations--products--tax-returns--achievements--resume").doAchievementsHtml()
        }
      },
      disconnected() {
        controller.getControllerByIdentifier("app--navigation--desktop--navbar").connectionOff()
      }
    });
  }

  broadcastChatChannel() {
    var controller = this;
    this.taxReturnChatChannel = createChannel({ channel: 'RoomChannel', id: this.chat_room.id, user_id: this.application.current_user.id }, {
      connected() {
        controller.chatListen()
        controller.getControllerByIdentifier("app--navigation--desktop--navbar").connectionOn()
      },
      received(data) {
        controller.application.messages[controller.application.messages.length] = data
        if (controller.getControllerByIdentifier("operations--products--tax-filings--chat--dashboard").hasMainDashboardTarget) {
          controller.getControllerByIdentifier("operations--products--tax-filings--chat--room").doMessageTable()
        }
        this.appear()
      },
      disconnected() {
        controller.getControllerByIdentifier("app--navigation--desktop--navbar").connectionOff()
      },
      // appear() {
      //   this.perform('appear')
      // },
    });
  }

  getRoom() {
    var data = { product: { id: this.application.tax_return.id, name: this.application.tax_return.name }, current_user: { current_user_id: this.application.current_user.id } }
    const token = $('meta[name=csrf-token]').attr('content');
    const url = "/operations/products/entities/get_room"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.rooms[controller.application.rooms.length] = controller.identifier
          controller.update_room = response.data.cln.update
          controller.chat_room = response.data.cln.chat
          controller.application.chat_room = response.data.cln.chat
          controller.broadcastUpdateChannel()
          controller.broadcastChatChannel()
        } else {
          controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
        }
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  updateListen() {
    if (this.taxReturnUpdateChannel) {
      this.taxReturnUpdateChannel.perform('follow', { id: this.update_room.id })
    }
  }

  chatListen() {
    if (this.taxReturnChatChannel) {
      this.taxReturnChatChannel.perform('follow', { id: this.chat_room.id })
    }
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)

    // new Promise(function (resolve) {
    //   resolve()
    // }).then(() => {
    // }) 
  }

}
