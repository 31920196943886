import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "content", "clinicName", "clinicDashboard", "subTitle", "dates"]

  connect() {
    this.controllerName = `operations--products--clinics--managements--show--main`
    this.account_kind = this.application.current_user.account_kind
    this.application.clinic_token = location.pathname.split("/").pop()
    this.application.current_date = {}
    this.application.permissions = {}
    this.application.domain = {}
    this.application.clinic = {}

    this.application.regimes = []
    this.application.main_apurations = []
    this.application.partners = []
    this.application.certificates = []
    this.application.helpers = []
    this.application.takers = []
    this.application.tickets = []
    this.application.balances = []
    this.application.main_apurations = []
    this.application.expenses = []
    this.application.emails = []
    this.application.helpers = []
    this.application.integration_companies = []
    this.application.transactions = []
    this.application.agents = []
    this.application.logs = []
    this.application.emails = []


    this.doClinicGrid()

  }

  doClinicGrid() {
    var html = `<div class="row">
                  <div class="col-12 px-0" data-controller="operations--products--clinics--managements--show--dashboard
                                                            operations--products--clinics--managements--apurations--dashboard
                                                            operations--products--clinics--managements--helpers--dashboard
                                                            operations--products--clinics--managements--integrations--dashboard
                                                            operations--products--clinics--managements--dates--dashboard
                                                            operations--products--clinics--managements--balances--dashboard
                                                            operations--products--clinics--managements--transactions--dashboard
                                                            operations--products--clinics--managements--regimes--dashboard
                                                            operations--products--clinics--managements--agents--dashboard
                                                            operations--products--clinics--managements--tickets--dashboard
                                                            operations--products--clinics--managements--notes--dashboard
                                                            operations--products--clinics--managements--show--logs
                                                            
                                                            operations--products--clinics--regularizations--show--check-invoice
                                                            app--stats--trackers--emails--dashboard
                                                            app--stats--trackers--logs--dashboard" data-${this.controllerName}-target="content"></div>
                </div>`

    var controller = this
    this.mainTarget.style.height = ""
    new Promise(function (resolve) {
      resolve(controller.mainTarget.innerHTML = html)
    }).then(() => {
      controller.getControllerByIdentifier(`operations--products--clinics--managements--show--dashboard`).doClinicDashboardPreloader()
      controller.getCurrentUserPermissions()
    })
  }

  finishLoadPage() {

    if (this.getControllerByIdentifier(`app--navigation--desktop--breadcrumb`).hasBreadcrumbNameTarget) {
      this.getControllerByIdentifier(`app--navigation--desktop--breadcrumb`).breadcrumbNameTarget.innerText = this.application.clinic.company_name
    } else {
      setTimeout(() => {
        this.getControllerByIdentifier(`app--navigation--desktop--breadcrumb`).breadcrumbNameTarget.innerText = this.application.clinic.company_name
      }, 500);
    }

    this.getControllerByIdentifier("operations--products--clinics--managements--show--dates").setCurrents()
    if (this.reload) {
      this.getControllerByIdentifier("operations--products--clinics--managements--show--dates").runDashboardsHtml()
      this.getControllerByIdentifier("operations--products--clinics--managements--show--submenu").doBadgeCounts()
    } else {
      this.getControllerByIdentifier("operations--products--clinics--managements--show--dashboard").doClinicDashboard()
      this.getControllerByIdentifier("operations--products--clinics--managements--show--submenu").doSubmenuHtml()
      this.getControllerByIdentifier("operations--products--clinics--managements--show--dates").doDatesHTML()
    }
    this.application.current_date.data_loaded = true

    this.stopRefreshing()
    this.load = true
    var response = { type: `success`, message: `Página carregada com sucesso!` }
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).closeLoadModal()
    this.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
  }

  getLogs() {
    this.pageLoadMessage = `Carregando Logs ${this.application.current_date.name}`
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: this.pageLoadMessage, value: 99 })

    var data = { log: { record_id: this.application.clinic.id, record_type: `operation_products`, obj_id: this.application.current_date.id, obj_type: `operation_dates` }, current_user: { current_user_id: this.application.current_user.id, feature: `medclinic_managements` } }
    const url = "/app/stats/trackers/logs/list_by_record_and_obj"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          response.data.cln.forEach(element => {
            controller.application.logs[controller.application.logs.length] = element
          })
        }

        controller.finishLoadPage()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getEmails() {
    this.pageLoadMessage = `Carregando Emails ${this.application.current_date.name}`
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: this.pageLoadMessage, value: 98 })

    var data = { tracker_email: { domain_id: this.application.clinic.id, domain_type: `operation_products`, date_id: this.application.current_date.id, date_type: `operation_dates` }, current_user: { current_user_id: this.application.current_user.id, feature: `medclinic_managements` } }
    const url = `/app/stats/trackers/emails/list_by_date`
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          response.data.cln.forEach(element => {
            controller.application.emails[controller.application.emails.length] = element
          })
        }

        controller.getLogs()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getExpenses() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Despesas ${this.application.current_date.name}`, value: 97 })

    var data = { expense: { clinic_id: this.application.clinic.id, date_id: this.application.current_date.id }, current_user: { current_user_id: this.application.current_user.id, feature: `medclinic_management_expenses` } }
    const url = `/operations/products/clinics/financials/expenses/list_by_date`
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this

    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          response.data.cln.forEach(element => {
            controller.application.expenses[controller.application.expenses.length] = element
          })
        }

        controller.getEmails()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getMainApurations() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Apurações ${this.application.current_date.name}`, value: 95 })

    const data = { apuration: { clinic_id: this.application.clinic.id, date_id: this.application.current_date.id }, current_user: { current_user_id: this.application.current_user.id, feature: `medclinic_management_main_apurations` } }
    const url = `/operations/products/clinics/financials/main_apurations/list_by_date`
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          response.data.cln.forEach(element => {
            controller.application.main_apurations[controller.application.main_apurations.length] = element
          })
        }

        controller.getExpenses()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getRegimes() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Regimes Tributários ${this.application.current_date.name}`, value: 90 })

    var data = { regime: { clinic_id: this.application.clinic.id, date_id: this.application.current_date.id }, current_user: { current_user_id: this.application.current_user.id, feature: `medclinic_management_regimes` } }
    const url = `/operations/products/clinics/financials/regimes/list_by_date`
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          response.data.cln.forEach(element => {
            controller.application.regimes[controller.application.regimes.length] = element
          })
          
          controller.application.regimes.forEach(element => {
            if (element.date_id == controller.application.current_date.id) {
              controller.application.current_regime = element
            }
          })
        }

        controller.getMainApurations()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getTransactions() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Transações`, value: 85 })

    var data = { transaction: { clinic_id: this.application.clinic.id, date_id: this.application.current_date.id }, current_user: { current_user_id: this.application.current_user.id, feature: `medclinic_management_transactions` } }
    const url = `/operations/products/clinics/financials/transactions/list_by_date`
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          var application_data = { date_id: response.data.cln.date_id, data: response.data.cln.collection }
          controller.application.transactions[controller.application.transactions.length] = application_data
        }

        controller.getRegimes()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getBalances() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Contas de Balanço`, value: 80 })

    var data = { balance: { clinic_id: this.application.clinic.id, date_id: this.application.current_date.id }, current_user: { current_user_id: this.application.current_user.id, feature: `medclinic_management_balances` } }
    const url = `/operations/products/clinics/financials/balances/list_by_date`
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          var application_data = { date_id: response.data.cln.date_id, data: response.data.cln.collection }
          controller.application.balances[controller.application.balances.length] = application_data
        }

        controller.getTransactions()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  loadDateData() {
    if (this.application.current_date.data_loaded == false) {
      if (!this.getControllerByIdentifier("app--helpers--page-load")) {
        this.getControllerByIdentifier(`app--helpers--pre-page-load`).openLoadModal({ submessage: `Carregando Informações da Competência ${this.application.current_date.name}` })
        // this.refreshLoadValue()
      }
      this.getBalances()
    } else {
      this.getControllerByIdentifier("operations--products--clinics--managements--show--dates").setCurrents()
      this.getControllerByIdentifier("operations--products--clinics--managements--show--dates").runDashboardsHtml()
    }
  }

  getNotes() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Anotações`, value: 75 })

    var data = { note: { domain_id: this.application.clinic.id, domain_type: `operation_products` }, current_user: { current_user_id: this.application.current_user.id, feature: `medclinic_management_notes` } }
    const url = "/users/accounts/notes/list_by_domain"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.notes = response.data.cln
        }

        controller.loadDateData()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getTickets() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Tickets`, value: 70 })

    var data = { ticket: { board_id: this.application.clinic.id, board_type: "operation_products" }, current_user: { current_user_id: this.application.current_user.id, feature: `medclinic_management_tickets` } }
    const url = "/users/works/activities/tickets/list_by_board"

    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.tickets = response.data.cln
        }

        controller.getNotes()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getAgents() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Agentes`, value: 65 })

    const data = { agent: { clinic_id: this.application.clinic.id }, current_user: { current_user_id: this.application.current_user.id, feature: `medclinic_management_agents` } }
    const url = "/operations/products/clinics/managements/agents/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.application.agents = response.data.cln

        controller.getTickets()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getClinicTeam() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Time de Operação`, value: 60 })

    const data = { current_user: { current_user_id: this.application.current_user.id, feature: `medclinic_managements` } }
    const url = "/operations/products/config/teams/list_clinic_team"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.application.team_list = response.data.cln

        controller.getAgents()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getIntegrationCompanies() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Integração PJ Médica`, value: 55 })

    var data = { company: { clinic_id: this.application.clinic.id }, current_user: { current_user_id: this.application.current_user.id, feature: `medclinic_management_integration_companies` } }
    const url = "/operations/products/clinics/integrations/accountings/companies/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this

    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.integration_companies = response.data.cln
        }

        controller.getClinicTeam()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getCertificates() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Certificado Digital`, value: 50 })

    var data = { certificate: { clinic_id: this.application.clinic.id }, current_user: { current_user_id: this.application.current_user.id, feature: `medclinic_management_integration_companies` } }
    const url = "/operations/products/clinics/regularizations/certificates/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this

    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.application.certificates = response.data.cln

        controller.getIntegrationCompanies()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }


  getHelpers() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Assistentes`, value: 30 })

    var data = { helper: { clinic_id: this.application.clinic.id }, current_user: { current_user_id: this.application.current_user.id, feature: `medclinic_management_helpers` } }
    const url = "/operations/products/clinics/managements/helpers/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this

    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.helpers = response.data.cln
        }

        controller.getCertificates()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getPartners() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Sócios`, value: 25 })

    var data = { partner: { clinic_id: this.application.clinic.id }, current_user: { current_user_id: this.application.current_user.id, feature: `medclinic_management_partners` } }
    const url = `/operations/products/clinics/regularizations/partners/list`
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.application.partners = response.data.cln
        controller.getHelpers()

        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  checkInvoice() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Check para Emissão de NF`, value: 20 })

    var data = { clinic: { clinic_id: this.application.clinic.id }, current_user: { current_user_id: this.application.current_user.id, feature: `medclinic_managements` } }
    const url = `/operations/products/clinics/managements/verifications/check_can_invoice`
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this

    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.check_invoice = response.data.cln
          controller.getPartners()
        }

        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getClinicDates() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Competências`, value: 15 })

    var data = { date: { clinic_id: this.application.clinic.id }, current_user: { current_user_id: this.application.current_user.id, feature: `medclinic_management_dates` } }
    const url = `/operations/products/clinics/managements/dates/list`
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.dates = response.data.cln

          if (controller.application.dates.length > 0) {
            controller.application.current_date = response.data.cln[0]
            controller.setClinicName()
            controller.doSubtitle()

            controller.checkInvoice()

            if (controller.application.current_date.open == false) {
              controller.application.current_date.open = false
              controller.application.current_date.not_defined = true
              controller.application.current_date.message = `A Competência está fechada`
            }
          } else {
            controller.getControllerByIdentifier(`app--helpers--pre-page-load`).closeLoadModal()
            var response = { type: `danger`, message: `Favor adicionar a Competência, no Submenu > Competências!` }
          }
        }

        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getRoom() {
    var controllerBroadcast = this.getControllerByIdentifier(`operations--products--clinics--managements--show--broadcast`)
    
    var data = { product: { id: this.application.clinic.id, name: `medclinic_managements` }, current_user: { current_user_id: this.application.current_user.id } }
    const url = "/operations/products/entities/get_room"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controllerBroadcast.application.rooms[controller.application.rooms.length] = controllerBroadcast.identifier
          controllerBroadcast.update_room = response.data.cln.update
          controllerBroadcast.chat_room = response.data.cln.chat
          controllerBroadcast.application.chat_room = response.data.cln.chat
          controllerBroadcast.broadcastUpdateChannel()
          controllerBroadcast.broadcastChatChannel()

          controller.getClinicDates()
        } else {
          controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
        }
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getClinic() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando PJ Médica`, value: 10 })

    var data = { product: { token: this.application.clinic_token }, current_user: { current_user_id: this.application.current_user.id, feature: `medclinic_managements` } }
    const url = `/operations/products/entities/read_show`
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.clinic = response.data.cln

          controller.getRoom()
        }
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getCurrentUserPermissions() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).openLoadModal({ submessage: `Carregando Permissões` })

    const data = { permissions: { subdomain: `operation_clinic_managements` }, current_user: { current_user_id: this.application.current_user.id, feature: `medclinic_management_permissions`, account_kind: this.application.current_user.account_kind } }
    const url = "/users/permissions/policies/list_by_domain"

    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          response.data.cln.forEach(permission => {
            controller.application.permissions[permission.name] = permission.data
          })

          controller.getClinic()
        } else {
          window.open(`/dashboard`, `_self`)
        }
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
        window.location.reload()
      })
  }

  setClinicName() {
    var html = `<strong>
                  <span class="mb-0 mc-tooltip grab">
                    <span data-controller="app--helpers--copy" data-copy="${this.application.clinic.company_name}" data-action="click->app--helpers--copy#copy">${this.application.clinic.company_name}</span>
                    <span class="mc-tooltiptext mc-tooltiptext-right">Clique para Copiar</span>
                  </span>
                </strong>
                <span> | CNPJ: </span>
                <strong>
                  <span class="mb-0 mc-tooltip grab">
                    <span data-controller="app--helpers--copy" data-copy="${this.application.clinic.company_cnpj}" data-action="click->app--helpers--copy#copy">${this.application.clinic.company_cnpj_pretty}</span>
                    <span class="mc-tooltiptext mc-tooltiptext-right">Clique para Copiar</span>
                  </span>
                </strong>`

    this.clinicNameTarget.innerHTML = html
  }

  doSubtitle() {
    this.subTitleTarget.innerText = `Integrações e Controle Financeiro da PJ | ${this.application.current_date.name}`
  }

  refreshLoadValue() {
    var controller = this
    var value = 10
    this.refreshLoad = setInterval(function () {
      controller.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Informações da Competência ${controller.application.current_date.name}`, value: value })
      value += 1
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshLoad) {
      clearInterval(this.refreshLoad)
    }
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    })
  }

  nameTarget(target) {
    return this.targets.find(target)
  }
}
