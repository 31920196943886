import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["legal", "individual", "view", "4444", "5555", "6666", "7777", "8888", "9999"]

  connect() {
    this.controllerName = `operations--products--tax-returns--sources--dashboard`
  }

  doSourcesDashboard() {
    var html = `<div class="row">
                  <div class="col-4 pr-1" data-${this.controllerName}-target="legal" data-controller="operations--products--tax-returns--sources--legal"></div>
                  <div class="col-4 px-1" data-${this.controllerName}-target="individual" data-controller="operations--products--tax-returns--sources--individual"></div>
                  <div class="col-4 pl-1" data-${this.controllerName}-target="view" data-controller="operations--products--tax-returns--sources--show operations--products--tax-returns--sources--save"></div>
                </div>`
    this.getControllerByIdentifier("operations--products--tax-returns--entities--show").contentTarget.innerHTML = html
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = "operations--products--tax-returns--sources--dashboard"
    this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
