import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "dateDropdown", "dateInput", "dateList", "dateDropdownBtn", "dates", "7777", "8888", "9999"]

  connect() {
    this.controllerName = `financials--books--apurations--regimes--dates`
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
  }

  changeDate(ev) {
    var id = ev.currentTarget.dataset.id
    this.setFinancialDate(id)
    this.toggleDateBtn(id)
  }

  setFinancialDate(id) {
    var findElement = false
    this.application.dates.forEach(element => {
      if (element.id == id) {
        findElement = true

        this.getControllerByIdentifier("financials--books--apurations--regimes--dashboard").current_date = element
      }
    });

    if (findElement == false) {
      this.application.current_date = { open: false, not_defined: true, message: `A Competência está fechada` }
    }

    this.runRegimeHtml()
  }

  runRegimeHtml() {
    this.getControllerByIdentifier("financials--books--apurations--regimes--dashboard").getRegimes()
  }

  doDatesHTML() {
    var datesHtml = ``
    var currentDates = []
    var today = new Date()

    this.application.dates.forEach(element => {
      datesHtml += `<button type="button" class="btn btn-flat-dark btn-no-border btn-sm w-100 px-1 mx-1 f-065" data-action="${this.controllerName}#changeDate" data-id="${element.id}" data-${this.controllerName}-target="btn-${element.id}">${element.date_pretty}</button>`
    });

    var html = `<div class="btn-group" role="group" style="box-shadow:none" data-${this.controllerName}-target="datesBtnGroup">
                  ${datesHtml}
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.controllerDash.datesTarget.innerHTML = html)
    }).then(() => {
      controller.nameTarget(`btn-${this.application.dates[0].id}`).classList.remove(`btn-flat-dark`)
      controller.nameTarget(`btn-${this.application.dates[0].id}`).classList.add(`btn-primary`)
      // controller.rollDates()
    })
  }

  toggleDateBtn(id) {
    this.application.dates.forEach(element => {
      if (element.id == id) {
        this.nameTarget(`btn-${element.id}`).classList.remove(`btn-flat-dark`)
        this.nameTarget(`btn-${element.id}`).classList.add(`btn-primary`)
      } else {
        this.nameTarget(`btn-${element.id}`).classList.add(`btn-flat-dark`)
        this.nameTarget(`btn-${element.id}`).classList.remove(`btn-primary`)

      }
    });
  }

  doDatesPreloader() {
    var html = `<div class="row mb-3 w-100">
                  <div class="col-2 px-1 offset-2">${this.loader}</div>
                  <div class="col-2 px-1">${this.loader}</div>
                  <div class="col-2 px-1">${this.loader}</div>
                  <div class="col-2 px-1">${this.loader}</div>
                </div>`

    this.mainTarget.innerHTML = html
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

}
