import { Controller } from "stimulus"
import createChannel from "cables/cable";

export default class extends Controller {
  static targets = ["main", "2222", "3333", "4444", "5555", "6666", "7777", "8888", "9999"]

  connect() {
    this.application.receivement_socket = new WebSocket(this.mainTarget.dataset.webSocketUrl)
  }

  broadcastChannel() {
    let controller = this;
    this.receivementChannel = createChannel({ channel: 'Operations::Products::ReceivementChannel', id: this.application.receivement_room.id }, {
      connected() {
        controller.listen()
      },
      received(data) {
        controller.application.calculations.forEach(element => {
          if (element.id == data.id) {
            controller.application.calculations.splice(controller.application.calculations.indexOf(element), 1, data)
          }
        });
        controller.getControllerByIdentifier("mobile--operations--products--receivements--entities--show").reconnect()
      }
    });
  }

  getRoom() {
    var data = { product: { id: this.application.receivement.id, name: this.application.receivement.name }, current_user: { current_user_id: currentUser.id } }
    const token = $('meta[name=csrf-token]').attr('content');
    const url = "/operations/products/entities/get_room"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.receivement_room = response.data.cln
          controller.broadcastChannel()
        } else {
          processingSnackbar(response.type, response.message, device)
        }
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  listen() {
    if (this.receivementChannel) {
      this.receivementChannel.perform('follow', { id: this.application.receivement_room.id })
    }
  }

  openSocket() {
    var controller = this
    this.application.receivement_socket.onopen = function (e) {
      var msg = {
            command: 'subscribe',
            identifier: JSON.stringify({
              id: controller.application.receivement_room.id,
              channel: 'Operations::Products::ReceivementChannel'
            }),
          };
      controller.application.receivement_socket.send(JSON.stringify(msg));
    }

    this.application.receivement_socket.onmessage = function (e) {
      var response = e.data;
      var msg = JSON.parse(response);

      if (msg.type === "ping") {
        return;
      }
    }
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)

    // new Promise(function (resolve) {
    //   resolve()
    // }).then(() => {
    // }) 
  }

}
