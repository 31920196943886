import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["mainCard", "bodyTable", "footerTable", "asdf", "pendencyCount", "sideBarReport",
    "refreshListBtn", "optionBtn", "searchTable"]

  connect() {
    this.controllerName = `financials--books--payrolls--remunerations--contracts`
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.cardLoader = this.getControllerByIdentifier("app--helpers--loaders").cardLoader()
    this.controllerNumber = this.getControllerByIdentifier(`app--helpers--numbers`)
    this.itemsOnPage = 300
    this.pageNumber = 0
    this.numberPages = []
    this.tableCols = 3
    this.sort = { mode: `asc`, field: `account_name` }
  }

  sortTable(ev) {
    var mode = ev.currentTarget.dataset.mode
    var field = ev.currentTarget.dataset.field

    if (mode == `desc`) {
      ev.currentTarget.nextElementSibling.classList.remove("d-none")
      ev.currentTarget.classList.add("d-none")
    } else if (mode == `asc`) {
      ev.currentTarget.previousElementSibling.classList.remove("d-none")
      ev.currentTarget.classList.add("d-none")
    }

    this.sort.mode = mode
    this.sort.field = field
    this.doDataTable()
  }

  doContractList() {
    var refreshLine = `<span data-action="click->${this.controllerName}#refreshContracts" class="dropdown-item py-1 pointer dropdown-submenu-item">Atualizar Contratos</span>`

    var submenuHtml = `<div class="dropdown mc-tooltip" data-${this.controllerName}-target="optionBtn">
                        <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-toggle="dropdown" id="submenuMenuDrop" type="button"><span class="material-icons md-dark">more_vert</span></button>
                        <div aria-labelledby="submenuMenuDrop" class="dropdown-menu dropdown-menu-right menu" style="font-size:80%">
                          ${refreshLine}
                        </div>
                        <span class="mc-tooltiptext">Opções</span>
                      </div>`

    var refreshBtnHtml = `<span class="mc-tooltip">
                            <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0 d-none" data-${this.controllerName}-target="refreshListBtn" data-action="click->${this.controllerName}#refreshList" type="button">
                              <span class="material-icons md-dark">clear</span>
                            </button>
                            <span class="mc-tooltiptext">Limpar</span>
                          </span>`

    var html = `<div class="row">
                  <div class="col-12 px-0">
                    <div class="card" style="" data-controller="app--helpers--search" data-${this.controllerName}-target="mainCard">
                      <div class="card-header d-flex align-items-center card-header-table-list f-065">
                        <h6 class="card-title f-065 mb-0 mr-auto">Contratos</h6>
                        <input class="form-control f-070 w-50 mx-auto my-2 py-2" data-app--helpers--search-target="searchInput" data-${this.controllerName}-target="searchInput" data-action="keyup->app--helpers--search#doSearchTable keyup->${this.controllerName}#enterShowRemunerations" placeholder="Buscar ..." type="text">
                        <div class="card-actions ml-auto py-0">
                          ${submenuHtml}
                          ${refreshBtnHtml}
                        </div>
                      </div>
                      <div class="card-body py-0" style="overflow:auto;">
                        <div class="row">
                          <div class="col p-0">
                            <table class="table table-sm table-hover" data-app--helpers--search-target="searchTable" data-${this.controllerName}-target="searchTable" style="font-size:80%;">
                              <thead>
                                <tr>
                                  <th style="font-size:80%;" scope="col" class="p-1 table-50 align-middle">
                                    Nome
                                    <span class="material-icons md-sm md-dark pointer" data-action="click->${this.controllerName}#sortTable" data-field="record_name" data-mode="desc">south</span>
                                    <span class="material-icons md-sm md-dark pointer d-none" data-action="click->${this.controllerName}#sortTable" data-field="record_name" data-mode="asc">north</span>
                                  </th>
                                  <th style="font-size:80%;" scope="col" class="pointer p-1 table-30 align-middle text-center">Contrato</th>
                                </tr>
                              </thead>
                              <tbody data-${this.controllerName}-target="bodyTable">
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div class="card-footer py-0" data-${this.controllerName}-target="footerTable">
                      </div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier(`financials--books--payrolls--remunerations--dashboard`).listTarget.innerHTML = html)
    }).then(() => {
      controller.mainCardTarget.style.height = ($(window).height() * 0.65) + "px"
      controller.bodyTableTarget.insertAdjacentHTML("beforeend", controller.getControllerByIdentifier("app--helpers--table").doTablePreloader(controller.tableCols, 10))

      controller.doDataTable()
    })
  }

  doDataTable() {
    this.current_contracts = []

    if (this.application.current_med.id) {
      this.application.contracts.forEach(element => {
        if (element.med_id == this.application.current_med.id && element.started_date_token <= this.application.current_date.date_token) {
          if (element.finished && element.closing_date_token >= this.application.current_date.date_token) {
            this.current_contracts[this.current_contracts.length] = element
          } else if (element.finished == false) {
            this.current_contracts[this.current_contracts.length] = element
          }
        }
      })
    } else {
      this.application.contracts.forEach(element => {
        if (element.started_date_token <= this.application.current_date.date_token) {
          if (element.finished && element.closing_date_token >= this.application.current_date.date_token) {
            this.current_contracts[this.current_contracts.length] = element
          } else if (element.finished == false) {
            this.current_contracts[this.current_contracts.length] = element
          }
        }
      })
    }

    // this.application.contracts.forEach(element => {
    //   if (element.started_date_token <= this.application.current_date.date_token) {
    //     if (element.finished && element.closing_date_token >= this.application.current_date.date_token) {
    //       this.current_contracts[this.current_contracts.length] = element
    //     } else if (element.finished == false) {
    //       this.current_contracts[this.current_contracts.length] = element
    //     }
    //   }
    // })

    this.orderDataTable()
  }

  orderDataTable() {
    if (this.sort.mode == `asc`) {
      var contracts = this.getControllerByIdentifier("app--helpers--data").sortByKeyAsc(this.current_contracts, this.sort.field)
    } else if (this.sort.mode == `desc`) {
      var contracts = this.getControllerByIdentifier("app--helpers--data").sortByKeyDesc(this.current_contracts, this.sort.field)
    }

    if (contracts.length == 0) {
      var pagination = { itemsOnPage: 0, fromItem: 0, toItem: 0, totalItems: 0 }
      this.listData()
      this.doFooterTable(pagination)
    } else {
      var itemsOnPage = this.itemsOnPage
      var pageNumber = this.pageNumber
      var numberPages = this.numberPages
      var pages = []
      var itemLoop = Math.ceil(contracts.length / itemsOnPage)
      var x = 0
      var i = 1
      for (i; i < itemLoop + 1; i++) {
        var internPage = []
        for (x; x < itemsOnPage * i; x++) {
          if (contracts[x] !== undefined) {
            internPage[internPage.length] = contracts[x]
          }
        }
        pages[pages.length] = internPage
        numberPages[numberPages.length] = i - 1
      }

      var fromItem = itemsOnPage * pageNumber + 1
      var toItem = Math.min(itemsOnPage * (pageNumber + 1), (itemsOnPage * pageNumber) + pages[pageNumber].length)
      var pagination = { itemsOnPage: itemsOnPage, fromItem: fromItem, toItem: toItem, totalItems: contracts.length }
      this.listData(pages[pageNumber])
      this.doFooterTable(pagination)
    }
  }

  listData(data) {
    this.bodyTableTarget.innerHTML = ""
    if (data == undefined || data == [] || data.length == 0) {
      var noData = `</tr>
                      <td colspan="${this.tableCols}" class="p-3 align-middle text-center" style="font-size:100%;">
                        <span class="fa-stack fa-1x">
                          <i class="fas fa-list fa-stack-1x"></i>
                        </span>
                        <span class="f-075 align-middle">Não há Contratos</span>
                      </td>
                    </tr>`

      this.bodyTableTarget.innerHTML = noData
    } else {
      data.forEach(element => {
        this.bodyTableTarget.insertAdjacentHTML("beforeend", this.contractTablePartial(element, data.length))
      });
    }
  }

  contractTablePartial(element, length) {
    if (length == 1) {
      var tableRow = `<tr class="itemRow" data-id="${element.id}" style="height:50px;">`
    } else {
      var tableRow = `<tr class="itemRow" data-id="${element.id}">`
    }

    // `<td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">
    //   <small class="mb-0 mc-tooltip grab">
    //     <span data-controller="app--helpers--copy" data-copy="${element.record_number}" data-action="click->app--helpers--copy#copy">${element.record_number_pretty}</span>
    //     <span class="mc-tooltiptext">Clique para Copiar</span>
    //   </small>
    // </td>`

    var rowHtml = `${tableRow}
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-left pointer" data-action="click->${this.controllerName}#showRemunerationsEv">${element.account_name}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center pointer" data-action="click->${this.controllerName}#showRemunerationsEv">${element.kind_pretty}</td>
                  </tr>`

    return rowHtml
  }

  itemsLength(ev) {
    this.itemsOnPage = ev.target.dataset.items
    this.pageNumber = 0
    this.numberPages = []
    this.doDataTable()
  }

  previousTablePage(ev) {
    if (this.numberPages.includes(this.pageNumber - 1)) {
      this.pageNumber -= 1
      this.doDataTable()
    }
  }

  nextTablePage(ev) {
    if (this.numberPages.includes(this.pageNumber + 1)) {
      this.pageNumber += 1
      this.doDataTable()
    }
  }

  doFooterTable(pagination) {
    var footerHtml = `<div class="row">
                        <div class="col-2 d-flex px-0">
                          <div class="card-actions align-items-center justify-content-end py-0 f-065">
                            <span data-app--helpers--search-target="footerTable"></span>
                          </div>
                        </div>
                        <div class="col-10 px-0">
                          <div class="card-actions align-items-center justify-content-end py-0 f-065">
                            <span class="align-self-center mb-1 mx-1 text-muted">Itens por Página:</span>
                            <div class="dropdown">
                              <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline dropdown-toggle f-065" data-toggle="dropdown" type="button">${this.controllerNumber.numberMask(pagination.itemsOnPage)}</button>
                              <div class="dropdown-menu dropdown-menu-right menu">
                                <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="300">300</span>
                                <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="1000">1.000</span>
                                <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="1500">1.500</span>
                              </div>
                            </div>
                            <span class="align-self-center mb-1 mr-2 text-muted">${this.controllerNumber.numberMask(pagination.fromItem)}-${this.controllerNumber.numberMask(pagination.toItem)} de ${this.controllerNumber.numberMask(pagination.totalItems)}</span>
                            <a class="btn btn-outline py-0" data-action="click->${this.controllerName}#previousTablePage"><i class="material-icons md-sm">chevron_left</i></a>
                            <a class="btn btn-outline py-0" data-action="click->${this.controllerName}#nextTablePage"><i class="material-icons md-sm">chevron_right</i></a>
                          </div>
                        </div>
                      </div>`

    this.footerTableTarget.innerHTML = footerHtml
  }

  enterShowRemunerations(ev) {
    if (ev.type == "keyup" && ev.key == "Enter") {

      var table = this.searchTableTarget
      var tr = table.getElementsByTagName("tr")
      var contractIds = []

      for (var i = 1; i < tr.length; i++) {
        if (tr[i].matches(`.filter-row`)) {
          contractIds[contractIds.length] = tr[i].dataset.id
        }
      }

      if (contractIds.length) {
        this.showRemunerations(contractIds[0])
      }
    }
  }

  showRemunerationsEv(ev) {
    var contractId = ev.currentTarget.closest(`.itemRow`).dataset.id

    this.showRemunerations(contractId)
  }


  showRemunerations(contractId) {
    this.optionBtnTarget.classList.add(`d-none`)
    this.refreshListBtnTarget.classList.remove(`d-none`)

    var current_contract = {}
    this.application.contracts.forEach(contract => {
      if (contract.id == contractId) {
        current_contract = contract
      }
    })

    this.getControllerByIdentifier("financials--books--payrolls--remunerations--list").current_contract = current_contract
    this.getControllerByIdentifier("financials--books--payrolls--remunerations--list").doRemunerationList()

    // this.getControllerByIdentifier("financials--books--payrolls--remunerations--resume").current_contract = current_contract
    // this.getControllerByIdentifier("financials--books--payrolls--remunerations--resume").doResumeHtml()

    this.current_contracts = [current_contract]
    this.orderDataTable()
  }

  refreshList() {
    this.optionBtnTarget.classList.remove(`d-none`)
    this.refreshListBtnTarget.classList.add(`d-none`)
    this.current_contracts = this.application.contracts
    this.doDataTable()

    this.getControllerByIdentifier("financials--books--payrolls--remunerations--dashboard").doRemunerationsDashboard()
  }

  refreshContracts() {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()

    const data = { contract: { active: true, status: `active` }, current_user: { current_user_id: this.application.current_user.id, feature: this.permission } }
    const url = "/financials/books/payrolls/contracts/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this

    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.contracts = response.data.cln
        }

        controller.refreshList()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

}
