import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["list", "view", "mainCard", "bodyTable", "footerTable", "checkboxShow", "opportunityLeadName", "8888", "9999"]

  connect() {
    this.controllerName = `financials--books--dashboards--channels--index`
    this.itemsOnPage = 100
    this.pageNumber = 0
    this.numberPages = []
    this.sort = { mode: `asc`, field: `name` }
    this.hasShow = false
  }

  showChannel(ev) {
    var controllerShow = this.getControllerByIdentifier("financials--books--dashboards--channels--show")
    var channelId = ev.target.closest(".itemRow").dataset.id
    
    this.application.channels.forEach(element => {
      if (element.id == channelId) {
        controllerShow.current_channel = element
      }
    })
    controllerShow.doChannelHtml()

  }

  addChannel() {
    if (this.application.meds.length == 0) {
      this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(`danger`, `Favor adicionar Empresas MedCapital`, 4000)
    } else {
      this.getControllerByIdentifier("financials--books--dashboards--channels--save").actionMode = "new"
      this.getControllerByIdentifier("financials--books--dashboards--channels--save").current_channel = {}
      this.getControllerByIdentifier("financials--books--dashboards--channels--save").doFormHtml()
    }
  }

  editChannel(ev) {
    var id = ev.currentTarget.closest(".itemRow").dataset.id
    this.application.channels.forEach(element => {
      if (element.id == id) {
        this.getControllerByIdentifier("financials--books--dashboards--channels--save").current_channel = element
      }
    })
    this.getControllerByIdentifier("financials--books--dashboards--channels--save").actionMode = "edit"
    this.getControllerByIdentifier("financials--books--dashboards--channels--save").doFormHtml()
  }

  sortTable(ev) {
    var mode = ev.currentTarget.dataset.mode
    var field = ev.currentTarget.dataset.field

    if (mode == `desc`) {
      ev.currentTarget.nextElementSibling.classList.remove("d-none")
      ev.currentTarget.classList.add("d-none")
    } else if (mode == `asc`) {
      ev.currentTarget.previousElementSibling.classList.remove("d-none")
      ev.currentTarget.classList.add("d-none")
    }

    this.sort.mode = mode
    this.sort.field = field
    this.doDataTable()
  }

  doIndexListHtml() {

    if (this.application.permissions.financial_settings_channels.can_create) {
      var addBtnHtml = `<span class="mc-tooltip">
                          <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-${this.controllerName}-target="addChannelBtn" data-action="click->${this.controllerName}#addChannel" type="button">
                            <span class="material-icons md-dark">add</span>
                          </button>
                          <span class="mc-tooltiptext">Adicionar Canal de Pagamento</span>
                        </span>`
    } else {
      var addBtnHtml = ``
    }

    var html = `<div class="card" style="width:100%;display:relative;" data-controller="app--helpers--search" data-${this.controllerName}-target="mainCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 card-title-table-list">Canais de Pagamento</h6>
                    <input class="form-control f-070 w-50 mx-auto" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchTable" placeholder="Buscar ..." type="text">
                    <div class="card-actions ml-auto py-0 mc-tooltip">
                      ${addBtnHtml}
                    </div>
                  </div>
                  <div class="card-body py-0" style="overflow:auto;">
                    <div class="row">
                      <div class="col p-0">
                        <table class="table table-sm table-hover" data-app--helpers--search-target="searchTable" style="font-size:80%;">
                          <thead>
                            <tr>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-35 align-middle">
                                Nome
                                <span class="material-icons md-sm md-dark" data-action="click->${this.controllerName}#sortTable" data-field="name" data-mode="desc">south</span>
                                <span class="material-icons md-sm md-dark d-none" data-action="click->${this.controllerName}#sortTable" data-field="name" data-mode="asc">north</span>
                              </th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-10 align-middle">
                                Tipo
                                <span class="material-icons md-sm md-dark" data-action="click->${this.controllerName}#sortTable" data-field="kind" data-mode="desc">south</span>
                                <span class="material-icons md-sm md-dark d-none" data-action="click->${this.controllerName}#sortTable" data-field="kind" data-mode="asc">north</span>
                              </th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-10 align-middle">
                                MedCapital
                                <span class="material-icons md-sm md-dark" data-action="click->${this.controllerName}#sortTable" data-field="name" data-mode="desc">south</span>
                                <span class="material-icons md-sm md-dark d-none" data-action="click->${this.controllerName}#sortTable" data-field="name" data-mode="asc">north</span>
                              </th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-35 align-middle">Conta</th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-5 align-middle">Status</th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-5 align-middle">Principal</th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-5 align-middle"></th>
                            </tr>
                          </thead>
                          <tbody data-${this.controllerName}-target="bodyTable">
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer py-0" data-${this.controllerName}-target="footerTable">
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("financials--books--dashboards--channels--dashboard").contentTarget.innerHTML = html)
    }).then(() => {
      controller.mainCardTarget.style.height = ($(window).height() * 0.65) + "px"
      controller.bodyTableTarget.insertAdjacentHTML("beforeend", controller.getControllerByIdentifier("app--helpers--table").doTablePreloader(7, 10))
      controller.doDataTable()
    })
  }

  doDataTable() {
    var current_channels = this.application.channels

    if (this.sort.mode == `asc`) {
      var channels = this.getControllerByIdentifier("app--helpers--data").sortByKeyAsc(current_channels, this.sort.field)
    } else if (this.sort.mode == `desc`) {
      var channels = this.getControllerByIdentifier("app--helpers--data").sortByKeyDesc(current_channels, this.sort.field)
    }

    if (channels.length == 0) {
      var pagination = { itemsOnPage: 0, fromItem: 0, toItem: 0, totalItems: 0 }
      this.listData()
      this.doFooterTable(pagination)
    } else {
      var itemsOnPage = this.itemsOnPage
      var pageNumber = this.pageNumber
      var numberPages = this.numberPages
      var pages = []
      var itemLoop = Math.ceil(channels.length / itemsOnPage)
      var x = 0
      var i = 1
      for (i; i < itemLoop + 1; i++) {
        var internPage = []
        for (x; x < itemsOnPage * i; x++) {
          if (channels[x] !== undefined) {
            internPage[internPage.length] = channels[x]
          }
        }
        pages[pages.length] = internPage
        numberPages[numberPages.length] = i - 1
      }

      var fromItem = itemsOnPage * pageNumber + 1
      var toItem = Math.min(itemsOnPage * (pageNumber + 1), (itemsOnPage * pageNumber) + pages[pageNumber].length)
      var pagination = { itemsOnPage: itemsOnPage, fromItem: fromItem, toItem: toItem, totalItems: channels.length }
      this.listData(pages[pageNumber])
      this.doFooterTable(pagination)
    }
  }

  listData(data) {
    this.bodyTableTarget.innerHTML = ""
    if (data == undefined || data == [] || data.length == 0) {
      var noData = `</tr>
                      <td colspan="8" class="p-3 align-middle text-center" style="font-size:100%;">
                        <span class="fa-stack fa-1x">
                          <i class="fas fa-list fa-stack-1x"></i>
                        </span>
                        <span class="f-075 align-middle">Não há Canais de Pagamento</span>
                      </td>
                    </tr>`

      this.bodyTableTarget.innerHTML = noData
    } else {
      data.forEach(element => {
        this.bodyTableTarget.insertAdjacentHTML("beforeend", this.channelTablePartial(element, data.length))
      });
    }

    if (this.checked_id) {
      this.checkboxShowTargets.forEach(element => {
        if (element.id == `check-${this.checked_id}`) {
          element.checked = true
        }
      })
    }
  }

  channelTablePartial(element, length) {

    if (this.application.permissions.financial_settings_channels.can_update) {
      var edit = `<button data-action="click->${this.controllerName}#editChannel" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                    <span class="material-icons md-sm md-dark">edit</span>
                    <span class="mc-tooltiptext">Editar</span>
                  </button>`
    } else {
      var edit = ``
    }

    if (element.subkind_boolean) {
      var subkind = `<span class="mc-tooltip">
                          <span class="material-icons md-200 md-success default">done</span>
                          <span class="mc-tooltiptext">${element.subkind_pretty}</span>
                        </span>`
    } else {
      var subkind = `<span class="mc-tooltip">
                          <span class="material-icons md-200 md-danger default">clear</span>
                          <span class="mc-tooltiptext">${element.subkind_pretty}</span>
                        </span>`
    }

    if (element.status) {
      var status = `<span class="mc-tooltip">
                          <span class="material-icons md-200 md-success default">done</span>
                          <span class="mc-tooltiptext">${element.status_pretty}</span>
                        </span>`
    } else {
      var status = `<span class="mc-tooltip">
                          <span class="material-icons md-200 md-danger default">clear</span>
                          <span class="mc-tooltiptext">${element.status_pretty}</span>
                        </span>`
    }

    if (length == 1) {
      var tableRow = `<tr class="itemRow" data-id="${element.id}" style="height:50px;">`
    } else {
      var tableRow = `<tr class="itemRow" data-id="${element.id}">`
    }

    var rowHtml = `${tableRow}
                    
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${element.name}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${element.kind_pretty}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${element.med_name}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${element.chart_name}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${status}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${subkind}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${edit}</td>
                  </tr>`

    return rowHtml
  }

  itemsLength(ev) {
    this.itemsOnPage = ev.target.dataset.items
    this.pageNumber = 0
    this.numberPages = []
    this.doDataTable()
  }

  previousTablePage(ev) {
    if (this.numberPages.includes(this.pageNumber - 1)) {
      this.pageNumber -= 1
      this.doDataTable()
    }
  }

  nextTablePage(ev) {
    if (this.numberPages.includes(this.pageNumber + 1)) {
      this.pageNumber += 1
      this.doDataTable()
    }
  }

  doFooterTable(pagination) {
    var footerHtml = `<div class="card-actions align-items-center justify-content-end py-0 f-065">
                        <span class="align-self-center mb-1 mx-1 text-muted">Itens por Página:</span>
                        <div class="dropdown">
                          <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline dropdown-toggle f-065" data-toggle="dropdown" type="button">${pagination.itemsOnPage}</button>
                          <div class="dropdown-menu dropdown-menu-right menu">
                            <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="100">100</span>
                            <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="250">250</span>
                            <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="500">500</span>
                          </div>
                        </div>
                        <span class="align-self-center mb-1 mr-2 text-muted">${pagination.fromItem}-${pagination.toItem} de ${pagination.totalItems}</span>
                        <a class="btn btn-outline py-0" data-action="click->${this.controllerName}#previousTablePage"><i class="material-icons md-sm">chevron_left</i></a>
                        <a class="btn btn-outline py-0" data-action="click->${this.controllerName}#nextTablePage"><i class="material-icons md-sm">chevron_right</i></a>
                      </div>`

    this.footerTableTarget.innerHTML = footerHtml
  }

  cleanCheckBox() {
    this.checkboxShowTargets.forEach(element => {
      element.checked = false
    });
    this.checked_id = ``
  }

  userCanUpdate(ticketId) {
    var ticket = {}
    this.application.in_process_tickets.forEach(element => {
      if (element.id == ticketId) {
        ticket = element
      }
    })
    var allowed = [ticket.owner_id, ticket.performer_id]
    if (allowed.includes(this.application.current_user.account_id) && this.stage != "done") {
      return true
    } else {
      return false
    }
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0
      controller.validGroupTargets.forEach(element => {
        if (this.fromStringToBoolean(element.dataset.valid) == false) {
          len += 1
        }
      });
      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  goToURL(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_self`)
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    });
    this.getControllerByIdentifier("app--helpers--console").console(results)
    return results
  }

  // var controller = this
  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
