import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["modal", "dueAtInput", "paidDateInput", "channelDropdown", "channelDropdownBtn", "channelInput",
                    "channelList", "saveBtn", "totalAmountInput", "descriptionInput", "principalAmountInput", "interestsAmountInput",
                    "methodType", "methodLine", "finesAmountInput", "chartAccountInput", "providerLine", "body"]

  connect() {
    this.controllerName = `financials--books--payables--entities--paid-modal`
    this.controllerNumber = this.getControllerByIdentifier(`app--helpers--numbers`)
    // this.open()
  }

  open(payableId) {
    this.refreshSaveBtn()
    document.body.classList.add("modal-open");
    this.element.setAttribute("style", "display: block;");
    this.element.classList.add("show");
    const html = `<div class="modal-backdrop fade show"></div>`
    // document.body.insertAdjacentHTML("beforeend", html)
    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.doHtml(payableId)
    })
    
  }

  doHtml(payableId) {
    var html = `<div class="row my-2 d-flex align-items-center">
                  <div class="col-12 f-065 f-bold">
                    Descrição
                  </div>
                  <div class="col-12" data-${this.controllerName}-target="descriptionInput">
                  </div>
                </div>
                <div class="row my-2 d-flex align-items-center">
                  <div class="col-12 f-065 f-bold">
                    Plano de Contas
                  </div>
                  <div class="col-12" data-${this.controllerName}-target="chartAccountInput">
                  </div>
                </div>
                <div class="row my-2 d-flex align-items-center">
                  <div class="col-12 f-065 f-bold" data-${this.controllerName}-target="methodType">
                    Método
                  </div>
                  <div class="col-12" data-${this.controllerName}-target="methodLine">
                  </div>
                </div>
                <div class="row my-2 d-flex align-items-center">
                  <div class="col-12 f-065 f-bold">
                    Fornecedor
                  </div>
                  <div class="col-12" data-${this.controllerName}-target="providerLine">
                  </div>
                </div>
                <div class="row my-2 mt-3">
                  <div class="col-3">
                    <div class="form-group">
                      <div class="floating-label floating-label-sm">
                        <label for="principalAmountForm">Vlr Principal</label>
                        <input disabled aria-describedby="principalAmountFormHelp" class="form-control" id="principalAmountForm" data-${this.controllerName}-target="principalAmountInput" placeholder="Valor Principal" type="text" required>
                      </div>
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="form-group">
                      <div class="floating-label floating-label-sm">
                        <label for="finesAmountForm">Vlr Multa</label>
                        <input aria-describedby="finesAmountFormHelp" class="form-control" id="finesAmountForm" data-${this.controllerName}-target="finesAmountInput" data-action="blur->${this.controllerName}#currencyMask keypress->${this.controllerName}#currencyMask keyup->${this.controllerName}#currencyMask keyup->${this.controllerName}#bindTotalAmount" placeholder="Valor Multa" type="text" required>
                      </div>
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="form-group">
                      <div class="floating-label floating-label-sm">
                        <label for="interestsAmountForm">Vlr Juros</label>
                        <input aria-describedby="interestsAmountFormHelp" class="form-control" id="interestsAmountForm" data-${this.controllerName}-target="interestsAmountInput" data-action="blur->${this.controllerName}#currencyMask keypress->${this.controllerName}#currencyMask keyup->${this.controllerName}#currencyMask keyup->${this.controllerName}#bindTotalAmount" placeholder="Valor Juros" type="text" required>
                      </div>
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="form-group">
                      <div class="floating-label floating-label-sm">
                        <label for="totalAmountForm">Vlr Total</label>
                        <input disabled aria-describedby="totalAmountFormHelp" class="form-control" id="totalAmountForm" data-${this.controllerName}-target="totalAmountInput" placeholder="Valor Total" type="text" required>
                      </div>
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="form-group">
                      <div class="floating-label floating-label-sm">
                        <label for="dueAtForm">Vencimento</label>
                        <input disabled aria-describedby="dueAtFormHelp" class="form-control" id="dueAtForm" data-${this.controllerName}-target="dueAtInput" placeholder="" type="text" required>
                      </div>
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="form-group">
                      <div class="floating-label floating-label-sm">
                        <label for="paidAtForm">Pagamento</label>
                        <input aria-describedby="paidAtFormHelp" class="form-control" id="paidAtForm" data-${this.controllerName}-target="paidDateInput" placeholder="" type="text" required>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row my-2">
                  <div class="col-12">
                    <div class="form-group">
                      <div class="floating-label floating-label-sm">
                        <label>Canal Pagamento</label>
                        <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="channelDropdown">
                          <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="channelDropdownBtn"><span class="mr-auto w-100 selected-item" id="selected" data-app--helpers--selector-target="input" data-${this.controllerName}-target="channelInput"></span></button>
                          <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                            <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                            <ul class="ul-select" data-${this.controllerName}-target="channelList" data-app--helpers--search-target="searchList">
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>`

    
    var controller = this
    new Promise(function (resolve) {
      resolve(controller.bodyTarget.innerHTML = html)
    }).then(() => {
      controller.setCurrentPayable(payableId)
      controller.setChannels()

      var date = new Date(controller.application.current_date.year, controller.application.current_date.month - 1, 1)
      // controller.getControllerByIdentifier("app--helpers--pickdate").pickWithLimit($(controller.issuedOnInputTarget), { kind: "monthly", year: date.getFullYear(), month: date.getMonth() + 1 })
      // controller.getControllerByIdentifier("app--helpers--pickdate").pickWithLimit($(controller.paidDateInputTarget), "monthly", date.getFullYear(), date.getMonth() + 1)
      controller.getControllerByIdentifier("app--helpers--pickdate").pickOnlyFutureFromDate($(controller.paidDateInputTarget), "monthly", date.getFullYear(), date.getMonth() + 1, date)

      controller.paidDateInputTarget.value = controller.current_payable.due_date_pretty
      controller.dueAtInputTarget.value = controller.current_payable.due_date_pretty
      controller.principalAmountInputTarget.value = controller.controllerNumber.currencyOptionMask(controller.current_payable.amount)
      controller.interestsAmountInputTarget.value = controller.controllerNumber.currencyOptionMask(controller.current_payable.interests)
      controller.finesAmountInputTarget.value = controller.controllerNumber.currencyOptionMask(controller.current_payable.fines)
      controller.totalAmountInputTarget.value = controller.controllerNumber.currencyOptionMask(controller.current_payable.total_amount)
      controller.saveBtnTarget.innerText = controller.current_payable.subkind_action

      controller.descriptionInputTarget.innerHTML = `<span class="mc-tooltip grab">
                                              <span class="f-065" data-controller="app--helpers--copy" data-copy="${controller.current_payable.description}" data-action="click->app--helpers--copy#copy">${controller.current_payable.description}</span>
                                              <span class="mc-tooltiptext">Clique para copiar</span>
                                            </span>`

      controller.chartAccountInputTarget.innerHTML = `<span class="f-065">${controller.current_payable.chart_account}</span>`
      controller.providerLineTarget.innerHTML = `<span class="f-065">${controller.current_payable.provider_name}</span>`

      if (controller.current_payable.method == `pix`) {
        controller.methodLineTarget.innerHTML = `<span class="f-065">${controller.current_payable.method_pretty}</span> |
                                        <span class="f-065">${controller.current_payable.pix_kind_pretty}</span> |
                                        <span class="mc-tooltip grab">
                                          <span class="f-065" data-controller="app--helpers--copy" data-copy="${controller.current_payable.pix_key}" data-action="click->app--helpers--copy#copy">${controller.current_payable.pix_key}</span>
                                          <span class="mc-tooltiptext">Clique para copiar</span>
                                        </span>`
      } else if (controller.current_payable.method == `bank_slip`) {
        controller.methodLineTarget.innerHTML = `<span class="f-065">${controller.current_payable.method_pretty}</span> |
                                        <span class="mc-tooltip grab">
                                          <span class="f-065" data-controller="app--helpers--copy" data-copy="${controller.current_payable.bank_line}" data-action="click->app--helpers--copy#copy">${controller.current_payable.bank_line}</span>
                                          <span class="mc-tooltiptext">Clique para copiar</span>
                                        </span>`
      } else if (controller.current_payable.method == `credit_card`) {
        var splitAmount = Number(controller.current_payable.amount) / Number(controller.current_payable.installment)
        controller.methodLineTarget.innerHTML = `<span class="f-065">${controller.current_payable.method_pretty}</span> |
                                        <span class="f-065">${controller.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(controller.current_payable.amount)} em ${controller.current_payable.installment}x de ${controller.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(splitAmount)}</span>`
      }

      controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
    })
  }

  close() {
    this.stopRefreshing()
    document.body.classList.remove("modal-open");
    this.element.removeAttribute("style");
    this.element.classList.remove("show");
    document.getElementsByClassName("modal-backdrop")[0].remove();
    this.modalTarget.remove()
  }

  setCurrentPayable(payableId) {
    this.application.payables.forEach(element => {
      if (element.id == payableId) {
        this.current_payable = element
      }
    })
  }

  updatePaid() {
    this.send_data = { current_user: {}, payable: {}, tracker: { log: true } }

    this.send_data.payable.id = this.current_payable.id
    this.send_data.payable.paid = true
    this.send_data.payable.paid_date = this.getControllerByIdentifier("app--helpers--date").transformAllKindDate(this.paidDateInputTarget.value)
    this.send_data.payable.channel_id = this.channelInputTarget.dataset.channelId
    this.send_data.payable.channel_name = this.channelInputTarget.innerText
    this.send_data.payable.amount = this.controllerNumber.fromCurrencyToNumber(this.principalAmountInputTarget.value)
    this.send_data.payable.interests = this.controllerNumber.fromCurrencyToNumber(this.interestsAmountInputTarget.value)
    this.send_data.payable.fines = this.controllerNumber.fromCurrencyToNumber(this.finesAmountInputTarget.value)
    this.send_data.payable.total_amount = this.controllerNumber.fromCurrencyToNumber(this.totalAmountInputTarget.value)

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = `financial_payable_entities`

    this.send_data.tracker.record_id = this.current_payable.id
    this.send_data.tracker.record_type = `financial_payables`
    this.send_data.tracker.action = `paid`
    this.send_data.tracker.observations = `Pagamento ${this.current_payable.description} pago, sendo Principal: ${this.current_payable.amount}, Juros: ${this.current_payable.interests} e Total: ${this.current_payable.total_amount}`

    var r = confirm(`A Conta foi efetivamente Paga?`)
    if (r) {
      this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()
      this.requestSave()
    }
  }

  requestSave() {
    var url = "/financials/books/payables/entities/update_paid"
    var method = "PUT"
    var controller = this
    var init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }

    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          var payable = response.data.cln
          controller.application.payables.forEach((element, i) => {
            if (element.id == payable.id) {
              controller.application.payables.splice(controller.application.payables.indexOf(element), 1, payable)
            }
          })
        }
        controller.close()

        if (controller.getControllerByIdentifier(`financials--books--payables--dashboard--list`)) {
          controller.getControllerByIdentifier(`financials--books--payables--dashboard--list`).doDataTable()
        } else if (controller.getControllerByIdentifier(`financials--books--payables--entities--show-modal`)) {
          controller.getControllerByIdentifier(`financials--books--payables--entities--show-modal`).current_payable = payable
          controller.getControllerByIdentifier(`financials--books--payables--entities--show-modal`).doPayableHtml()
          controller.getControllerByIdentifier(`financials--books--payables--entities--index`).doDataTable()
        }

        controller.getControllerByIdentifier(`financials--books--payables--dashboard--submenu`).doBadgeCounts()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.close()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error, `Tivemos problemas para atualizar o Pagamento`)
      })
  }

  setChannels() {
    var html = ``
    var medId = this.current_payable.med_id

    this.application.channels.forEach(channel => {
      if (channel.status && channel.med_id == medId) {
        html += `<li data-channel-id="${channel.id}" data-action="click->${this.controllerName}#selectChannel click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">${channel.name}</li>`

        if (channel.id == this.current_payable.channel_id) {
          this.channelDropdownTarget.value = channel.id
          this.channelInputTarget.innerText = `${channel.name}`
          this.channelInputTarget.dataset.channelId = channel.id
        }
      }
    })

    this.channelListTarget.innerHTML = html
  }

  selectChannel(ev) {
    var channelId = ev.currentTarget.dataset.channelId
    this.application.channels.forEach(element => {
      if (element.id == channelId) {
        this.channelDropdownTarget.value = element.id
        this.channelInputTarget.innerText = `${element.name}`
        this.channelInputTarget.dataset.channelId = element.id
      }
    })
  }

  bindTotalAmount(ev) {
    var amount = Number(this.current_payable.amount)
    var fines = Number(this.controllerNumber.fromCurrencyToNumber(this.finesAmountInputTarget.value))
    var interests = Number(this.controllerNumber.fromCurrencyToNumber(this.interestsAmountInputTarget.value))

    var totalAmount = amount + interests + fines
    this.totalAmountInputTarget.value = this.controllerNumber.currencyOptionMask(totalAmount)
  }

  currencyMask(ev) {
    this.controllerNumber.changeNumberToCurrency(ev)
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0

      if (controller.interestsAmountInputTarget.value == ``) {
        len += 1
      }

      if (controller.paidDateInputTarget.value == ``) {
        len += 1
      }

      if (controller.channelInputTarget.innerText == ``) {
        len += 1
      }

      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

}