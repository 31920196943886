import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["mainCard", "cardBody", "footerTable", "addSignatureBtn", "viewCard", "viewTitleCard", "viewCardBody", "saveBtn", "bodyTable",
                    "nameInput", "titleInput", "numberInput"]




  connect() {
    this.controllerName = `operations--products--bookings--personalizations--signatures`
    this.send_data = { current_user: {}, signature: {} }
    this.doSignaturesListHtml()
  }

  addSignature() {
    this.actionMode = "new"
    this.doSignatureHtml()
  }

  editSignature(ev) {

    this.application.signatures.forEach(element => {
      if (element.id == ev.currentTarget.closest(".itemRow").dataset.id) {
        this.current_signature = element
      }
    });

    this.actionMode = "edit"
    this.doSignatureHtml()
  }

  cancelSave() {
    this.send_data = { current_user: {}, signature: {} }
    this.getControllerByIdentifier("operations--products--bookings--personalizations--dashboard").showSignatureTarget.innerHTML = ``
  }

  saveSignature() {

    if (this.actionMode == "edit") { this.send_data.signature.id = this.current_signature.id }
    if (this.actionMode == "new") { this.send_data.signature.id = 2 }
    if (this.actionMode == "new") { this.send_data.signature.active = true }
    if (this.actionMode == "edit") { this.send_data.signature.active = true }
    this.send_data.signature.name = this.nameInputTarget.value
    this.send_data.signature.title = this.titleInputTarget.value
    this.send_data.signature.number = this.numberInputTarget.value
    
    this.send_data.signature.booking_id = this.application.booking.id
    this.send_data.current_user.current_user_id = this.application.current_user.id

    this.stopRefreshing()
    this.saveBtnTarget.disabled = true

    this.requestSave()
  }

  requestSave() {
    if (this.actionMode == "new") {
      var url = "/operations/products/bookings/signatures/create"
      var method = "POST"
    } else if (this.actionMode == "edit") {
      var url = "/operations/products/bookings/signatures/update"
      var method = "PUT"
    }
    var controller = this
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.stopRefreshing()
        if (response.save) {
          var signature = response.data.cln
          if (controller.actionMode == "new") {
            controller.application.signatures[controller.application.signatures.length] = signature
          } else if (controller.actionMode == "edit") {
            if (signature.active) {
              controller.application.signatures.forEach((element, i) => {
                if (element.id == signature.id) {
                  controller.application.signatures.splice(controller.application.signatures.indexOf(element), 1, signature)
                }
              })
            } else {
              controller.application.signatures.forEach((element, i) => {
                if (element.id == signature.id) {
                  controller.application.signatures.splice(controller.application.signatures.indexOf(element), 1)
                }
              })
            }
          }
          controller.doTableHtml()
          controller.send_data = { current_user: {}, signature: {} }
          controller.getControllerByIdentifier("operations--products--bookings--personalizations--dashboard").showSignatureTarget.innerHTML = ``
        } else {
          if (controller.hasSaveBtnTarget) {
            controller.saveBtnTarget.disabled = false
          }
          if (controller.hasDeleteBtnTarget) {
            controller.deleteBtnTarget.disabled = true
          }
        }
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  doSignatureHtml() {
    var html = `<div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="viewCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 card-title-table-list" data-${this.controllerName}-target="viewTitleCard"></h6>
                  </div>
                  <div class="card-body py-0" data-${this.controllerName}-target="viewCardBody">
                    <table class="table table-sm table-borderless my-0" style="font-size:80%;">
                      <tbody>
                        <tr class="itemRow">
                          <td style="font-size:80%;" scope="col" class="p-1 align-middle">
                            <div class="floating-label floating-label-sm">
                              <label for="nameInput">Nome Profissional</label>
                              <input id="nameInput" data-${this.controllerName}-target="nameInput" class="form-control p-1 s-title-0p6rem" type="text" required>
                            </div>
                          </td>
                        </tr>
                        <tr class="itemRow">
                          <td style="font-size:80%;" scope="col" class="p-1 align-middle">
                            <div class="floating-label floating-label-sm">
                              <label for="titleInput">Título Profissional</label>
                              <input id="titleInput" data-${this.controllerName}-target="titleInput" class="form-control p-1 s-title-0p6rem" type="text" required>
                            </div>
                          </td>
                        </tr>
                        <tr class="itemRow">
                          <td style="font-size:80%;" scope="col" class="p-1 align-middle">
                            <div class="floating-label floating-label-sm">
                              <label for="numberInput">Conselho Profissional</label>
                              <input id="numberInput" data-${this.controllerName}-target="numberInput" class="form-control p-1 s-title-0p6rem" type="text" required>
                            </div>
                          </td>
                        </tr>
                        
                      </tbody>
                    </table>
                  </div>
                  <div class="card-footer py-1 text-right">
                    <button type="button" class="btn btn-secondary btn-sm py-2 btn-table f-065" data-dismiss="modal" data-action="click->${this.controllerName}#cancelSave">Fechar</button>
                    <button type="button" class="btn btn-primary btn-sm py-2 btn-table f-065" data-action="click->${this.controllerName}#saveSignature" data-${this.controllerName}-target="saveBtn">Salvar</button>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("operations--products--bookings--personalizations--dashboard").showSignatureTarget.innerHTML = html)
    }).then(() => {
      controller.viewCardTarget.style.height = ($(window).height() * 0.40) + "px"

      if (controller.actionMode == "new") {
        controller.viewTitleCardTarget.innerText = "Nova Assinatura"
      } else if (controller.actionMode == "edit") {
        controller.viewTitleCardTarget.innerText = "Editando Assinatura"
        controller.nameInputTarget.value = controller.current_signature.name
        controller.titleInputTarget.value = controller.current_signature.title
        controller.numberInputTarget.value = controller.current_signature.number
      }
      controller.getControllerByIdentifier("app--helpers--elements").tooltip()
      controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
    })
  }

  doSignaturesListHtml() {
    var html = `<div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="mainCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 card-title-table-list">Assinaturas</h6>
                    <div class="card-actions ml-auto py-0">
                      <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0 mc-tooltip" data-${this.controllerName}-target="addSignatureBtn" data-action="click->${this.controllerName}#addSignature" type="button">
                        <span class="material-icons">add</span>
                        <span class="mc-tooltiptext">Nova Assinatura</span>
                      </button>
                    </div>
                  </div>
                  <div class="card-body py-0" data-${this.controllerName}-target="cardBody" style="overflow:auto;"></div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("operations--products--bookings--personalizations--dashboard").listSignaturesTarget.innerHTML = html)
    }).then(() => {
      controller.getControllerByIdentifier("app--helpers--elements").tooltip()
      controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
      controller.mainCardTarget.style.height = ($(window).height() * 0.25) + "px"
      controller.doTableHtml()
      if (!controller.application.permissions.medbooking_signatures.can_create) {
        controller.addSignatureBtnTarget.remove()
      }
    })
  }

  doTableHtml() {
    var html = `<div class="table-responsive">
                  <table class="table table-sm table-borderless my-0" style="font-size:80%;">
                    <tbody data-${this.controllerName}-target="bodyTable">
                    </tbody>
                  </table>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.cardBodyTarget.innerHTML = html)
    }).then(() => {
      controller.listData(controller.application.signatures)
    })
  }

  listData(data) {
    this.bodyTableTarget.innerHTML = ""
    if (data == undefined || data == [] || data.length == 0) {
      var noData = `<td colspan="9" class="px-0 align-middle text-center" style="font-size:80%;">
                      <span class="fa-stack fa-1x">
                        <i class="fas fa-list fa-stack-1x"></i>
                      </span>
                      <span class="f-065 align-middle">Sem assinatura cadastrada</span>
                    </td>`

      this.bodyTableTarget.innerHTML = noData
    } else {
      data.forEach(element => {
        this.bodyTableTarget.insertAdjacentHTML("beforeend", this.rowTablePartial(element, data.length))
      });
    }
    this.getControllerByIdentifier("app--helpers--elements").tooltip()
  }

  rowTablePartial(element, length) {
    if (this.application.permissions.medbooking_signatures.can_update) {
      var edit = `<button data-action="click->${this.controllerName}#editSignature" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                    <span class="material-icons md-sm md-dark">edit</span>
                    <span class="mc-tooltiptext">Editar</span>
                  </button>`
    } else {
      var edit = ``
    }

    if (length == 1) {
      var tableRow = `<tr class="itemRow" data-id="${element.id}" style="height:50px;">`
    } else {
      var tableRow = `<tr class="itemRow" data-id="${element.id}">`
    }

    var rowHtml = `<tr>
                    <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-20 align-middle"></td>
                    <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-20 align-middle"></td>
                    <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-20 align-middle"></td>
                    <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-20 align-middle"></td>
                    <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-20 align-middle"></td>
                    <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-20 align-middle"></td>
                  </tr>
                  <tr class="itemRow" data-id="${element.id}">
                    <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" colspan="5" style="font-size:80%;" scope="col" class="p-1 align-middle">${element.name}</td>
                    <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" colspan="1" rowspan="2" style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${edit}</td>
                  </tr>
                  <tr>
                    <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" colspan="3" style="font-size:80%;" scope="col" class="p-1 align-middle">${element.title}</td>
                    <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" colspan="2" style="font-size:80%;" scope="col" class="p-1 align-middle">${element.number}</td>
                    
                  </tr>`

    return rowHtml
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard", "viewCard"]
    var identifier = this.identifier
    this.connect()
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0
      controller.validGroupTargets.forEach(element => {
        if (this.fromStringToBoolean(element.dataset.valid) == false) {
          len += 1
        }
      });
      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
