import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "content", "mainDashboard", "list", "mainCard", "sideBarReport"]

  connect() {
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.cardLoader = this.getControllerByIdentifier("app--helpers--loaders").cardLoader()
    this.controllerName = `operations--products--clinics--clients--index--main`
    this.application.permissions = {}
    this.application.chart_accounts = []
    this.application.team_list = []
    this.application.receivables = []
    // this.application.clinics = []
    this.application.domain = {}

    this.doMainGrid()

    // 1 - this.getCurrentUserPermissions
    // 2 - this.getProducts

  }

  doMainDashboard() {
    this.doPageGrid()
    this.sideBarReport()
  }

  doMainGrid() {
    var html = `<div class="row">
                  <div class="col-12 px-0" data-controller="operations--products--clinics--clients--index--dashboard
                                                            operations--products--clinics--clients--index--XXX
                                                            users--works--permissions--entities--list-users" data-${this.controllerName}-target="content">
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.mainTarget.innerHTML = html)
    }).then(() => {
      controller.doPageGrid()
      controller.getCurrentUserPermissions()
    })
  }

  doPageGrid() {
    var loader = `<div class='card timeline-item-100 m-0 p-1 w-100' style="box-shadow:none;"><div class='animated-background animated-background-20'><div class='background-masker title'></div></div></div>`

    var html = `<div class="row">
                  <div class="col-10" data-${this.controllerName}-target="list">
                    <div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="mainCard">
                      <div class="card-header py-0">
                        <div class="row my-2"><div class="col">${loader}</div></div>  
                      </div>
                      <div class="card-body">
                        <div class="row my-2"><div class="col">${loader}</div></div>
                        <div class="row my-2"><div class="col">${loader}</div></div>
                        <div class="row my-2"><div class="col">${loader}</div></div>
                      </div>
                      <div class="card-footer py-0">
                        <div class="row my-2"><div class="col">${loader}</div></div>
                      </div>
                    </div>
                  </div>

                  <div class="col-2" data-${this.controllerName}-target="sideBarReport">
                    <div class="row">
                      <div class="col-12 px-0">
                        ${this.cardLoader}
                      </div>
                    </div>
                    <div class="row my-4">
                      <div class="col-12 px-0">
                        ${this.cardLoader}
                      </div>
                    </div>
                    <div class="row my-4">
                      <div class="col-12 px-0">
                        ${this.cardLoader}
                      </div>
                    </div>
                    <div class="row my-4">
                      <div class="col-12 px-0">
                        ${this.cardLoader}
                      </div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.contentTarget.innerHTML = html)
    }).then(() => {
      controller.mainCardTarget.style.height = ($(window).height() * 0.5) + "px"
      controller.getControllerByIdentifier(`operations--products--clinics--clients--index--dashboard`).doClinicsPreloader()
    })
  }

  sideBarReport() {
    var html = ``
    this.application.sidebar_report?.forEach(report => {
      html += `<div class="row mb-3">
                  <div class="col-12 px-0">
                    <div class="card w-100">
                      <div class="card-header py-0">
                        <div class="row my-2"><div class="col pointer f-bold px-0 text-center">${report.name}</div></div>
                      </div>
                      <div class="card-body py-0 px-2">
                        <div class="row my-2">
                          <div class="col-12 px-0 text-center">
                            ${report.result}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>`
    })


    if (this.hasSideBarReportTarget) {
      this.sideBarReportTarget.innerHTML = html
    }
  }

  finishLoadPage() {
    this.getControllerByIdentifier("operations--products--clinics--clients--index--submenu").doSubmenuHtml()
    this.getControllerByIdentifier("operations--products--clinics--clients--index--dashboard").doMainDashboard()
    // this.getControllerByIdentifier("operations--products--clinics--managements--index--list").doDataTable()

    var response = { type: `success`, message: `Página carregada com sucesso!` }
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).closeLoadModal()
    this.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
  }

  getProducts() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando PJ's Médicas`, value: 20 })

    const data = { product: { name: `medclinic`, kind: `` }, current_user: { current_user_id: this.application.current_user.id, feature: `medclinic_clients` } }
    const url = `/operations/products/entities/list_by_company`

    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.clinics = response.data.cln
          controller.getControllerByIdentifier("operations--products--clinics--clients--index--submenu").dataLoaded.clinic = true
        }

        controller.finishLoadPage()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getCurrentUserPermissions() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).openLoadModal({ submessage: `Carregando Permissões` })

    const data = { permissions: { subdomain: `operation_clinic_clients` }, current_user: { current_user_id: this.application.current_user.id, feature: `medclinic_client_permissions`, account_kind: this.application.current_user.account_kind } }
    const url = "/users/permissions/policies/list_by_domain"

    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        response.data.cln.forEach(permission => {
          controller.application.permissions[permission.name] = permission.data
        })
        controller.application.domain_permission = controller.application.permissions[`medclinic_client_permissions`]

        controller.getProducts()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
        window.location.reload()
      })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }
}