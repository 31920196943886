import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["modal", "bottom", "top", "list", "view", "mainCard", "medBtn", "actionCards", "body", "searchTable", "searchInput"]

  connect() {
    this.controllerName = `financials--books--receivables--conciliations--panel-modal`
    this.controllerNumber = this.getControllerByIdentifier(`app--helpers--numbers`)
  }

  open() {
    document.body.classList.add("modal-open");
    this.element.setAttribute("style", "display: block;");
    this.element.classList.add("show");
    const html = `<div class="modal-backdrop fade show"></div>`
    // document.body.insertAdjacentHTML("beforeend", html)
    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.doPanelHtml()
    })
  }

  close() {
    document.body.classList.remove("modal-open");
    this.element.removeAttribute("style");
    this.element.classList.remove("show");
    document.getElementsByClassName("modal-backdrop")[0].remove();
    this.modalTarget.remove()
  }

  sortTable(ev) {
    var mode = ev.currentTarget.dataset.mode
    var field = ev.currentTarget.dataset.field

    if (mode == `desc`) {
      ev.currentTarget.nextElementSibling.classList.remove("d-none")
      ev.currentTarget.classList.add("d-none")
    } else if (mode == `asc`) {
      ev.currentTarget.previousElementSibling.classList.remove("d-none")
      ev.currentTarget.classList.add("d-none")
    }

    this.sort.mode = mode
    this.sort.field = field
    this.doDataTable()
  }

  doPanelHtml() {

    var medsHtml = ``
    var medsBtn = ``

    this.application.meds.forEach(element => {
      medsBtn += `<div class="col-3 text-center">
                    <button type="button" data-kind="company" data-med-id="${element.id}" data-med-id-iugu="${element.id_iugu}" data-action="${this.controllerName}#changeMed ${this.controllerName}#toggleBtn" class="btn btn-sm f-065" data-${this.controllerName}-target="medBtn">${element.name}</button>
                  </div>`
    })

    medsHtml = medsBtn

    // medsHtml += `<div class="btn-group mx-auto" role="group" style="box-shadow:none" data-${this.controllerName}-target="medsBtnGroup">
    //                 ${medsBtn}
    //               </div>`



    var closeLine = `<span data-action="click->${this.controllerName}#close" class="dropdown-item py-1 pointer dropdown-submenu-item">Fechar</span>`

    var clearBtnHtml = `<span class="mc-tooltip">
                          <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0 d-none" data-${this.controllerName}-target="clearListBtn" data-action="click->${this.controllerName}#clearList" type="button">
                            <span class="material-icons md-dark">clear</span>
                          </button>
                          <span class="mc-tooltiptext">Limpar</span>
                        </span>`

    var submenuHtml = `<div class="dropdown mc-tooltip" data-${this.controllerName}-target="optionBtn">
                        <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-toggle="dropdown" id="submenuMenuDrop" type="button"><span class="material-icons md-dark">more_vert</span></button>
                        <div aria-labelledby="submenuMenuDrop" class="dropdown-menu dropdown-menu-right menu" style="font-size:80%">
                          ${closeLine}
                        </div>
                        <span class="mc-tooltiptext">Opções</span>
                      </div>`
    

    var html = `<div class="card" style="width:100%;display:relative;" data-controller="app--helpers--search" data-${this.controllerName}-target="mainCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 card-title-table-list">Escolha a Med e o Tipo de Fatura referente ao dia ${this.current_day}</h6>
                    <div class="card-actions ml-auto py-0">
                      ${submenuHtml}
                      ${clearBtnHtml}
                    </div>
                  </div>
                  <div class="card-body py-0">
                    <div class="row my-5">
                      ${medsHtml}
                    </div>
                    <div class="row my-5"></div>
                    <div class="row my-5">
                      <div class="col-4">
                        <div class="card pointer" data-status="paid" data-mode="paid_date" data-action="click->${this.controllerName}#getBillings" data-${this.controllerName}-target="actionCards paidCard">
                          <div class="card-header p-1 text-center f-065 f-bold" data-${this.controllerName}-target="indicatorHeader">
                            <span data-${this.controllerName}-target="paidHeader">Pagas</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-4">
                        <div class="card pointer" data-status="refunded" data-mode="paid_date" data-action="click->${this.controllerName}#getBillings" data-${this.controllerName}-target="actionCards dueCard">
                          <div class="card-header p-1 text-center f-065 f-bold" data-${this.controllerName}-target="indicatorHeader">
                            <span data-${this.controllerName}-target="partiallyPaidHeader">Pagas Parcialmente</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-4">
                        <div class="card pointer" data-status="partially_refunded" data-mode="paid_date" data-action="click->${this.controllerName}#getBillings" data-${this.controllerName}-target="actionCards dueCard">
                          <div class="card-header p-1 text-center f-065 f-bold" data-${this.controllerName}-target="indicatorHeader">
                            <span data-${this.controllerName}-target="partiallyRefundedHeader">Reembolsadas Parcial</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row my-2">
                      <div class="col-4">
                        <div class="card pointer" data-status="externally_paid" data-mode="paid_date" data-action="click->${this.controllerName}#getBillings" data-${this.controllerName}-target="actionCards dueCard">
                          <div class="card-header p-1 text-center f-065 f-bold" data-${this.controllerName}-target="indicatorHeader">
                            <span data-${this.controllerName}-target="externallyPaidHeader">Pagas Externamente</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-4">
                        <div class="card pointer" data-mode="due_date" data-action="click->${this.controllerName}#getBillings" data-${this.controllerName}-target="actionCards dueCard">
                          <div class="card-header p-1 text-center f-065 f-bold" data-${this.controllerName}-target="indicatorHeader">
                            <span data-${this.controllerName}-target="dueHeader">Vencidas</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.bodyTarget.innerHTML = html)
    }).then(() => {
      controller.mainCardTarget.style.height = ($(window).height() * 0.55) + "px"
    })
  }

  enterCloseModal(ev) {
    if (ev.type == "keyup" && ev.key == "Enter") {

      var table = this.searchTableTarget
      var tr = table.getElementsByTagName("tr")
      var providerIds = []
      var providerId = ``

      for (var i = 1; i < tr.length; i++) {
        if (tr[i].matches(`.filter-row`)) {
          providerIds[providerIds.length] = tr[i].dataset.id
          // providerId = tr[i].dataset.id
        }
      }

      if (providerIds.length) {
        this.closeModal(providerIds[0])
      }
    }
  }

  closeModalEv(ev) {
    var providerId = ev.currentTarget.closest(".itemRow").dataset.id

    this.closeModal(providerId)
  }

  closeModal(providerId) {
    var provider = {}

    this.application.providers.forEach(element => {
      if (element.id == providerId) {
        provider = element
      }
    })

    this.controllerForm.current_provider = provider
    this.controllerForm.providerInputTarget.value = provider.name
    this.controllerForm.providerInputTarget.dataset.providerId = provider.id

    // if (controllerForm == this.getControllerByIdentifier(`financials--books--payables--entities--save`) && this.getControllerByIdentifier(`financials--books--payables--entities--save`).hasFormCardTarget) {
    if (this.controllerForm == this.getControllerByIdentifier(`financials--books--payables--entities--save`)) {
      var method = this.controllerForm.methodInputTarget.dataset.method

      if (method == `pix`) {
        this.controllerForm.pixKeyInputTarget.value = provider.pix_key
        this.controllerForm.pixKindDropdownTarget.value = provider.pix_kind
        this.controllerForm.pixKindInputTarget.dataset.kind = provider.pix_kind
        this.controllerForm.pixKindInputTarget.innerText = provider.pix_kind_pretty
      } else if (method == `transfer`) {
        this.controllerForm.bankAccountInputTarget.value = provider.bank_account
      }
    }

    this.getControllerByIdentifier("app--helpers--forms").floatingLabel()
    this.close()
  }

  toggleBtn(ev) {
    this.medBtnTargets.forEach(element => {
      // element.classList.add(`btn-flat-dark`)
      element.classList.remove(`btn-primary`)
    })

    this.actionCardsTargets.forEach(element => {
      element.classList.remove(`bg-primary`)
    })

    // ev.currentTarget.classList.remove(`btn-flat-dark`)
    ev.currentTarget.classList.add(`btn-primary`)
  }

  changeMed(ev) {
    this.med_id_iugu = ev.currentTarget.dataset.medIdIugu
  }

  getBillings(ev) {
    this.mode = ev.currentTarget.dataset.mode
    this.status = ev.currentTarget.dataset.status

    this.actionCardsTargets.forEach(element => {
      element.classList.remove(`bg-primary`)
    })

    ev.currentTarget.classList.add(`bg-primary`)

    this.getControllerByIdentifier(`financials--books--receivables--conciliations--days`).mode = this.mode
    this.getControllerByIdentifier(`financials--books--receivables--conciliations--days`).status = this.status
    this.getControllerByIdentifier(`financials--books--receivables--conciliations--days`).med_id_iugu = this.med_id_iugu
    this.getControllerByIdentifier(`financials--books--receivables--conciliations--days`).goToDay()
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    })
  }

  nameTarget(target) {
    return this.targets.find(target)
  }
}