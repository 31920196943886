import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["mainDashboard", "list", "view", "printCardElement", "4444", "5555", "6666", "7777", "8888", "9999"]

  connect() {
    this.controllerName = `operations--products--clinics--clients--apurations--dashboard`
    this.setAccountPermission()
  }

  doApurationsDashboard() {
    var html = `<div class="row" data-${this.controllerName}-target="mainDashboard">
                  <div class="col-2 pr-1" data-${this.controllerName}-target="list" data-controller="operations--products--clinics--financials--apurations--list"></div>
                  <div class="col-10 pr-1" data-${this.controllerName}-target="view" data-controller="operations--products--clinics--financials--apurations--principal operations--products--clinics--financials--apurations--partner"></div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier(`operations--products--clinics--clients--show--main`).contentTarget.innerHTML = html)
    }).then(() => {
      controller.getControllerByIdentifier(`operations--products--clinics--clients--show--submenu`).dashboardToggleBtn(`APURAÇÕES`)

      controller.getControllerByIdentifier(`operations--products--clinics--financials--apurations--list`).permission = this.permission
      controller.getControllerByIdentifier(`operations--products--clinics--financials--apurations--list`).doListHtml()
    })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  setAccountPermission() {
    if (this.application.current_user.account_kind == `admin` || this.application.current_user.account_kind == `team`) {
      this.permission = `medclinic_client_main_apurations`
    } else if (this.application.current_user.account_kind == `doctor`) {
      this.permission = `medclinic_doctor_client_main_apurations`
    } else if (this.application.current_user.account_kind == `helper`) {
      this.permission = `medclinic_helper_client_main_apurations`
    }
  }

}
