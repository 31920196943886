import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["productAmount", "productGain", "productLost", "modal", "body", "saveBtn",
                    "reasonDropdown", "reasonDropdownBtn", "reasonInput", "reasonList", "reasonDescription",
                    "reasonDescriptionDiv"]

  connect() {
    this.controllerName = `operations--products--clinics--financials--receivables--cancel`
    this.stage = ``
    this.open()
  }

  setReasonList() {
    var html = `<li data-reason="taker_request" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectReason" data-app--helpers--selector-target="select" class="li-selector dark f-065">Pedido do Tomador</li>
                <li data-reason="provider_request" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectReason" data-app--helpers--selector-target="select" class="li-selector dark f-065">Pedido do Médico</li>
                <li data-reason="internal_error" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectReason" data-app--helpers--selector-target="select" class="li-selector dark f-065">Erro Interno</li>
                <li data-reason="external_error" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectReason" data-app--helpers--selector-target="select" class="li-selector dark f-065">Erro Externo</li>
                <li data-reason="others_reasons" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectReason" data-app--helpers--selector-target="select" class="li-selector dark f-065">Outras Razões</li>`

    this.reasonListTarget.innerHTML = html
  }

  selectReason(ev) {
    var reason = ev.currentTarget.dataset.reason
    this.reasonInputTarget.dataset.reason = reason

    if (reason == `others_reasons`) {
      this.reasonDescriptionDivTarget.classList.remove(`d-none`)
    } else {
      this.reasonDescriptionDivTarget.classList.add(`d-none`)
      this.reasonDescriptionTarget.value = ``
    }

    this.getControllerByIdentifier("app--helpers--forms").floatingLabel()
  }

  confirmCancelInvoice() {
    var r = confirm(`Tem certeza que deseja Cancelar a Nota Fiscal?`)
    if (r) {
      this.cancelInvoice()
    }
  }

  cancelInvoice() {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()
    this.getControllerByIdentifier(`operations--products--clinics--financials--receivables--action`).doWatingBoardHtml()

    this.send_data = { invoice: {}, current_user: {}, tracker: { log: true } }
    this.send_data.invoice.id = this.current_receivable.main_invoice.id
    this.send_data.invoice.receivable_id = this.current_receivable.id
    this.send_data.invoice.cancel_reason = this.reasonInputTarget.dataset.reason
    this.send_data.invoice.cancel_description = this.reasonDescriptionTarget.value.trim()
    this.send_data.invoice.invoice_status = this.current_receivable.invoice_status

    this.send_data.tracker.action = `canceled`
    this.send_data.tracker.record_id = this.current_receivable.clinic_id
    this.send_data.tracker.record_type = `operation_products`
    this.send_data.tracker.obj_id = this.application.current_date.id
    this.send_data.tracker.obj_type = `operation_dates`
    this.send_data.tracker.observations = `Nota Fiscal da ${this.current_receivable.provider_name} para ${this.current_receivable.taker_name} no valor de ${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(this.current_receivable.total_amount)}`

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = this.permission

    var url = "/operations/products/clinics/integrations/accountings/invoices/cancel_invoice"
    var method = "DELETE"
    var init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    this.close()
    this.getControllerByIdentifier(`operations--products--clinics--financials--receivables--action`).doFetchRequest(url, init)
  }

  closeModal() {
    this.close()
  }

  open() {
    document.body.classList.add("modal-open");
    this.element.setAttribute("style", "display: block;");
    this.element.classList.add("show");
    const html = `<div class="modal-backdrop fade show"></div>`
    document.body.insertAdjacentHTML("beforeend", html)
    this.setReasonList()
    this.refreshSaveBtn()
  }

  close() {
    document.body.classList.remove("modal-open");
    this.element.removeAttribute("style");
    this.element.classList.remove("show");
    document.getElementsByClassName("modal-backdrop")[0].remove();
    this.modalTarget.remove()
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0

      if (controller.hasReasonInputTarget && controller.reasonInputTarget.innerText == ``) {
        len += 1
      }

      if (controller.reasonInputTarget.dataset.reason == `others_reasons`) {
        if (controller.hasReasonDescriptionTarget && controller.reasonDescriptionTarget.value == ``) {
          len += 1
        } 
      }

      if (controller.hasSaveBtnTarget) {
        if (len == 0) {
          controller.saveBtnTarget.disabled = false
        } else {
          controller.saveBtnTarget.disabled = true
        }
      } else {
        controller.stopRefreshing()
      }

    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  goToURL(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_self`)
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    });
    this.getControllerByIdentifier("app--helpers--console").console(results)
    return results
  }

  // var controller = this
  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
