import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "journey", "call", "3333", "4444", "5555", "6666", "7777", "8888", "9999"]

  connect() {
    this.controllerName = `financials--books--clients--renewals--calls`
    this.blockedAdd = false
  }

  doCallsDashboard() {
    var html = `<div class="col-12 pl-1" data-app--communications--voips--calls--list-target="main" data-controller="app--communications--voips--calls--list"></div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier(`financials--books--clients--renewals--show`).callsRowTarget.innerHTML = html)
    }).then(() => {

      controller.getControllerByIdentifier(`app--communications--voips--calls--list`).permission = `financial_client_renewals`
      controller.getControllerByIdentifier(`app--communications--voips--calls--list`).recordId = controller.current_renewal.client_id
      controller.getControllerByIdentifier(`app--communications--voips--calls--list`).recordType = `financial_client_entities`
      controller.getControllerByIdentifier(`app--communications--voips--calls--list`).objId = controller.current_renewal.id
      controller.getControllerByIdentifier(`app--communications--voips--calls--list`).objType = `financial_client_renewals`
      controller.getControllerByIdentifier(`app--communications--voips--calls--list`).dataMode = `obj`

      controller.getControllerByIdentifier(`app--communications--voips--calls--list`).doListHtml()
    })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    })
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
