import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["cards", "viewTitle", "cardBodyLog", "viewCard", "responseBody", "totalResponses", "9999"]

  connect() {
    this.controllerName = `app--stats--builders--responses`
    this.controllerDashboard = this.getControllerByIdentifier(`app--stats--builders--dashboard`)
    this.controllerNumber = this.getControllerByIdentifier(`app--helpers--numbers`)
    this.tableCols = 4
    this.itemsOnPage = 100
    this.pageNumber = 0
    this.numberPages = []
    this.responses = []
    this.sort = { mode: `desc`, field: `created_at_to_time` }
    this.request = { batch: 1, duration: 0, db_runtime: 0, view_runtime: 0 }
    this.responsesLength = 0
  }

  doResponseViewHtml(response) {

    // ${JSON.stringify(response.data.cln)}
    var html = `<div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="viewCard">
                  <div class="card-body py-0" style="overflow:auto;" data-${this.controllerName}-target="cardBodyLog">
                    <div class="row my-3">
                      <div class="col-12 trix-content">
                        <pre data-${this.controllerName}-target="totalResponses"></pre>
                      </div>
                    </div>
                    <div class="row my-3">
                      <div class="col-12 trix-content">
                        <pre data-${this.controllerName}-target="responseBody"></pre>
                      </div>
                    </div>
                  </div>
                </div>`


    var controller = this
    new Promise(function (resolve) {
      resolve(controller.controllerDashboard.responsesTarget.innerHTML = html)
    }).then(() => {
      controller.viewCardTarget.style.height = ($(window).height() * 0.65) + "px"
      controller.totalResponsesTarget.innerText = `Total de Itens: ${response.data.cln.length}`
      controller.responseBodyTarget.textContent = JSON.stringify(response.data.cln, undefined, 4)
    })
  }

  doErrorResponseViewHtml(bodyText) {

    // ${JSON.stringify(response.data.cln)}
    var html = `<div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="viewCard">
                  <div class="card-body py-0" style="overflow:auto;" data-${this.controllerName}-target="cardBodyLog">
                    <div class="row my-3">
                      <div class="col-12 trix-content">
                        <pre data-${this.controllerName}-target="responseBody"></pre>
                      </div>
                    </div>
                  </div>
                </div>`


    var controller = this
    new Promise(function (resolve) {
      resolve(controller.controllerDashboard.responsesTarget.innerHTML = html)
    }).then(() => {
      controller.viewCardTarget.style.height = ($(window).height() * 0.65) + "px"
      controller.responseBodyTarget.textContent = bodyText
    })
  }

  sortTable(ev) {
    var mode = ev.currentTarget.dataset.mode
    var field = ev.currentTarget.dataset.field

    if (mode == `desc`) {
      ev.currentTarget.nextElementSibling.classList.remove("d-none")
      ev.currentTarget.classList.add("d-none")
    } else if (mode == `asc`) {
      ev.currentTarget.previousElementSibling.classList.remove("d-none")
      ev.currentTarget.classList.add("d-none")
    }

    this.sort.mode = mode
    this.sort.field = field
    this.doDataTable()
  }

  doResponsesCalculations() {
    var total_duration = 0
    var total_db_runtime = 0
    var total_view_runtime = 0

    this.request.duration = 0
    this.request.db_runtime = 0
    this.request.view_runtime = 0

    this.responsesLength = Number(this.responses.length)

    this.responses.forEach(element => {
      total_duration += Number(element.duration)
      total_db_runtime += Number(element.db_runtime)
      total_view_runtime += Number(element.view_runtime)
    })

    this.request.duration = Number(total_duration / this.responsesLength)
    this.request.db_runtime = Number(total_db_runtime / this.responsesLength)
    this.request.view_runtime = Number(total_view_runtime / this.responsesLength)

    this.nameTarget(`length-${this.request.batch}`).innerText = this.responsesLength
    this.nameTarget(`duration-${this.request.batch}`).innerText = this.controllerNumber.numberMask(this.request.duration)
    this.nameTarget(`db-${this.request.batch}`).innerText = this.controllerNumber.numberMask(this.request.db_runtime)
    this.nameTarget(`view-${this.request.batch}`).innerText = this.controllerNumber.numberMask(this.request.view_runtime)
  }

  doResponseCardHtml() {

    if (this.getControllerByIdentifier(`app--stats--builders--show`).hasViewNoResponseCardTarget) {
      this.getControllerByIdentifier(`app--stats--builders--show`).viewNoResponseCardTarget.remove()
    }

    var html = `<div class="row mb-3">
                  <div class="col-3 px-0">
                    <div class="card">
                      <div class="card-header p-0">
                        <div class="row my-1 px-0"><div class="col-12 f-bold text-center px-0">Batch | # Requests</div></div>
                      </div>
                      <div class="card-body py-0 px-2 d-flex align-items-center justify-content-center f-1p5 f-bold">
                        <div class="row my-1">
                          <div class="col-12 px-0">
                            <span>${this.request.batch}</span> |
                            <span data-${this.controllerName}-target="length-${this.request.batch}">${this.responsesLength}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-3 px-0">
                    <div class="card">
                      <div class="card-header p-0">
                        <div class="row my-1 px-0"><div class="col-12 f-bold text-center px-0">Duração [ms]</div></div>
                      </div>
                      <div class="card-body py-0 px-2 d-flex align-items-center justify-content-center f-1p5 f-bold">
                        <div class="row my-1">
                          <div class="col-12 px-0">
                            <span data-${this.controllerName}-target="duration-${this.request.batch}">${this.controllerNumber.numberMask(this.request.duration)}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-3 px-0">
                    <div class="card">
                      <div class="card-header p-0">
                        <div class="row my-1 px-0"><div class="col-12 f-bold text-center px-0">DB Runtime [ms]</div></div>
                      </div>
                      <div class="card-body py-0 px-2 d-flex align-items-center justify-content-center f-1p5 f-bold">
                        <div class="row my-1">
                          <div class="col-12 px-0">
                            <span data-${this.controllerName}-target="db-${this.request.batch}">${this.controllerNumber.numberMask(this.request.db_runtime)}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-3 px-0">
                    <div class="card">
                      <div class="card-header p-0">
                        <div class="row my-1 px-0"><div class="col-12 f-bold text-center px-0">View Runtime [ms]</div></div>
                      </div>
                      <div class="card-body py-0 px-2 d-flex align-items-center justify-content-center f-1p5 f-bold">
                        <div class="row my-1">
                          <div class="col-12 px-0">
                            <span data-${this.controllerName}-target="view-${this.request.batch}">${this.controllerNumber.numberMask(this.request.view_runtime)}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.controllerDashboard.responsesTarget.insertAdjacentHTML("afterbegin", html))
    }).then(() => {
      // controller.mainCardTarget.style.height = ($(window).height() * 0.6) + "px"
      // controller.bodyTableTarget.insertAdjacentHTML("beforeend", controller.getControllerByIdentifier("app--helpers--table").doTablePreloader(this.tableCols, 7))
      // controller.doDataTable()
    })
  }

  // `<h6 class="trix-content">${speaker.speaker_bio}</h6>`

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
