import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["mainDashboard", "companyCard", "editBtn", "cancelBtn", "saveBtn", "5555", "6666", "7777", "8888", "9999",
                    "nameDiv", "nameInputDiv", "nameInput",
                    "tradeNameDiv", "tradeNameInputDiv", "tradeNameInput",
                    "municipalDiv", "municipalInputDiv", "municipalInput",]

  connect() {
    this.controllerName = `operations--products--clinics--regularizations--entities--company`
  }

  doClinicCard() {

    if (this.application.permissions[this.permission].can_update) {
      var edit = `<button data-${this.controllerName}-target="editBtn" data-action="click->${this.controllerName}#editCompany" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                    <span class="material-icons md-sm md-dark">edit</span>
                    <span class="mc-tooltiptext">Editar</span>
                  </button>`
      var cancel = `<button data-${this.controllerName}-target="cancelBtn" data-action="click->${this.controllerName}#cancelInput" type="button" class="btn btn-sm btn-table p-0 d-none mc-tooltip">
                    <span aria-hidden="true">&times;</span>
                    <span class="mc-tooltiptext">Cancelar</span>
                  </button>`
    } else {
      var edit = ``
    }

    var html = `<div class="card" data-${this.controllerName}-target="companyCard">
                        <div class="card-header py-0">
                          <div class="row my-2"><div class="col f-bold">Informações da PJ Médica</div></div>  
                        </div>
                        <div class="card-body py-0 px-2" style="overflow:auto;">
                          <div class="col-12 px-2">
                          <table class="table table-sm table-borderless" style="font-size:80%;">
                            <tbody>
                              <tr>
                                <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                                <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                                <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                                <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                                <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                                <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                                <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                                <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                                <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                                <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                                
                              </tr>
                              <tr data-field="tradeName" data-${this.controllerName}-target="tradeNameRow">
                                <th colspan="3" class="px-0 f-065 align-middle"><strong>Razão Social</strong></th>
                                <td colspan="6" class="px-0 f-065 align-middle">
                                  <div data-${this.controllerName}-target="tradeNameDiv">
                                    ${this.application.clinic.company_trade_name}
                                  </div>
                                  <div class="d-none" data-${this.controllerName}-target="tradeNameInputDiv">
                                    <input data-${this.controllerName}-target="tradeNameInput" class="form-control p-1 s-title-0p6rem" type="text" required>
                                  </div>
                                </td>
                                <td colspan="1" class="align-middle text-center">
                                  ${edit}
                                  ${cancel}
                                </td>
                              </tr>

                              <tr data-field="name" data-${this.controllerName}-target="nameRow">
                                <th colspan="3" class="px-0 f-065 align-middle"><strong>Nome Fantasia</strong></th>
                                <td colspan="6" class="px-0 f-065 align-middle">
                                  <div data-${this.controllerName}-target="nameDiv">
                                    ${this.application.clinic.company_name}
                                  </div>
                                  <div class="d-none" data-${this.controllerName}-target="nameInputDiv">
                                    <input data-${this.controllerName}-target="nameInput" class="form-control p-1 s-title-0p6rem" type="text" required>
                                  </div>
                                </td>
                                <td colspan="1" class="align-middle text-center">
                                  ${edit}
                                  ${cancel}
                                </td>
                              </tr>
                              
                              
                              <tr>
                                <th colspan="3" class="f-065 align-middle px-0">
                                  <strong>CNPJ</strong> <br>
                                </th>
                                <td colspan="7" class="f-065 align-middle px-0 text-left">
                                  <span class="mb-0 f-065 mc-tooltip grab">
                                    <span data-controller="app--helpers--copy" data-copy="${this.application.clinic.company_cnpj}" data-action="click->app--helpers--copy#copy">${this.application.clinic.company_cnpj_pretty}</span>
                                    <span class="mc-tooltiptext mc-tooltiptext-right">Clique para Copiar</span>
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <th colspan="3" class="f-065 align-middle px-0">
                                  <strong>Inscrição Municipal</strong> <br>
                                </th>
                                <td colspan="7" class="f-065 align-middle px-0 text-left">
                                  <span class="mb-0 f-065 mc-tooltip grab">
                                    <span data-controller="app--helpers--copy" data-copy="${this.application.clinic.company_municipal_number}" data-action="click->app--helpers--copy#copy">${this.application.clinic.company_municipal_number}</span>
                                    <span class="mc-tooltiptext mc-tooltiptext-right">Clique para Copiar</span>
                                  </span>
                                </td>
                              </tr>

                              
                              <tr>
                                <th colspan="3" class="f-065 align-middle px-0">
                                  <strong>Abertura</strong> <br>
                                </th>
                                <td colspan="7" class="f-065 align-middle px-0 text-left">
                                  ${this.application.clinic.company_opened_at_pretty}
                                </td>
                              </tr>
                              <tr>
                                <td colspan="10" class="text-right">
                                  <button type="button" class="btn btn-primary btn-sm f-065 d-none" data-action="click->${this.controllerName}#saveCompany" data-${this.controllerName}-target="saveBtn">Salvar</button>
                                </td>
                              </tr>
                              

                            </tbody>
                          </table>
                        </div>
                        </div>
                      </div>`

    // this.companyTarget.innerHTML = html
    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier(`operations--products--clinics--regularizations--entities--dashboard`).companyTarget.innerHTML = html)
    }).then(() => {
      controller.companyCardTarget.style.height = ($(window).height() * 0.5) + "px"
    })
  }

  editCompany(ev) {
    this.saveBtnTarget.classList.remove("d-none")
    this.refreshSaveBtn()

    if (ev.currentTarget.closest("tr").dataset) {
      this.field = ev.currentTarget.closest("tr").dataset.field
      ev.currentTarget.classList.add("d-none")
      ev.currentTarget.nextElementSibling.classList.remove("d-none")
    } else {
      this.field = "checkbox"
    }



    if (this.field == "name") {
      this.nameDivTarget.classList.add("d-none")
      this.nameInputDivTarget.classList.remove("d-none")
      this.nameInputTarget.value = this.nameDivTarget.innerText.trim()
      this.nameInputTarget.focus()
    } else if (this.field == "tradeName") {
      this.tradeNameDivTarget.classList.add("d-none")
      this.tradeNameInputDivTarget.classList.remove("d-none")
      this.tradeNameInputTarget.value = this.tradeNameDivTarget.innerText.trim()
      this.tradeNameInputTarget.focus()
    }
    // } else if (this.field == "birthdate") {
    //   this.birthdateDivTarget.classList.add("d-none")
    //   this.birthdateInputDivTarget.classList.remove("d-none")
    //   this.birthdateInputTarget.value = this.birthdateDivTarget.innerText.trim()
    //   this.birthdateInputTarget.focus()
    //   this.getControllerByIdentifier("app--helpers--pickdate").pick($(this.birthdateInputTarget), { max: true, months: true, years: 60 })
    // } else if (this.field == "sex") {
    //   this.sexDivTarget.classList.add("d-none")
    //   this.sexInputDivTarget.classList.remove("d-none")
    //   this.sexInputTarget.innerText = this.sexDivTarget.innerText.trim()
    //   this.sexInputTarget.focus()
    // } else if (this.field == "shares") {
    //   this.sharesDivTarget.classList.add("d-none")
    //   this.sharesInputDivTarget.classList.remove("d-none")
    //   this.sharesInputTarget.value = this.getControllerByIdentifier("app--helpers--numbers").cleanNumber(this.sharesDivTarget.innerText.trim())
    //   this.sharesInputTarget.focus()
    // } else if (this.field == "entriedAt") {
    //   this.entriedAtDivTarget.classList.add("d-none")
    //   this.entriedAtInputDivTarget.classList.remove("d-none")
    //   this.entriedAtInputTarget.value = this.entriedAtDivTarget.innerText.trim()
    //   this.entriedAtInputTarget.focus()
    //   this.getControllerByIdentifier("app--helpers--pickdate").pick($(this.entriedAtInputTarget), { max: false, months: true, years: 5 })
    // } else if (this.field == "tradeName") {
    //   this.tradeNameDivTarget.classList.add("d-none")
    //   this.tradeNameInputDivTarget.classList.remove("d-none")
    //   this.tradeNameInputTarget.value = this.tradeNameDivTarget.innerText.trim()
    //   this.tradeNameInputTarget.focus()
    // } else if (this.field == "fantasyName") {
    //   this.fantasyNameDivTarget.classList.add("d-none")
    //   this.fantasyNameInputDivTarget.classList.remove("d-none")
    //   this.fantasyNameInputTarget.value = this.fantasyNameDivTarget.innerText.trim()
    //   this.fantasyNameInputTarget.focus()
    // } else if (this.field == "cnpj") {
    //   this.cnpjDivTarget.classList.add("d-none")
    //   this.cnpjInputDivTarget.classList.remove("d-none")
    //   this.cnpjInputTarget.value = this.cnpjDivTarget.innerText.trim()
    //   this.cnpjInputTarget.focus()
    // }
  }

  cancelInput(ev) {
    ev.currentTarget.classList.add("d-none")
    ev.currentTarget.previousElementSibling.classList.remove("d-none")

    if (this.field == "name") {
      this.nameDivTarget.classList.remove("d-none")
      this.nameInputDivTarget.classList.add("d-none")
      this.nameDivTarget.innerText = this.application.clinic.company_name
    } else if (this.field == "tradeName") {
      this.tradeNameDivTarget.classList.remove("d-none")
      this.tradeNameInputDivTarget.classList.add("d-none")
      this.tradeNameDivTarget.innerText = this.application.clinic.company_trade_name
    }
    // } else if (this.field == "fantasyName") {
    //   this.fantasyNameDivTarget.classList.remove("d-none")
    //   this.fantasyNameInputDivTarget.classList.add("d-none")
    //   this.fantasyNameDivTarget.innerText = this.application.clinic.company_fantasy_name
    // } else if (this.field == "cpf") {
    //   this.cpfDivTarget.classList.remove("d-none")
    //   this.cpfInputDivTarget.classList.add("d-none")
    //   this.cpfDivTarget.innerText = this.application.clinic.id_number_pretty
    // } else if (this.field == "cnpj") {
    //   this.cnpjDivTarget.classList.remove("d-none")
    //   this.cnpjInputDivTarget.classList.add("d-none")
    //   this.cnpjDivTarget.innerText = this.application.clinic.id_number_pretty
    // } else if (this.field == "birthdate") {
    //   this.birthdateDivTarget.classList.remove("d-none")
    //   this.birthdateInputDivTarget.classList.add("d-none")
    //   this.birthdateDivTarget.innerText = this.application.clinic.account_birthdate_pretty
    // } else if (this.field == "sex") {
    //   this.sexDivTarget.classList.remove("d-none")
    //   this.sexInputDivTarget.classList.add("d-none")
    //   this.sexDivTarget.innerText = this.application.clinic.account_sex_pretty
    // } else if (this.field == "shares") {
    //   this.sharesDivTarget.classList.remove("d-none")
    //   this.sharesInputDivTarget.classList.add("d-none")
    //   this.sharesDivTarget.innerText = this.application.clinic.shares
    // } else if (this.field == "entriedAt") {
    //   this.entriedAtDivTarget.classList.remove("d-none")
    //   this.entriedAtInputDivTarget.classList.add("d-none")
    //   this.entriedAtDivTarget.innerText = this.application.clinic.entried_at_pretty
    // }

  }

  closeInput() {
    this.saveBtnTarget.classList.add("d-none")

    if (this.tradeNameDivTarget.classList.contains("d-none")) {
      this.tradeNameDivTarget.classList.remove("d-none")
      this.tradeNameInputDivTarget.classList.add("d-none")
      this.tradeNameDivTarget.innerText = this.tradeNameInputTarget.value
    }

    if (this.nameDivTarget.classList.contains("d-none")) {
      this.nameDivTarget.classList.remove("d-none")
      this.nameInputDivTarget.classList.add("d-none")
      this.nameDivTarget.innerText = this.nameInputTarget.value
    }

    // if (this.entriedAtDivTarget.classList.contains("d-none")) {
    //   this.entriedAtDivTarget.classList.remove("d-none")
    //   this.entriedAtInputDivTarget.classList.add("d-none")
    //   this.entriedAtDivTarget.innerText = this.entriedAtInputTarget.value
    // }

    // if (this.sharesDivTarget.classList.contains("d-none")) {
    //   this.sharesDivTarget.classList.remove("d-none")
    //   this.sharesInputDivTarget.classList.add("d-none")
    //   this.sharesDivTarget.innerText = this.getControllerByIdentifier("app--helpers--numbers").numberMask(this.sharesInputTarget.value)
    // }
  }

  clickCheckbox() {
    this.saveBtnTarget.classList.remove("d-none")
    this.refreshSaveBtn()
  }

  saveCompany() {
    this.send_data = { current_user: {}, company: {}, kind: {} }

    this.editBtnTargets.forEach(element => {
      element.classList.remove("d-none")
    });

    this.cancelBtnTargets.forEach(element => {
      element.classList.add("d-none")
    });

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.closeInput())
    }).then(() => {
      controller.send_data.current_user.current_user_id = this.application.current_user.id
      controller.send_data.current_user.feature = `medclinic_regularizations`

      controller.send_data.kind.kind = this.application.clinic.company_kind

      controller.send_data.company.id = this.application.clinic.company_id
      controller.send_data.company.trade_name = controller.tradeNameDivTarget.innerText.trim()
      controller.send_data.company.name = controller.nameDivTarget.innerText.trim()

      controller.stopRefreshing()
      controller.requestSave()
    })

  }

  requestSave() {
    var url = "/users/companies/entities/update"
    var method = "PUT"
    var controller = this
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          controller.stopRefreshing()
          var company = response.data.cln
          controller.application.clinic.company_name = company.name
          controller.application.clinic.company_trade_name = company.trade_name

        } else {
          this.saveBtnTarget.disabled = false
          if (this.hasDeleteBtnTarget) {
            this.deleteBtnTarget.disabled = true
          }
        }
        this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", this.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  refreshSaveBtn() {
    this.stopRefreshing()
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0

      if (controller.nameInputTarget.classList.contains("d-none") && controller.nameInputTarget.value == "") {
        len += 1
      }

      if (controller.tradeNameInputTarget.classList.contains("d-none") && controller.tradeNameInputTarget.value == "") {
        len += 1
      }

      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}