import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["content", "mainDashboard", "contentDashboard", "indicadorDashboard", "calculatorsDashboard", "productsDashboard", "postCardBody", "productCardBody", "main"]

  connect() {
    // this.getCurrentUserPermissions()
    this.controllerName = `users--dashboards--main--team`
    this.teamPreloader()
  }

  goToURL(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_self`)
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0
      controller.validGroupTargets.forEach(element => {
        if (this.fromStringToBoolean(element.dataset.valid) == false) {
          len += 1
        }
      });
      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  teamPreloader() {

    var html = `<div class="row" data-controller="users--dashboards--main--tickets users--works--activities--tickets--resume users--dashboards--main--alias" data-users--dashboards--main--team-target="mainDashboard">
                  <div class="col-4" data-users--dashboards--main--alias-target="alias"></div>
                  
                  <div class="col-8" data-${this.controllerName}-target="main"></div>
                  
                </div>`

    

    // `<div class="col-2" data-users--dashboards--main--content-target="calculator"></div>`
    // `<div class="col-2" data-users--dashboards--main--alias-target="tutorials"></div>`

    
    var controller = this
    new Promise(function (resolve) {
      resolve(controller.contentTarget.innerHTML = html)
    }).then(() => {
      controller.getControllerByIdentifier(`users--dashboards--main--tickets`).gridElement = controller.mainTarget
      controller.getControllerByIdentifier(`users--dashboards--main--tickets`).typePreloader()
      // controller.getControllerByIdentifier(`users--works--activities--tickets--resume`).gridElement = controller.mainTarget
    })

  }

  getCurrentUserPermissions() {
    const featureNames = ["financial_purchase_entities", "user_works_trackers_projects", "company_entities", "account_entities", "posts", "user_permissions_features",
                          "operation_onboard_entities", "financial_settings", "operations", "medclinic_financial_receivables", "commercial_main_dashboard",
                          "app_stats_tracker_general", "user_works_recruitments_vacancies"]
    const data = { permission: { features: featureNames }, current_user: { current_user_id: this.application.current_user.id } }
    const url = "/users/permissions/list_by_features"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        response.data.cln.forEach(permission => {
          controller.application.permissions[permission.name] = permission.data
        });
        controller.teamPreloader()
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
