import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "list", "view", "viewCard", "viewTitle", "mainCard", "bodyTable", "footerTable", "submenu", "addNewLeadBtn",
                    "labelUpload", "uploadBtn", "fileName", "progressUpload", "progressUploadBar", "progressUploadCounter", "profileLink",
                    "cancelProfileBtn", "listProfiles", "addProfileBtn", "cancelProfileBtn",
                    "nonContacts", "totalSQLs", "searchLeadBtn", "refreshLeadBtn"]

  connect() {
    this.controllerName = `commercial--sales--leads--entities--show`
    this.application.searchMode = false
    this.application.leads = []
    this.application.search_leads = []
    this.filesPermittedTypes = ["pdf", "PDF", "png", "PNG"]
    this.doViewCard()
  }

  showOpportunities() {
    var opportunitiesHtml = ``
    var productHtml = ``
    var productsHtml = ``

    if (this.current_lead.has_products) {
      if (this.current_lead.products.has_tax_return) {
        productHtml += `<span class="badge badge-primary mx-2">Planner</span>`
      }
      if (this.current_lead.products.has_booking) {
        productHtml += `<span class="badge badge-primary mx-2">Livro-Caixa</span>`
      }
      if (this.current_lead.products.has_clinic) {
        productHtml += `<span class="badge badge-primary mx-2">PJ Médica</span>`
      }
      if (this.current_lead.products.has_billing) {
        productHtml += `<span class="badge badge-primary mx-2">Faturamento</span>`
      }
      if (this.current_lead.products.has_receivement) {
        productHtml += `<span class="badge badge-primary mx-2">Gestão de Recebimentos</span>`
      }
      if (this.current_lead.products.has_tax_filing) {
        productHtml += `<span class="badge badge-primary mx-2">Declaração IRPF</span>`
      }
      if (this.current_lead.products.has_banking) {
        productHtml += `<span class="badge badge-primary mx-2">Conta Bancária</span>`
      }

      productsHtml += `<div class="row my-2">
                        <div class="col-12 px-1 f-075">${this.current_lead.name} é Cliente nos Serviços:</div>
                      </div>
                      <div class="row my-3">
                        ${productHtml}
                      </div>
                      <div class="row my-2">
                        <div class="col-12 px-1 f-075">E possui as seguintes Oportunidades:</div>
                      </div>`
    } else {
      productsHtml += `<div class="row my-2">
                        <div class="col-12 px-1 f-075">${this.current_lead.name} ainda não é Cliente</div>
                      </div>
                      <div class="row my-2">
                        <div class="col-12 px-1 f-075">E possui as seguintes Oportunidades para se tornar Cliente:</div>
                      </div>`
    }

    this.current_lead.opportunities.forEach(element => {
      opportunitiesHtml += `<div class="col-4 px-1 my-3">
                              <div class="card border-top-primary">
                                <div class="card-header p-1 text-center f-065">
                                  <span>${element.name}</span>
                                </div>
                                <div class="card-body text-center py-2 mc-tooltip pointer" data-url="${element.opportunity_path}" data-action="click->${this.controllerName}#goToURL">
                                  <div class="card-show-dropdown">
                                  <span class="material-icons md-dark">monetization_on</span>
                                    <div class="card-show-dropdown-content text-left">
                                      <p class="mb-0 f-065">${element.name}</p>
                                      <p class="mb-0 f-065">Estágio: ${element.stage_pretty}</p>
                                      <p class="mb-0 f-065">Valor: ${this.getControllerByIdentifier("app--helpers--numbers").currencyMask(parseInt(element.total_amount_recurring * 100))}</p>
                                      <p class="mb-0 f-065">Incío: ${element.started_at_pretty}</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>`
    })

    var html = `${productsHtml}
                
                <div class="row my-3">
                  ${opportunitiesHtml}
                </div>
                <div class="row my-3">
                  <div class="col-12 px-1 f-075">Se for adicionar uma nova Oportunidade, clique aqui:</div>
                </div>
                <div class="row my-3">
                  <div class="col-9 px-1">
                    <div class="card pointer" data-action="click->${this.controllerName}#addNewOportunity">
                      <div class="card-header p-1 text-center f-075 bg-primary">
                        <span>Adicionar Nova Oportunidade</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-3 px-1">
                    <div class="card pointer" data-action="click->${this.controllerName}#cancelNew">
                      <div class="card-header p-1 text-center f-075 bg-secondary">
                        <span>Cancelar</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row my-3">
                  <div class="col-12 px-1 f-075">Se for adicionar ao Radar, clique aqui:</div>
                </div>
                <div class="row my-3">
                  <div class="col-9 px-1">
                    <div class="card pointer" data-action="click->${this.controllerName}#addNewRadar">
                      <div class="card-header p-1 text-center f-075 bg-primary">
                        <span>Adicionar Novo Radar</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-3 px-1">
                    <div class="card pointer" data-action="click->${this.controllerName}#cancelNew">
                      <div class="card-header p-1 text-center f-075 bg-secondary">
                        <span>Cancelar</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row my-4">
                  <div class="col-12 px-0">
                    <h6 class="mb-0 d-flex align-items-center">
                      <span>Perfil do Cliente</span>
                      <span class="mc-tooltip ml-auto pointer" data-action="click->${this.controllerName}#addProfile" data-${this.controllerName}-target="addProfileBtn">
                        <span class="material-icons md-sm md-dark">add</span>
                        <span class="mc-tooltiptext">Adicionar Perfil do Cliente</span>
                      </span>
                      <span class="mc-tooltip ml-auto pointer d-none" data-action="click->${this.controllerName}#cancelProfile" data-${this.controllerName}-target="cancelProfileBtn">
                        <span class="material-icons md-sm md-dark">clear</span>
                        <span class="mc-tooltiptext">Cancelar</span>
                      </span>
                    </h6>
                    <hr class="my-1">
                  </div>
                </div>
                <div class="row" data-${this.controllerName}-target="listProfiles">
                  <span class="w-100">${this.loader}</span>
                </div>`


    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("commercial--sales--leads--entities--dashboard").viewTarget.innerHTML = html)
    }).then(() => {
      controller.setProfile()      
    })
  }

  cancelNew() {
    this.getControllerByIdentifier(`commercial--sales--leads--entities--list`).clearList()
  }

  doViewCard() {
    var html = `<div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="viewLeadCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 py-3" style="font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="viewTitle">Nenhum Lead Selecionado</h6>
                  </div>
                  <div class="card-body py-0 px-2" style="overflow:auto;" data-${this.controllerName}-target="viewLeadCardBody"></div>
                </div>`

    this.getControllerByIdentifier("commercial--sales--leads--entities--dashboard").viewTarget.innerHTML = html
  }

  addNewOportunity() {
    this.getControllerByIdentifier("commercial--sales--opportunities--entities--save").current_lead = this.current_lead
    this.getControllerByIdentifier("commercial--sales--opportunities--entities--save").doFormHtml()
  }

  addNewRadar() {
    this.getControllerByIdentifier("commercial--sales--opportunities--radars--save").current_lead = this.current_lead
    this.getControllerByIdentifier("commercial--sales--opportunities--radars--save").actionMode = `new`
    this.getControllerByIdentifier("commercial--sales--opportunities--radars--save").doFormHtml()
  }

  setProfile() {
    var html = ``

    if (this.current_lead.has_profile) {
      this.addProfileBtnTarget.classList.add(`d-none`)
      var profile = `<span class="f-085 text-bold">Perfil do Cliente</span>
                          <button data-action="click->${this.controllerName}#goToURL" data-url="${this.current_lead.profile_url}" type="button" class="btn btn-sm btn-table p-0 mc-tooltip ml-auto">
                            <span class="material-icons md-sm md-dark">launch</span>
                            <span class="mc-tooltiptext">Ver Perfil do Cliente</span>
                          </button>
                          <button data-action="click->${this.controllerName}#deleteFile" data-id="${this.current_lead.id}" data-field="profile" type="button" class="btn btn-sm btn-table p-0 mc-tooltip ml-2">
                            <span class="material-icons md-sm md-dark">delete</span>
                            <span class="mc-tooltiptext">Apagar Perfil do Cliente</span>
                          </button>`
    } else {
      this.addProfileBtnTarget.classList.remove(`d-none`)
      var profile = `<span class="f-085 text-bold">Sem Arquivo Perfil do Cliente</span>`
    }

    html += `<div class="row w-100 mt-3">
              <div class="col-12 d-flex align-items-center">
                <div class="row w-100" data-${this.controllerName}-target="fileLinks">
                  <div class="col-12 my-1 d-flex px-0">
                    ${profile}
                  </div>
                </div>
              </div>
            </div>`

    this.listProfilesTarget.innerHTML = html
  }

  addProfile() {
    this.uploadFeature = true
    this.canUploadFile = false
    this.uploadReady = false

    var html = `<div class="row my-3">
                  <div class="col-6 px-0 d-flex align-items-center justify-content-center">
                    <label class="drop-area copy" for="dropzoneArea" data-${this.controllerName}-target="labelUpload" data-action="dragover->${this.controllerName}#dragOverHandler drop->${this.controllerName}#dropHandler">
                      <div class="row my-2">
                        <div class="col p-0 d-flex align-items-center justify-content-center">
                          <span class="material-icons md-lg md-dark mx-3">add_a_photo</span>
                        </div>
                      </div>
                    </label>
                    <input class="file-input mt-0" type="file" id="dropzoneArea" multiple data-action="change->${this.controllerName}#dropHandler"></input>
                  </div>
                  <div class="col-6 px-0 d-flex align-items-center justify-content-center">
                    <div class="card bg-disabled w-50 h-50" data-action="click->${this.controllerName}#uploadFile" data-${this.controllerName}-target="uploadBtn">
                      <div class="row my-1">
                        <div class="col-12 d-flex align-items-center justify-content-center">
                          <span class="material-icons md-white">cloud_upload</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 px-0 d-flex align-items-center justify-content-center">
                    <span data-${this.controllerName}-target="fileName"></span>
                  </div>
                  <div class="col-12 px-0 d-flex align-items-center justify-content-center" data-${this.controllerName}-target="profileLink">
                  </div>
                </div>`


    this.listProfilesTarget.innerHTML = html
    this.addProfileBtnTarget.classList.add(`d-none`)
    this.cancelProfileBtnTarget.classList.remove(`d-none`)
    this.refreshProfileBtn()
  }

  cancelProfile() {
    this.addProfileBtnTarget.classList.remove(`d-none`)
    this.cancelProfileBtnTarget.classList.add(`d-none`)
    this.setProfile()
  }

  uploadSwitch(value) {
    if (value) {
      this.uploadBtnTarget.classList.remove("bg-disabled")
      this.uploadBtnTarget.classList.add("pointer")
      this.uploadBtnTarget.classList.add("bg-primary")
      this.uploadFeature = false
      this.canUploadFile = true
    } else {
      this.uploadBtnTarget.classList.add("bg-disabled")
      this.uploadBtnTarget.classList.remove("pointer")
      this.uploadBtnTarget.classList.remove("bg-primary")
      this.uploadFeature = true
      this.canUploadFile = false
    }
  }

  dragOverHandler(ev) {
    ev.preventDefault();
  }

  dropHandler(ev) {
    ev.preventDefault()

    if (ev.target.files.length > 0 && this.uploadFeature) {
      if (ev.type == "change") {
        this.file = ev.target.files[0]

        var element = this.file
        var obj = {}
        var date = new Date()
        obj.file_name = element.name
        obj.file_size = element.size
        obj.file_type = element.type
        obj.file_last_modified = element.lastModified
        obj.uploaded = false
        obj.message_erro = ""
        obj.created_at_time = parseInt(date.getTime() / 1000)
        if ((element.size / 1000000).toFixed(2) <= 5 && (this.filesPermittedTypes.includes(element.type.split("/")[1]) || this.filesPermittedTypes.includes(element.name.substr(element.name.lastIndexOf('.') + 1)))) {
          obj.can_upload = true

          obj.file = element
          this.upload_file = obj
          this.uploadReady = true
          this.fileNameTarget.innerText = this.upload_file.file_name
        } else {
          if ((element.size / 1000000).toFixed(2) > 5) {
            obj.message_erro += "Arquivo deve ser menor que 5MB.\n"
          }

          if (!this.filesPermittedTypes.includes(element.type.split("/")[1])) {
            obj.message_erro += "Formato do arquivo não é permitido."
          }
          obj.can_upload = false
          this.uploadReady = false
          this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", obj.message_erro, 5000)
        }
      }
    } else {
      this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", `O Upload de Arquivos está desabilitado`, 3000)
    }
  }

  uploadFile() {
    if (this.canUploadFile) {
      var progressSpan = `<div class="form-group my-0 text-center w-100">
                            <h7 class="progress">
                              <span class="progress_count"></span>
                            </h7>
                            <div class="progress" style="height: 6px;overflow: inherit;" data-${this.controllerName}-target="progressUpload">
                              <div class="progress-bar" role="progressbar" style="width:0%;border-bottom:0.5rem solid #053B5E;" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100" data-${this.controllerName}-target="progressUploadBar"></div>
                            </div>
                            <span data-${this.controllerName}-target="progressUploadCounter" class="d-block"></span>
                          </div>`

      this.upload_file.kind = `profile`

      var controller = this
      new Promise(function (resolve) {
        resolve(controller.profileLinkTarget.innerHTML = progressSpan)
      }).then(() => {
        controller.progressCount(0)
        controller.requestUpload(controller.current_lead.id, controller.upload_file.file, controller.upload_file.kind)
      })
    }
  }

  requestUpload(lead_id, file, kind) {
    var formData = new FormData()
    formData.append('file', file)
    formData.append('field', kind)
    formData.append('lead_id', lead_id)
    formData.append('current_user_id', this.application.current_user.id)
    formData.append('feature', `sales_qualified_leads`)

    var url = "/commercial/sales/leads/entities/save_upload"
    var method = "PUT"
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token }, body: formData }

    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          controller.current_lead = response.data.cln

          controller.progressCount(100)
          controller.stopRefreshing()
          controller.uploadSwitch(false)
          controller.cancelProfile()
        }

        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  deleteFile(ev) {
    this.send_data = { current_user: {}, lead: {} }

    this.send_data.lead.id = this.current_lead.id
    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = `sales_qualified_leads`

    var url = "/commercial/sales/leads/entities/destroy_upload"
    var method = "DELETE"
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }

    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          controller.current_lead = response.data.cln

          controller.stopRefreshing()
          controller.cancelProfile()
        }

        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  progressCount(value) {
    var controller = this
    this.progressUploadBarTarget.style.width = value + `%`
    var i = 0
    if (value != 100) {
      this.progressCountTimer = setInterval(function () {
        controller.progressUploadBarTarget.style.width = Math.floor(i + 1) + `%`
        controller.progressUploadCounterTarget.innerText = Math.floor(i + 1) + `%`
        i++
        if (i == 95) {
          i = 94
        }
      }, 500);
    } else {
      this.stopRefreshing()
      this.progressUploadCounterTarget.innerText = value + `%`
    }
  }

  goToURL(ev) {
    var url = ev.currentTarget.dataset.url
    // window.open(url, `_self`)
    window.open(url, `_blank`)
  }

  readLead() {
    const data = { lead: { id: this.lead_id }, current_user: { current_user_id: this.application.current_user.id, feature: `sales_qualified_leads` } }
    const url = "/commercial/sales/leads/entities/read"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.current_lead = response.data.cln
        controller.opportunities = controller.current_lead.opportunities

        // if (controller.opportunities.length == 0) {
        //   controller.addNewOportunity()
        // } else {
        // }
        controller.showOpportunities()

      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  stopRefreshing() {
    if (this.refreshProfileTimer) {
      clearInterval(this.refreshProfileTimer)
    }
    if (this.progressCountTimer) {
      clearInterval(this.progressCountTimer)
    }
  }

  refreshProfileBtn() {
    var controller = this
    this.refreshProfileTimer = setInterval(function () {
      var len = 0

      if (controller.uploadReady == false) {
        len += 1
      }

      if (controller.hasUploadBtnTarget) {
        if (len == 0) {
          controller.uploadSwitch(true)
        } else {
          controller.uploadSwitch(false)
        }
      } else {
        controller.stopRefreshing()
      }

    }, 200);
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = "operations--products--tax-filings--entities--list"
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
