import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["list", "view", "main", "submenu", "5555", "6666", "7777", "8888", "9999"]

  connect() {
    this.controllerName = `financials--products--purchases--records--dashboard`
    this.doPageGrid()
  }

  doPageGrid() {
    this.stopRefreshing()
    var html = `<div class="row my-4">
                  <div class="col-9 px-1" data-controller="financials--products--purchases--records--index" data-${this.controllerName}-target="list"></div>
                  <div class="col-3 px-1" data-controller="financials--products--purchases--records--show financials--products--purchases--records--save" data-${this.controllerName}-target="view"></div>
                </div>`

    this.mainTarget.innerHTML = html
  }

  goToPurchases() {
    var url = `/a/contratacoes`
    window.open(url, `_self`)
  }

  goToRecords() {
    var url = `/a/todas-contratacoes`
    window.open(url, `_self`)
  }

  doSubmenuHtml() {
    var submenuHtml = `<div class="dropdown mc-tooltip">
                        <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-toggle="dropdown" id="submenuMenuDrop" type="button"><i class="material-icons">apps</i></button>
                        <div aria-labelledby="submenuMenuDrop" class="dropdown-menu dropdown-menu-right menu" style="font-size:80%">
                          <span data-action="click->${this.controllerName}#goToPurchases" class="dropdown-item py-1 pointer">Dashboard</span>
                          <span data-action="click->${this.controllerName}#goToRecords" class="dropdown-item py-1 pointer">Todas as Contratações</span>
                        </div>
                        <span class="mc-tooltiptext">Submenu</span>
                      </div>`

    this.submenuTarget.innerHTML = submenuHtml
  }

  userCanUpdate(ticketId) {
    var ticket = {}
    this.application.in_process_tickets.forEach(element => {
      if (element.id == ticketId) {
        ticket = element
      }
    })
    var allowed = [ticket.owner_id, ticket.performer_id]
    if (allowed.includes(this.application.current_user.account_id) && this.stage != "done") {
      return true
    } else {
      return false
    }
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0
      controller.validGroupTargets.forEach(element => {
        if (this.fromStringToBoolean(element.dataset.valid) == false) {
          len += 1
        }
      });
      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  goToURL(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_self`)
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    });
    this.getControllerByIdentifier("app--helpers--console").console(results)
    return results
  }

  // var controller = this
  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
