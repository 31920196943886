import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["viewRegimeCard", "viewTitle", "viewRegimeCardBody", "previewCard", "previewTitle", "finalAdjustedLabel",
                    "potentialPgbl", "diffPgbl", "economyPgbl", "realDueTax", "potentialDueTax", "editBtn"]

  connect() {
    this.controllerName = `operations--products--tax-filings--summaries--show`
    this.numberController = this.getControllerByIdentifier(`app--helpers--numbers`)
    this.spaceRow = `<td colspan="5" style="height:0rem;padding-top:1rem;padding-bottom:1rem;" scope="col"></td>`

    this.doShowHtml()
  }

  doShowHtml() {
    // ${this.numberController.currencyOptionMask(this.current_receivable.iss_tax_amount, { scale: 4 })}
    // ${this.numberController.percentOptionMask(this.current_receivable.iss_rate, { scale: 4 })}


    var html = `<div class="row">
                  <div class="col-11 pl-0">
                    <div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="previewCard" data-action="resize@window->${this.controllerName}#layout">
                      <div class="card-header d-flex align-items-center card-header-table-list f-065">
                        <h6 class="card-title display-4" style="padding:1rem;font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="previewTitle">Resumo IRPF ${this.application.current_date.financial_year}</h6>
                        <h6 class="ml-auto card-title display-4" style="padding:1rem;font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="previewTitle">Status Ajuste Final do IRPF ${this.application.current_date.financial_year}: ${this.application.current_summary.statement_pretty}</h6>
                      </div>
                      <div class="card-body py-0" style="overflow:auto;" data-${this.controllerName}-target="previewCardBody">
                        <div class="row">
                          <div class="col-4">
                            ${this.doChartAccountsHtml()}
                          </div>
                          <div class="col-4">
                            ${this.doResumeHtml()}
                          </div>
                          <div class="col-4">
                            ${this.doEcononyHtml()}
                          </div>
                        </div>
                      </div>
                      <div class="card-footer border-top text-right py-1">
                        <button type="button" class="btn btn-secondary btn-sm py-2 f-065" data-action="click->${this.controllerName}#editSummary" data-${this.controllerName}-target="editBtn">Editar</button>
                      </div>
                    </div>
                  </div>
                  <div class="col-1 text-center pr-0" data-${this.controllerName}-target="action">
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier(`operations--products--tax-filings--summaries--dashboard`).viewTarget.innerHTML = html)
    }).then(() => {
      controller.previewCardTarget.style.height = ($(window).height() * 0.6) + "px"

      if (controller.application.current_user.account_kind == `doctor`) {
        controller.editBtnTarget.remove()
      }

      var finalAdjusted = Number(this.application.current_summary.adjusted_income_tax_amount)

      if (finalAdjusted > 0) {
        this.finalAdjustedLabelTarget.innerText = `Saldo de Imposto à Pagar`
      } else if (finalAdjusted < 0) {
        this.finalAdjustedLabelTarget.innerText = `Imposto à Restituir`
      } else if (finalAdjusted == 0) {
        this.finalAdjustedLabelTarget.innerText = `Sem Ajuste`
      }

      controller.doEconomyCalculus()
      controller.getControllerByIdentifier("app--helpers--elements").tooltip()
      controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
    })
  }

  doChartAccountsHtml() {
    var spaceRow = `<td colspan="10" style="height:2rem;padding-top:1rem;padding-bottom:1rem;" scope="col"></td>`

    var html = `<div class="" style="width:100%;display:relative;" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-body p-0" style="overflow:auto;" data-${this.controllerName}-target="previewCardBody">
                    <div class="row">
                      <div class="col-12 px-0">
                        <table class="table table-sm table-borderless mb-0" style="font-size:80%;">
                          <tbody>
                            ${this.tableHeader()}
                            <tr>
                              <th colspan="7" class="f-065 align-middle px-0">
                                <strong>Total Rendimento Tributável</strong>
                              </th>
                              <td colspan="3" class="f-065 align-middle px-0 text-center">
                                ${this.numberController.currencyOptionMask(this.application.current_summary.total_taxable_income, { scale: 2 })}
                              </td>
                            <tr>
                            <tr>
                              <th colspan="7" class="f-065 align-middle px-0">
                                <strong>Total Rendimento Isento e Não-Tributável</strong>
                              </th>
                              <td colspan="3" class="f-065 align-middle px-0 text-center">
                                ${this.numberController.currencyOptionMask(this.application.current_summary.total_exempt_and_non_taxable_income, { scale: 2 })}
                              </td>
                            <tr>
                            <tr>
                              <th colspan="7" class="f-065 align-middle px-0">
                                <strong>Total Rendimento Tributação Exclusiva</strong>
                              </th>
                              <td colspan="3" class="f-065 align-middle px-0 text-center">
                                ${this.numberController.currencyOptionMask(this.application.current_summary.total_income_exclusive_taxation, { scale: 2 })}
                              </td>
                            <tr>
                            
                            <tr>
                              ${spaceRow}
                            </tr>

                            <tr>
                              <th colspan="7" class="f-065 align-middle px-0">
                                <strong>Previdência Oficial Pública</strong>
                              </th>
                              <td colspan="3" class="f-065 align-middle px-0 text-center">
                                ${this.numberController.currencyOptionMask(this.application.current_summary.official_social_contribution, { scale: 2 })}
                              </td>
                            <tr>
                            <tr>
                              <th colspan="7" class="f-065 align-middle px-0">
                                <strong>Previdência Privada</strong>
                              </th>
                              <td colspan="3" class="f-065 align-middle px-0 text-center">
                                ${this.numberController.currencyOptionMask(this.application.current_summary.supplementary_pension, { scale: 2 })}
                              </td>
                            <tr>
                            <tr>
                              <th colspan="7" class="f-065 align-middle px-0">
                                <strong>Dedução Dependentes</strong>
                              </th>
                              <td colspan="3" class="f-065 align-middle px-0 text-center">
                                ${this.numberController.currencyOptionMask(this.application.current_summary.dependents, { scale: 2 })}
                              </td>
                            <tr>
                            <tr>
                              <th colspan="7" class="f-065 align-middle px-0">
                                <strong>Dedução com Instrução</strong>
                              </th>
                              <td colspan="3" class="f-065 align-middle px-0 text-center">
                                ${this.numberController.currencyOptionMask(this.application.current_summary.instruction_expense, { scale: 2 })}
                              </td>
                            <tr>
                            <tr>
                              <th colspan="7" class="f-065 align-middle px-0">
                                <strong>Dedução com Saúde</strong>
                              </th>
                              <td colspan="3" class="f-065 align-middle px-0 text-center">
                                ${this.numberController.currencyOptionMask(this.application.current_summary.medical_expenses, { scale: 2 })}
                              </td>
                            <tr>
                            <tr>
                              <th colspan="7" class="f-065 align-middle px-0">
                                <strong>Dedução com Pensão Alimentícia</strong>
                              </th>
                              <td colspan="3" class="f-065 align-middle px-0 text-center">
                                ${this.numberController.currencyOptionMask(this.application.current_summary.alimony, { scale: 2 })}
                              </td>
                            <tr>
                            <tr>
                              <th colspan="7" class="f-065 align-middle px-0">
                                <strong>Dedução Despesas Livro-Caixa</strong>
                              </th>
                              <td colspan="3" class="f-065 align-middle px-0 text-center">
                                ${this.numberController.currencyOptionMask(this.application.current_summary.booking, { scale: 2 })}
                              </td>
                            <tr>
                            

                            
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>`

    return html
  }

  doResumeHtml() {
    var spaceRow = `<td colspan="10" style="height:2rem;padding-top:1rem;padding-bottom:1rem;" scope="col"></td>`

    var html = `<div class="" style="width:100%;display:relative;" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-body p-0" style="overflow:auto;" data-${this.controllerName}-target="previewCardBody">
                    <div class="row">
                      <div class="col-12 px-0">
                        <table class="table table-sm table-borderless mb-0" style="font-size:80%;">
                          <tbody>
                            ${this.tableHeader()}
                            <tr>
                              <th colspan="7" class="f-065 align-middle px-0">
                                <strong>Total Rendimento</strong>
                              </th>
                              <td colspan="3" class="f-065 align-middle px-0 text-center">
                                ${this.numberController.currencyOptionMask(this.application.current_summary.total_income, { scale: 2 })}
                              </td>
                            <tr>
                            <tr>
                              <th colspan="7" class="f-065 align-middle px-0">
                                <strong>Total Deduções</strong>
                              </th>
                              <td colspan="3" class="f-065 align-middle px-0 text-center">
                                ${this.numberController.currencyOptionMask(this.application.current_summary.total_deductions, { scale: 2 })}
                              </td>
                            <tr>
                            <tr>
                              <th colspan="7" class="f-065 align-middle px-0">
                                <strong>Base de Cálculo</strong>
                              </th>
                              <td colspan="3" class="f-065 align-middle px-0 text-center">
                                ${this.numberController.currencyOptionMask(this.application.current_summary.calculation_basis, { scale: 2 })}
                              </td>
                            <tr>
                            
                            <tr>
                              ${spaceRow}
                            </tr>

                            <tr>
                              <th colspan="7" class="f-065 align-middle px-0">
                                <strong>Imposto Total Devido</strong>
                              </th>
                              <td colspan="3" class="f-065 align-middle px-0 text-center">
                                ${this.numberController.currencyOptionMask(this.application.current_summary.tax_due, { scale: 2 })}
                              </td>
                            <tr>
                            <tr>
                              <th colspan="7" class="f-065 align-middle px-0">
                                <strong>Imposto Total Retido</strong>
                              </th>
                              <td colspan="3" class="f-065 align-middle px-0 text-center">
                                ${this.numberController.currencyOptionMask(this.application.current_summary.total_withholding_income_tax, { scale: 2 })}
                              </td>
                            <tr>
                            <tr>
                              <th colspan="7" class="f-065 align-middle px-0">
                                <strong>Dedução Incentivo</strong>
                              </th>
                              <td colspan="3" class="f-065 align-middle px-0 text-center">
                                ${this.numberController.currencyOptionMask(this.application.current_summary.incentive_deduction, { scale: 2 })}
                              </td>
                            <tr>
                            <tr>
                              <th colspan="7" class="f-065 align-middle px-0">
                                <strong>Imposto Devido RRA</strong>
                              </th>
                              <td colspan="3" class="f-065 align-middle px-0 text-center">
                                ${this.numberController.currencyOptionMask(this.application.current_summary.tax_due_rra, { scale: 2 })}
                              </td>
                            <tr>
                            
                            <tr>
                              <th colspan="7" class="f-065 align-middle px-0">
                                <strong data-${this.controllerName}-target="finalAdjustedLabel"></strong>
                              </th>
                              <td colspan="3" class="f-065 align-middle px-0 text-center">
                                ${this.numberController.currencyOptionMask(Math.abs(this.application.current_summary.adjusted_income_tax_amount), { scale: 2 })}
                              </td>
                            <tr>
                            <tr>
                              <th colspan="7" class="f-065 align-middle px-0">
                                <strong>Tipo Tributação</strong>
                              </th>
                              <td colspan="3" class="f-065 align-middle px-0 text-center">
                                ${this.application.current_summary.taxation_pretty}
                              </td>
                            <tr>
                            
                            

                            
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>`

    return html
  }

  doEcononyHtml() {
    var spaceRow = `<td colspan="10" style="height:2rem;padding-top:1rem;padding-bottom:1rem;" scope="col"></td>`

    var html = `<div class="" style="width:100%;display:relative;" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-body p-0" style="overflow:auto;" data-${this.controllerName}-target="previewCardBody">
                    <div class="row">
                      <div class="col-12 px-0">
                        <table class="table table-sm table-borderless mb-0" style="font-size:80%;">
                          <tbody>
                            ${this.tableHeader()}
                            <tr>
                              <th colspan="7" class="f-065 align-middle px-0">
                                <strong>PGBL Potencial</strong>
                              </th>
                              <td colspan="3" class="f-065 align-middle px-0 text-center" data-${this.controllerName}-target="potentialPgbl">
                              </td>
                            <tr>
                            <tr>
                              <th colspan="7" class="f-065 align-middle px-0">
                                <strong>PGBL Real</strong>
                              </th>
                              <td colspan="3" class="f-065 align-middle px-0 text-center">
                                ${this.numberController.currencyOptionMask(this.application.current_summary.supplementary_pension, { scale: 2 })}
                              </td>
                            <tr>
                            <tr>
                              <th colspan="7" class="f-065 align-middle px-0">
                                <strong>Diferença PGBL</strong>
                              </th>
                              <td colspan="3" class="f-065 align-middle px-0 text-center" data-${this.controllerName}-target="diffPgbl">
                              </td>
                            <tr>
                            <tr>
                              <th colspan="7" class="f-065 align-middle px-0">
                                <strong>Economia PGBL</strong>
                              </th>
                              <td colspan="3" class="f-065 align-middle px-0 text-center" data-${this.controllerName}-target="economyPgbl">
                              </td>
                            <tr>
                            
                            <tr>
                              ${spaceRow}
                            </tr>

                            <tr>
                              <th colspan="7" class="f-065 align-middle px-0">
                                <strong>Imposto Total Devido Real</strong>
                              </th>
                              <td colspan="3" class="f-065 align-middle px-0 text-center" data-${this.controllerName}-target="realDueTax">
                                ${this.numberController.currencyOptionMask(this.application.current_summary.tax_due, { scale: 2 })}
                              </td>
                            <tr>
                            <tr>
                              <th colspan="7" class="f-065 align-middle px-0">
                                <strong>Imposto Total Devido Potencial</strong>
                              </th>
                              <td colspan="3" class="f-065 align-middle px-0 text-center" data-${this.controllerName}-target="potentialDueTax">
                              </td>
                            <tr>
                            
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>`

    return html
  }

  editSummary() {
    var modalName = `operations--products--tax-filings--summaries--save`

    var html = `<div class="modal fade" data-controller="${modalName}" data-${modalName}-target="modal" id="modal" tabindex="-1" role="dialog" aria-labelledby="modalTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                  <div class="modal-dialog modal-dialog-centered modal-slg" role="document">
                    <div class="modal-content">
                      <div class="modal-body p-0" data-${modalName}-target="body"></div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.getControllerByIdentifier(`operations--products--tax-filings--summaries--save`).controllerForm = controller
      controller.getControllerByIdentifier(`operations--products--tax-filings--summaries--save`).open()
    })
  }

  doEconomyCalculus() {

    var potentialPgbl = Number(this.application.current_summary.total_taxable_income) * 0.12
    this.potentialPgblTarget.innerText = this.numberController.currencyOptionMask(potentialPgbl)

    var diffPgbl = Number(potentialPgbl) - Number(this.application.current_summary.supplementary_pension)

    if (diffPgbl > 0) {
      this.diffPgblTarget.innerText = this.numberController.currencyOptionMask(diffPgbl)
      
      var potentialDueTax = this.setTaxDue(Number(this.application.current_summary.calculation_basis) - Number(diffPgbl))
      this.potentialDueTaxTarget.innerText = this.numberController.currencyOptionMask(potentialDueTax)
      
      var realDueTax = Number(this.application.current_summary.tax_due)
      var economyPgbl = Number(realDueTax) - Number(potentialDueTax)
      this.economyPgblTarget.innerText = this.numberController.currencyOptionMask(economyPgbl)
      
    } else {      
      this.diffPgblTarget.innerText = this.numberController.currencyOptionMask(0)
      this.potentialDueTaxTarget.innerText = this.numberController.currencyOptionMask(this.application.current_summary.tax_due)
      this.economyPgblTarget.innerText = this.numberController.currencyOptionMask(0)
    }

  }

  setTaxDue(base) {

    var due = 0

    if (base > Number(1903.98 * 12) && base <= Number(2826.65 * 12)) {
      due = Number(0.075 * base) - Number(142.80 * 12)
    } else if (base > Number(2826.65 * 12) && base <= Number(3751.05 * 12)) {
      due = Number(0.15 * base) - Number(354.80 * 12)
    } else if (base > Number(3751.05 * 12) && base <= Number(4664.68 * 12)) {
      due = Number(0.225 * base) - Number(636.13 * 12)
    } else if (base > Number(4664.68 * 12)) {
      due = Number(0.275 * base) - Number(869.36 * 12)
    } else if (base <= Number(1903.98 * 12)) {
      due = 0
    }

    return due
  }

  tableHeader() {
    var html = `<tr>
                  <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                  <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                  <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                  <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                  <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                  <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                  <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                  <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                  <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                  <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                </tr>`

    return html
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }


}