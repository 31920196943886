import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["mainCard", "bodyTable", "footerTable", "4444", "5555", "6666", "7777", "8888", "9999"]

  connect() {
    this.controllerName = `financials--books--payables--dashboard--list`
    this.controllerNumber = this.getControllerByIdentifier(`app--helpers--numbers`)
    this.itemsOnPage = 300
    this.pageNumber = 0
    this.numberPages = []
    this.tableCols = 5
    this.updatePayble = false
    this.sort = { mode: `asc`, field: `due_date_to_time` }
  }

  addPayable() {
    if (this.application.current_date.open) {
      this.getControllerByIdentifier("financials--books--payables--entities--dashboard").doPayablesDashboard()

      var controller = this
      new Promise(function (resolve) {
        resolve(controller.getControllerByIdentifier("financials--books--payables--entities--dashboard").doPayablesDashboard())
      }).then(() => {
        controller.getControllerByIdentifier("financials--books--payables--entities--save").current_payable = {}
        controller.getControllerByIdentifier("financials--books--payables--entities--save").actionMode = "new"
        controller.getControllerByIdentifier("financials--books--payables--entities--save").doFormGridHtml()
      })
    } else {
      this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(`danger`, `A Competência está Fechada. Favor selecionar a Competência atual ou abrir a Competência.`, 5000)
    }
  }

  destroyAllPayables() {
    var r = confirm(`Tem certeza que deseja apagar os Pagamentos?`)
    if (r) {
      this.requestDestroyAll()
    }
  }

  sortTable(ev) {
    var mode = ev.currentTarget.dataset.mode
    var field = ev.currentTarget.dataset.field

    if (mode == `desc`) {
      ev.currentTarget.nextElementSibling.classList.remove("d-none")
      ev.currentTarget.classList.add("d-none")
    } else if (mode == `asc`) {
      ev.currentTarget.previousElementSibling.classList.remove("d-none")
      ev.currentTarget.classList.add("d-none")
    }

    this.sort.mode = mode
    this.sort.field = field
    this.doDataTable()
  }

  doPayableList() {
    if (this.application.current_date) {
      this.getControllerByIdentifier(`financials--books--payables--dashboard--main`).payableListTitleTarget.innerText = `Todos os Pagamentos ${this.application.current_med.name} - ${this.application.current_date.due_name}`
    }

    if (this.application.permissions.financial_payable_entities.can_create) {
      var addLine = `<span data-action="click->${this.controllerName}#addPayable" class="dropdown-item py-1 pointer dropdown-submenu-item">Novo Contas à Pagar</span>`
    } else {
      var addLine = ``
    }

    if (this.application.permissions.financial_payable_entities.can_manage && this.application.current_user.super_admin) {
      var destroyLine = `<span data-action="click->${this.controllerName}#destroyAllPayables" class="dropdown-item py-1 pointer dropdown-submenu-item">Zerar Competência</span>`
    } else {
      var destroyLine = ``
    }

    if (this.application.permissions.financial_payable_entities.can_list) {
      var refreshLine = `<span data-action="click->${this.controllerName}#refreshPayable" class="dropdown-item py-1 pointer dropdown-submenu-item">Atualizar Contas à Pagar</span>`
    } else {
      var refreshLine = ``
    }

    var clearBtnHtml = `<span class="mc-tooltip">
                          <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0 d-none" data-${this.controllerName}-target="clearListBtn" data-action="click->${this.controllerName}#clearList" type="button">
                            <span class="material-icons md-dark">clear</span>
                          </button>
                          <span class="mc-tooltiptext">Limpar</span>
                        </span>`

    var submenuHtml = `<div class="dropdown mc-tooltip" data-${this.controllerName}-target="optionBtn">
                        <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-toggle="dropdown" id="submenuMenuDrop" type="button"><span class="material-icons md-dark">more_vert</span></button>
                        <div aria-labelledby="submenuMenuDrop" class="dropdown-menu dropdown-menu-right menu" style="font-size:80%">
                          ${addLine}
                          ${refreshLine}
                          ${destroyLine}
                        </div>
                        <span class="mc-tooltiptext">Opções</span>
                      </div>`

    var html = `<div class="card" style="width:100%;display:relative;" data-controller="app--helpers--search" data-${this.controllerName}-target="mainCard">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 card-title-table-list">Todas os Pagamentos | ${this.application.current_med.name}</h6>
                    <input class="form-control f-070 w-50 mx-auto" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchTable" placeholder="Buscar ..." type="text">
                    <div class="card-actions ml-auto py-0">
                      ${submenuHtml}
                      ${clearBtnHtml}
                    </div>
                  </div>
                  <div class="card-body py-0" style="overflow:auto;">
                    <div class="row">
                      <div class="col p-0">
                        <table class="table table-sm table-hover" data-app--helpers--search-target="searchTable" style="font-size:80%;">
                          <thead>
                            <tr>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-15 align-middle">
                                Vencimento
                                <span class="material-icons md-sm md-dark" data-action="click->${this.controllerName}#sortTable" data-field="due_date_to_time" data-mode="desc">south</span>
                                <span class="material-icons md-sm md-dark d-none" data-action="click->${this.controllerName}#sortTable" data-field="due_date_to_time" data-mode="asc">north</span>
                              </th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-30 align-middle">Descrição</th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-30 align-middle">
                                Conta
                                <span class="material-icons md-sm md-dark" data-action="click->${this.controllerName}#sortTable" data-field="chart_name" data-mode="desc">south</span>
                                <span class="material-icons md-sm md-dark d-none" data-action="click->${this.controllerName}#sortTable" data-field="chart_name" data-mode="asc">north</span>
                              </th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-15 align-middle">
                                Valor
                                <span class="material-icons md-sm md-dark" data-action="click->${this.controllerName}#sortTable" data-field="amount_to_integer" data-mode="desc">south</span>
                                <span class="material-icons md-sm md-dark d-none" data-action="click->${this.controllerName}#sortTable" data-field="amount_to_integer" data-mode="asc">north</span>
                              </th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-10 align-middle">Status</th>
                            </tr>
                          </thead>
                          <tbody data-${this.controllerName}-target="bodyTable">
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer py-0" data-${this.controllerName}-target="footerTable">
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier(`financials--books--payables--dashboard--main`).payableListTarget.innerHTML = html)
    }).then(() => {
      controller.mainCardTarget.style.height = ($(window).height() * 0.65) + "px"
      controller.bodyTableTarget.insertAdjacentHTML("beforeend", controller.getControllerByIdentifier("app--helpers--table").doTablePreloader(this.tableCols, 10))
      controller.doDataTable()
    })
  }

  doDataTable() {
    this.current_payables = []

    this.application.payables.forEach(element => {
      if (this.application.current_med.id) {
        if (element.due_date_id == this.application.current_date.id && element.paid == false && element.med_id == this.application.current_med.id) {
          this.current_payables[this.current_payables.length] = element
        } else if (element.due_date_id == this.application.current_date.id && element.subkind == `prepaid_expenses` && element.recognized == false && element.med_id == this.application.current_med.id) {
          this.current_payables[this.current_payables.length] = element
        } else if (element.due_date_id == this.application.current_date.id && element.subkind == `loan_prepaid` && element.recognized == false && element.borrower_id == this.application.current_med.id) {
          this.current_payables[this.current_payables.length] = element
        }
      } else {
        if (element.due_date_id == this.application.current_date.id && element.paid == false) {
          this.current_payables[this.current_payables.length] = element
        } else if (element.due_date_id == this.application.current_date.id && (element.subkind == `prepaid_expenses` || element.subkind == `loan_prepaid`) && element.recognized == false) {
          this.current_payables[this.current_payables.length] = element
        }
      }
    })

    if (this.sort.mode == `asc`) {
      var payables = this.getControllerByIdentifier("app--helpers--data").sortByKeyAsc(this.current_payables, this.sort.field)
    } else if (this.sort.mode == `desc`) {
      var payables = this.getControllerByIdentifier("app--helpers--data").sortByKeyDesc(this.current_payables, this.sort.field)
    }

    if (payables.length == 0) {
      var pagination = { itemsOnPage: 0, fromItem: 0, toItem: 0, totalItems: 0 }
      this.listData()
      this.doFooterTable(pagination)
    } else {
      var itemsOnPage = this.itemsOnPage
      var pageNumber = this.pageNumber
      var numberPages = this.numberPages
      var pages = []
      var itemLoop = Math.ceil(payables.length / itemsOnPage)
      var x = 0
      var i = 1
      for (i; i < itemLoop + 1; i++) {
        var internPage = []
        for (x; x < itemsOnPage * i; x++) {
          if (payables[x] !== undefined) {
            internPage[internPage.length] = payables[x]
          }
        }
        pages[pages.length] = internPage
        numberPages[numberPages.length] = i - 1
      }

      var fromItem = itemsOnPage * pageNumber + 1
      var toItem = Math.min(itemsOnPage * (pageNumber + 1), (itemsOnPage * pageNumber) + pages[pageNumber].length)
      var pagination = { itemsOnPage: itemsOnPage, fromItem: fromItem, toItem: toItem, totalItems: payables.length }
      this.listData(pages[pageNumber])
      this.doFooterTable(pagination)
    }
  }

  listData(data) {
    this.bodyTableTarget.innerHTML = ""
    if (data == undefined || data == [] || data.length == 0) {
      var noData = `</tr>
                      <td colspan="8" class="p-3 align-middle text-center" style="font-size:100%;">
                        <span class="fa-stack fa-1x">
                          <i class="fas fa-list fa-stack-1x"></i>
                        </span>
                        <span class="f-075 align-middle">Não há Pagamentos em Aberto com ${this.application.current_date.due_name}</span>
                      </td>
                    </tr>`

      this.bodyTableTarget.innerHTML = noData
    } else {
      data.forEach(element => {
        this.bodyTableTarget.insertAdjacentHTML("beforeend", this.payableTablePartial(element, data.length))
      });
    }

    if (this.checked_id) {
      this.checkboxShowTargets.forEach(element => {
        if (element.id == `check-${this.checked_id}`) {
          element.checked = true
        }
      })
    }
  }

  payableTablePartial(element, length) {
    if (this.application.permissions.financial_payable_entities.can_update) {
      var edit = `<button data-action="click->${this.controllerName}#editOpportunity" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                    <span class="material-icons md-sm md-dark">edit</span>
                    <span class="mc-tooltiptext">Editar</span>
                  </button>`
    } else {
      var edit = ``
    }

    if (length == 1) {
      var tableRow = `<tr class="itemRow" data-id="${element.id}" style="height:50px;">`
    } else {
      var tableRow = `<tr class="itemRow" data-id="${element.id}">`
    }

    if (element.paid) {
      if ((element.subkind == `prepaid_expenses` || element.subkind == `loan_prepaid`) && element.recognized == false) {
        var action = `<span class="f-070 badge badge-danger pointer" data-action="click->${this.controllerName}#recognizePayable">${element.recognized_action}</span>`
      } else if (element.subkind == `prepaid_expenses` || element.subkind == `loan_prepaid`) {
        var action = `<span class="f-070 badge badge-success">${element.recognized_action}</span>`
      } else {
        var action = `<span class="f-070 badge badge-success">${element.subkind_status}</span>`
      }
    } else {
      var action = `<span class="f-070 badge badge-danger pointer" data-action="click->${this.controllerName}#paidPayable">Não ${element.subkind_status}</span>`
    }

    var rowHtml = `${tableRow}
                    
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${element.due_date_pretty}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle help">
                      <span class="mb-0 f-065 mc-tooltip">
                        <span>${element.description}</span>
                        <span class="mc-tooltiptext mc-tooltiptext-right">${element.provider_name}</span>
                      </span>
                    </td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${element.chart_name}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(element.amount)}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle" data-${this.controllerName}-target="paidTd-${element.id}">${action}</td>
                  </tr>`

    return rowHtml
  }

  itemsLength(ev) {
    this.itemsOnPage = ev.target.dataset.items
    this.pageNumber = 0
    this.numberPages = []
    this.doDataTable()
  }

  previousTablePage(ev) {
    if (this.numberPages.includes(this.pageNumber - 1)) {
      this.pageNumber -= 1
      this.doDataTable()
    }
  }

  nextTablePage(ev) {
    if (this.numberPages.includes(this.pageNumber + 1)) {
      this.pageNumber += 1
      this.doDataTable()
    }
  }

  doFooterTable(pagination) {
    var footerHtml = `<div class="row">
                        <div class="col-3 d-flex">
                          <div class="card-actions align-items-center justify-content-end py-0 f-065">
                            <span data-app--helpers--search-target="footerTable"></span>
                          </div>
                        </div>
                        <div class="col-9 px-0">
                          <div class="card-actions align-items-center justify-content-end py-0 f-065">
                            <span class="align-self-center mb-1 mx-1 text-muted">Itens por Página:</span>
                            <div class="dropdown">
                              <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline dropdown-toggle f-065" data-toggle="dropdown" type="button">${this.controllerNumber.numberMask(pagination.itemsOnPage)}</button>
                              <div class="dropdown-menu dropdown-menu-right menu">
                                <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="300">300</span>
                                <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="750">750</span>
                                <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="1000">1.000</span>
                              </div>
                            </div>
                            <span class="align-self-center mb-1 mr-2 text-muted">${this.controllerNumber.numberMask(pagination.fromItem)}-${this.controllerNumber.numberMask(pagination.toItem)} de ${this.controllerNumber.numberMask(pagination.totalItems)}</span>
                            <a class="btn btn-outline py-0" data-action="click->${this.controllerName}#previousTablePage"><i class="material-icons md-sm">chevron_left</i></a>
                            <a class="btn btn-outline py-0" data-action="click->${this.controllerName}#nextTablePage"><i class="material-icons md-sm">chevron_right</i></a>
                          </div>
                        </div>
                      </div>`

    this.footerTableTarget.innerHTML = footerHtml
  }

  refreshPayable() {
    var element = this.bodyTableTarget
    var cols = this.tableCols
    this.getControllerByIdentifier(`financials--books--payables--dashboard--main`).refreshPayable(element, cols)
  }

  paidPayable(ev) {
    if (this.application.current_date.open == false) {
      alert(`Competência já está fechada. Favor abrí-la para confirmar o pagamento.`)
    } else {
      this.findObj = true
      var payableId = ev.currentTarget.closest(".itemRow").dataset.id
    }

    if (this.application.permissions.financial_payable_entities.can_manage) {
      this.doUpdatePaidModalHtml(payableId)
    }
  }

  doUpdatePaidModalHtml(payableId) {
    var modalController = `financials--books--payables--entities--paid-modal`

    var html = `<div class="modal fade" data-controller="${modalController}" data-${modalController}-target="modal" id="connectionModal" tabindex="-1" role="dialog" aria-labelledby="connectionModalTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                  <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                      <div class="modal-header border-bottom py-0">
                        <h6 class="modal-title py-2 mx-auto"><strong class="f-075">Pagamento Contas à Pagar</strong></h6><br>
                      </div>
                      <div class="modal-body p-3" data-${modalController}-target="body"></div>
                      <div class="modal-footer">
                        <button type="button" class="btn btn-secondary btn-sm py-2 btn-table f-065" data-dismiss="modal" data-action="click->${modalController}#close">Fechar</button>
                        <button disabled type="button" class="btn btn-primary btn-sm py-2 btn-table f-065" data-action="click->${modalController}#updatePaid" data-${modalController}-target="saveBtn"></button>
                      </div>
                    </div>
                  </div>
                </div>`
    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.getControllerByIdentifier(`financials--books--payables--entities--paid-modal`).open(payableId)
    })
  }

  recognizePayable(ev) {
    if (this.application.current_date.open == false) {
      alert(`Competência já está fechada. Favor abrí-la para confirmar o pagamento.`)
    } else {
      var payableId = ev.currentTarget.closest(".itemRow").dataset.id
      this.doUpdateRecognizedModalHtml(payableId)
    }
  }

  doUpdateRecognizedModalHtml(payableId) {
    var modalController = `financials--books--payables--entities--recognized-modal`

    var html = `<div class="modal fade" data-controller="${modalController}" data-${modalController}-target="modal" id="connectionModal" tabindex="-1" role="dialog" aria-labelledby="connectionModalTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                  <div class="modal-dialog modal-dialog-centered modal-md" role="document">
                    <div class="modal-content">
                      <div class="modal-header border-bottom py-0">
                        <h6 class="modal-title py-2 mx-auto"><strong class="f-075">Reconhecimento de Despesa Antecipada</strong></h6><br>
                      </div>
                      <div class="modal-body p-3" data-${modalController}-target="body"></div>
                      <div class="modal-footer">
                        <button type="button" class="btn btn-secondary btn-sm py-2 btn-table f-065" data-dismiss="modal" data-action="click->${modalController}#close">Fechar</button>
                        <button disabled type="button" class="btn btn-primary btn-sm py-2 btn-table f-065" data-action="click->${modalController}#updateRecognized" data-${modalController}-target="saveBtn">Reconhecer</button>
                      </div>
                    </div>
                  </div>
                </div>`
    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.getControllerByIdentifier(`financials--books--payables--entities--recognized-modal`).open(payableId)
    })
  }

  requestDestroyAll() {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()
    this.bodyTableTarget.innerHTML = this.getControllerByIdentifier(`app--helpers--table`).doTablePreloader(this.tableCols, 10)

    const data = { payable: { active: true, date_id: this.application.current_date.id }, current_user: { current_user_id: this.application.current_user.id, feature: `financial_payable_entities` } }
    const url = "/financials/books/payables/entities/destroy_all"

    const init = { method: "DELETE", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          var payableIds = controller.mapElements(controller.application.payables, `id`)
          response.data.cln.forEach(payable => {
            if (payableIds.includes(payable.id)) {
              controller.application.payables.forEach((element, i) => {
                if (element.id == payable.id) {
                  controller.application.payables.splice(controller.application.payables.indexOf(element), 1)
                }
              })
            }
          })
        }

        controller.doDataTable()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  requestSave() {
    var url = "/financials/books/payables/entities/update_paid"
    var method = "PUT"

    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          var payable = response.data.cln
          controller.application.payables.forEach((element, i) => {
            if (element.id == payable.id) {
              controller.application.payables.splice(controller.application.payables.indexOf(element), 1, payable)
            }
          })
          controller.doDataTable()
        }
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
        controller.updatePayble = false
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }




  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  getTargetByIdentifier(target) {
    var targetElement = ``
    this.application.controllers.forEach(controller => {
      if (controller.targets.find(target)) {
        targetElement = controller.targets.find(target)
      }
    })

    return targetElement
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0
      controller.validGroupTargets.forEach(element => {
        if (this.fromStringToBoolean(element.dataset.valid) == false) {
          len += 1
        }
      });
      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  confirm() {
    var r = confirm("Confirma a resolução do Ticket?")
    if (r) {
    }
  }

  goToURL(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_self`)
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    });
    return results
  }

  randonNumber() {
    var number = Math.floor(Math.random() * 1000)

    return number
  }

  requireUser() {
    var user = this.getControllerByIdentifier("app--visits--users").checkUser()

    if (user) {
      this.doMainGrid()
    } else {
      window.open(window.location.origin, `_self`)
    }
  }

  leadingZeros(number, size) {
    var s = String(number);
    while (s.length < (size || 2)) { s = "0" + s; }
    return s;
  }

  // controller.bodyTableTarget.insertAdjacentHTML("beforeend", controller.getControllerByIdentifier("app--helpers--table").doTablePreloader(9, 6))
  // var controller = this
  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
