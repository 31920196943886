import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["formCard", "formTitle", "formCardBody", "nameInput", "chartInput", "chartFilter", "saveBtn",
                    "providerInput", "providerFilter", "channelDropdown", "channelDropdownBtn", "channelInput", "channelList",
                    "dueDate", "amountInput", "methodDropdown", "methodDropdownBtn", "methodInput", "methodList", "descriptionInput",
                    "bankLineInput", "bankLineDiv", "bankAccountDiv", "bankAccountInput", "pixKeyDiv", "pixKeyInput", "accrualDate",
                    "recurringCheckbox", "variableCheckbox", "deleteBtn", "cardDiv", "totalAmountInput", "splitInput", "amountSplitInput", "installmentInput",
                    "cpfCheckbox", "cnpjCheckbox", "idNumberInput", "cnpjInputDiv", "cnpjInput", "cpfInputDiv", "cpfInput"]


  connect() {
    this.controllerName = `operations--products--clinics--financials--expenses--save`

    if (this.getControllerByIdentifier("operations--products--clinics--financials--expenses--dashboard")) {
      this.dashboardController = this.getControllerByIdentifier("operations--products--clinics--financials--expenses--dashboard")
      this.submenuController = this.getControllerByIdentifier("operations--products--clinics--financials--show--submenu")
      this.mainController = this.getControllerByIdentifier("operations--products--clinics--financials--show--main")
    } else if (this.getControllerByIdentifier("operations--products--clinics--clients--expenses--dashboard")) {
      this.dashboardController = this.getControllerByIdentifier("operations--products--clinics--clients--expenses--dashboard")
      this.submenuController = this.getControllerByIdentifier("operations--products--clinics--clients--show--submenu")
      this.mainController = this.getControllerByIdentifier("operations--products--clinics--clients--show--main")
    }
  }

  cancelSave() {
    this.getControllerByIdentifier("operations--products--clinics--financials--expenses--index").clearList()
  }

  savePayable() {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()
    this.saveBtnTarget.disabled = true
    this.stopRefreshing()
    
    this.send_data = { current_user: {}, expense: {}, ticket: {}, tracker: { log: true } }

    if (this.actionMode == `edit`) {
      this.send_data.expense.id = this.current_expense.id
    }

    var chartId = this.chartInputTarget.dataset.filter
    var chart = {}
    this.application.chart_accounts.forEach(element => {
      if (element.id == chartId) {
        chart = element
      }
    })

    this.send_data.expense.clinic_id = this.application.clinic.id
    this.send_data.expense.date_id = this.application.current_date.id
    this.send_data.expense.channel_id = this.channelInputTarget.dataset.channelId
    this.send_data.expense.channel_name = this.channelInputTarget.innerText
    this.send_data.expense.provider_id = this.providerInputTarget.dataset.filter
    this.send_data.expense.provider_name = this.providerInputTarget.dataset.text
    this.send_data.expense.due_date = this.getControllerByIdentifier("app--helpers--date").transformFullDate(this.dueDateTarget.value)
    this.send_data.expense.accrual_date = this.getControllerByIdentifier("app--helpers--date").transformFullDate(this.accrualDateTarget.value)
    this.send_data.expense.amount = this.getControllerByIdentifier("app--helpers--numbers").fromCurrencyToNumber(this.amountInputTarget.value)
    this.send_data.expense.net_amount = this.getControllerByIdentifier("app--helpers--numbers").fromCurrencyToNumber(this.amountInputTarget.value)
    this.send_data.expense.description = `Pgt. Ref. ${this.descriptionInputTarget.value}`
    this.send_data.expense.chart_id = chart.id
    this.send_data.expense.chart_account = chart.chart_account
    this.send_data.expense.chart_name = chart.chart_name
    this.send_data.expense.chart_master_name = chart.master_name
    this.send_data.expense.chart_group = chart.group
    this.send_data.expense.kind = chart.kind
    this.send_data.expense.method = this.methodInputTarget.dataset.method
    this.send_data.expense.bank_line = this.bankLineInputTarget.value
    this.send_data.expense.bank_account = this.bankAccountInputTarget.value
    this.send_data.expense.pix_key = this.pixKeyInputTarget.value
    this.send_data.expense.installment = this.installmentInputTarget.value
    // this.send_data.expense.variable = this.variableCheckboxTarget.checked
    // this.send_data.expense.recurring = this.recurringCheckboxTarget.checked

    this.send_data.tracker.record_id = this.application.clinic.id
    this.send_data.tracker.record_type = `operation_products`
    this.send_data.tracker.obj_id = this.application.current_date.id
    this.send_data.tracker.obj_type = `operation_dates`
    this.send_data.tracker.action = this.actionMode
    this.send_data.tracker.observations = `${this.send_data.expense.description} da ${this.application.clinic.company_name} na ${this.application.current_date.name}`

    var partner_ids = []

    this.application.partners.forEach(element => {
      if (element.operational && this.nameTarget(`partnerCheckbox-${element.id}`).checked) {
        partner_ids[partner_ids.length] = element.id
      }
    });
    this.send_data.expense.partner_ids = partner_ids

    this.send_data.expense.provider_name = this.providerInputTarget.value
    if (this.cpfCheckboxTarget.checked) {
      this.send_data.expense.provider_type = "individual"
      this.send_data.expense.provider_id = this.getControllerByIdentifier("app--helpers--numbers").fromCpfToNumber(this.cpfInputTarget.value)
    } else if (this.cnpjCheckboxTarget.checked) {
      this.send_data.expense.provider_type = "legal"
      this.send_data.expense.provider_id = this.getControllerByIdentifier("app--helpers--numbers").fromCnpjToNumber(this.cnpjInputTarget.value)
    }

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = this.permission

    this.requestSave()
  }

  requestSave() {
    if (this.actionMode == `new`) {
      var url = "/operations/products/clinics/financials/expenses/create"
      var method = "POST"
    } else if (this.actionMode == `edit`) {
      var url = "/operations/products/clinics/financials/expenses/update"
      var method = "PUT"
    } else if (this.actionMode == `delete`) {
      var url = "/operations/products/clinics/financials/expenses/destroy"
      var method = "PUT"
    }
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          controller.stopRefreshing()
          var expense = response.data.cln
          if (controller.actionMode == `new`) {
            controller.application.expenses[controller.application.expenses.length] = expense
          } else if (controller.actionMode == `edit`) {
            controller.application.expenses.forEach((element, i) => {
              if (element.id == expense.id) {
                controller.application.expenses.splice(controller.application.expenses.indexOf(element), 1, expense)
              }
            })
          } else if (controller.actionMode == `delete`) {
            controller.application.expenses.forEach((element, i) => {
              if (element.id == expense.id) {
                controller.application.expenses.splice(controller.application.expenses.indexOf(element), 1)
              }
            })
          }

          controller.cancelSave()
          controller.mainController.getProviders()
          controller.submenuController.doExpensesCount()
          controller.getControllerByIdentifier("operations--products--clinics--financials--expenses--index").doDataTable()
        } else {
          controller.saveBtnTarget.disabled = false
        }
        
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  doFormHtml() {
    this.stopRefreshing()

    if (this.application.permissions[this.permission].can_create) {
      var saveBtnHtml = `<button disabled type="button" class="btn btn-primary btn-sm py-2 btn-table f-065" data-action="click->${this.controllerName}#savePayable" data-${this.controllerName}-target="saveBtn">Salvar</button>`
    } else {
      var saveBtnHtml = ``
    }

    if (this.application.permissions[this.permission].can_delete && this.actionMode == "edit") {
      var deleteBtnHtml = `<button type="button" class="btn btn-danger btn-sm py-2 btn-table f-065" data-action="click->${this.controllerName}#deletePayable" data-${this.controllerName}-target="deleteBtn">Apagar</button>`
    } else {
      var deleteBtnHtml = ``
    }

    var partnersHtml = ``
    
    this.application.partners.forEach(element => {
      if (element.operational) {
        partnersHtml += `<div class="col-6 d-flex mb-3">
                        <div class="custom-control custom-checkbox pr-0 pl-2 w-100 my-0 d-flex">
                          <input type="checkbox" class="custom-control-input" id="partnerCheck-${element.id}" data-${this.controllerName}-target="partnerCheckbox-${element.id}" data-id="${element.id}">
                          <label class="custom-control-label f-065" for="partnerCheck-${element.id}">${element.account_name}</label>
                        </div>
                      </div>`
      }
      
    });

    var html = `<div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="formCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 py-3" style="font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="formTitle"></h6>
                  </div>
                  <div class="card-body py-0 px-2" style="overflow:auto;" data-${this.controllerName}-target="formCardBody">
                    <div class="row mt-2">
                      <div class="col-4 pr-2">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="accrualDateForm">Lançamento</label>
                            <input aria-describedby="accrualDateFormHelp" class="form-control form-valid-control" id="accrualDateForm" data-${this.controllerName}-target="accrualDate" placeholder="Lançamento" type="text" required>
                          </div>
                        </div>
                      </div>
                      <div class="col-4 px-2">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="dueDateForm">Vencimento</label>
                            <input aria-describedby="dueDateFormHelp" class="form-control form-valid-control" id="dueDateForm" data-${this.controllerName}-target="dueDate" placeholder="Vencimento" type="text" required>
                          </div>
                        </div>
                      </div>
                      <div class="col-4 pl-2">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="amountForm">Valor</label>
                            <input aria-describedby="amountFormHelp" class="form-control f-065" id="amountForm" data-${this.controllerName}-target="amountInput" data-action="blur->${this.controllerName}#currencyMask keypress->${this.controllerName}#currencyMask keyup->${this.controllerName}#currencyMask" placeholder="Valor" type="text" required>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="descriptionForm">Descrição</label>
                            <input aria-describedby="descriptionFormHelp" class="form-control" id="descriptionForm" data-${this.controllerName}-target="descriptionInput" placeholder="Descrição" type="text" required>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row d-flex align-items-center">
                      <div class="col-1 pr-1">
                        <button data-action="click->${this.controllerName}#showChartAccounts" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                          <span class="material-icons md-sm md-dark">help_center</span>
                          <span class="mc-tooltiptext mc-tooltiptext-right">Clique para ver o Plano de Contas</span>
                        </button>
                      </div>
                      <div class="col-11">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="nameForm">Conta</label>
                            <input class="form-control f-075 pt-0" data-${this.controllerName}-target="chartInput" data-filter-mode="simple" data-action="focus->${this.controllerName}#chartFilter keyup->${this.controllerName}#chartFilter blur->${this.controllerName}#hideList keyup->${this.controllerName}#enterChart" type="text" placeholder="Plano de Conta" required>
                            <ul class="ul-filter filter-list d-none w-100" style="z-index:1" data-${this.controllerName}-target="chartFilter"></ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row d-flex align-items-center">
                      <div class="col-1 pr-1">
                        <button data-action="click->${this.controllerName}#showProviders" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                          <span class="material-icons md-sm md-dark">help_center</span>
                          <span class="mc-tooltiptext mc-tooltiptext-right">Clique para ver os Fornecedores</span>
                        </button>
                      </div>
                      <div class="col-4 pr-2">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="nameForm">Fornecedor</label>
                            <input class="form-control f-075 pt-1 pb-1" data-${this.controllerName}-target="providerInput" data-filter-mode="simple" data-action="focus->${this.controllerName}#providerFilter keyup->${this.controllerName}#providerFilter blur->${this.controllerName}#hideList keyup->${this.controllerName}#enterProvider" type="text" placeholder="Fornecedor" required>
                            <ul class="ul-filter filter-list d-none w-100" style="z-index:1" data-${this.controllerName}-target="providerFilter"></ul>
                          </div>
                        </div>
                      </div>
                      <div class="col-3 d-flex px-1">
                        <div class="custom-control custom-checkbox pr-0 pl-2 w-50 my-0 d-flex">
                          <input type="checkbox" class="custom-control-input" id="expenseCpfCheck" data-kind="individual" data-${this.controllerName}-target="cpfCheckbox" data-action="click->${this.controllerName}#providerIdCheck">
                          <label class="custom-control-label f-065" for="expenseCpfCheck">CPF</label>
                        </div>
                        <div class="custom-control custom-checkbox pr-0 pl-2 w-50 my-0 d-flex">
                          <input type="checkbox" class="custom-control-input" id="expenseCnpjCheck" data-kind="legal" data-${this.controllerName}-target="cnpjCheckbox" data-action="click->${this.controllerName}#providerIdCheck">
                          <label class="custom-control-label f-065" for="expenseCnpjCheck">CNPJ</label>
                        </div>
                      </div>
                      <div class="col-4 pr-2 pl-0 d-flex align-items-center" data-${this.controllerName}-target="idNumberInput"><span><small>Escolha se é CPF ou CNPJ</small></span></div>
                      <div class="col-4 pr-2 pl-0 d-none" data-${this.controllerName}-target="cnpjInputDiv">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="cnpjForm">CNPJ do Fornecedor</label>
                            <input aria-describedby="cnpjFormHelp" class="form-control form-valid-control" id="cnpjForm" data-${this.controllerName}-target="cnpjInput" data-action="blur->${this.controllerName}#cnpjMask keypress->${this.controllerName}#cnpjMask keyup->${this.controllerName}#cnpjValidate blur->${this.controllerName}#cnpjValidate" placeholder="CNPJ do Fornecedor" type="text" required>
                          </div>
                        </div>
                      </div>
                      <div class="col-4 pr-2 pl-0 d-none" data-${this.controllerName}-target="cpfInputDiv">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="cpfForm">CPF do Fornecedor</label>
                            <input aria-describedby="cpfFormHelp" class="form-control form-valid-control" id="cpfForm" data-${this.controllerName}-target="cpfInput" data-action="blur->${this.controllerName}#cpfMask keypress->${this.controllerName}#cpfMask keyup->${this.controllerName}#cpfValidate blur->${this.controllerName}#cpfValidate" placeholder="CPF do Fornecedor" type="text" required>
                          </div>
                        </div>
                      </div>

                    </div>
                    <div class="row">
                      <div class="col-6 pr-2">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label>Canal</label>
                            <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="channelDropdown">
                              <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="channelDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="channelInput"></span></button>
                              <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                <ul class="ul-select" data-${this.controllerName}-target="channelList" data-app--helpers--search-target="searchList"></ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-6 pl-2">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label>Método</label>
                            <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="methodDropdown">
                              <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="methodDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="methodInput"></span></button>
                              <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                <ul class="ul-select" data-${this.controllerName}-target="methodList" data-app--helpers--search-target="searchList">
                                  <li data-method="bank_slip" data-action="click->app--helpers--selector#select click->${this.controllerName}#setBankData" data-app--helpers--selector-target="select" class="li-selector dark f-065">Boleto Bancário</li>
                                  <li data-method="credit_card" data-action="click->app--helpers--selector#select click->${this.controllerName}#setBankData" data-app--helpers--selector-target="select" class="li-selector dark f-065">Cartão de Crédito</li>
                                  <li data-method="transfer" data-action="click->app--helpers--selector#select click->${this.controllerName}#setBankData" data-app--helpers--selector-target="select" class="li-selector dark f-065">Transferência</li>
                                  <li data-method="pix" data-action="click->app--helpers--selector#select click->${this.controllerName}#setBankData" data-app--helpers--selector-target="select" class="li-selector dark f-065">PIX</li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row d-none" data-${this.controllerName}-target="cardDiv">
                      <div class="col-4 pr-2">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="totalAmountForm">Valor Total</label>
                            <input aria-describedby="totalAmountFormHelp" class="form-control form-valid-control" id="totalAmountForm" data-${this.controllerName}-target="totalAmountInput" data-action="blur->${this.controllerName}#currencyMask keypress->${this.controllerName}#currencyMask keyup->${this.controllerName}#currencyMask" placeholder="Valor Total" type="tel" required>
                          </div>
                        </div>
                      </div>
                      <div class="col-4 px-2">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="splitForm"># Parcelas</label>
                            <input aria-describedby="splitFormHelp" class="form-control form-valid-control" id="splitForm" data-${this.controllerName}-target="installmentInput" data-action="blur->${this.controllerName}#splitAmount keyup->${this.controllerName}#splitAmount" placeholder="Parcelas" type="tel" required>
                          </div>
                        </div>
                      </div>
                      <div class="col-4 pl-2">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="amountSplitForm">Valor Parcela</label>
                            <input disabled aria-describedby="amountSplitFormHelp" class="form-control form-valid-control" id="amountSplitForm" data-${this.controllerName}-target="amountSplitInput" data-action="blur->${this.controllerName}#currencyMask keypress->${this.controllerName}#currencyMask keyup->${this.controllerName}#currencyMask" placeholder="Valor Parcela" type="tel" required>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row d-none" data-${this.controllerName}-target="bankLineDiv">
                      <div class="col-12">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="bankLineForm">Linha Digitável Boleto</label>
                            <input aria-describedby="bankLineFormHelp" class="form-control" id="bankLineForm" data-${this.controllerName}-target="bankLineInput" placeholder="Descrição" type="text" required>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row d-none"  data-${this.controllerName}-target="bankAccountDiv">
                      <div class="col-12">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="bankAccountForm">Dados Bancários</label>
                            <input aria-describedby="bankAccountFormHelp" class="form-control" id="bankAccountForm" data-${this.controllerName}-target="bankAccountInput" placeholder="Dados Bancários" type="text" required>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row d-none" data-${this.controllerName}-target="pixKeyDiv">
                      <div class="col-12">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="pixKeyForm">Chave PIX</label>
                            <input aria-describedby="pixKeyFormHelp" class="form-control" id="pixKeyForm" data-${this.controllerName}-target="pixKeyInput" placeholder="Descrição" type="text" required>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mt-2">
                      ${partnersHtml}
                    </div>
                  </div>
                  <div class="card-footer border-top text-right py-1">
                    <button type="button" class="btn btn-secondary btn-sm py-2 btn-table f-065" data-action="click->${this.controllerName}#cancelSave">Fechar</button>
                    ${saveBtnHtml}
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.dashboardController.viewTarget.innerHTML = html)
    }).then(() => {
      controller.formCardTarget.style.height = ($(window).height() * 0.6) + "px"
      // controller.getControllerByIdentifier("app--helpers--pickdate").pickNormal($(this.accrualDateTarget), { kind: "yearly", max: false, months: true, years: 1 })
      // controller.getControllerByIdentifier("app--helpers--pickdate").pickNormal($(this.dueDateTarget), { kind: "yearly", max: false, months: true, years: 1 })

      
      // controller.getControllerByIdentifier("app--helpers--pickdate").pickOnlyFuture($(this.accrualDateTarget), "monthly", date.getFullYear(), date.getMonth() + 1)
      var date = new Date(this.application.current_date.year, this.application.current_date.month - 1, 1)
      controller.getControllerByIdentifier("app--helpers--pickdate").pickInsideDate($(this.accrualDateTarget), "monthly", date.getFullYear(), date.getMonth() + 1)
      
      var today = new Date()
      if (today.getFullYear() > date.getFullYear()) {
        controller.getControllerByIdentifier("app--helpers--pickdate").pickWithLimit($(this.dueDateTarget), "monthly", date.getFullYear(), date.getMonth() + 1)
      } else if (today.getFullYear() == date.getFullYear() && today.getMonth() > date.getMonth()) {
        controller.getControllerByIdentifier("app--helpers--pickdate").pickOnlyFutureFromDate($(this.dueDateTarget), "monthly", date.getFullYear(), date.getMonth() + 1, date)
        // controller.getControllerByIdentifier("app--helpers--pickdate").pickWithLimit($(this.dueDateTarget), "monthly", today.getFullYear(), today.getMonth() + 1)
      } else {
        controller.getControllerByIdentifier("app--helpers--pickdate").pickOnlyFutureFromDate($(this.dueDateTarget), "monthly", date.getFullYear(), date.getMonth() + 1, date)
        // controller.getControllerByIdentifier("app--helpers--pickdate").pickOnlyFuture($(this.dueDateTarget), "monthly", date.getFullYear(), date.getMonth() + 1)
      }

      if (controller.actionMode == `edit`) {
        controller.formTitleTarget.innerText = `Editando Pagamento`
        controller.descriptionInputTarget.value = controller.current_expense.description.replace(`Pgt. Ref. `, ``)

        controller.accrualDateTarget.value = controller.current_expense.accrual_date_pretty
        controller.dueDateTarget.value = controller.current_expense.due_date_pretty
        controller.amountInputTarget.value = controller.getControllerByIdentifier("app--helpers--numbers").currencyMask(parseInt(controller.current_expense.amount * 100))
        controller.chartInputTarget.dataset.filter = controller.current_expense.chart_id
        controller.chartInputTarget.dataset.text = controller.current_expense.chart_name
        controller.chartInputTarget.value = controller.current_expense.chart_name
        controller.methodDropdownTarget.value = controller.current_expense.method
        controller.methodInputTarget.innerText = controller.current_expense.method_pretty
        controller.methodInputTarget.dataset.method = controller.current_expense.method

        controller.channelDropdownTarget.value = controller.current_expense.channel_id
        controller.channelInputTarget.innerText = controller.current_expense.channel_name
        controller.channelInputTarget.dataset.channelId = controller.current_expense.channel_id
        // controller.variableCheckboxTarget.checked = controller.current_expense.variable
        // controller.recurringCheckboxTarget.checked = controller.current_expense.recurring
        
        controller.bankLineInputTarget.value = controller.current_expense.bank_line

        // controller.providerInputTarget.dataset.filter = controller.current_expense.provider_id
        // controller.providerInputTarget.dataset.text = controller.current_expense.provider_name

        controller.providerInputTarget.value = controller.current_expense.provider_name
        controller.providerTypeCheck(controller.current_expense.provider_type)
        if (controller.current_expense.provider_type == `legal`) {
          controller.cnpjInputTarget.value = controller.current_expense.provider_id_pretty
        } else if (controller.current_expense.provider_type == `individual`) {
          controller.cpfInputTarget.value = controller.current_expense.provider_id_pretty
        }

        if (controller.current_expense.method == `bank_slip`) {
          controller.bankLineDivTarget.classList.remove("d-none")
          controller.bankAccountDivTarget.classList.add("d-none")
          controller.pixKeyDivTarget.classList.add("d-none")
          controller.cardDivTarget.classList.add("d-none")
        } else if (controller.current_expense.method == `transfer`) {
          controller.bankLineDivTarget.classList.add("d-none")
          controller.bankAccountDivTarget.classList.remove("d-none")
          controller.pixKeyDivTarget.classList.add("d-none")
          controller.cardDivTarget.classList.add("d-none")
        } else if (controller.current_expense.method == `pix`) {
          controller.bankLineDivTarget.classList.add("d-none")
          controller.bankAccountDivTarget.classList.add("d-none")
          controller.pixKeyDivTarget.classList.remove("d-none")
          controller.cardDivTarget.classList.add("d-none")
        } else if (controller.current_expense.method == `credit_card`) {
          controller.bankLineDivTarget.classList.add("d-none")
          controller.bankAccountDivTarget.classList.add("d-none")
          controller.pixKeyDivTarget.classList.add("d-none")
          controller.cardDivTarget.classList.remove("d-none")

          controller.totalAmountInputTarget.disabled = true
          controller.totalAmountInputTarget.value = controller.amountInputTarget.value
          controller.amountSplitInputTarget.disabled = true
          controller.installmentInputTarget.value = controller.current_expense.installment
          controller.amountSplitInputTarget.value = controller.getControllerByIdentifier("app--helpers--numbers").currencyMask(0)
        }

        
        if (this.application.partners.length == 1) {
          this.nameTarget(`partnerCheckbox-${this.application.partners[0].id}`).checked = true
          this.nameTarget(`partnerCheckbox-${this.application.partners[0].id}`).disabled = true
        } else {
          controller.current_expense.partner_ids.forEach(id => {
            this.nameTarget(`partnerCheckbox-${id}`).checked = true
          });
        }
        

      } else if (controller.actionMode == `new`) {
        controller.formTitleTarget.innerText = `Novo Pagamento para ${this.application.current_date.name}`
        controller.amountInputTarget.value = `R$ 0,00`

        controller.dueDateTarget.disabled = true

        if (this.application.partners.length == 1) {
          this.nameTarget(`partnerCheckbox-${this.application.partners[0].id}`).checked = true
          this.nameTarget(`partnerCheckbox-${this.application.partners[0].id}`).disabled = true
        }
        
        
        // controller.amountInputTarget.value = controller.getControllerByIdentifier("app--helpers--numbers").currencyMask(50000)
        // controller.descriptionInputTarget.value = `Mensalidade MedCapital`
        // controller.accrualDateTarget.value = controller.getControllerByIdentifier("app--helpers--date").transformPrettyDate(new Date())
        // controller.dueDateTarget.value = controller.getControllerByIdentifier("app--helpers--date").transformPrettyDate(new Date())
      }

      controller.listChartAccounts()
      controller.listChannels()
      controller.listProviders()
      controller.refreshSaveBtn()
      controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
    })
  }

  kindCheck(ev) {
    if (ev.target.dataset.kind == "recurring") {
      this.recurringCheckboxTarget.checked = true
      this.variableCheckboxTarget.checked = false
    } else if (ev.target.dataset.kind == "variable") {
      this.recurringCheckboxTarget.checked = false
      this.variableCheckboxTarget.checked = true
    }
  }

  providerIdCheck(ev) {
    var providerType = ev.target.dataset.kind
    this.providerTypeCheck(providerType)
  }

  providerTypeCheck(providerType) {
    if (this.hasIdNumberInputTarget) {
      this.idNumberInputTarget.remove()
    }

    if (providerType == "individual") {
      this.cpfCheckboxTarget.checked = true
      this.cnpjCheckboxTarget.checked = false
      this.cnpjInputDivTarget.classList.add("d-none")
      this.cpfInputDivTarget.classList.remove("d-none")
    } else if (providerType == "legal") {
      this.cpfCheckboxTarget.checked = false
      this.cnpjCheckboxTarget.checked = true
      this.cnpjInputDivTarget.classList.remove("d-none")
      this.cpfInputDivTarget.classList.add("d-none")
    }

    this.getControllerByIdentifier("app--helpers--forms").floatingLabel()
  }

  hideList(ev) {
    // this.getControllerByIdentifier("app--helpers--input").hideList(ev)
  }

  listChannels() {
    var html = ``

    this.application.channels.forEach(element => {
      html += `<li data-channel-id="${element.id}" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">${element.name}</li>`
    })

    if (this.application.channels.length == 1) {
      this.channelDropdownTarget.value = this.application.channels[0].id
      this.channelInputTarget.innerText = this.application.channels[0].name
      this.channelInputTarget.dataset.channelId = this.application.channels[0].id
    }

    this.channelListTarget.innerHTML = html
  }

  listChartAccounts() {
    var html = ``

    this.application.chart_accounts.forEach(element => {
      // if (element.master == `expenses`) {
      if (this.checkChartAccount(element.group)) {
        html += `<li data-action="click->${this.controllerName}#selectChart" data-${this.controllerName}-target="chartFilterItem" data-text="${element.chart_name}" data-filter="${element.id}" class="li-selector dark f-065">${element.chart_name}</li>`
      }
    })

    this.chartFilterTarget.innerHTML = html
  }

  chartFilter(ev) {
    this.getControllerByIdentifier("app--helpers--input").filterList(ev, 2)
  }

  selectChart(ev) {
    this.getControllerByIdentifier("app--helpers--input").selectItem(ev)
  }

  enterChart(ev) {
    if (ev.type == "keyup" && ev.key == "Enter") {
      this.getControllerByIdentifier("app--helpers--input").enterItem(ev)
    }
  }
  
  listProviders() {
    var html = ``

    this.application.providers.forEach(element => {
      html += `<li data-action="click->${this.controllerName}#selectProvider" data-${this.controllerName}-target="providerFilterItem" data-text="${element.name}" data-filter="${element.id}" class="li-selector dark f-065">${element.name}</li>`
    })

    this.providerFilterTarget.innerHTML = html
  }

  setBankData(ev) {
    var method = ev.currentTarget.dataset.method

    // if (method == `bank_slip`) {
    //   this.bankLineDivTarget.classList.remove("d-none")
    //   this.bankAccountDivTarget.classList.add("d-none")
    //   this.pixKeyDivTarget.classList.add("d-none")
    //   this.cardDivTarget.classList.add("d-none")
    // } else if (method == `transfer`) {
    //   this.bankLineDivTarget.classList.add("d-none")
    //   this.bankAccountDivTarget.classList.remove("d-none")
    //   this.pixKeyDivTarget.classList.add("d-none")
    //   this.cardDivTarget.classList.add("d-none")
    // } else if (method == `pix`) {
    //   this.bankLineDivTarget.classList.add("d-none")
    //   this.bankAccountDivTarget.classList.add("d-none")
    //   this.pixKeyDivTarget.classList.remove("d-none")
    //   this.cardDivTarget.classList.add("d-none")
    // } else if (method == `credit_card`) {
    //   this.bankLineDivTarget.classList.add("d-none")
    //   this.bankAccountDivTarget.classList.add("d-none")
    //   this.pixKeyDivTarget.classList.add("d-none")
    //   this.cardDivTarget.classList.remove("d-none")
    //   this.totalAmountInputTarget.disabled = true
    //   this.totalAmountInputTarget.value = this.amountInputTarget.value
    //   this.amountSplitInputTarget.disabled = true
    //   this.installmentInputTarget.value = 1
    //   this.amountSplitInputTarget.value = this.amountInputTarget.value
    // }
    this.getControllerByIdentifier("app--helpers--forms").floatingLabel()
  }

  splitAmount(ev) {
    var split = ev.currentTarget.value

    if (split) {
      var amount = this.getControllerByIdentifier("app--helpers--numbers").fromCurrencyToNumber(this.totalAmountInputTarget.value) / split
    } else {
      var amount = this.getControllerByIdentifier("app--helpers--numbers").fromCurrencyToNumber(this.totalAmountInputTarget.value)
    }

    this.amountSplitInputTarget.value = this.getControllerByIdentifier("app--helpers--numbers").currencyMask(parseInt(amount * 100))
  }

  providerFilter(ev) {
    this.getControllerByIdentifier("app--helpers--input").filterList(ev, 2)
  }

  selectProvider(ev) {
    this.getControllerByIdentifier("app--helpers--input").selectItem(ev)

    this.setProvider(ev)
  }


  enterProvider(ev) {
    if (ev.type == "keyup" && ev.key == "Enter") {
      this.getControllerByIdentifier("app--helpers--input").enterItem(ev)
      this.setProvider(ev)
    }
  }

  setProvider(ev) {
    var id = ev.currentTarget.dataset.filter

    this.application.providers.forEach(element => {
      if (element.id == id) {
        // this.providerInputTarget.value = element.name
        this.providerTypeCheck(element.id_type)
        if (element.id_type == `legal`) {
          this.cnpjInputTarget.value = element.id_pretty
        } else if (element.id_type == `individual`) {
          this.cpfInputTarget.value = element.id_pretty
        }
      }
    });

    this.getControllerByIdentifier("app--helpers--forms").floatingLabel()
  }

  currencyMask(ev) {
    this.getControllerByIdentifier("app--helpers--numbers").changeNumberToCurrency(ev)
  }

  cpfValidate(ev) {
    var cleanCpf = this.getControllerByIdentifier("app--helpers--numbers").fromCpfToNumber(ev.target.value)
    if ((ev.type == "keyup" && ev.shiftKey == false && cleanCpf.length == 11) || ev.type == "blur" && cleanCpf.length == 11) {
      this.cpf_result = this.getControllerByIdentifier("app--helpers--tax-number").cpf_validate(ev.target.value)
      if (this.cpf_result.invalid) {
        this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", this.cpf_result.message, 3000)
        this.cpfInputTarget.classList.add("f-danger")
        this.cpfInputTarget.classList.remove("f-normal")
      } else {
        this.cpfInputTarget.classList.add("f-normal")
        this.cpfInputTarget.classList.remove("f-danger")
      }
    } else {
      this.cpfInputTarget.classList.add("f-normal")
      this.cpfInputTarget.classList.remove("f-danger")
    }
  }

  cnpjValidate(ev) {
    var cleanCnpj = this.getControllerByIdentifier("app--helpers--numbers").fromCnpjToNumber(ev.target.value)
    if ((ev.type == "keyup" && ev.shiftKey == false && cleanCnpj.length == 14) || ev.type == "blur" && cleanCnpj.length == 14) {
      this.cnpj_result = this.getControllerByIdentifier("app--helpers--tax-number").cnpj_validate(ev.target.value)
      if (this.cnpj_result.invalid) {
        this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", this.cnpj_result.message, 3000)
        this.cnpjInputTarget.classList.add("f-danger")
        this.cnpjInputTarget.classList.remove("f-normal")
      } else {
        this.cnpjInputTarget.classList.add("f-normal")
        this.cnpjInputTarget.classList.remove("f-danger")
      }
    } else {
      this.cnpjInputTarget.classList.add("f-normal")
      this.cnpjInputTarget.classList.remove("f-danger")
    }
  }

  cnpjMask(ev) {
    this.getControllerByIdentifier("app--helpers--numbers").changeNumberToCnpj(ev)
  }

  cpfMask(ev) {
    this.getControllerByIdentifier("app--helpers--numbers").changeNumberToCpf(ev)
  }

  showChartAccounts() {
    var html = `<div class="modal fade" data-hour="bottom" data-controller="operations--products--clinics--managements--chart-accounts--modal" data-operations--products--clinics--managements--chart-accounts--modal-target="modal" id="connectionModal" tabindex="-1" role="dialog" aria-labelledby="connectionModalTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                  <div class="modal-dialog modal-dialog-centered modal-slg" role="document">
                    <div class="modal-content">
                      <div class="modal-body p-3" data-operations--products--clinics--managements--chart-accounts--modal-target="body">
                        <div class="row my-2">
                          <div class="col-12 text-center">
                            <span class="f-075">Em processamento, aguarde!</span>
                          </div>
                        </div>
                        <div class="row my-2">
                          <div class="col-12 text-center">
                            <span class="material-icons md-dark md-lg d-none" data-operations--products--clinics--managements--chart-accounts--modal-target="bottom">hourglass_bottom</span>
                            <span class="material-icons md-dark md-lg d-none" data-operations--products--clinics--managements--chart-accounts--modal-target="top">hourglass_top</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>`

    document.body.insertAdjacentHTML("beforeend", html)
  }

  showProviders() {
    var html = `<div class="modal fade" data-hour="bottom" data-controller="operations--products--clinics--financials--providers--modal" data-operations--products--clinics--financials--providers--modal-target="modal" id="connectionModal" tabindex="-1" role="dialog" aria-labelledby="connectionModalTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div class="modal-content">
                      <div class="modal-body p-3" data-operations--products--clinics--financials--providers--modal-target="body">
                        <div class="row my-2">
                          <div class="col-12 text-center">
                            <span class="f-075">Em processamento, aguarde!</span>
                          </div>
                        </div>
                        <div class="row my-2">
                          <div class="col-12 text-center">
                            <span class="material-icons md-dark md-lg d-none" data-operations--products--clinics--financials--providers--modal-target="bottom">hourglass_bottom</span>
                            <span class="material-icons md-dark md-lg d-none" data-operations--products--clinics--financials--providers--modal-target="top">hourglass_top</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>`

    document.body.insertAdjacentHTML("beforeend", html)
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0

      if (controller.hasAccrualDateTarget && controller.accrualDateTarget.value == ``) {
        len += 1
      } else {
        controller.dueDateTarget.disabled = false
      }

      if (controller.hasDueDateTarget && controller.dueDateTarget.value == ``) {
        len += 1
      }

      if (controller.hasAmountInputTarget && controller.amountInputTarget.value == `R$ 0,00`) {
        len += 1
      }

      if (controller.hasDescriptionInputTarget && controller.descriptionInputTarget.value == ``) {
        len += 1
      }

      if (controller.hasChartInputTarget && controller.chartInputTarget.value == ``) {
        len += 1
      }

      if (controller.hasProviderInputTarget && controller.providerInputTarget.value == ``) {
        len += 1
      }

      if (controller.hasCpfInputTarget && controller.cpfCheckboxTarget.checked && controller.cpfInputTarget.value == ``) {
        len += 1
      }

      if (controller.hasCnpjInputTarget && controller.cnpjCheckboxTarget.checked && controller.cnpjInputTarget.value == ``) {
        len += 1
      }

      if (controller.hasChannelInputTarget && controller.channelInputTarget.innerText == ``) {
        len += 1
      }

      if (controller.hasMethodInputTarget && controller.methodInputTarget.innerText == ``) {
        len += 1
      }

      // controller.saveBtnTarget.disabled = false

      if (controller.hasSaveBtnTarget) {
        if (len == 0) {
          controller.saveBtnTarget.disabled = false
        } else {
          controller.saveBtnTarget.disabled = true
        }  
      } else {
        controller.stopRefreshing()
      }
      
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  mapUniqElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    });

    results = results.filter((value, index, self) => self.indexOf(value) == index)
    // results = results.filter((value, index, self) => self.map(x => x[field]).indexOf(value[field]) == index)

    return results
  }

  randonNumber() {
    var number = Math.floor(Math.random() * 1000)
    return number
  }

  checkChartAccount(group) {
    var groupArray = ["staff_expenses",
                      "administrative_expenses",
                      "financial_expenses",
                      "municipal_taxes_and_contributions",
                      "federal_taxes_and_contributions"]


    return groupArray.includes(group)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
