import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["cards", "carouselIndicator", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf",
    "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf",
    "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf"]

  connect() {
    this.controllerNumber = this.getControllerByIdentifier(`app--helpers--numbers`)
    this.controllerName = `mobile--site--principal--frames--differentials`
    this.pageNumber = 0
    this.numberPages = []

    if (this.getControllerByIdentifier(`web--site--principal--main`)) {
      this.siteController = this.getControllerByIdentifier(`web--site--principal--main`)
    } else if (this.getControllerByIdentifier(`mobile--site--principal--main`)) {
      this.siteController = this.getControllerByIdentifier(`mobile--site--principal--main`)
    }
  }

  doHtml() {
    var html = `<section class="ws-section-differentials d-flex align-items-center">
                  <div class="container">
                    <div class="col-lg-12 text-center my-3">
                      <h1 class="mws-title-action mb-5">${this.application.site.differentials_title}</h1>
                    </div>
                    <div class="row" data-${this.controllerName}-target="cards"></div>
                  </div>
                </section>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.siteController.contentTarget.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.setDifferentials()
    })
  }

  setDifferentials() {
    var current_differentials = []

    this.application.site.differentials.forEach(differential => {
      if (differential.status) {
        current_differentials[current_differentials.length] = differential
      }
    })
    current_differentials = this.getControllerByIdentifier("app--helpers--data").sortByKeyAsc(current_differentials, `order`)
    this.itemsOnPage = 1

    var itemsOnPage = this.itemsOnPage
    var pageNumber = this.pageNumber
    var numberPages = []
    var pages = []
    var itemLoop = Math.ceil(current_differentials.length / itemsOnPage)

    var x = 0
    var i = 1
    for (i; i < itemLoop + 1; i++) {
      var internPage = []
      for (x; x < itemsOnPage * i; x++) {
        if (current_differentials[x] !== undefined) {
          internPage[internPage.length] = current_differentials[x]
        }
      }
      pages[pages.length] = internPage
      numberPages[numberPages.length] = i - 1
    }

    this.numberPages = numberPages
    this.doCarouselData(pages[pageNumber])
  }

  doCarouselData(data) {
    var html = ``

    data.forEach(differential => {
      var link = this.siteController.setLink(differential.link_cta)
      var colClass = ``

      // if (this.application.size == `large`) {
      //   colClass += `col-4`
      // } else if (this.application.size == `medium`) {
      //   colClass += `col-6`
      // } else if (this.application.size == `small`) {
      // }
      colClass += `col-12`

      html += `<div class="${colClass}">
                  <div class="ws-card-services mws-card-services pointer" data-link="${link}" data-action="click->${this.controllerName}#goToCTA">
                    <div class="container">
                      <h3 class="mws-card-title">${differential.title}</h3>
                      <p class="mws-card-text-small">
                        ${differential.description}
                      </p>
                      <a class="mws-card-link">${differential.cta}</a>
                    </div>
                  </div>
                </div>`
    })

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.cardsTarget.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.doCarouselIndicators()
    })
  }

  doCarouselIndicators() {

    var indicatorsHtml = ``
    this.numberPages.forEach(element => {
      indicatorsHtml += `<li data-action="click->${this.controllerName}#changeCarousel" data-${this.controllerName}-target="carouselIndicator" data-slide-to="${element}" class="carousel-indicator-icon mws-carousel-indicator"></li>`
    })

    var html = `<div class="col-lg-12 text-center mt-5 d-flex">
                  <span class="material-icons md-dark mr-auto pointer" data-action="click->${this.controllerName}#previousPage">arrow_back_ios</span>
                  <ol class="carousel-indicators">
                    ${indicatorsHtml}
                  </ol>
                  <span class="material-icons md-dark ml-auto pointer" data-action="click->${this.controllerName}#nextPage">arrow_forward_ios</span>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.cardsTarget.insertAdjacentHTML("beforeend", html))
    }).then(() => {

      if (controller.carouselIndicatorTargets.length > 8) {

        controller.carouselIndicatorTargets.forEach((element, i) => {
          element.classList.add(`d-none`)
        })

        for (var index = 0; index < 3; index++) {
          if (controller.carouselIndicatorTargets[controller.pageNumber - index]) {
            controller.carouselIndicatorTargets[controller.pageNumber - index].classList.remove(`d-none`)
          }
          if (controller.carouselIndicatorTargets[controller.pageNumber + index]) {
            controller.carouselIndicatorTargets[controller.pageNumber + index].classList.remove(`d-none`)
          }
        }
      }

      controller.carouselIndicatorTargets[controller.pageNumber].classList.add(`active`)
    })
  }

  changeCarousel(ev) {
    this.cardsTarget.innerHTML = ``
    this.pageNumber = Number(ev.currentTarget.dataset.slideTo)

    this.setDifferentials()
  }

  previousPage(ev) {
    this.cardsTarget.innerHTML = ``
    if (this.numberPages.includes(this.pageNumber - 1)) {
      this.pageNumber -= 1
    }
    this.setDifferentials()
  }

  nextPage(ev) {
    this.cardsTarget.innerHTML = ``
    if (this.numberPages.includes(this.pageNumber + 1)) {
      this.pageNumber += 1
    }
    this.setDifferentials()
  }

  goToCTA(ev) {
    var url = ev.currentTarget.dataset.link
    window.open(url, `_blank`)
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  getTargetByIdentifier(target) {
    var targetElement = ``
    this.application.controllers.forEach(controller => {
      if (controller.targets.find(target)) {
        targetElement = controller.targets.find(target)
      }
    })

    return targetElement
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

}
