import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["list", "view", "mainCard", "bodyTable", "footerTable", "checkboxShow", "saveBtn", "featureRow",
                    "userDropdown", "userDropdownBtn", "userInput", "userList", "featureName", "userAccountBtn",]

  connect() {
    this.controllerName = `users--works--permissions--entities--page`
    this.user_permissions = []
    this.tableCols = 12
  }

  doPermissionsDashboard(element) {
    this.elementDashboard = element
    var html = `<div class="row" data-${this.controllerName}-target="mainDashboard">
                  <div class="col-12 pr-1" data-${this.controllerName}-target="list"></div>
                </div>`
    
    var controller = this
    new Promise(function (resolve) {
      resolve(controller.elementDashboard.innerHTML = html)
    }).then(() => {
      controller.doIndexListHtml()
    })

    // this.doIndexListHtml()
  }

  sortTable(ev) {
    var mode = ev.currentTarget.dataset.mode
    var field = ev.currentTarget.dataset.field

    if (mode == `desc`) {
      ev.currentTarget.nextElementSibling.classList.remove("d-none")
      ev.currentTarget.classList.add("d-none")
    } else if (mode == `asc`) {
      ev.currentTarget.previousElementSibling.classList.remove("d-none")
      ev.currentTarget.classList.add("d-none")
    }

    this.sort.mode = mode
    this.sort.field = field
    this.doDataTable()
  }

  doIndexListHtml() {
    // userBtn = `<button data-action="click->${this.controllerName}#basicPermissions" data-${this.controllerName}-target="basicBtn-${element.feature_id}" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
    //               <span class="material-icons md-sm md-dark">check_circle</span>
    //               <span class="mc-tooltiptext mc-tooltiptext-right">Marcar Permissões Básicas</span>
    //             </button>`


    var html = `<div class="card" style="width:100%;display:relative;" data-controller="app--helpers--search" data-${this.controllerName}-target="mainCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 card-title-table-list">Todas as Permissões</h6>
                    <input class="form-control f-070 w-30 mx-5" data-app--helpers--search-target="searchInput" data-action="keyup->${this.controllerName}#doFeatureTable" placeholder="Buscar ..." type="text">
                    <div class="card-actions py-0 mc-tooltip w-30 px-5 mx-5">
                      <div class="form-group">
                        <div class="floating-label floating-label-sm">
                          <label>Time</label>
                          <div class="dropdown dropdown-selector" data-controller="app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="userDropdown">
                            <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="userDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="userInput"></span></button>
                            <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                              <ul class="ul-select" data-${this.controllerName}-target="userList"></ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <span class="mc-tooltip mx-auto mr-2 pointer d-none" data-action="click->${this.controllerName}#goToURL" data-url="" data-${this.controllerName}-target="userAccountBtn">
                      <span class="material-icons md-200 md-dark">account_box</span>
                      <span class="mc-tooltiptext">Ir para Usuário</span>
                    </span>
                  </div>
                  <div class="card-body py-0" style="overflow:auto;">
                    <div class="row">
                      <div class="col p-0">
                        <table class="table table-sm table-hover table-borderless" data-${this.controllerName}-target="searchTable" style="font-size:80%;">
                          <thead>
                            <tr>
                              <td style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;" scope="col" class="table-5 align-middle"></td>
                              <td style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;" scope="col" class="table-5 align-middle"></td>
                              <td style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;" scope="col" class="table-5 align-middle"></td>
                              <td style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;" scope="col" class="table-5 align-middle"></td>
                              <td style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;" scope="col" class="table-5 align-middle"></td>
                              <td style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;" scope="col" class="table-5 align-middle"></td>
                              <td style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;" scope="col" class="table-5 align-middle"></td>
                              <td style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;" scope="col" class="table-5 align-middle"></td>
                              <td style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;" scope="col" class="table-5 align-middle"></td>
                              <td style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;" scope="col" class="table-5 align-middle"></td>
                              <td style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;" scope="col" class="table-5 align-middle"></td>
                              <td style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;" scope="col" class="table-5 align-middle"></td>
                            </tr>
                          </thead>
                          <tbody data-${this.controllerName}-target="bodyTable">
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer py-0" data-${this.controllerName}-target="footerTable">
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.listTarget.innerHTML = html)
    }).then(() => {
      controller.mainCardTarget.style.height = ($(window).height() * 0.65) + "px"
      controller.bodyTableTarget.insertAdjacentHTML("beforeend", controller.getControllerByIdentifier("app--helpers--table").doTablePreloader(this.tableCols, 10))
      controller.listUsers()
      controller.doDataTable()
    })
  }

  doDataTable() {
    var current_permissions = this.user_permissions

    var permissions = this.getControllerByIdentifier("app--helpers--data").sortByKeyAsc(current_permissions, `feature_humanize`)

    this.listData(permissions)
  }

  listData(data) {
    this.bodyTableTarget.innerHTML = ""
    if (data == undefined || data == [] || data.length == 0) {
      var noData = `</tr>
                      <td colspan="${this.tableCols}" class="p-3 align-middle text-center" style="font-size:100%;">
                        <span class="fa-stack fa-1x">
                          <i class="fas fa-list fa-stack-1x"></i>
                        </span>
                        <span class="f-075 align-middle">Não há Permissões</span>
                      </td>
                    </tr>`

      this.bodyTableTarget.innerHTML = noData
    } else {
      data.forEach(element => {
        this.bodyTableTarget.insertAdjacentHTML("beforeend", this.permissionTablePartial(element, data.length))
        
        var sensitive_feature_permissions = this.mapElements(this.application.sensitive_permissions, `feature_name`)

        const hasPermission = (currentValue) => currentValue != undefined

        if ((!sensitive_feature_permissions.every(hasPermission)) || (sensitive_feature_permissions.includes(element.feature_name))) {
          this.nameTarget(`check-${element.feature_id}-create`).disabled = true
          this.nameTarget(`check-${element.feature_id}-update`).disabled = true
          this.nameTarget(`check-${element.feature_id}-delete`).disabled = true
          this.nameTarget(`check-${element.feature_id}-read`).disabled = true
          this.nameTarget(`check-${element.feature_id}-list`).disabled = true
          this.nameTarget(`check-${element.feature_id}-show`).disabled = true
          this.nameTarget(`check-${element.feature_id}-index`).disabled = true
          this.nameTarget(`check-${element.feature_id}-upload`).disabled = true
          this.nameTarget(`check-${element.feature_id}-download`).disabled = true
          this.nameTarget(`check-${element.feature_id}-copy`).disabled = true
          this.nameTarget(`check-${element.feature_id}-report`).disabled = true
        } else {
          this.nameTarget(`check-${element.feature_id}-create`).disabled = false
          this.nameTarget(`check-${element.feature_id}-update`).disabled = false
          this.nameTarget(`check-${element.feature_id}-delete`).disabled = false
          this.nameTarget(`check-${element.feature_id}-read`).disabled = false
          this.nameTarget(`check-${element.feature_id}-list`).disabled = false
          this.nameTarget(`check-${element.feature_id}-show`).disabled = false
          this.nameTarget(`check-${element.feature_id}-index`).disabled = false
          this.nameTarget(`check-${element.feature_id}-upload`).disabled = false
          this.nameTarget(`check-${element.feature_id}-download`).disabled = false
          this.nameTarget(`check-${element.feature_id}-copy`).disabled = false
          this.nameTarget(`check-${element.feature_id}-report`).disabled = false
        }


        if (element.can_create) {
          this.nameTarget(`check-${element.feature_id}-create`).checked = true
        } else {
          this.nameTarget(`check-${element.feature_id}-create`).checked = false
        }
        if (element.can_update) {
          this.nameTarget(`check-${element.feature_id}-update`).checked = true
        } else {
          this.nameTarget(`check-${element.feature_id}-update`).checked = false
        }
        if (element.can_delete) {
          this.nameTarget(`check-${element.feature_id}-delete`).checked = true
        } else {
          this.nameTarget(`check-${element.feature_id}-delete`).checked = false
        }
        if (element.can_read) {
          this.nameTarget(`check-${element.feature_id}-read`).checked = true
        } else {
          this.nameTarget(`check-${element.feature_id}-read`).checked = false
        }
        if (element.can_list) {
          this.nameTarget(`check-${element.feature_id}-list`).checked = true
        } else {
          this.nameTarget(`check-${element.feature_id}-list`).checked = false
        }
        if (element.can_show) {
          this.nameTarget(`check-${element.feature_id}-show`).checked = true
        } else {
          this.nameTarget(`check-${element.feature_id}-show`).checked = false
        }
        if (element.can_index) {
          this.nameTarget(`check-${element.feature_id}-index`).checked = true
        } else {
          this.nameTarget(`check-${element.feature_id}-index`).checked = false
        }
        if (element.can_upload) {
          this.nameTarget(`check-${element.feature_id}-upload`).checked = true
        } else {
          this.nameTarget(`check-${element.feature_id}-upload`).checked = false
        }
        if (element.can_download) {
          this.nameTarget(`check-${element.feature_id}-download`).checked = true
        } else {
          this.nameTarget(`check-${element.feature_id}-download`).checked = false
        }
        if (element.can_copy) {
          this.nameTarget(`check-${element.feature_id}-copy`).checked = true
        } else {
          this.nameTarget(`check-${element.feature_id}-copy`).checked = false
        }
        if (element.can_report) {
          this.nameTarget(`check-${element.feature_id}-report`).checked = true
        } else {
          this.nameTarget(`check-${element.feature_id}-report`).checked = false
        }
      });
    }
  }

  permissionTablePartial(element, length) {

    // if (this.application.permissions.medclinic_permissions.can_update) {
    if (this.main_permission.can_update) {
      var edit = `<button data-action="click->${this.controllerName}#editCard" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                    <span class="material-icons md-sm md-dark">save</span>
                    <span class="mc-tooltiptext mc-tooltiptext-right">Editar</span>
                  </button>`
    } else {
      var edit = ``
    }
    
    if (this.application.permissions[this.main_permission].can_update) {
      var basicPermissions = `<button data-action="click->${this.controllerName}#basicPermissions" data-${this.controllerName}-target="basicBtn-${element.feature_id}" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                                <span class="material-icons md-sm md-dark">check_circle</span>
                                <span class="mc-tooltiptext mc-tooltiptext-right">Marcar Permissões Básicas</span>
                              </button>`
    } else {
      var basicPermissions = ``
    }

    var edit = `<button data-action="click->${this.controllerName}#savePermission" data-${this.controllerName}-target="saveBtn-${element.feature_id}" type="button" class="btn btn-sm btn-table p-0 mc-tooltip d-none">
                    <span class="material-icons md-sm md-dark">save</span>
                    <span class="mc-tooltiptext">Salvar</span>
                  </button>`

    if (element.id) {
      var tableRow = `<tr class="itemRow" data-id="${element.id}" data-feature-id="${element.feature_id}" data-feature-name="${element.feature_name}" data-mode="edit" data-${this.controllerName}-target="featureRowTitle-${element.feature_name}">`
    } else {
      var tableRow = `<tr class="itemRow" data-id="${element.id}" data-feature-id="${element.feature_id}" data-feature-name="${element.feature_name}" data-mode="new" data-${this.controllerName}-target="featureRowTitle-${element.feature_name}">`
    }

    // `<td colspan="${this.tableCols - 2}" style="font-size:120%;" scope="col" class="p-1 align-middle text-center border-top-gray"><strong>${element.feature_humanize}</strong></td>`

    var rowHtml = `${tableRow}
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center border-top-gray">${edit}</td>
                    <td colspan="4" style="font-size:120%;" scope="col" class="p-1 align-middle border-top-gray"><strong data-${this.controllerName}-target="featureName" data-feature-name="${element.feature_name}">${element.feature_humanize}</strong></td>
                    <td colspan="2" style="font-size:120%;" scope="col" class="p-1 align-middle border-top-gray">
                      ${basicPermissions}
                    </td>
                    <td colspan="4" style="font-size:120%;" scope="col" class="p-1 align-middle border-top-gray">
                      <span class="mb-0 f-065 mc-tooltip">
                        <span data-controller="app--helpers--copy" data-copy="${element.feature_name}" data-action="click->app--helpers--copy#copy">${element.feature_name}</span>
                        <span class="mc-tooltiptext mc-tooltiptext-right">Clique para Copiar</span>
                      </span>
                    </td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center border-top-gray"></td>
                  </tr>
                  <tr class="itemRow" data-${this.controllerName}-target="featureRowLabel-${element.feature_name}">
                    <td style="font-size:80%;" scope="col" class="pointer p-1 align-middle"></td>
                    <td style="font-size:80%;" scope="col" class="pointer p-1 align-middle">Criar</td>
                    <td style="font-size:80%;" scope="col" class="pointer p-1 align-middle">Editar</td>
                    <td style="font-size:80%;" scope="col" class="pointer p-1 align-middle">Apagar</td>
                    <td style="font-size:80%;" scope="col" class="pointer p-1 align-middle">Ler</td>
                    <td style="font-size:80%;" scope="col" class="pointer p-1 align-middle">Listar</td>
                    <td style="font-size:80%;" scope="col" class="pointer p-1 align-middle">Show</td>
                    <td style="font-size:80%;" scope="col" class="pointer p-1 align-middle">Index</td>
                    <td style="font-size:80%;" scope="col" class="pointer p-1 align-middle">Upload</td>
                    <td style="font-size:80%;" scope="col" class="pointer p-1 align-middle">Download</td>
                    <td style="font-size:80%;" scope="col" class="pointer p-1 align-middle">Copiar</td>
                    <td style="font-size:80%;" scope="col" class="pointer p-1 align-middle">Relatórios</td>
                  </tr>
                  <tr class="itemRow" data-${this.controllerName}-target="featureRowCheck-${element.feature_name}" data-feature-id="${element.feature_id}">
                    <td style="font-size:80%;" scope="col" class="pointer p-1 align-middle"></td>
                    <td style="font-size:80%;" scope="col" class="pointer p-1 align-middle text-center">
                      <div class="custom-control custom-checkbox">
                        <input data-action="click->${this.controllerName}#changePermission" type="checkbox" class="custom-control-input" id="check-${element.feature_id}-create" data-permission="can_create" data-${this.controllerName}-target="check-${element.feature_id}-create">
                        <label class="custom-control-label pointer" for="check-${element.feature_id}-create"></label>
                      </div>
                    </td>
                    <td style="font-size:80%;" scope="col" class="pointer p-1 align-middle text-center">
                      <div class="custom-control custom-checkbox">
                        <input data-action="click->${this.controllerName}#changePermission" type="checkbox" class="custom-control-input" id="check-${element.feature_id}-update" data-permission="can_update" data-${this.controllerName}-target="check-${element.feature_id}-update">
                        <label class="custom-control-label pointer" for="check-${element.feature_id}-update"></label>
                      </div>
                    </td>
                    <td style="font-size:80%;" scope="col" class="pointer p-1 align-middle text-center">
                      <div class="custom-control custom-checkbox">
                        <input data-action="click->${this.controllerName}#changePermission" type="checkbox" class="custom-control-input" id="check-${element.feature_id}-delete" data-permission="can_delete" data-${this.controllerName}-target="check-${element.feature_id}-delete">
                        <label class="custom-control-label pointer" for="check-${element.feature_id}-delete"></label>
                      </div>
                    </td>
                    <td style="font-size:80%;" scope="col" class="pointer p-1 align-middle text-center">
                      <div class="custom-control custom-checkbox">
                        <input data-action="click->${this.controllerName}#changePermission" type="checkbox" class="custom-control-input" id="check-${element.feature_id}-read" data-permission="can_read" data-${this.controllerName}-target="check-${element.feature_id}-read">
                        <label class="custom-control-label pointer" for="check-${element.feature_id}-read"></label>
                      </div>
                    </td>
                    <td style="font-size:80%;" scope="col" class="pointer p-1 align-middle text-center">
                      <div class="custom-control custom-checkbox">
                        <input data-action="click->${this.controllerName}#changePermission" type="checkbox" class="custom-control-input" id="check-${element.feature_id}-list" data-permission="can_list" data-${this.controllerName}-target="check-${element.feature_id}-list">
                        <label class="custom-control-label pointer" for="check-${element.feature_id}-list"></label>
                      </div>
                    </td>
                    <td style="font-size:80%;" scope="col" class="pointer p-1 align-middle text-center">
                      <div class="custom-control custom-checkbox">
                        <input data-action="click->${this.controllerName}#changePermission" type="checkbox" class="custom-control-input" id="check-${element.feature_id}-show" data-permission="can_show" data-${this.controllerName}-target="check-${element.feature_id}-show">
                        <label class="custom-control-label pointer" for="check-${element.feature_id}-show"></label>
                      </div>
                    </td>
                    <td style="font-size:80%;" scope="col" class="pointer p-1 align-middle text-center">
                      <div class="custom-control custom-checkbox">
                        <input data-action="click->${this.controllerName}#changePermission" type="checkbox" class="custom-control-input" id="check-${element.feature_id}-index" data-permission="can_index" data-${this.controllerName}-target="check-${element.feature_id}-index">
                        <label class="custom-control-label pointer" for="check-${element.feature_id}-index"></label>
                      </div>
                    </td>
                    <td style="font-size:80%;" scope="col" class="pointer p-1 align-middle text-center">
                      <div class="custom-control custom-checkbox">
                        <input data-action="click->${this.controllerName}#changePermission" type="checkbox" class="custom-control-input" id="check-${element.feature_id}-upload" data-permission="can_upload" data-${this.controllerName}-target="check-${element.feature_id}-upload">
                        <label class="custom-control-label pointer" for="check-${element.feature_id}-upload"></label>
                      </div>
                    </td>
                    <td style="font-size:80%;" scope="col" class="pointer p-1 align-middle text-center">
                      <div class="custom-control custom-checkbox">
                        <input data-action="click->${this.controllerName}#changePermission" type="checkbox" class="custom-control-input" id="check-${element.feature_id}-download" data-permission="can_download" data-${this.controllerName}-target="check-${element.feature_id}-download">
                        <label class="custom-control-label pointer" for="check-${element.feature_id}-download"></label>
                      </div>
                    </td>
                    <td style="font-size:80%;" scope="col" class="pointer p-1 align-middle text-center">
                      <div class="custom-control custom-checkbox">
                        <input data-action="click->${this.controllerName}#changePermission" type="checkbox" class="custom-control-input" id="check-${element.feature_id}-copy" data-permission="can_copy" data-${this.controllerName}-target="check-${element.feature_id}-copy">
                        <label class="custom-control-label pointer" for="check-${element.feature_id}-copy"></label>
                      </div>
                    </td>                  
                    <td style="font-size:80%;" scope="col" class="pointer p-1 align-middle text-center">
                      <div class="custom-control custom-checkbox">
                        <input data-action="click->${this.controllerName}#changePermission" type="checkbox" class="custom-control-input" id="check-${element.feature_id}-report" data-permission="can_report" data-${this.controllerName}-target="check-${element.feature_id}-report">
                        <label class="custom-control-label pointer" for="check-${element.feature_id}-report"></label>
                      </div>
                    </td>                  
                  </tr>`

    return rowHtml
  }

  changePermission(ev) {
    var featureId = ev.currentTarget.closest(".itemRow").dataset.featureId

    this.nameTarget(`saveBtn-${featureId}`)?.classList.remove("d-none")
  }

  basicPermissions(ev) {
    var featureId = ev.currentTarget.closest(".itemRow").dataset.featureId
    var featureName = ev.currentTarget.closest(".itemRow").dataset.featureName

    var sensitive_permission = {}
    var sensitive_feature_permissions = this.mapElements(this.application.sensitive_permissions, `feature_name`)

    this.application.sensitive_permissions.forEach(element => {
      if (featureName == element.feature_name) {
        sensitive_permission = element
      }
    });

    const hasPermission = (currentValue) => currentValue != undefined

    if ((!sensitive_feature_permissions.every(hasPermission)) || (sensitive_feature_permissions.includes(featureName))) {
      this.nameTarget(`saveBtn-${featureId}`).classList.remove("d-none")
      this.nameTarget(`check-${featureId}-create`).checked = sensitive_permission.can_create
      this.nameTarget(`check-${featureId}-update`).checked = sensitive_permission.can_update
      this.nameTarget(`check-${featureId}-delete`).checked = sensitive_permission.can_delete
      this.nameTarget(`check-${featureId}-read`).checked = sensitive_permission.can_read
      this.nameTarget(`check-${featureId}-list`).checked = sensitive_permission.can_list
      this.nameTarget(`check-${featureId}-show`).checked = sensitive_permission.can_show
      this.nameTarget(`check-${featureId}-index`).checked = sensitive_permission.can_index
      this.nameTarget(`check-${featureId}-upload`).checked = sensitive_permission.can_upload
      this.nameTarget(`check-${featureId}-download`).checked = sensitive_permission.can_download
      this.nameTarget(`check-${featureId}-copy`).checked = sensitive_permission.can_copy
      this.nameTarget(`check-${featureId}-report`).checked = sensitive_permission.can_report
    } else {
      this.nameTarget(`saveBtn-${featureId}`).classList.remove("d-none")
      this.nameTarget(`check-${featureId}-create`).checked = true
      this.nameTarget(`check-${featureId}-update`).checked = true
      this.nameTarget(`check-${featureId}-delete`).checked = false
      this.nameTarget(`check-${featureId}-read`).checked = true
      this.nameTarget(`check-${featureId}-list`).checked = true
      this.nameTarget(`check-${featureId}-show`).checked = false
      this.nameTarget(`check-${featureId}-index`).checked = true
      this.nameTarget(`check-${featureId}-upload`).checked = false
      this.nameTarget(`check-${featureId}-download`).checked = false
      this.nameTarget(`check-${featureId}-copy`).checked = false
      this.nameTarget(`check-${featureId}-report`).checked = false
    }

  }

  savePermission(ev) {
    this.actionMode = ev.currentTarget.closest(".itemRow").dataset.mode
    var featureId = ev.currentTarget.closest(".itemRow").dataset.featureId

    this.send_data = { current_user: {}, permission: {} }

    if (this.actionMode == `edit`) {
      this.send_data.permission.id = ev.currentTarget.closest(".itemRow").dataset.id
    }

    this.send_data.permission.can_create = this.nameTarget(`check-${featureId}-create`).checked
    this.send_data.permission.can_update = this.nameTarget(`check-${featureId}-update`).checked
    this.send_data.permission.can_delete = this.nameTarget(`check-${featureId}-delete`).checked
    this.send_data.permission.can_read = this.nameTarget(`check-${featureId}-read`).checked
    this.send_data.permission.can_list = this.nameTarget(`check-${featureId}-list`).checked
    this.send_data.permission.can_show = this.nameTarget(`check-${featureId}-show`).checked
    this.send_data.permission.can_index = this.nameTarget(`check-${featureId}-index`).checked
    this.send_data.permission.can_upload = this.nameTarget(`check-${featureId}-upload`).checked
    this.send_data.permission.can_download = this.nameTarget(`check-${featureId}-download`).checked
    this.send_data.permission.can_copy = this.nameTarget(`check-${featureId}-copy`).checked
    this.send_data.permission.can_report = this.nameTarget(`check-${featureId}-report`).checked
    this.send_data.permission.feature_id = featureId
    this.send_data.permission.feature_name = ev.currentTarget.closest(".itemRow").dataset.featureName
    this.send_data.permission.user_id = this.user_id

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = this.main_permission

    this.requestSave()
  }

  requestSave() {
    if (this.actionMode == `new`) {
      var url = "/users/permissions/entities/create"
      var method = "POST"
    } else if (this.actionMode == `edit`) {
      var url = "/users/permissions/entities/update"
      var method = "PUT"
    }
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          var permission = response.data.cln
          if (controller.actionMode == `new`) {
            controller.user_permissions.forEach((element, i) => {
              if (element.feature_name == permission.feature_name) {
                controller.user_permissions.splice(controller.user_permissions.indexOf(element), 1)
              }
            })
            controller.user_permissions[controller.user_permissions.length] = permission
          } else if (controller.actionMode == `edit`) {
            controller.user_permissions.forEach((element, i) => {
              if (element.id == permission.id) {
                controller.user_permissions.splice(controller.user_permissions.indexOf(element), 1, permission)
              }
            })
          }
        }
        controller.doDataTable()
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  listUsers() {
    var listHtml = ``

    this.application.team_list.forEach(element => {
      listHtml += `<li data-app--helpers--selector-target="select" data-user-id="${element.user_id}" data-account-id="${element.id}" data-account-token="${element.token}" data-action="click->app--helpers--selector#select click->${this.controllerName}#changeUser" class="li-selector dark f-065">${element.name}</li>`
    });

    this.userListTarget.innerHTML = listHtml
  }

  changeUser(ev) {
    this.user_id = ev.currentTarget.dataset.userId
    this.account_id = ev.currentTarget.dataset.accountId
    this.token = ev.currentTarget.dataset.accountToken
    this.getPermissions()

    this.userAccountBtnTarget.classList.remove("d-none")
    this.userAccountBtnTarget.dataset.url = `/a/minha-conta/${this.token}`

    // this.checkPermissions()
  }

  checkPermissions() {
    var data = { permission: { features: this.application.feature_names }, account: { account_id: this.account_id }, current_user: { current_user_id: this.application.current_user.id } }
    var url = "/users/permissions/check_if_has_permissions"
    var init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        var has_permissions = response.data.cln.has_permissions
        var is_confirmed = response.data.cln.is_confirmed

      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  getPermissions() {
    const data = { permissions: { user_id: this.user_id, features: this.application.feature_names }, current_user: { current_user_id: this.application.current_user.id, feature: this.main_permission } }
    const url = "/users/permissions/entities/list_by_user"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.user_permissions = response.data.cln
        controller.doDataTable()
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0
      controller.validGroupTargets.forEach(element => {
        if (this.fromStringToBoolean(element.dataset.valid) == false) {
          len += 1
        }
      });
      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  goToURL(ev) {
    var url = ev.currentTarget.dataset.url
    if (url) {
      window.open(url, `_blank`)
    }
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    });
    return results
  }

  doFeatureTable(ev) {
    if ((ev.type == "keyup" && ev.key == "Escape" && ev.shiftKey == false)) {
      ev.target.value = ""
    }
    var filter = ev.target.value.toUpperCase()
    var filterUnaccent = filter.normalize("NFD").replace(/[\u0300-\u036f]/g, "")

    var features = this.featureNameTargets
    if (filterUnaccent.length > 2 || filterUnaccent == ``) {
      for (var i = 0; i < features.length; i++) {
        var feature = features[i]
        var show = false

        if (feature) {
          var txtValue = feature.textContent || feature.innerText;
          var txtValueUnaccent = txtValue.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
          if ((txtValue.toUpperCase().indexOf(filter) > -1) || (txtValueUnaccent.toUpperCase().indexOf(filterUnaccent) > -1)) {
            show = true;
          }
        }

        var name = feature.dataset.featureName
        this.nameTarget(`featureRowTitle-${name}`)
        this.nameTarget(`featureRowLabel-${name}`)
        this.nameTarget(`featureRowCheck-${name}`)
        if (show == true) {
          this.nameTarget(`featureRowTitle-${name}`).style.display = "";
          this.nameTarget(`featureRowLabel-${name}`).style.display = "";
          this.nameTarget(`featureRowCheck-${name}`).style.display = "";
          this.nameTarget(`featureRowTitle-${name}`).classList.add("filter-row")
          this.nameTarget(`featureRowLabel-${name}`).classList.add("filter-row")
          this.nameTarget(`featureRowCheck-${name}`).classList.add("filter-row")
        } else {
          this.nameTarget(`featureRowTitle-${name}`).style.display = "none";
          this.nameTarget(`featureRowLabel-${name}`).style.display = "none";
          this.nameTarget(`featureRowCheck-${name}`).style.display = "none";
          this.nameTarget(`featureRowTitle-${name}`).classList.remove("filter-row")
          this.nameTarget(`featureRowLabel-${name}`).classList.remove("filter-row")
          this.nameTarget(`featureRowCheck-${name}`).classList.remove("filter-row")
        }
      }
    }
  }

  // var controller = this
  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
