import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["mainContent", "mainFrame", "secondaryFrame", "questionsFrame", "5555", "6666", "7777", "8888", "9999"]

  connect() {
    this.downloadSlug = location.pathname.split("/").pop()
    if (this.application.current_user_id != null || this.application.current_user_id != undefined) {
      this.application.public_view = false
    } else {
      this.application.public_view = true
    }
    this.doMainContentHeight()
    this.doDownloadPreloaderHtml()
    this.getDownload()
  }

  doMainFrameHtml() {
    var html = `<section class="frame-site-services py-3 my-3 frame-white-100 justify-ebook" id="downloadMaterial" data-marketing--downloads--entities--public-show-target="mainFrame">
                    <div class="row justify-ebook h-inherit d-flex align-items-center">
                      <div class="col-lg-6 col-sm-12 justify-ebook">
                          <img class="img-fluid" src="${this.download.cover_url}" alt="MedCapital" width="800">
                      </div>
                      <div class="col-lg-6 col-sm-12 h-inherit text-center">
                        <h1 class="title-lp-ebook my-5 py-5"></h1>
                        <h1 class="title-lp-ebook my-4 py-4">
                          ${this.download.title_one}
                        </h1>
                        <p class="text-justify mt-5 pt-5">
                          ${this.download.subtitle_one}
                        </p>
                        <span class="btn btn-success a-dark" data-action="click->marketing--downloads--entities--public-show#getDownloadMaterial">Baixar Material</span>
                      </div>
                    </div> 
                </section>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.mainContentTarget.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.mainFrameTarget.style.height = $(window).height() + `px`
    }) 
  }

  doSecondaryFrameHtml() {
    var html = `<section class="py-3 my-3 imgback justify-ebook text-center" data-marketing--downloads--entities--public-show-target="secondaryFrame">
                  <div class="row d-flex flex-column align-items-center justify-content-center">
                    <div class="col-lg-8 col-sm-12">
                      <h1 class="dark-title-lp-ebook my-4">
                        ${this.download.title_two}
                      </h1>
                    </div>
                    <div class="col-sm-12 col-lg-8 text-center my-3">
                      <div class="embed-responsive embed-responsive-16by9">
                        <iframe id="video" class="embed-responsive-item" src="https://www.youtube.com/embed/${this.download.video}" allowfullscreen></iframe>
                      </div>
                    </div>
                    <div class="col-lg-8 col-sm-12 my-4">
                      <h1 class="dark-title-lp-ebook">
                        ${this.download.setence_cta}
                      </h1>
                      <a class="btn btn-primary color-btn-ebook a-dark" href="#downloadMaterial">${this.download.btn_cta}</a>
                    </div>
                  </div>
                </section>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.mainContentTarget.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      // controller.secondaryFrameTarget.style.height = $(window).height() + `px`
    })
  }

  doQuestionsFrameHtml() {
    var html = `<section class="justify-ebook" data-marketing--downloads--entities--public-show-target="questionsFrame">
                  <div class="row d-flex align-items-center py-3 my-3 mt-2" id="siteQuestions">
                    <div class="col-sm-12 mb-4">
                      <h1 class="display-4 title-two text-center">
                        Perguntas <span style="color:#26C485;"> Frequentes</span>
                      </h1>
                    </div>
                    <div class="col-sm-8 offset-sm-2">
                      <div class="accordion" id="accordionLanding">
                        <div class="card" style="z-index:20;">
                          <div class="card-header" id="headingOne">
                            <h5 class="mb-0">
                              <button id="questionOne" class="btn btn-link btn-wrap question" type="button" data-toggle="collapse"
                                data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                ${this.download.question_one}
                              </button>
                            </h5>
                          </div>
                          <div id="collapseOne" class="collapse " aria-labelledby="headingOne" data-parent="#accordionLanding">
                            <div class="card-body answer">
                              <span id="answerOne">
                                ${this.download.answer_one}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div class="card" style="z-index:20;">
                          <div class="card-header" id="headingTwo">
                            <h5 class="mb-0 ">
                              <button id="questionTwo" class="btn btn-link btn-wrap collapsed question" type="button"
                                data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                ${this.download.question_two}
                              </button>
                            </h5>
                          </div>
                          <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionLanding">
                            <div class="card-body answer">
                              <span id="answerTwo">
                                ${this.download.answer_two}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div class="card" style="z-index:20;">
                          <div class="card-header" id="headingThree">
                            <h5 class="mb-0">
                              <button id="questionThree" class="btn btn-link btn-wrap collapsed question" type="button"
                                data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                ${this.download.question_three}
                              </button>
                            </h5>
                          </div>
                          <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionLanding">
                            <div class="card-body answer">
                              <span id="answerThree">
                                ${this.download.answer_three}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.mainContentTarget.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.questionsFrameTarget.style.height = $(window).height() + `px`
    })
  }

  getDownloadMaterial() {
    window.open(this.download.material_url, `_blank`)
  }

  getDownload() {
    var data = { download: { slug: this.downloadSlug } }
    const token = $('meta[name=csrf-token]').attr('content');
    const url = "/commercial/marketing/downloads/entities/read_public"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.download = response.data.cln
          
          controller.mainContentTarget.style.height = ""
          controller.mainContentTarget.innerHTML = ""
          controller.doMainFrameHtml()
          controller.doSecondaryFrameHtml()
          controller.doQuestionsFrameHtml()
          if (controller.application.public_view) {
            controller.saveDownloadConversion()
          }
        } else {
          processingSnackbar(response.type, response.message, device)
        }
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  saveDownloadConversion() {

    var visitorToken = this.getControllerByIdentifier("app--shared--cookies").getCookie("ahoy_visitor")
    var data = { conversion: { download_id: this.download.id, visitor_token: visitorToken } }

    const token = $('meta[name=csrf-token]').attr('content');
    const url = "/commercial/marketing/downloads/conversions/save_public"
    const init = { method: "PUT", credentials: "same-origin", headers: { "X-CSRF-Token": token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          var conversion = response.data.cln
        } else {
          processingSnackbar(response.type, response.message, device)
        }
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  doDownloadPreloaderHtml() {
    
    var preloaderHtml = ''
    for (var i = 0; i < 8; i++) {
      preloaderHtml += `<div class='card timeline-item m-0 p-1 w-100 my-3' style="box-shadow:none;max-width:none;">
                          <div class='animated-background animated-background-5'>
                            <div class='background-masker title'></div>
                          </div>
                        </div>`
    }

    var html = `<div class="row d-flex align-items-center my-5 py-5" style="height:20rem;">
                  <div class="col-4 offset-2 my-5 py-5">
                    ${preloaderHtml}
                  </div>
                  <div class="col-4 my-5 py-5">
                    ${preloaderHtml}
                  </div>
                </div>`

    this.mainContentTarget.insertAdjacentHTML("beforeend", html)
  }

  doMainContentHeight() {
    this.mainContentTarget.style.height = $(window).height() + `px`
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

}
