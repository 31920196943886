import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["defaultFields", "customFields", "nameCheckbox", "emailCheckbox", "phonCheckbox", "sourceCheckbox", "crmCheckbox", "cpfCheckbox", "buttonCheckbox", "button", "titleCheckbox", "title", "clinicQuestionCheckbox", "clinicQuestion", "individualQuestionCheckbox", "individualQuestion", "billingQuestionCheckbox", "billingQuestion", "irQuestionCheckbox", "irQuestion", "insuranceQuestionCheckbox", "insuranceQuestion", "employeeQuestionCheckbox", "employeeQuestion", "investmentsQuestionCheckbox", "investmentsQuestion", "saveDefaultFieldsBtn", "saveCustomFieldsBtn" ]

  connect() {
    this.controllerName = `commercial--marketing--landings--show--form`
    
    // send_data dividido entre campos padrão e customizados
    this.send_data = { form: { kind: "", default: {}, custom: {} } }
    
    // APAGAR - objeto form da api
    this.formFetch = {
      nameCheckbox: true,
      emailCheckbox: true,
      phonCheckbox: true,
      sourceCheckbox: true,
      crmCheckbox: true,
      cpfCheckbox: true,
      button: "Salvar",
      titleCheckbox: true,
      title: "Título da Landing",
      clinicQuestionCheckbox: true,
      clinicQuestion: "Pergunta sobre Pessoa Júridica?",
      individualQuestionCheckbox: true,
      individualQuestion: "Pergunta sobre Pessoa Física?",
      billingQuestionCheckbox: true,
      billingQuestion: "Pergunta sobre Faturamento?",
      irQuestionCheckbox: true,
      irQuestion: "Pergunta sobre IR?",
      insuranceQuestionCheckbox: true,
      insuranceQuestion: "Pergunta sobre Seguros?",
      employeeQuestionCheckbox: true,
      employeeQuestion: "Pergunta sobre Funcionarios?",
      investmentsQuestionCheckbox: true,
      investmentsQuestion: "Pergunta sobre Investimentos?"
    }
  }

  doFormGrid() {
    var html = `<div class="row">
                  <div class="col-6" data-${this.controllerName}-target="defaultFields"></div>
                  <div class="col-6" data-${this.controllerName}-target="customFields"></div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier(`commercial--marketing--landings--show--dashboard`).contentTarget.innerHTML = html)
    }).then(() => {
      // construir grid dos campos padrões
      controller.doDefaultFieldsHtml()

      // construir grid dos campos customizados
      controller.doCustomFieldsHtml()
    })
  }

  doDefaultFieldsHtml() {
    // html campos padrão
    if (this.application.permissions.landings.can_update) {
      var saveBtnHtml = `<button type="button" class="btn btn-primary btn-sm py-2 btn-table f-065" data-action="click->${this.controllerName}#saveData" data-${this.controllerName}-target="saveDefaultFieldsBtn">Salvar</button>`
    } else {
      var saveBtnHtml = ``
    }

    var html = `<div class="card style="width:100%;display:relative;" data-${this.controllerName}-target="mainCard">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 py-3" style="font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="formTitle">Campos Padrão do Formulário</h6>
                  </div>
    
                  <div class="row rm-1">
                    <div class="col-sm-4 px-0">
                      <div class="custom-control custom-switch ml-3" style="display:inline;margin-right:1rem;">
                        <input data-${this.controllerName}-target="nameCheckbox" class="custom-control-input" id="nameCheckbox" type="checkbox">
                        <span class="custom-control-track"></span>
                        <label class="custom-control-label" for="nameCheckbox" style="font-size:61%;">Nome</label>
                      </div>
                    </div>                    

                    <div class="col-sm-4 px-0">
                      <div class="custom-control custom-switch ml-3" style="display:inline;margin-right:1rem;">
                        <input data-${this.controllerName}-target="emailCheckbox" class="custom-control-input" id="emailCheckbox" type="checkbox">
                        <span class="custom-control-track"></span>
                        <label class="custom-control-label" for="emailCheckbox" style="font-size:61%;">E-mail</label>
                      </div>
                    </div>
                    <div class="col-sm-4 px-0">
                      <div class="custom-control custom-switch ml-3" style="display:inline;margin-right:1rem;">
                        <input data-${this.controllerName}-target="phonCheckbox" class="custom-control-input" id="phonCheckbox" type="checkbox">
                        <span class="custom-control-track"></span>
                        <label class="custom-control-label" for="phonCheckbox" style="font-size:61%;">Telefone</label>
                      </div>
                    </div>
                  </div>
                  <div class="row rm-bottom-1">
                    <div class="col-sm-4 px-0">
                      <div class="custom-control custom-switch ml-3" style="display:inline;margin-right:1rem;">
                        <input data-${this.controllerName}-target="sourceCheckbox" class="custom-control-input" id="sourceCheckbox" type="checkbox">
                        <span class="custom-control-track"></span>
                        <label class="custom-control-label" for="sourceCheckbox" style="font-size:61%;">Fonte</label>
                      </div>
                    </div>
                    <div class="col-sm-4 px-0">
                      <div class="custom-control custom-switch ml-3" style="display:inline;margin-right:1rem;">
                        <input data-${this.controllerName}-target="crmCheckbox" class="custom-control-input" id="crmCheckbox" type="checkbox">
                        <span class="custom-control-track"></span>
                        <label class="custom-control-label" for="crmCheckbox" style="font-size:61%;">CRM</label>
                      </div>
                    </div>
                    <div class="col-sm-4 px-0">
                      <div class="custom-control custom-switch ml-3" style="display:inline;margin-right:1rem;">
                        <input data-${this.controllerName}-target="cpfCheckbox" class="custom-control-input" id="cpfCheckbox" type="checkbox">
                        <span class="custom-control-track"></span>
                        <label class="custom-control-label" for="cpfCheckbox" style="font-size:61%;">CPF</label>
                      </div>
                    </div>
                  </div>
                  <div class="row d-flex align-items-center">
                    <div class="col-sm-4 px-0">
                      <div class="custom-control custom-switch ml-3" style="display:inline;" disabled="true">
                        <input data-${this.controllerName}-target="buttonCheckbox" class="custom-control-input" id="buttonCheckbox" type="checkbox">
                        <span class="custom-control-track"></span>
                        <label class="custom-control-label" for="buttonCheckbox" style="font-size:61%;">Salvar</label>
                      </div>
                    </div>
                    <div class="col-sm-8">
                      <div class="form-group" is-valid="false">
                        <div class="floating-label floating-label-sm">
                          <label for="button">Qual o titulo do Button?</label>
                          <input data-${this.controllerName}-target="button" aria-describedby="qButtonLeadFormHelp" class="form-control input-lead-form" id="button" placeholder="Qual o titulo do Button?" style="font-size:61%;" type="text">
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row d-flex align-items-center">
                    <div class="col-sm-4 px-0">
                      <div class="custom-control custom-switch ml-3" style="display:inline;">
                        <input data-${this.controllerName}-target="titleCheckbox" class="custom-control-input" id="titleCheckbox" type="checkbox">
                        <span class="custom-control-track"></span>
                        <label class="custom-control-label" for="titleCheckbox" style="font-size:61%;">Título?</label>
                      </div>
                    </div>
                    <div class="col-sm-8">
                      <div class="form-group" is-valid="false">
                        <div class="floating-label floating-label-sm">
                          <label for="title">Qual o titulo do Formulário?</label>
                          <input data-${this.controllerName}-target="title" aria-describedby="qTitleLeadFormHelp" class="form-control input-lead-form" id="title" placeholder="Qual o titulo do Formulário?" style="font-size:61%;" type="text">
                        </div>
                      </div> 
                    </div>
                  </div>

                  <div class="card-footer border-top text-right py-1">
                    ${saveBtnHtml}
                  </div>
                
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.defaultFieldsTarget.innerHTML = html)
    }).then(() => {
      // preencher formulário com valores buscados no load da página
      // trocar this.formFetch pelo this.landings.form
      if(this.formFetch.nameCheckbox) {
        this.nameCheckboxTarget.checked = this.formFetch.nameCheckbox
      }
      if(this.formFetch.emailCheckbox) {
        this.emailCheckboxTarget.checked = this.formFetch.emailCheckbox
      }
      if(this.formFetch.phonCheckbox) {
        this.phonCheckboxTarget.checked = this.formFetch.phonCheckbox
      }
      if(this.formFetch.sourceCheckbox) {
        this.sourceCheckboxTarget.checked = this.formFetch.sourceCheckbox
      }
      if(this.formFetch.crmCheckbox) {
        this.crmCheckboxTarget.checked = this.formFetch.crmCheckbox
      }
      if(this.formFetch.cpfCheckbox) {
        this.cpfCheckboxTarget.checked = this.formFetch.cpfCheckbox
      }
      // checkbox salvar sempre true
      this.buttonCheckboxTarget.checked = true
      if(this.formFetch.button) {
        this.buttonTarget.value = this.formFetch.button
      }
      if(this.formFetch.titleCheckbox) {
        this.titleCheckboxTarget.checked = this.formFetch.titleCheckbox
      }
      if(this.formFetch.title) {
        this.titleTarget.value = this.formFetch.title
      }
  
      this.getControllerByIdentifier("app--helpers--forms").floatingLabel()

    })
  }

  doCustomFieldsHtml(){
    // html campos customizados
    if (this.application.permissions.landings.can_update) {
      var saveBtnHtml = `<button type="button" class="btn btn-primary btn-sm py-2 btn-table f-065" data-action="click->${this.controllerName}#saveData" data-${this.controllerName}-target="saveCustomFieldsBtn">Salvar</button>`
    } else {
      var saveBtnHtml = ``
    }

    var html = `<div class="card style="width:100%;display:relative;" data-${this.controllerName}-target="mainCard">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065 mb-2">
                    <h6 class="card-title display-4 py-3" style="font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="formTitle">Campos Customizados do Formulário</h6>
                  </div>

                  <div class="row d-flex align-items-center">
                    <div class="col-sm-4 px-0">
                      <div class="custom-control custom-switch ml-3" style="display:inline;">
                        <input data-${this.controllerName}-target="clinicQuestionCheckbox" class="custom-control-input" id="clinicQuestionCheckbox" type="checkbox">
                        <span class="custom-control-track"></span>
                        <label class="custom-control-label" for="clinicQuestionCheckbox" style="font-size:61%;">Sobre PJ?</label>
                      </div>
                    </div>
                    <div class="col-sm-8">
                      <div class="form-group" is-valid="false">
                        <div class="floating-label floating-label-sm">
                          <label for="clinicQuestion">Pergunta sobre PJ Médica</label>
                          <input data-${this.controllerName}-target="clinicQuestion" aria-describedby="qClinicLeadFormHelp" class="form-control input-lead-form" id="clinicQuestion" placeholder="Pergunta sobre PJ Médica" style="font-size:61%;" type="text">
                        </div>
                      </div> 
                    </div>
                  </div>
                  <div class="row d-flex align-items-center">
                    <div class="col-sm-4 px-0">
                      <div class="custom-control custom-switch ml-3" style="display:inline;">
                        <input data-${this.controllerName}-target="individualQuestionCheckbox" class="custom-control-input" id="individualQuestionCheckbox" type="checkbox">
                        <span class="custom-control-track"></span>
                        <label class="custom-control-label" for="individualQuestionCheckbox" style="font-size:61%;">Sobre PF?</label>
                      </div>
                    </div>
                    <div class="col-sm-8">
                      <div class="form-group" is-valid="false">
                        <div class="floating-label floating-label-sm">
                          <label for="individualQuestion">Pergunta sobre PF Médica</label>
                          <input data-${this.controllerName}-target="individualQuestion" aria-describedby="qDoctorLeadFormHelp" class="form-control input-lead-form" id="individualQuestion" placeholder="Pergunta sobre PF Médica" style="font-size:61%;" type="text">
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row d-flex align-items-center">
                    <div class="col-sm-4 px-0">
                      <div class="custom-control custom-switch ml-3" style="display:inline;">
                        <input data-${this.controllerName}-target="billingQuestionCheckbox" class="custom-control-input" id="billingQuestionCheckbox" type="checkbox">
                        <span class="custom-control-track"></span>
                        <label class="custom-control-label" for="billingQuestionCheckbox" style="font-size:61%;">Faturamento?</label>
                      </div>
                    </div>
                    <div class="col-sm-8">
                      <div class="form-group" is-valid="false">
                        <div class="floating-label floating-label-sm">
                          <label for="billingQuestion">Pergunta sobre Faturamento</label>
                          <input data-${this.controllerName}-target="billingQuestion" aria-describedby="qInvoicesLeadFormHelp" class="form-control input-lead-form" id="billingQuestion" placeholder="Pergunta sobre Faturamento" style="font-size:61%;" type="text">
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row d-flex align-items-center">
                    <div class="col-sm-4 px-0">
                      <div class="custom-control custom-switch ml-3" style="display:inline;">
                        <input data-${this.controllerName}-target="irQuestionCheckbox" class="custom-control-input" id="irQuestionCheckbox" type="checkbox">
                        <span class="custom-control-track"></span>
                        <label class="custom-control-label" for="irQuestionCheckbox" style="font-size:61%;">IR?</label>
                      </div>
                    </div>
                    <div class="col-sm-8">
                      <div class="form-group" is-valid="false">
                        <div class="floating-label floating-label-sm">
                          <label for="irQuestion">Pergunta sobre Imposto de Renda</label>
                          <input data-${this.controllerName}-target="irQuestion" aria-describedby="qTaxesLeadFormHelp" class="form-control input-lead-form" id="irQuestion" placeholder="Pergunta sobre Imposto de Renda" style="font-size:61%;" type="text">
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row d-flex align-items-center">
                    <div class="col-sm-4 px-0">
                      <div class="custom-control custom-switch ml-3" style="display:inline;">
                        <input data-${this.controllerName}-target="insuranceQuestionCheckbox" class="custom-control-input" id="insuranceQuestionCheckbox" type="checkbox">
                        <span class="custom-control-track"></span>
                        <label class="custom-control-label" for="insuranceQuestionCheckbox" style="font-size:61%;">Seguros?</label>
                      </div>
                    </div>
                    <div class="col-sm-8">
                      <div class="form-group" is-valid="false">
                        <div class="floating-label floating-label-sm">
                          <label for="insuranceQuestion">Pergunta sobre Seguros</label>
                          <input data-${this.controllerName}-target="insuranceQuestion" aria-describedby="qInsuranceLeadFormHelp" class="form-control input-lead-form" id="insuranceQuestion" placeholder="Pergunta sobre Seguros" style="font-size:61%;" type="text">
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row d-flex align-items-center">
                    <div class="col-sm-4 px-0">
                      <div class="custom-control custom-switch ml-3" style="display:inline;">
                        <input data-${this.controllerName}-target="employeeQuestionCheckbox" class="custom-control-input" id="employeeQuestionCheckbox" type="checkbox">
                        <span class="custom-control-track"></span>
                        <label class="custom-control-label" for="employeeQuestionCheckbox" style="font-size:61%;">Funcionários?</label>
                      </div>
                    </div>
                    <div class="col-sm-8">
                      <div class="form-group" is-valid="false">
                        <div class="floating-label floating-label-sm">
                          <label for="employeeQuestion">Pergunta sobre Funcionários</label>
                          <input data-${this.controllerName}-target="employeeQuestion" aria-describedby="qEmployeeLeadFormHelp" class="form-control input-lead-form" id="employeeQuestion" placeholder="Pergunta sobre Funcionários" style="font-size:61%;" type="text">
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row d-flex align-items-center">
                    <div class="col-sm-4 px-0">
                      <div class="custom-control custom-switch ml-3" style="display:inline;">
                        <input data-${this.controllerName}-target="investmentsQuestionCheckbox" class="custom-control-input" id="investmentsQuestionCheckbox" type="checkbox">
                        <span class="custom-control-track"></span>
                        <label class="custom-control-label" for="investmentsQuestionCheckbox" style="font-size:61%;">Investimentos?</label>
                      </div>
                    </div>
                    <div class="col-sm-8">
                      <div class="form-group" is-valid="false">
                        <div class="floating-label floating-label-sm">
                          <label for="investmentsQuestion">Pergunta sobre Investimentos</label>
                          <input data-${this.controllerName}-target="investmentsQuestion" aria-describedby="qInvestmentsLeadFormHelp" class="form-control input-lead-form" id="investmentsQuestion" placeholder="Pergunta sobre Investimentos" style="font-size:61%;" type="text">
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="card-footer border-top text-right py-1">
                    ${saveBtnHtml}
                  </div>

                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.customFieldsTarget.innerHTML = html)
    }).then(() => {
      // preencher formulário com valores buscados no load da página
      // trocar this.formFetch pelo this.landings.form
      if (this.formFetch.clinicQuestionCheckbox) {
        this.clinicQuestionCheckboxTarget.checked = this.formFetch.clinicQuestionCheckbox
      }
      if (this.formFetch.clinicQuestion) {
        this.clinicQuestionTarget.value = this.formFetch.clinicQuestion
      }
      if (this.formFetch.individualQuestionCheckbox) {
        this.individualQuestionCheckboxTarget.checked = this.formFetch.individualQuestionCheckbox
      } 
      if (this.formFetch.individualQuestion) {
        this.individualQuestionTarget.value = this.formFetch.individualQuestion
      }
      if (this.formFetch.billingQuestionCheckbox) {
        this.billingQuestionCheckboxTarget.checked = this.formFetch.billingQuestionCheckbox
      }
      if (this.formFetch.billingQuestion) {
        this.billingQuestionTarget.value = this.formFetch.billingQuestion
      }
      if (this.formFetch.irQuestionCheckbox) {
        this.irQuestionCheckboxTarget.checked = this.formFetch.irQuestionCheckbox
      }
      if (this.formFetch.irQuestion) {
        this.irQuestionTarget.value = this.formFetch.irQuestion
      }
      if (this.formFetch.insuranceQuestionCheckbox) {
        this.insuranceQuestionCheckboxTarget.checked = this.formFetch.insuranceQuestionCheckbox
      }
      if (this.formFetch.insuranceQuestion) {
        this.insuranceQuestionTarget.value = this.formFetch.insuranceQuestion
      }
      if (this.formFetch.employeeQuestionCheckbox) {
        this.employeeQuestionCheckboxTarget.checked = this.formFetch.employeeQuestionCheckbox
      }
      if (this.formFetch.employeeQuestion) {
        this.employeeQuestionTarget.value = this.formFetch.employeeQuestion
      }
      if (this.formFetch.investmentsQuestionCheckbox) {
        this.investmentsQuestionCheckboxTarget.checked = this.formFetch.investmentsQuestionCheckbox
      }
      if (this.formFetch.investmentsQuestion) {
        this.investmentsQuestionTarget.value = this.formFetch.investmentsQuestion
      }

      this.getControllerByIdentifier("app--helpers--forms").floatingLabel()
    })
  }

  saveData(ev) {
    // esvazir o send_data.form 
    this.send_data.form.default = {}
    this.send_data.form.custom = {}
    this.send_data.form.kind = ""

    // salvar alterações dos campos padrão no send_data antes de realizar o fetch
    if(ev.target.dataset.target == "commercial--marketing--landings--show--form.saveDefaultFieldsBtn"){
      this.send_data.form.kind = "default"
      this.send_data.form.default.i_name = this.nameCheckboxTarget.checked
      this.send_data.form.default.i_email = this.emailCheckboxTarget.checked
      this.send_data.form.default.i_phone = this.phonCheckboxTarget.checked
      this.send_data.form.default.i_source = this.sourceCheckboxTarget.checked
      this.send_data.form.default.i_crm = this.crmCheckboxTarget.checked
      this.send_data.form.default.i_cpf = this.cpfCheckboxTarget.checked
      this.send_data.form.default.q_button = this.buttonTarget.value
      this.send_data.form.default.i_title = this.titleCheckboxTarget.checked
      this.send_data.form.default.q_title = this.titleTarget.value
    }
    // salvar alterações dos campos customizados no send_data antes de realizar o fetch
    if(ev.target.dataset.target == "commercial--marketing--landings--show--form.saveCustomFieldsBtn"){
      this.send_data.form.kind = "custom"
      this.send_data.form.custom.i_clinic = this.clinicQuestionCheckboxTarget.checked
      this.send_data.form.custom.q_clinic = this.clinicQuestionTarget.value
      this.send_data.form.custom.i_doctor = this.individualQuestionCheckboxTarget.checked
      this.send_data.form.custom.q_doctor = this.individualQuestionTarget.value
      this.send_data.form.custom.i_invoices = this.billingQuestionCheckboxTarget.checked
      this.send_data.form.custom.q_invoices = this.billingQuestionTarget.value
      this.send_data.form.custom.i_taxes = this.irQuestionCheckboxTarget.checked
      this.send_data.form.custom.q_taxes = this.irQuestionTarget.value
      this.send_data.form.custom.i_insurance = this.insuranceQuestionCheckboxTarget.checked
      this.send_data.form.custom.q_insurance = this.insuranceQuestionTarget.value
      this.send_data.form.custom.i_employee = this.employeeQuestionCheckboxTarget.checked
      this.send_data.form.custom.q_employee = this.employeeQuestionTarget.value
      this.send_data.form.custom.i_investments= this.investmentsQuestionCheckboxTarget.checked
      this.send_data.form.custom.q_investments = this.investmentsQuestionTarget.value
    }

    // fetch dos campos padrão
    this.requestSave()
  }

  requestSave() {
    if(this.send_data.form.kind == "default") {
      // fetch data
      // var url = "/commercial/marketing/landings/forms/update"
      // var method = "PUT"
      // const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data.form.default) }
      // var controller = this
      // fetch(url, init)
      //   .then(response => response.json())
      //   .then(response => {
      //     if (response.save) {
      //       controller.application.landing = response.data.cln
      //       controller.doDefaultFieldsHtml()
      //       controller.doCustomFieldsHtml()
      //     }
      //     controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
      //   })
      //   .catch(error => {
      //     controller.getControllerByIdentifier("app--helpers--console").console(error)
      //     controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      //   })
    } else if(this.send_data.form.kind == "custom") {
      // fetch data
      // var url = "/commercial/marketing/landings/forms/update"
      // var method = "PUT"
      // const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data.form.custom) }
      // var controller = this
      // fetch(url, init)
      //   .then(response => response.json())
      //   .then(response => {
      //     if (response.save) {
      //       controller.application.landing = response.data.cln
      //       controller.doDefaultFieldsHtml()
      //       controller.doCustomFieldsHtml()
      //     }
      //     controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
      //   })
      //   .catch(error => {
      //     controller.getControllerByIdentifier("app--helpers--console").console(error)
      //     controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      //   })
    }
        
  }
  
  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0
      if (controller.hasKindInputTarget) {
        if (controller.uploadReady == false) {
          len += 1
        }

        if (controller.kindInputTarget.innerText == ``) {
          len += 1
        }

        if (len == 0) {
          controller.uploadSwitch(true)
        } else {
          controller.uploadSwitch(false)
        }
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }

    if (this.progressTimer) {
      clearInterval(this.progressTimer)
    }
  }

  goToURL(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_blank`)
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    });
    this.getControllerByIdentifier("app--helpers--console").console(results)
    return results
  }

  // controller.bodyTableTarget.insertAdjacentHTML("beforeend", controller.getControllerByIdentifier("app--helpers--table").doTablePreloader(9, 6))
  // var controller = this
  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
