import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["formCard", "formTitle", "formCardBody", "nameInput", "setupCheckbox", "recurringCheckbox", "saveBtn",
                    "startedAtInput", "iuguIdInput", "iuguEmailInput", "cnpj", "finishedAtInput", "amountInput", "descriptionInput", "errorMessage",
                    "productNameDropdown", "productNameDropdownBtn", "productNameInput", "productNameList", "purchaseKind",
                    "productKindDropdown", "productKindDropdownBtn", "productKindInput", "productKindList",
                    "currentAmountInput", "goalAmountInput", "renewedAmountInput", "productServiceList",
                    "currentDiscountInput", "goalDiscountInput", "renewedDiscountInput", "productIntervalList",
                    "descriptionInput", "financialStatusDropdownBtn", "financialStatusInput", "financialStatusList", "financialStatusDiv",
                    "productPlanDropdown", "productPlanDropdownBtn", "productPlanInput", "productPlanList", "productPlanDiv",
                    "newProductPlanDropdown", "newProductPlanDropdownBtn", "newProductPlanInput", "newProductPlanList", "productAccrualDiv",
                    "kindDropdown", "kindDropdownBtn", "kindInput", "kindList", "addonForm", "renewalStatusForm", "churnDescriptionInput",
                    "subkindDropdown", "subkindDropdownBtn", "subkindInput", "subkindList", "churnDescriptionDiv",
                    "planDropdown", "planDropdownBtn", "planInput", "planList", "notesInput",
                    "statusDropdown", "statusDropdownBtn", "statusInput", "statusList",
                    "renewedAmount", "renewedDiscount", "adjustedAmountInput", "adjustedDiscountInput"]


  connect() {
    this.controllerName = `financials--books--clients--renewals--save`
    this.controllerNumber = this.getControllerByIdentifier(`app--helpers--numbers`)
    this.canRequest = true
    this.cpf_result = {}
    this.record = {}
  }

  cancelSave() {
    this.stopRefreshing()

    if (this.controllerDashboard.context.identifier == `financials--books--clients--renewals--save-modal`) {
      this.getControllerByIdentifier(`financials--books--clients--renewals--save-modal`).close()

      if (this.getControllerByIdentifier(`financials--books--clients--radars--show`)) {
        this.getControllerByIdentifier(`financials--books--clients--radars--show`).saveRenewal()
      }

      if (this.getControllerByIdentifier(`financials--books--clients--renewals--show`) && this.getControllerByIdentifier(`financials--books--clients--renewals--show`).hasShowRenewalTarget) {
        this.getControllerByIdentifier(`financials--books--clients--renewals--show`).showRenewal()
      }

      if (this.getControllerByIdentifier(`financials--books--clients--renewals--list`) && this.getControllerByIdentifier(`financials--books--clients--renewals--list`).hasMainCardTarget) {
        this.getControllerByIdentifier(`financials--books--clients--renewals--list`).clearList()
        this.getControllerByIdentifier(`financials--books--clients--renewals--list`).doDataTable()
      }

    } else if (this.controllerDashboard.context.identifier == `financials--books--clients--renewals--settings`) {
      this.getControllerByIdentifier(`financials--books--clients--renewals--settings`).renewalsTarget.remove()
      this.getControllerByIdentifier(`financials--books--clients--renewals--settings`).setRenewalInput()
    }
  }

  saveRenewal() {
    this.saveBtnTarget.disabled = true
    this.stopRefreshing()

    this.send_data = { current_user: {}, renewal: {}, tracker: { manual: true } }
    var contractName = `${this.productNameInputTarget.innerText} | ${this.productKindInputTarget.innerText}`

    if (this.actionMode == `edit`) {
      this.send_data.renewal.id = this.current_renewal.id
      this.send_data.renewal.status = this.statusInputTarget.dataset.status
      this.send_data.renewal.renewal_amount = this.controllerNumber.fromCurrencyToNumber(this.renewedAmountInputTarget.value)
      this.send_data.renewal.renewal_discount = this.controllerNumber.fromCurrencyToNumber(this.renewedDiscountInputTarget.value)

      this.send_data.tracker.action = `updated`
      this.send_data.tracker.observations = `Atualizou a Renovação do Contrato ${this.current_renewal.contract_name}`
      this.send_data.tracker.obj_id = this.current_renewal.id

    } else if (this.actionMode == `closing`) {

      this.send_data.renewal.id = this.current_renewal.id
      this.send_data.renewal.status = this.statusInputTarget.dataset.status
      this.send_data.renewal.finished_at = this.getControllerByIdentifier("app--helpers--date").transformAllKindDate(this.finishedAtInputTarget.value)

      this.send_data.renewal.renewed_amount = this.controllerNumber.fromCurrencyToNumber(this.renewedAmountInputTarget.value)
      this.send_data.renewal.renewed_discount = this.controllerNumber.fromCurrencyToNumber(this.renewedDiscountInputTarget.value)
      this.send_data.renewal.adjusted_amount = this.controllerNumber.fromCurrencyToNumber(this.adjustedAmountInputTarget.value)
      this.send_data.renewal.adjusted_discount = this.controllerNumber.fromCurrencyToNumber(this.adjustedDiscountInputTarget.value)

      this.send_data.renewal.new_plan = this.newProductPlanInputTarget.dataset.productPlan

      this.send_data.tracker.action = `updated`
      this.send_data.tracker.observations = `Finalizou a Renovação do Contrato ${this.current_renewal.contract_name}`
      this.send_data.tracker.obj_id = this.current_renewal.id

    } else if (this.actionMode == `new`) {
      this.send_data.renewal.status = `active`

      this.send_data.renewal.client_id = this.current_client.id
      this.send_data.renewal.contract_id = this.current_contract.id
      this.send_data.renewal.date_id = this.application.current_date.id
      this.send_data.renewal.status = `active`
      this.send_data.renewal.current_plan = this.productPlanInputTarget.dataset.productPlan
      this.send_data.renewal.new_plan = this.newProductPlanInputTarget.dataset.productPlan
      // this.send_data.renewal.kind = this.kindInputTarget.dataset.kind
      // this.send_data.renewal.subkind = this.subkindInputTarget.dataset.subkind
      
      this.send_data.tracker.action = `created`
      this.send_data.tracker.observations = `Adicionou a Renovação do Contrato ${contractName} para o Cliente ${this.current_client.record_name}`
    }

    this.send_data.renewal.description = this.descriptionInputTarget.value.trim()
    this.send_data.renewal.notes = this.notesInputTarget.value.trim()

    this.send_data.renewal.current_amount = this.controllerNumber.fromCurrencyToNumber(this.currentAmountInputTarget.value)
    this.send_data.renewal.goal_amount = this.controllerNumber.fromCurrencyToNumber(this.goalAmountInputTarget.value)
    this.send_data.renewal.current_discount = this.controllerNumber.fromCurrencyToNumber(this.currentDiscountInputTarget.value)
    this.send_data.renewal.goal_discount = this.controllerNumber.fromCurrencyToNumber(this.goalDiscountInputTarget.value)

    this.send_data.renewal.product_name = this.productNameInputTarget.dataset.productName
    this.send_data.renewal.product_kind = this.productKindInputTarget.dataset.productKind
    this.send_data.renewal.started_at = this.getControllerByIdentifier("app--helpers--date").transformAllKindDate(this.startedAtInputTarget.value)    

    this.send_data.tracker.record_id = this.current_client.id
    this.send_data.tracker.record_type = `financial_client_entities`
    this.send_data.tracker.obj_type = `financial_client_renewals`

    this.requestSave()
  }

  requestSave() {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = this.permission

    if (this.actionMode == `new`) {
      var url = "/financials/books/clients/renewals/create"
      var method = "POST"
    } else if (this.actionMode == `edit` || this.actionMode == `closing`) {
      var url = "/financials/books/clients/renewals/update"
      var method = "PUT"
    } else if (this.actionMode == `destroy`) {
      var url = "/financials/books/clients/renewals/destroy"
      var method = "DELETE"
    }

    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this

    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.stopRefreshing()
        if (response.save) {
          var renewal = response.data.cln

          if (controller.actionMode == `new`) {
            controller.application.renewals[controller.application.renewals.length] = renewal

            if (this.getControllerByIdentifier(`financials--books--clients--radars--show`)) {
              this.getControllerByIdentifier(`financials--books--clients--radars--show`).current_renewal = renewal
            }

          } else if (controller.actionMode == `edit` || controller.actionMode == `closing`) {
            controller.application.renewals.forEach((element, i) => {
              if (element.id == renewal.id) {
                controller.application.renewals.splice(controller.application.renewals.indexOf(element), 1, renewal)
              }
            })

            if (controller.getControllerByIdentifier(`financials--books--clients--renewals--show`) && controller.getControllerByIdentifier(`financials--books--clients--renewals--show`).hasShowRenewalTarget) {
              controller.getControllerByIdentifier(`financials--books--clients--renewals--show`).current_renewal = renewal
            }

          } else if (controller.actionMode == `destroy`) {
            controller.application.renewals.forEach((element, i) => {
              if (element.id == renewal.id) {
                controller.application.renewals.splice(controller.application.renewals.indexOf(element), 1)
              }
            })
          }


        } else {
          if (controller.hasSaveBtnTarget) {
            controller.saveBtnTarget.disabled = false
          }
        }

        controller.cancelSave()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        // if (controller.hasSaveBtnTarget) {
        //   controller.saveBtnTarget.disabled = false
        // }
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error, ``, controller)
      })
  }

  doFormHtml() {
    this.stopRefreshing()

    if (this.application.permissions[this.permission].can_create) {
      var saveBtnHtml = `<button disabled type="button" class="btn btn-primary btn-sm py-2 btn-table f-065" data-action="click->${this.controllerName}#saveRenewal" data-${this.controllerName}-target="saveBtn">Salvar</button>`
    } else {
      var saveBtnHtml = ``
    }

    var html = `<div class="row">
                  <div class="col-12 px-0">
                    <div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="formCard" data-action="resize@window->${this.controllerName}#layout">
                      <div class="card-header d-flex align-items-center card-header-table-list f-065">
                        <h6 class="card-title display-4 py-3" style="font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="formTitle"></h6>
                      </div>
                      <div class="card-body py-0 px-2" style="overflow:auto;" data-${this.controllerName}-target="formCardBody">
                        <div class="row my-2">
                          <div class="col-3">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="startedAtForm">Início</label>
                                <input aria-describedby="startedAtFormHelp" class="form-control" id="startedAtForm" data-action="blur->${this.controllerName}#dateMask keypress->${this.controllerName}#dateMask keyup->${this.controllerName}#dateValidate blur->${this.controllerName}#dateValidate" data-${this.controllerName}-target="startedAtInput" placeholder="MM/AAAA" type="text" required>
                              </div>
                            </div>
                          </div>
                          <div class="col-3">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="finishedAtForm">Expiração</label>
                                <input aria-describedby="finishedAtFormHelp" class="form-control" id="finishedAtForm" data-action="blur->${this.controllerName}#dateMask keypress->${this.controllerName}#dateMask keyup->${this.controllerName}#dateValidate blur->${this.controllerName}#dateValidate" data-${this.controllerName}-target="finishedAtInput" placeholder="MM/AAAA" type="text" required>
                              </div>
                            </div>
                          </div>
                          <div class="col-6">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label>Produto</label>
                                <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="productNameDropdown">
                                  <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="productNameDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="productNameInput"></span></button>
                                  <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                    <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                    <ul class="ul-select" data-${this.controllerName}-target="productNameList" data-app--helpers--search-target="searchList"></ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row my-2">
                          
                          <div class="col-4">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label>Tipo</label>
                                <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="productKindDropdown">
                                  <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="productKindDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="productKindInput"></span></button>
                                  <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                    <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                    <ul class="ul-select" data-${this.controllerName}-target="productKindList" data-app--helpers--search-target="searchList"></ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="col-4" data-${this.controllerName}-target="productPlanDiv">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label>Plano Atual</label>
                                <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="productPlanDropdown">
                                  <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="productPlanDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="productPlanInput"></span></button>
                                  <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                    <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                    <ul class="ul-select" data-${this.controllerName}-target="productPlanList" data-app--helpers--search-target="searchList"></ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-4" data-${this.controllerName}-target="productPlanDiv">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label>Plano Proposto</label>
                                <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="newProductPlanDropdown">
                                  <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="newProductPlanDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="newProductPlanInput"></span></button>
                                  <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                    <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                    <ul class="ul-select" data-${this.controllerName}-target="newProductPlanList" data-app--helpers--search-target="searchList"></ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        
                        <div class="row my-2">
                          <div class="col-3 pr-2">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="currentAmountForm">Valor Atual</label>
                                <input aria-describedby="currentAmountFormHelp" class="form-control" id="currentAmountForm" data-${this.controllerName}-target="currentAmountInput" placeholder="Valor Atual" data-action="blur->${this.controllerName}#currencyMask keypress->${this.controllerName}#currencyMask keyup->${this.controllerName}#currencyMask" type="text" required>
                              </div>
                            </div>
                          </div>
                          <div class="col-3 px-2">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="goalAmountForm">Valor Objetivo</label>
                                <input aria-describedby="goalAmountFormHelp" class="form-control" id="goalAmountForm" data-${this.controllerName}-target="goalAmountInput" placeholder="Valor Objetivo" data-action="blur->${this.controllerName}#currencyMask keypress->${this.controllerName}#currencyMask keyup->${this.controllerName}#currencyMask" type="text" required>
                              </div>
                            </div>
                          </div>
                          <div class="col-3 px-2 d-none" data-${this.controllerName}-target="renewedAmount">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="renewedAmountForm">Valor Renovado</label>
                                <input aria-describedby="renewedAmountFormHelp" class="form-control" id="renewedAmountForm" data-${this.controllerName}-target="renewedAmountInput" placeholder="Valor Renovado" data-action="blur->${this.controllerName}#currencyMask keypress->${this.controllerName}#currencyMask keyup->${this.controllerName}#currencyMask" type="text" required>
                              </div>
                            </div>
                          </div>
                          <div class="col-3 pl-2 d-none" data-${this.controllerName}-target="renewedAmount">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="adjustedAmountForm">Valor Ajustado</label>
                                <input aria-describedby="adjustedAmountFormHelp" class="form-control" id="adjustedAmountForm" data-${this.controllerName}-target="adjustedAmountInput" placeholder="Valor Ajustado" data-action="blur->${this.controllerName}#currencyMask keypress->${this.controllerName}#currencyMask keyup->${this.controllerName}#currencyMask" type="text" required>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row my-2">
                          <div class="col-3 pr-2">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="currentDiscountForm">Desconto Atual</label>
                                <input aria-describedby="currentDiscountFormHelp" class="form-control" id="currentDiscountForm" data-${this.controllerName}-target="currentDiscountInput" placeholder="Desconto Atual" data-action="blur->${this.controllerName}#currencyMask keypress->${this.controllerName}#currencyMask keyup->${this.controllerName}#currencyMask" type="text" required>
                              </div>
                            </div>
                          </div>
                          <div class="col-3 px-2">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="goalDiscountForm">Desconto Objetivo</label>
                                <input aria-describedby="goalDiscountFormHelp" class="form-control" id="goalDiscountForm" data-${this.controllerName}-target="goalDiscountInput" placeholder="Desconto Objetivo" data-action="blur->${this.controllerName}#currencyMask keypress->${this.controllerName}#currencyMask keyup->${this.controllerName}#currencyMask" type="text" required>
                              </div>
                            </div>
                          </div>
                          <div class="col-3 px-2 d-none" data-${this.controllerName}-target="renewedDiscount">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="renewedDiscountForm">Desconto Renovado</label>
                                <input aria-describedby="renewedDiscountFormHelp" class="form-control" id="renewedDiscountForm" data-${this.controllerName}-target="renewedDiscountInput" placeholder="Desconto Ajustado" data-action="blur->${this.controllerName}#currencyMask keypress->${this.controllerName}#currencyMask keyup->${this.controllerName}#currencyMask" type="text" required>
                              </div>
                            </div>
                          </div>
                          <div class="col-3 pl-2 d-none" data-${this.controllerName}-target="renewedDiscount">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="adjustedDiscountForm">Desconto Ajustado</label>
                                <input aria-describedby="adjustedDiscountFormHelp" class="form-control" id="adjustedDiscountForm" data-${this.controllerName}-target="adjustedDiscountInput" placeholder="Desconto Renovado" data-action="blur->${this.controllerName}#currencyMask keypress->${this.controllerName}#currencyMask keyup->${this.controllerName}#currencyMask" type="text" required>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row my-2 d-none" data-${this.controllerName}-target="renewalStatusForm">
                          <div class="col-6">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label>Status</label>
                                <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="statusDropdown">
                                  <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="statusDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="statusInput"></span></button>
                                  <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                    <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                    <ul class="ul-select" data-${this.controllerName}-target="statusList" data-app--helpers--search-target="searchList">
                                      <li data-status="active" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectStatus" data-app--helpers--selector-target="select" class="li-selector dark f-065">Ativa</li>
                                      <li data-status="gain" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectStatus" data-app--helpers--selector-target="select" class="li-selector dark f-065">Ganha</li>
                                      <li data-status="lost" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectStatus" data-app--helpers--selector-target="select" class="li-selector dark f-065">Perdida</li>
                                      <li data-status="canceled" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectStatus" data-app--helpers--selector-target="select" class="li-selector dark f-065">Cancelada</li>
                                      <li data-status="write_off" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectStatus" data-app--helpers--selector-target="select" class="li-selector dark f-065">Anulada</li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row my-2">
                          <div class="col-12">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="descriptionForm">Descrição</label>
                                <input aria-describedby="descriptionFormHelp" class="form-control textarea px-0" id="descriptionForm" data-${this.controllerName}-target="descriptionInput" placeholder="Descrição" type="text" required>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row my-2">
                          <div class="col-12">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="notesForm">Anotações</label>
                                <textarea aria-describedby="notesFormHelp" class="form-control textarea px-0" id="notesForm" data-${this.controllerName}-target="notesInput" placeholder="Anotações" type="text" required rows="4"></textarea>
                              </div>
                            </div>
                          </div>
                        </div>

                        
                      </div>
                      <div class="card-footer border-top text-right py-1">
                        <span class="mr-auto text-left f-danger my-auto" data-${this.controllerName}-target="errorMessage"></span>
                        <button type="button" class="btn btn-secondary btn-sm py-2 btn-table f-065" data-dismiss="modal" data-action="click->${this.controllerName}#cancelSave">Fechar</button>
                        ${saveBtnHtml}
                      </div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.gridElement.innerHTML = html)
    }).then(() => {
      controller.formCardTarget.style.height = ($(window).height() * 0.65) + "px"

      var date = new Date(this.application.current_date.year, this.application.current_date.month - 1, 1)
      var today = new Date()
      controller.getControllerByIdentifier("app--helpers--pickdate").pickInsideDate($(this.startedAtInputTarget), "monthly", date.getFullYear(), date.getMonth() + 1)
      if (today.getFullYear() > date.getFullYear()) {
        controller.getControllerByIdentifier("app--helpers--pickdate").pickWithLimit($(this.finishedAtInputTarget), "monthly", date.getFullYear(), date.getMonth() + 1)
      } else if (today.getFullYear() == date.getFullYear() && today.getMonth() > date.getMonth()) {
        controller.getControllerByIdentifier("app--helpers--pickdate").pickOnlyFutureFromDate($(this.finishedAtInputTarget), "monthly", date.getFullYear(), date.getMonth() + 1, date)
      } else {
        controller.getControllerByIdentifier("app--helpers--pickdate").pickOnlyFuture($(this.finishedAtInputTarget), "monthly", date.getFullYear(), date.getMonth() + 1)
      }

      if (controller.actionMode == `edit` || controller.actionMode == `closing`) {

        controller.renewalStatusFormTarget.classList.remove(`d-none`)
        controller.formTitleTarget.innerText = `Editando Renovação`

        if (controller.application.permissions[controller.permission].can_manage) {
          controller.startedAtInputTarget.disabled = false
        } else {
          controller.startedAtInputTarget.disabled = true
        }

        controller.startedAtInputTarget.value = controller.current_renewal.started_at_full_pretty

        controller.statusDropdownTarget.value = controller.current_renewal.status
        controller.statusInputTarget.dataset.status = controller.current_renewal.status
        controller.statusInputTarget.innerText = controller.current_renewal.status_pretty

        controller.productPlanDropdownTarget.value = controller.current_renewal.current_plan_pretty
        controller.productPlanInputTarget.dataset.productPlan = controller.current_renewal.current_plan
        controller.productPlanInputTarget.innerText = controller.current_renewal.current_plan_pretty

        controller.newProductPlanDropdownTarget.value = controller.current_renewal.new_plan_pretty
        controller.newProductPlanInputTarget.dataset.productPlan = controller.current_renewal.new_plan
        controller.newProductPlanInputTarget.innerText = controller.current_renewal.new_plan_pretty

        controller.productNameDropdownTarget.value = controller.current_renewal.product_name
        controller.productNameInputTarget.dataset.productName = controller.current_renewal.product_name
        controller.productNameInputTarget.innerText = controller.current_renewal.product_name_pretty

        controller.productKindDropdownTarget.value = controller.current_renewal.product_kind
        controller.productKindInputTarget.dataset.productKind = controller.current_renewal.product_kind
        controller.productKindInputTarget.innerText = controller.current_renewal.product_kind_pretty

        controller.currentAmountInputTarget.value = controller.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(controller.current_renewal.current_amount)
        controller.goalAmountInputTarget.value = controller.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(controller.current_renewal.goal_amount)
        controller.renewedAmountInputTarget.value = controller.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(controller.current_renewal.renewal_amount)
        controller.currentDiscountInputTarget.value = controller.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(controller.current_renewal.current_discount)
        controller.goalDiscountInputTarget.value = controller.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(controller.current_renewal.goal_discount)
        controller.renewedDiscountInputTarget.value = controller.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(controller.current_renewal.renewal_discount)

        controller.descriptionInputTarget.value = controller.current_renewal.description
        controller.notesInputTarget.value = controller.current_renewal.notes

      } else if (controller.actionMode == `new`) {
        controller.formTitleTarget.innerText = `Nova Renovação | ${controller.current_client.record_name} - ${controller.current_client.record_type_pretty}: ${controller.current_client.record_number_pretty} | Início Cliente: ${controller.current_client.started_at_pretty}`
        controller.currentAmountInputTarget.value = controller.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(controller.current_contract.total_amount)
        controller.goalAmountInputTarget.value = controller.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(0)
        controller.currentDiscountInputTarget.value = controller.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(controller.current_contract.total_discounts)
        controller.goalDiscountInputTarget.value = controller.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(0)

        var today = new Date()
        controller.startedAtInputTarget.value = `${today.getDate()}/${controller.application.current_date.date_accrual}`

        controller.productPlanDropdownTarget.value = controller.current_contract.product_plan_pretty
        controller.productPlanInputTarget.dataset.productPlan = controller.current_contract.product_plan
        controller.productPlanInputTarget.innerText = controller.current_contract.product_plan_pretty

        controller.productNameDropdownTarget.value = controller.current_contract.product_name
        controller.productNameInputTarget.dataset.productName = controller.current_contract.product_name
        controller.productNameInputTarget.innerText = controller.current_contract.product_name_pretty

        controller.productKindDropdownTarget.value = controller.current_contract.product_kind
        controller.productKindInputTarget.dataset.productKind = controller.current_contract.product_kind
        controller.productKindInputTarget.innerText = controller.current_contract.product_kind_pretty

        if (controller.current_radar) {
          controller.currentAmountInputTarget.value = controller.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(controller.current_radar.current_amount)
          controller.goalAmountInputTarget.value = controller.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(controller.current_radar.goal_amount)
          controller.currentDiscountInputTarget.value = controller.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(controller.current_radar.current_discount)
          controller.goalDiscountInputTarget.value = controller.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(controller.current_radar.goal_discount)

          controller.productPlanDropdownTarget.value = controller.current_radar.current_plan_pretty
          controller.productPlanInputTarget.dataset.productPlan = controller.current_radar.current_plan
          controller.productPlanInputTarget.innerText = controller.current_radar.current_plan_pretty

          controller.newProductPlanDropdownTarget.value = controller.current_radar.new_plan_pretty
          controller.newProductPlanInputTarget.dataset.productPlan = controller.current_radar.new_plan
          controller.newProductPlanInputTarget.innerText = controller.current_radar.new_plan_pretty

          controller.descriptionInputTarget.value = controller.current_radar.description
          controller.notesInputTarget.value = controller.current_radar.notes
        }
      }

      if (controller.actionMode == `closing`) {
        controller.formTitleTarget.innerText = `Finalizando Renovação`
        controller.productPlanDropdownBtnTarget.disabled = true
        // controller.newProductPlanDropdownBtnTarget.disabled = true

        controller.currentAmountInputTarget.disabled = true
        controller.goalAmountInputTarget.disabled = true
        controller.currentDiscountInputTarget.disabled = true
        controller.goalDiscountInputTarget.disabled = true
        controller.adjustedAmountInputTarget.disabled = true
        controller.adjustedDiscountInputTarget.disabled = true

        controller.descriptionInputTarget.disabled = true

        controller.renewedAmountTargets.forEach(element => {
          element.classList.remove(`d-none`)
        })
        controller.renewedDiscountTargets.forEach(element => {
          element.classList.remove(`d-none`)
        })

        controller.renewedAmountInputTarget.value = controller.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(0)
        controller.adjustedAmountInputTarget.value = controller.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(0)
        controller.renewedDiscountInputTarget.value = controller.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(0)
        controller.adjustedDiscountInputTarget.value = controller.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(0)
      }

      controller.productNameDropdownBtnTarget.disabled = true
      controller.productKindDropdownBtnTarget.disabled = true

      controller.setProductPlanList()
      controller.refreshSaveBtn()
      controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
    })
  }

  setProductPlanList() {
    var html = ``

    html += `<li data-product-plan="light" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Light</li>`
    html += `<li data-product-plan="basic" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Basic</li>`
    html += `<li data-product-plan="pro" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Pro</li>`
    html += `<li data-product-plan="enterprise" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Enterprise</li>`

    this.productPlanListTarget.innerHTML = html
    this.newProductPlanListTarget.innerHTML = html
  }

  showAccounts(ev) {
    var element = ev.currentTarget.dataset.element
    var modalName = `financials--books--clients--accounts--modal`

    var html = `<div class="modal fade" data-controller="${modalName}" data-${modalName}-target="modal" id="connectionModal" tabindex="-1" role="dialog" aria-labelledby="connectionModalTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div class="modal-content">
                      <div class="modal-body p-0" data-${modalName}-target="body"></div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.getControllerByIdentifier(modalName).controllerForm = controller
      controller.getControllerByIdentifier(modalName).current_client = controller.current_client
      controller.getControllerByIdentifier(modalName).inputElement = this.nameTarget(element)
      controller.getControllerByIdentifier(modalName).open()
    })
  }

  setDescription() {
    var kind = this.kindInputTarget.innerText
    var subkind = this.subkindInputTarget.innerText
    var plan = this.planInputTarget.innerText

    this.descriptionInputTarget.value = `${kind} | ${subkind} | ${plan}`

    this.getControllerByIdentifier("app--helpers--forms").floatingLabel()
  }

  selectStatus(ev) {
    var status = ev.currentTarget.dataset.status

    // if (status == `canceled`) {
    //   this.churnDescriptionDivTarget.classList.remove(`d-none`)
    // } else {
    //   this.churnDescriptionDivTarget.classList.add(`d-none`)
    // }
  }

  currencyMask(ev) {
    this.controllerNumber.changeNumberToCurrency(ev)

    if (this.actionMode == `closing`) {
      this.bindAdjusted()
    }
  }

  bindAdjusted() {

    var currentAmount = this.controllerNumber.fromCurrencyToNumber(this.currentAmountInputTarget.value)
    var goalAmount = this.controllerNumber.fromCurrencyToNumber(this.goalAmountInputTarget.value)
    var renewedAmount = this.controllerNumber.fromCurrencyToNumber(this.renewedAmountInputTarget.value)

    this.adjustedAmountInputTarget.value = this.controllerNumber.currencyOptionMask(Number(renewedAmount) - Number(currentAmount))

    var currentDiscount = this.controllerNumber.fromCurrencyToNumber(this.currentDiscountInputTarget.value)
    var goalDiscount = this.controllerNumber.fromCurrencyToNumber(this.goalDiscountInputTarget.value)
    var renewedDiscount = this.controllerNumber.fromCurrencyToNumber(this.renewedDiscountInputTarget.value)

    this.adjustedDiscountInputTarget.value = this.controllerNumber.currencyOptionMask(Math.abs(Number(renewedDiscount) - Number(currentDiscount)))    
  }

  dateMask(ev) {
    this.controllerNumber.changeNumberToDate(ev)
  }

  dateValidate(ev) {
    var cleanDate = this.controllerNumber.fromDateToNumber(ev.target.value)
    if ((ev.type == "keyup" && ev.shiftKey == false && cleanDate.length == 8) || ev.type == "blur" && cleanDate.length == 8) {
      this.checkDate = this.getControllerByIdentifier("app--helpers--date").checkDate(ev.target.value)
      if (this.checkDate.valid) {
        this.startedAtInputTarget.classList.add("f-normal")
        this.startedAtInputTarget.classList.remove("f-danger")
      } else {
        this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", this.checkDate.message, 3000)
        this.startedAtInputTarget.classList.add("f-danger")
        this.startedAtInputTarget.classList.remove("f-normal")
      }
    } else {
      this.startedAtInputTarget.classList.add("f-normal")
      this.startedAtInputTarget.classList.remove("f-danger")
    }
  }

  // dateMask(ev) {
  //   this.controllerNumber.changeNumberToMonthDate(ev)
  // }
  // dateValidate(ev) {
  //   var cleanDate = this.controllerNumber.fromDateToNumber(`01/${ev.target.value}`)
  //   if ((ev.type == "keyup" && ev.shiftKey == false && cleanDate.length == 8) || ev.type == "blur" && cleanDate.length == 8) {
  //     this.checkDate = this.getControllerByIdentifier("app--helpers--date").checkDate(`01/${ev.target.value}`)
  //     if (this.checkDate.valid) {
  //       this.startedAtInputTarget.classList.add("f-normal")
  //       this.startedAtInputTarget.classList.remove("f-danger")
  //     } else {
  //       this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", this.checkDate.message, 3000)
  //       this.startedAtInputTarget.classList.add("f-danger")
  //       this.startedAtInputTarget.classList.remove("f-normal")
  //     }
  //   } else {
  //     this.startedAtInputTarget.classList.add("f-normal")
  //     this.startedAtInputTarget.classList.remove("f-danger")
  //   }
  // }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0

      if (controller.hasFormCardTarget) {


        if (controller.hasStartedAtInputTarget && controller.startedAtInputTarget.value == ``) {
          len += 1
        }

        if (controller.actionMode == `edit`) {
          if (controller.hasExpiresAtInputTarget && controller.finishedAtInputTarget.value == `` && controller.statusInputTarget.dataset.status != `active`) {
            len += 1
          }
        }

        if (controller.actionMode == `closing`) {
          if (controller.renewedAmountInputTarget.value == `R$ 0,00`) {
            len += 1
          }

          if (controller.current_renewal.goal_discount > 0) {
            if (controller.renewedDiscountInputTarget.value == `R$ 0,00`) {
              len += 1
            }
          }

          if (controller.statusInputTarget.dataset.status == `active`) {
            len += 1
          }
        }

        if (controller.hasNumberInputTarget && controller.numberInputTarget.value == ``) {
          len += 1
        }

        if (controller.productNameInputTarget.innerText == ``) {
          len += 1
        }

        if (controller.productKindInputTarget.innerText == ``) {
          len += 1
        }

        if (controller.productPlanInputTarget.innerText == `` || controller.productPlanInputTarget.innerText == `N/D`) {
          len += 1
        }

        if (controller.newProductPlanInputTarget.innerText == `` || controller.newProductPlanInputTarget.innerText == `N/D`) {
          len += 1
        }

        if (controller.hasSaveBtnTarget) {
          if (len == 0) {
            controller.saveBtnTarget.disabled = false
          } else {
            controller.saveBtnTarget.disabled = true
          }
        }
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  randonNumber() {
    var number = Math.floor(Math.random() * 1000)
    return number
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    })
    return results
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
