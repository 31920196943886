import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["modal", "bottom", "top", "list", "view", "contracts", "bodyTable", "footerTable", "body", "searchTable", "searchInput"]

  connect() {
    this.controllerName = `financials--books--clients--contracts--save-modal`
    this.controllerNumber = this.getControllerByIdentifier(`app--helpers--numbers`)
    this.itemsOnPage = 750
    this.tableCols = 4
    this.pageNumber = 0
    this.numberPages = []
    this.sort = { mode: `asc`, field: `name` }
  }

  open() {
    document.body.classList.add("modal-open");
    this.element.setAttribute("style", "display: block;");
    this.element.classList.add("show");
    const html = `<div class="modal-backdrop fade show"></div>`
    // document.body.insertAdjacentHTML("beforeend", html)
    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.doFormHtml()
    })
  }

  close() {
    this.getControllerByIdentifier(`financials--books--clients--contracts--settings`).setContractInput()
    document.body.classList.remove("modal-open");
    this.element.removeAttribute("style");
    this.element.classList.remove("show");
    document.getElementsByClassName("modal-backdrop")[0].remove();
    this.modalTarget.remove()
  }

  doFormHtml() {
    var html = `<div class="col-12 px-0" data-${this.controllerName}-target="contracts" data-controller="financials--books--clients--contracts--save"></div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.bodyTarget.innerHTML = html)
    }).then(() => {
      controller.controllerSaveContract = controller.getControllerByIdentifier("financials--books--clients--contracts--save")

      controller.controllerSaveContract.controllerDashboard = controller
      controller.controllerSaveContract.kindModel = controller.kindModel
      controller.controllerSaveContract.permission = controller.permission
      controller.controllerSaveContract.gridElement = controller.contractsTarget
      controller.controllerSaveContract.current_client = controller.current_client
      controller.controllerSaveContract.current_contract = controller.current_contract
      // controller.controllerSaveContract.current_billing = controller.current_billing
      controller.controllerSaveContract.actionMode = controller.actionMode
      controller.controllerSaveContract.doFormHtml()
    })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }
}