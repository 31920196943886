import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["formCard", "formTitle", "formBody", "errorMessage", "nextBtn", "accountPartyBody", "companyPartyBody", "formAccountPartyBody",
    "formCompanyPartyBody", "accountCard", "companyCard", "accountRow", "companyRow"]

  connect() {
    this.controllerName = `operations--onboards--show--parties`
    // this.doPartiesFormHtml()
  }

  requestDestroy(data) {
    var url = "/operations/onboards/parties/destroy"
    var method = "DELETE"

    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        this.partiesActions(false)
        if (response.save) {
          var party = response.data.cln
          controller.application.parties.forEach((element, i) => {
            if (element.id == party.id) {
              controller.application.parties.splice(controller.application.parties.indexOf(element), 1)
            }
          })
          controller.application.onboard.parties.forEach((element, i) => {
            if (element.id == party.id) {
              controller.application.parties.splice(controller.application.parties.indexOf(element), 1)
            }
          })
          controller.getControllerByIdentifier("operations--onboards--show--parties--account--index").doDataTable()
          controller.getControllerByIdentifier("operations--onboards--show--parties--company--index").doDataTable()
          controller.getControllerByIdentifier("operations--onboards--show--entity").setParties()
        } else {
          controller.partyConfirmed = false
        }
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  doPartyCard() {
    if ((this.product == "medbilling" && this.kind == "practice") || this.product == "medclinic" || this.product == "medpayroll" || this.product == "medconsulting") {
    } else {
      this.accountCardTarget.classList.add("bg-primary")
      this.accountCardTarget.classList.add("pointer")
      this.accountRowTarget.classList.remove("d-none")
      this.companyCardTarget.classList.add("bg-disabled")
      this.companyCardTarget.classList.add("none")
      this.companyCardTarget.classList.remove("bg-primary")
      this.companyRowTarget.classList.add("d-none")
    }
  }

  toggleAccountCompany(ev) {
    var feature = ev.currentTarget.dataset.feature

    if ((this.product == "medbilling" && this.kind == "practice") || this.product == "medclinic" || this.product == "medpayroll" || this.product == "medconsulting") {
      if (feature == "account") {
        this.accountCardTarget.classList.add("bg-primary")
        this.companyCardTarget.classList.remove("bg-primary")
        this.accountRowTarget.classList.remove("d-none")
        this.companyRowTarget.classList.add("d-none")
      } else if (feature == "company") {
        this.accountCardTarget.classList.remove("bg-primary")
        this.companyCardTarget.classList.add("bg-primary")
        this.accountRowTarget.classList.add("d-none")
        this.companyRowTarget.classList.remove("d-none")
      }
    }
  }

  doPartiesFormHtml() {
    var html = `<div class="col-12">
                  <div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="formCard" data-action="resize@window->${this.controllerName}#layout">
                    <div class="card-header d-flex align-items-center py-2 f-065">
                      <div class="row w-100">
                        <div class="col-3 px-1">
                          <div class="card bg-primary pointer" data-${this.controllerName}-target="accountCard" data-feature="account" data-action="click->${this.controllerName}#toggleAccountCompany">
                            <div class="card-header py-0 f-065 text-center">
                              <h6 class="card-title py-1" style="font-size:110%;margin-bottom:0px;"><strong>PF Médica</strong></h6>
                            </div>
                          </div>
                        </div>
                        <div class="col-3 px-1">
                          <div class="card pointer" data-${this.controllerName}-target="companyCard" data-feature="company" data-action="click->${this.controllerName}#toggleAccountCompany">
                            <div class="card-header py-0 f-065 text-center">
                              <h6 class="card-title py-1" style="font-size:110%;margin-bottom:0px;"><strong>PJ Médica</strong></h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="card-body py-0" style="overflow:auto;" data-${this.controllerName}-target="formBody">

                      <div class="row" data-${this.controllerName}-target="accountRow">
                        <div class="col-4 my-4" data-controller="operations--onboards--show--parties--account--index" data-${this.controllerName}-target="accountPartyBody">
                        </div>
                        <div class="col-8 my-4" data-controller="operations--onboards--show--parties--account--save" data-${this.controllerName}-target="formAccountPartyBody">
                        </div>
                      </div>
                      <div class="row d-none" data-${this.controllerName}-target="companyRow">
                        <div class="col-4 my-4" data-controller="operations--onboards--show--parties--company--index" data-${this.controllerName}-target="companyPartyBody">
                        </div>
                        <div class="col-8 my-4" data-controller="operations--onboards--show--parties--company--save" data-${this.controllerName}-target="formCompanyPartyBody">
                        </div>
                      </div>
      
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier(`operations--onboards--show--entity`).partiesRowTarget.innerHTML = html)
    }).then(() => {
      var date = new Date()
      controller.product = controller.application.onboard.product_name
      controller.kind = controller.application.onboard.product_kind
      controller.refreshSaveBtn()
      controller.doPartyCard()
      controller.formCardTarget.style.height = ($(window).height() * 0.5) + "px"
    })
  }

  partiesActions(value) {

    if (value) {
      this.getControllerByIdentifier("operations--onboards--show--parties--account--index").addNewPartyBtnTarget.classList.add("d-none")
      this.getControllerByIdentifier("operations--onboards--show--parties--company--index").addNewPartyBtnTarget.classList.add("d-none")
      this.doingAction = true
    } else {
      this.formAccountPartyBodyTarget.innerHTML = ``
      this.formCompanyPartyBodyTarget.innerHTML = ``
      this.getControllerByIdentifier("operations--onboards--show--parties--account--save").stopAccountRefreshing()
      this.getControllerByIdentifier("operations--onboards--show--parties--company--save").stopCompanyRefreshing()
      this.getControllerByIdentifier("operations--onboards--show--parties--account--index").addNewPartyBtnTarget.classList.remove("d-none")
      this.getControllerByIdentifier("operations--onboards--show--parties--company--index").addNewPartyBtnTarget.classList.remove("d-none")
      this.doingAction = false
    }

  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0

      if (controller.hasNextBtnTarget) {

        if (controller.doingAction) {
          len += 1
        }

        if (controller.application.onboard.parties.length == 0) {
          len += 1
        }

        if (len == 0) {
          // controller.nextBtnTarget.disabled = false
        } else {
          // controller.nextBtnTarget.disabled = true
        }
      } else {
        controller.stopRefreshing()
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
