import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["mainDashboard", "urlInput", "duration", "methodDropdown", "methodDropdownBtn", "methodInput", "methodList",
                    "urlInput", "permissionInput", "actionInput", "routeInputs", "paramsInputs", "requestBtn", "timesInput", "batchInput", "9999",
                    "objInput", "keyInput", "valueInput", "saveBtn", "formCard", "formTitle", "timesInput", "batchInput", "performRow",
                    "9999", "9999", "9999", "9999", "9999", "9999", "9999", "9999", "9999"]


  connect() {
    this.controllerName = `app--stats--builders--perform`
  }

  disconnect() {
    this.stopRefreshing()
  }

  cancelSave() {
    this.actionMode = ``
    this.stopRefreshing()
    this.getControllerByIdentifier("app--stats--builders--list").clearList()
  }

  doRequest() {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()
    this.stopRefreshing()
    this.requestBtnTarget.disabled = true
    if (this.actionMode == `build`) {
      this.doBuildRequest()
    } else if (this.actionMode == `perform`) {
      this.doPerformRequest()
    }
  }

  doBuildRequest() {
    this.send_data = { current_user: {} }

    this.url = this.urlInputTarget.value
    this.method = this.methodInputTarget.dataset.method
    this.action = this.actionInputTarget.value

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = this.permissionInputTarget.value
    this.send_data.current_user.account_kind = this.application.current_user.account_kind

    this.paramsCountArray.forEach(count => {
      if (this.nameTarget(`paramsRow-${count}`)) {
        if (this.send_data[this.nameTarget(`objInput-${count}`).value] == undefined) {
          this.send_data[this.nameTarget(`objInput-${count}`).value] = {}
        }
        this.send_data[this.nameTarget(`objInput-${count}`).value][this.nameTarget(`keyInput-${count}`).value] = this.nameTarget(`valueInput-${count}`).value
      }
    })

    this.sendBuildRequest()
  }

  sendBuildRequest() {
    var controller = this
    var init = { method: this.method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    fetch(this.url, init)
      .then(response => {
        controller.responseClone = response.clone()
        return response.json()
      })
      .then(response => {
        this.requestBtnTarget.disabled = false
        controller.getControllerByIdentifier(`app--stats--builders--responses`).doResponseViewHtml(response)
        // var response = { type: `success`, message: `Requisições enviadas com sucesso!` }
        // this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 5000)

        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      }, function (rejectionReason) { // 3
        controller.requestBtnTarget.disabled = false
        console.log('Error parsing JSON from response:', rejectionReason, controller.responseClone); // 4
        if (controller.responseClone.status == 401 && controller.responseClone.redirected) {
          // window.location.reload()
        }
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse()
        controller.responseClone.text() // 5
          .then(function (bodyText) {
            controller.getControllerByIdentifier(`app--stats--builders--responses`).doErrorResponseViewHtml(bodyText)
            console.log('Received the following instead of valid JSON:', bodyText); // 6
          });
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  doPerformRequest() {
    this.send_data = { current_user: {}, tracker: { test: true } }

    this.url = this.urlInputTarget.value
    this.method = this.methodInputTarget.dataset.method
    this.action = this.actionInputTarget.value
    this.times = this.timesInputTarget.value

    var batch = this.batchInputTarget.value
    this.getControllerByIdentifier(`app--stats--builders--responses`).request.batch = batch
    this.batchInputTarget.value = Number(batch) + 1

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = this.permissionInputTarget.value

    this.paramsCountArray.forEach(count => {
      if (this.nameTarget(`paramsRow-${count}`)) {
        this.send_data[this.nameTarget(`objInput-${count}`).value] = {}
        this.send_data[this.nameTarget(`objInput-${count}`).value][this.nameTarget(`keyInput-${count}`).value] = this.nameTarget(`valueInput-${count}`).value
      }
    })

    this.getControllerByIdentifier(`app--stats--builders--responses`).responses = []
    this.getControllerByIdentifier(`app--stats--builders--responses`).request.duration = 0
    this.getControllerByIdentifier(`app--stats--builders--responses`).request.db_runtime = 0
    this.getControllerByIdentifier(`app--stats--builders--responses`).request.view_runtime = 0
    this.getControllerByIdentifier(`app--stats--builders--responses`).responsesLength = 0
    this.getControllerByIdentifier(`app--stats--builders--responses`).doResponseCardHtml()
    this.refreshProcessModal()

    this.sendPerformRequest()
  }

  sendPerformRequest() {
    this.requests = []

    for (var index = 0; index < this.times; index++) {
      var init = { method: this.method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
      this.requests[this.requests.length] = init
    }

    this.requestPerformNext()
  }

  requestPerformNext() {

    var controller = this
    if (this.requests.length) {

      fetch(this.url, this.requests.shift())
        .then(response => response.json())
        .then(response => {

          controller.requestPerformNext()
        })
        .catch(error => {
          controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
          controller.batchInputTarget.value = 1
          controller.getControllerByIdentifier(`app--stats--builders--dashboard`).responsesTarget.innerHTML = ``
          controller.refreshSaveBtn()
          controller.stopModalRefreshing()
        })
    } else {
      var response = { type: `success`, message: `Requisições enviadas com sucesso!` }
      this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 5000)
    }
  }

  doFormHtml() {
    this.paramsCount = 0
    this.paramsCountArray = []


    var html = `<div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="formCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 py-3" style="font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="formTitle"></h6>
                  </div>

                  <div class="card-body py-0" style="overflow:auto;" data-${this.controllerName}-target="formCardBody">
                    
                    
                    <div class="row my-2">
                      <div class="col-3 px-2">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label>Método</label>
                            <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="methodDropdown">
                              <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="methodDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="methodInput"></span></button>
                              <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                <ul class="ul-select" data-${this.controllerName}-target="methodList" data-app--helpers--search-target="searchList">
                                  <li data-method="POST" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">POST</li>
                                  <li data-method="PUT" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">PUT</li>
                                  <li data-method="DELETE" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">DELETE</li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-9 px-2">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="urlForm">URL</label>
                            <input aria-describedby="urlFormHelp" class="form-control form-valid-control" id="urlForm" data-${this.controllerName}-target="urlInput" placeholder="URL" type="text" required>
                          </div>
                        </div>
                      </div>
                      <div class="col-6 px-2">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="permissionForm">Permissão</label>
                            <input aria-describedby="permissionFormHelp" class="form-control form-valid-control" id="permissionForm" data-${this.controllerName}-target="permissionInput" placeholder="Permissão" type="text" required>
                          </div>
                        </div>
                      </div>
                      <div class="col-3 px-2">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="actionForm">Ação</label>
                            <input aria-describedby="actionFormHelp" class="form-control form-valid-control" id="actionForm" data-${this.controllerName}-target="actionInput" placeholder="Ação" type="text" required>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row my-2" data-${this.controllerName}-target="performRow">

                      <div class="col-3 px-2">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="timesForm"># Requests</label>
                            <input aria-describedby="timesFormHelp" class="form-control form-valid-control" id="timesForm" data-${this.controllerName}-target="timesInput" placeholder="# Requests" type="number" required>
                          </div>
                        </div>
                      </div>
                      <div class="col-3 px-2">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="batchForm"># Batch</label>
                            <input disabled aria-describedby="batchFormHelp" class="form-control form-valid-control" id="batchForm" data-${this.controllerName}-target="batchInput" placeholder="# Batch" type="number" required>
                          </div>
                        </div>
                      </div>

                    </div>
                    <div class="row my-2">
                      <div class="col-12 px-0" data-${this.controllerName}-target="paramsInputs"></div>
                    </div>
                    

                  </div>

                  <div class="card-footer border-top text-right py-1">
                    <button type="button" class="btn btn-secondary btn-sm py-2 btn-table f-065" data-dismiss="modal" data-action="click->${this.controllerName}#cancelSave">Fechar</button>
                    <button type="button" class="btn btn-success btn-sm py-2 btn-table f-065" data-action="click->${this.controllerName}#doRequest" data-${this.controllerName}-target="requestBtn">Enviar Request</button>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("app--stats--builders--dashboard").requestsTarget.innerHTML = html)
    }).then(() => {
      controller.formCardTarget.style.height = ($(window).height() * 0.6) + "px"

      controller.formTitleTarget.innerText = `Editando Request`

      controller.urlInputTarget.value = controller.current_request.url
      controller.permissionInputTarget.value = controller.current_request.permission
      controller.actionInputTarget.value = controller.current_request.action

      controller.methodDropdownTarget.value = controller.current_request.method
      controller.methodInputTarget.innerText = controller.current_request.method
      controller.methodInputTarget.dataset.method = controller.current_request.method

      controller.urlInputTarget.disabled = true
      controller.permissionInputTarget.disabled = true
      controller.actionInputTarget.disabled = true
      controller.methodDropdownBtnTarget.disabled = true

      if (controller.actionMode == `perform`) {
        controller.timesInputTarget.value = 10
        controller.batchInputTarget.value = 1
      } else {
        controller.performRowTarget.remove()
      }

      controller.setParameters()
      controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
    })
  }

  addParamsKeyValue() {
    var html = `<div class="row d-flex align-items-center" data-${this.controllerName}-target="paramsRow-${this.paramsCount}">
                  <div class="col-4 px-2">
                    <div class="form-group">
                      <div class="floating-label floating-label-sm">
                        <label for="objForm">Objeto</label>
                        <input aria-describedby="objFormHelp" class="form-control form-valid-control" id="objForm" data-${this.controllerName}-target="objInput-${this.paramsCount}" placeholder="Objeto" type="text" required>
                      </div>
                    </div>
                  </div>
                  <div class="col-4 px-2">
                    <div class="form-group">
                      <div class="floating-label floating-label-sm">
                        <label for="keyForm">Chave</label>
                        <input aria-describedby="keyFormHelp" class="form-control form-valid-control" id="keyForm" data-${this.controllerName}-target="keyInput-${this.paramsCount}" placeholder="Chave" type="text" required>
                      </div>
                    </div>
                  </div>
                  <div class="col-3 px-2">
                    <div class="form-group">
                      <div class="floating-label floating-label-sm">
                        <label for="valueForm">Valor</label>
                        <input aria-describedby="valueFormHelp" class="form-control form-valid-control" id="valueForm" data-${this.controllerName}-target="valueInput-${this.paramsCount}" placeholder="Valor" type="text" required>
                      </div>
                    </div>
                  </div>
                </div>`

    
    this.paramsInputsTarget.insertAdjacentHTML("beforeend", html)
    this.paramsCountArray[this.paramsCountArray.length] = this.paramsCount
    this.paramsCount += 1
    // var controller = this
    // new Promise(function (resolve) {
    //   resolve(controller.paramsInputsTarget.insertAdjacentHTML("beforeend", html))
    // }).then(() => {
    // })
  }

  setParameters() {
    this.current_request.parameters.forEach((parameter, index) => {
      var controller = this
      new Promise(function (resolve) {
        resolve(controller.addParamsKeyValue(index))
      }).then(() => {

        controller.nameTarget(`objInput-${index}`).value = parameter.parobj
        controller.nameTarget(`keyInput-${index}`).value = parameter.parkey
        controller.nameTarget(`valueInput-${index}`).value = parameter.parvalue

        controller.nameTarget(`objInput-${index}`).disabled = true
        controller.nameTarget(`keyInput-${index}`).disabled = true
        controller.nameTarget(`valueInput-${index}`).disabled = true
        
        controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
      })
    })
  }

  // setParameters() {
  //   this.current_request.parameters.forEach(parameter => {
  //     var controller = this
  //     new Promise(function (resolve) {
  //       resolve(controller.addParamsKeyValue())
  //     }).then(() => {
  //       controller.nameTarget(`objInput-${controller.paramsCount - 1}`).value = parameter.parobj
  //       controller.nameTarget(`keyInput-${controller.paramsCount - 1}`).value = parameter.parkey
  //       controller.nameTarget(`valueInput-${controller.paramsCount - 1}`).value = parameter.parvalue
  //       controller.nameTarget(`objInput-${controller.paramsCount - 1}`).disabled = true
  //       controller.nameTarget(`keyInput-${controller.paramsCount - 1}`).disabled = true
  //       controller.nameTarget(`valueInput-${controller.paramsCount - 1}`).disabled = true
  //       controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
  //     })
  //   })
  // }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshProcessModal() {
    var controller = this
    this.refreshModal = setInterval(function () {
      var len = 0

      if (Number(controller.times) != Number(controller.getControllerByIdentifier(`app--stats--builders--responses`).responsesLength)) {
        len += 1
      }

      if (len == 0) {
        controller.stopModalRefreshing()
        controller.refreshSaveBtn()
        var response = { type: `success`, message: `Teste de desempenho finalizado!` }
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      }
    }, 200);
  }

  stopBtnRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  stopModalRefreshing() {
    if (this.refreshModal) {
      clearInterval(this.refreshModal)
    }
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0

      if (controller.actionMode == `perform`) {
        if (controller.timesInputTarget.value == `` || controller.timesInputTarget.value == 0) {
          len += 1
        }
      }

      if (len == 0) {
        controller.requestBtnTarget.disabled = false
      } else {
        controller.requestBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

}
