import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["formCard", "formTitle", "formCardBody", "nameInput", "setupCheckbox", "recurringCheckbox", "saveBtn",
                    "startedAtInput", "expiresAtInput", "descriptionInput", "amountInput",
                    "kindDropdown", "kindDropdownBtn", "kindInput", "kindList",
                    "productKindDropdown", "productKindDropdownBtn", "productKindInput", "productKindList",
                    "subkindDropdown", "subkindDropdownBtn", "subkindInput", "subkindList",
                    "planDropdown", "planDropdownBtn", "planInput", "planList",
                    "errorMessage", "financialStatusDropdownBtn", "financialStatusInput", "financialStatusList",
                    "dueDayDropdown", "dueDayDropdownBtn", "dueDayInput", "dueDayList",
                    "iuguIdInput", "iuguEmailInput", "contractStatusForm", "churnDescriptionDiv", "churnDescriptionInput",
                    "statusDropdown", "statusDropdownBtn", "statusInput", "statusList", "notesInput"]


  connect() {
    this.controllerName = `financials--books--clients--addons--save`
    this.canRequest = true
    this.cpf_result = {}
    this.record = {}
    this.checkDate = {}
  }

  cancelSave() {
    this.stopRefreshing()

    if (this.controllerDashboard.context.identifier == `financials--books--clients--contracts--dashboard`) {
      this.getControllerByIdentifier(`financials--books--clients--addons--list`).doAddonsList()
    } else if (this.controllerDashboard.context.identifier == `financials--books--receivables--contracts--dashboard`) {
      this.getControllerByIdentifier(`financials--books--receivables--contracts--list`).doDataTable()
      this.getControllerByIdentifier(`financials--books--clients--addons--list`).doAddonsList()
    } else if (this.controllerDashboard.context.identifier == `financials--books--receivables--conciliations--modal--contracts`) {
      // this.getControllerByIdentifier(`financials--books--receivables--conciliations--modal--contracts`).doAddonsList()
      this.getControllerByIdentifier(`financials--books--receivables--conciliations--modal--billing`).disableItemsCheck()
      this.getControllerByIdentifier(`financials--books--receivables--conciliations--modal--contracts`).doContractShow()
    }
    
  }

  saveAddon() {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()
    this.saveBtnTarget.disabled = true
    this.stopRefreshing()

    this.send_data = { current_user: {}, addon: {}, tracker: { manual: true } }
    var contractName = `${this.current_contract.name} | ${this.current_contract.client_name} | ${this.current_contract.client_person_type_pretty}: ${this.current_contract.client_number_pretty}`

    if (this.actionMode == `new`) {
      this.send_data.addon.status = `active`

      this.send_data.tracker.action = `created`
      this.send_data.tracker.observations = `Adicionou Complemento ${this.descriptionInputTarget.value.trim()} ${this.notesInputTarget.value.trim() } ao Contrato ${contractName}`
    } else if (this.actionMode == `edit`) {
      this.send_data.addon.id = this.current_addon.id
      this.send_data.addon.status = this.statusInputTarget.dataset.status
      this.send_data.addon.changed_description = this.churnDescriptionInputTarget.value.trim()

      this.send_data.tracker.action = `updated`
      this.send_data.tracker.observations = `Atualizou Complemento #${this.current_addon.token} do Contrato ${contractName}`
    }

    this.send_data.addon.client_id = this.current_contract.client_id
    this.send_data.addon.contract_id = this.current_contract.id
    
    this.send_data.addon.kind = this.kindInputTarget.dataset.kind
    this.send_data.addon.subkind = this.subkindInputTarget.dataset.subkind
    this.send_data.addon.plan = this.planInputTarget.dataset.plan
    this.send_data.addon.description = this.descriptionInputTarget.value.trim()
    this.send_data.addon.notes = this.notesInputTarget.value.trim()
    this.send_data.addon.started_at = this.getControllerByIdentifier("app--helpers--date").transformAllKindDate(`01/${this.startedAtInputTarget.value}`)
    this.send_data.addon.amount = this.getControllerByIdentifier("app--helpers--numbers").fromCurrencyToNumber(this.amountInputTarget.value)
    if (this.expiresAtInputTarget.value && this.expiresAtInputTarget.value != `N/D`) {
      this.send_data.addon.expires_at = this.getControllerByIdentifier("app--helpers--date").transformAllKindDate(`01/${this.expiresAtInputTarget.value}`)
    }

    if (this.setupCheckboxTarget.checked) {
      this.send_data.addon.recurring = false
    } else if (this.recurringCheckboxTarget.checked) {
      this.send_data.addon.recurring = true
    }

    this.send_data.addon.client_iugu_id = this.iuguIdInputTarget.value.trim()
    this.send_data.addon.client_iugu_email = this.iuguEmailInputTarget.value.trim()

    this.send_data.tracker.record_id = this.current_contract.client_id
    this.send_data.tracker.record_type = `financial_client_entities`
    this.send_data.tracker.obj_id = this.current_contract.id
    this.send_data.tracker.obj_type = `financial_client_contracts`
    

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = this.permission

    this.requestSave()
  }

  requestSave() {
    if (this.actionMode == `new`) {
      var url = "/financials/books/clients/addons/create"
      var method = "POST"
    } else if (this.actionMode == `edit`) {
      var url = "/financials/books/clients/addons/update"
      var method = "PUT"
    }
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this

    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.stopRefreshing()
        if (response.save) {
          var addon = response.data.cln.addon
          var contract = response.data.cln.contract

          if (controller.controllerDashboard.context.identifier == `financials--books--clients--contracts--dashboard`) {
            if (controller.actionMode == `new`) {
              controller.application.client_addons[controller.application.client_addons.length] = addon
            } else if (controller.actionMode == `edit`) {
              controller.application.client_addons.forEach((element, i) => {
                if (element.id == addon.id) {
                  controller.application.client_addons.splice(controller.application.client_addons.indexOf(element), 1, addon)
                }
              })
            }

            controller.application.contracts.forEach((element, i) => {
              if (element.id == contract.id) {
                controller.application.contracts.splice(controller.application.contracts.indexOf(element), 1, contract)
              }
            })
          } else if (this.controllerDashboard.context.identifier == `financials--books--receivables--contracts--dashboard`) {
            if (controller.actionMode == `new`) {
              controller.application.client_addons[controller.application.client_addons.length] = addon
            } else if (controller.actionMode == `edit`) {
              controller.application.client_addons.forEach((element, i) => {
                if (element.id == addon.id) {
                  controller.application.client_addons.splice(controller.application.client_addons.indexOf(element), 1, addon)
                }
              })
            }

            controller.application.client_contracts.forEach((element, i) => {
              if (element.id == contract.id) {
                controller.application.client_contracts.splice(controller.application.client_contracts.indexOf(element), 1, contract)
              }
            })
          } else if (this.controllerDashboard.context.identifier == `financials--books--receivables--conciliations--modal--contracts`) {
            var controllerModalContracts = controller.getControllerByIdentifier(`financials--books--receivables--conciliations--modal--contracts`)
            
            controllerModalContracts.current_contract = contract

            controllerModalContracts.current_client_contracts.forEach((element, i) => {
              if (element.id == contract.id) {
                controllerModalContracts.current_client_contracts.splice(controllerModalContracts.current_client_contracts.indexOf(element), 1, contract)
              }
            })

            if (controller.actionMode == `new`) {
              controllerModalContracts.current_contract_addons[controllerModalContracts.current_contract_addons.length] = addon
            } else if (controller.actionMode == `edit`) {
              controllerModalContracts.current_contract_addons.forEach((element, i) => {
                if (element.id == addon.id) {
                  controllerModalContracts.current_contract_addons.splice(controllerModalContracts.current_contract_addons.indexOf(element), 1, addon)
                }
              })
            }
          }

          controller.cancelSave()
        } else {
          controller.saveBtnTarget.disabled = false
        }

        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  doFormHtml() {
    this.stopRefreshing()

    // if (this.application.permissions.financial_receivable_contracts.can_create) {
    if (this.application.permissions[this.permission].can_create) {
      var saveBtnHtml = `<button disabled type="button" class="btn btn-primary btn-sm py-2 btn-table f-065" data-action="click->${this.controllerName}#saveAddon" data-${this.controllerName}-target="saveBtn">Salvar</button>`
    } else {
      var saveBtnHtml = ``
    }

    var html = `<div class="row">
                  <div class="col-12 px-0">
                    <div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="formCard" data-action="resize@window->${this.controllerName}#layout">
                      <div class="card-header d-flex align-items-center card-header-table-list f-065">
                        <h6 class="card-title display-4 py-3" style="font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="formTitle"></h6>
                      </div>
                      <div class="card-body py-0 px-2" style="overflow:auto;" data-${this.controllerName}-target="formCardBody">
                        <div class="row my-2">
                          <div class="col-3">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="startedAtForm">Início</label>
                                <input aria-describedby="startedAtFormHelp" class="form-control" id="startedAtForm" data-action="blur->${this.controllerName}#dateMask keypress->${this.controllerName}#dateMask keyup->${this.controllerName}#dateValidateEv blur->${this.controllerName}#dateValidateEv" data-${this.controllerName}-target="startedAtInput" data-check-future="true" placeholder="MM/AAAA" type="text" required>
                              </div>
                            </div>
                          </div>
                          <div class="col-3">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="expiresAtForm">Expiração</label>
                                <input aria-describedby="expiresAtFormHelp" class="form-control" id="expiresAtForm" data-action="blur->${this.controllerName}#dateMask keypress->${this.controllerName}#dateMask keyup->${this.controllerName}#dateValidateEv blur->${this.controllerName}#dateValidateEv" data-${this.controllerName}-target="expiresAtInput" data-check-future="false" placeholder="MM/AAAA" type="text" required>
                              </div>
                            </div>
                          </div>
                          <div class="col-3 d-flex align-items-center">
                            <div class="custom-control custom-checkbox pr-0 pl-2 w-50 my-0 d-flex">
                              <input type="checkbox" class="custom-control-input" id="sourceCheck" data-kind="setup" data-${this.controllerName}-target="setupCheckbox" data-action="click->${this.controllerName}#uniqKind">
                              <label class="custom-control-label f-065" for="sourceCheck">Avulso</label>
                            </div>
                          </div>
                          <div class="col-3 d-flex align-items-center">
                            <div class="custom-control custom-checkbox pr-0 pl-2 w-50 my-0 d-flex">
                              <input type="checkbox" class="custom-control-input" id="recurringCheck" data-kind="recurring" data-${this.controllerName}-target="recurringCheckbox" data-action="click->${this.controllerName}#uniqKind">
                              <label class="custom-control-label f-065" for="recurringCheck">Recorrente</label>
                            </div>
                          </div>
                        </div>
                        <div class="row my-2">
                          <div class="col-6">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label>Tipo</label>
                                <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="kindDropdown">
                                  <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="kindDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="kindInput"></span></button>
                                  <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                    <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                    <ul class="ul-select" data-${this.controllerName}-target="kindList" data-app--helpers--search-target="searchList">
                                      <li data-kind="revenue" data-action="click->app--helpers--selector#select click->${this.controllerName}#setSubkindListEv" data-app--helpers--selector-target="select" class="li-selector dark f-065">Receita</li>
                                      <li data-kind="refund" data-action="click->app--helpers--selector#select click->${this.controllerName}#setSubkindListEv" data-app--helpers--selector-target="select" class="li-selector dark f-065">Reembolso</li>
                                      <li data-kind="discount" data-action="click->app--helpers--selector#select click->${this.controllerName}#setSubkindListEv" data-app--helpers--selector-target="select" class="li-selector dark f-065">Desconto</li>
                                      <li data-kind="reversal" data-action="click->app--helpers--selector#select click->${this.controllerName}#setSubkindListEv" data-app--helpers--selector-target="select" class="li-selector dark f-065">Estorno</li>
                                      <li data-kind="advance" data-action="click->app--helpers--selector#select click->${this.controllerName}#setSubkindListEv" data-app--helpers--selector-target="select" class="li-selector dark f-065">Antecipado</li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-6">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label>Subtipo</label>
                                <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="subkindDropdown">
                                  <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="subkindDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="subkindInput"></span></button>
                                  <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                    <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                    <ul class="ul-select" data-${this.controllerName}-target="subkindList" data-app--helpers--search-target="searchList"></ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-6">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label>Periodicidade</label>
                                <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="planDropdown">
                                  <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="planDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="planInput"></span></button>
                                  <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                    <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                    <ul class="ul-select" data-${this.controllerName}-target="planList" data-app--helpers--search-target="searchList">
                                      <li data-plan="monthly" data-action="click->app--helpers--selector#select click->${this.controllerName}#setDescription" data-app--helpers--selector-target="select" class="li-selector dark f-065">Mensal</li>
                                      <li data-plan="yearly" data-action="click->app--helpers--selector#select click->${this.controllerName}#setDescription" data-app--helpers--selector-target="select" class="li-selector dark f-065">Anual</li>
                                      <li data-plan="unique" data-action="click->app--helpers--selector#select click->${this.controllerName}#setDescription" data-app--helpers--selector-target="select" class="li-selector dark f-065">Único</li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-6">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="amountForm">Valor</label>
                                <input aria-describedby="amountFormHelp" class="form-control" id="amountForm" data-${this.controllerName}-target="amountInput" placeholder="Valor" data-action="blur->${this.controllerName}#currencyMask keypress->${this.controllerName}#currencyMask keyup->${this.controllerName}#currencyMask" type="text" required>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row my-2 d-none" data-${this.controllerName}-target="contractStatusForm">
                          <div class="col-6">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label>Status</label>
                                <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="statusDropdown">
                                  <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="statusDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="statusInput"></span></button>
                                  <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                    <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                    <ul class="ul-select" data-${this.controllerName}-target="statusList" data-app--helpers--search-target="searchList">
                                      <li data-status="active" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectStatus" data-app--helpers--selector-target="select" class="li-selector dark f-065">Ativo</li>
                                      <li data-status="paid_off" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectStatus" data-app--helpers--selector-target="select" class="li-selector dark f-065">Quitado</li>
                                      <li data-status="suspend" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectStatus" data-app--helpers--selector-target="select" class="li-selector dark f-065">Suspenso</li>
                                      <li data-status="canceled" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectStatus" data-app--helpers--selector-target="select" class="li-selector dark f-065">Churn</li>
                                      <li data-status="expired" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectStatus" data-app--helpers--selector-target="select" class="li-selector dark f-065">Expirado</li>
                                      <li data-status="write_off" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectStatus" data-app--helpers--selector-target="select" class="li-selector dark f-065">Anulado</li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="col-12 d-none" data-${this.controllerName}-target="churnDescriptionDiv">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="churnDescriptionForm">Descrição do Churn</label>
                                <input aria-describedby="churnDescriptionFormHelp" class="form-control" id="churnDescriptionForm" data-${this.controllerName}-target="churnDescriptionInput" placeholder="Descrição do Churn" type="text" required>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row my-3">
                          <div class="col-12">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="descriptionForm">Descrição Geral</label>
                                <input disabled aria-describedby="descriptionFormHelp" class="form-control" id="descriptionForm" data-${this.controllerName}-target="descriptionInput" placeholder="Descrição" type="text" required>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row my-3">
                          <div class="col-12">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="notesForm">Anotações</label>
                                <input aria-describedby="notesFormHelp" class="form-control" id="notesForm" data-${this.controllerName}-target="notesInput" placeholder="Anotações" type="text" required>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row my-3">
                          <div class="col-12">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="iuguIdForm">Iugu ID</label>
                                <input aria-describedby="iuguIdFormHelp" class="form-control" id="iuguIdForm" data-${this.controllerName}-target="iuguIdInput" placeholder="Iugu ID" type="text" required>
                              </div>
                            </div>
                          </div>
                          <div class="col-12">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="iuguEmailForm">Iugu Email</label>
                                <input aria-describedby="iuguEmailFormHelp" class="form-control" id="iuguEmailForm" data-${this.controllerName}-target="iuguEmailInput" placeholder="Iugu Email" type="text" required>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="card-footer border-top text-right py-1">
                        <span class="mr-auto text-left f-danger my-auto" data-${this.controllerName}-target="errorMessage"></span>
                        <button type="button" class="btn btn-secondary btn-sm py-2 btn-table f-065" data-dismiss="modal" data-action="click->${this.controllerName}#cancelSave">Fechar</button>
                        ${saveBtnHtml}
                      </div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.gridElement.innerHTML = html)
    }).then(() => {
      if (controller.getControllerByIdentifier(`financials--books--receivables--conciliations--modal--contracts`)) {
        controller.formCardTarget.classList.add(`mt-4`)
      } else {
        controller.formCardTarget.style.height = ($(window).height() * 0.65) + "px"
      }
      
      if (controller.actionMode == `edit`) {
        controller.contractStatusFormTarget.classList.remove(`d-none`)
        controller.formTitleTarget.innerText = `Editando Item de Contrato`

        controller.statusDropdownTarget.value = controller.current_contract.status
        controller.statusInputTarget.dataset.status = controller.current_contract.status
        controller.statusInputTarget.innerText = controller.current_contract.status_pretty

        controller.setupCheckboxTarget.disabled = true
        controller.recurringCheckboxTarget.disabled = true

        if (controller.current_addon.recurring) {
          controller.recurringCheckboxTarget.checked = true
          controller.setupCheckboxTarget.checked = false
        } else {
          controller.setupCheckboxTarget.checked = true
          controller.recurringCheckboxTarget.checked = false
        }

        controller.startedAtInputTarget.value = controller.current_addon.started_at_pretty

        if (controller.current_addon.expires_at_pretty) {
          controller.expiresAtInputTarget.value = controller.current_addon.expires_at_pretty
        }

        controller.kindDropdownTarget.value = controller.current_addon.kind
        controller.kindInputTarget.dataset.kind = controller.current_addon.kind
        controller.kindInputTarget.innerText = controller.current_addon.kind_pretty
        
        controller.subkindDropdownTarget.value = controller.current_addon.subkind
        controller.subkindInputTarget.dataset.subkind = controller.current_addon.subkind
        controller.subkindInputTarget.innerText = controller.current_addon.subkind_pretty
        
        controller.planDropdownTarget.value = controller.current_addon.plan
        controller.planInputTarget.dataset.plan = controller.current_addon.plan
        controller.planInputTarget.innerText = controller.current_addon.plan_pretty
        
        controller.descriptionInputTarget.value = controller.current_addon.description
        controller.notesInputTarget.value = controller.current_addon.notes
        
        controller.iuguIdInputTarget.value = controller.current_addon.client_iugu_id
        controller.iuguEmailInputTarget.value = controller.current_addon.client_iugu_email
        
        controller.amountInputTarget.value = controller.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(controller.current_addon.amount)
        controller.setDescription()
        controller.dateValidate(`01/${controller.startedAtInputTarget.value}`, controller.startedAtInputTarget)

        if (controller.current_addon.record_id) {
          controller.kindDropdownBtnTarget.disabled = true
          controller.subkindDropdownBtnTarget.disabled = true
          controller.planDropdownBtnTarget.disabled = true
          controller.amountInputTarget.disabled = true
          controller.startedAtInputTarget.disabled = true
          controller.notesInputTarget.disabled = true

          controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", `Esse Complemento deve ser editado no processo de origem!`, 3000)
        } else {
          controller.kindDropdownBtnTarget.disabled = false
          controller.subkindDropdownBtnTarget.disabled = false
          controller.planDropdownBtnTarget.disabled = false
          controller.amountInputTarget.disabled = false
          controller.startedAtInputTarget.disabled = false
        }

        controller.application.clients.forEach(client => {
          if (client.id == controller.current_contract.client_id) {
            controller.current_client = client
          }
        })

        var clientIuguIds = this.mapElements(controller.current_client.accounts, `iugu_id`)

        // if (controller.current_client.iugu_ids.length == 1) {
        if (clientIuguIds.length == 1) {
          controller.iuguIdInputTarget.value = controller.current_client.id_iugu
          controller.iuguEmailInputTarget.value = controller.current_client.record_email
        }
        
      } else if (controller.actionMode == `new`) {
        controller.formTitleTarget.innerText = `Novo Item de Contrato`

        controller.amountInputTarget.value = controller.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(0)

        controller.application.clients.forEach(client => {
          if (client.id == controller.current_contract.client_id) {
            controller.current_client = client  
          }
        })

        if (controller.current_billing) {
          controller.startedAtInputTarget.value = controller.current_billing.due_at_accrual
          controller.iuguIdInputTarget.value = controller.current_billing.client_id_iugu
          controller.iuguEmailInputTarget.value = controller.current_billing.email_iugu
        } else if (controller.current_client.iugu_ids.length == 1) {
          controller.iuguIdInputTarget.value = controller.current_client.id_iugu
          controller.iuguEmailInputTarget.value = controller.current_client.record_email
        }

        if (controller.kindModel) {
          controller.setKindModel()
        }

        // controller.startedAtInputTarget.value = `01/2022`
        // controller.kindDropdownTarget.value = `revenue`
        // controller.kindInputTarget.dataset.kind = `revenue`
        // controller.kindInputTarget.innerText = `Receita`
        // controller.subkindDropdownTarget.value = `revenue_amount`
        // controller.subkindInputTarget.dataset.subkind = `revenue_amount`
        // controller.subkindInputTarget.innerText = `Prestação de Serviços`
        // controller.planDropdownTarget.value = `monthly`
        // controller.planInputTarget.dataset.plan = `monthly`
        // controller.planInputTarget.innerText = `Mensal`
        // controller.descriptionInputTarget.value = `Receita Mensal`
        // controller.amountInputTarget.value = controller.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(400)
      }

      controller.refreshSaveBtn()
      controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
    })
  }

  setKindModel() {

    this.startedAtInputTarget.value = this.application.current_date.date_accrual
    this.amountInputTarget.value = this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(0)
    
    if (this.kindModel == `revenue`) {
      this.kindDropdownTarget.value = `revenue`
      this.kindInputTarget.dataset.kind = `revenue`
      this.kindInputTarget.innerText = `Receitas`
      
      // this.subkindDropdownTarget.value = `revenue_amount`
      // this.subkindInputTarget.dataset.subkind = `revenue_amount`
      // this.subkindInputTarget.innerText = `Prestação de Serviços`
    } else {
      this.kindDropdownTarget.value = `discount`
      this.kindInputTarget.dataset.kind = `discount`
      this.kindInputTarget.innerText = `Desconto`
      this.setupCheckboxTarget.checked = true
      this.descriptionInputTarget.value = `Desconto`
      this.notesInputTarget.value = `Desconto`

      // this.subkindDropdownTarget.value = `discount_amount`
      // this.subkindInputTarget.dataset.subkind = `discount_amount`
      // this.subkindInputTarget.innerText = `Prestação de Serviços` 
    }

    this.setSubkindList(this.kindModel)

  }

  uniqKind(ev) {
    var kind = ev.currentTarget.dataset.kind

    if (kind == `setup`) {
      this.setupCheckboxTarget.checked = true
      this.recurringCheckboxTarget.checked = false
    } else if (kind == `recurring`) {
      this.setupCheckboxTarget.checked = false
      this.recurringCheckboxTarget.checked = true
    }
  }

  setSubkindListEv(ev) {
    var kind = ev.currentTarget.dataset.kind

    this.subkindDropdownTarget.value = ``
    this.subkindInputTarget.dataset.subkind = ``
    this.subkindInputTarget.innerText = ``
    this.planDropdownTarget.value = ``
    this.planInputTarget.dataset.plan = ``
    this.planInputTarget.innerText = ``
    this.descriptionInputTarget.value = ``
    this.amountInputTarget.value = this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(0)

    this.setDescription()
    this.setSubkindList(kind)
  }

  setSubkindList(kind) {
    var html = ``
    if (kind == `revenue`) {
      html += `<li data-subkind="revenue_amount" data-action="click->app--helpers--selector#select click->${this.controllerName}#setDescription" data-app--helpers--selector-target="select" class="li-selector dark f-065">Prestação de Serviços</li>`
    } else if (kind == `refund`) {
      html += `<li data-subkind="refund_amount" data-action="click->app--helpers--selector#select click->${this.controllerName}#setDescription" data-app--helpers--selector-target="select" class="li-selector dark f-065">Antecipação de Valores</li>`
    } else if (kind == `discount`) {
      html += `<li data-subkind="commercial_discount" data-action="click->app--helpers--selector#select click->${this.controllerName}#setDescription" data-app--helpers--selector-target="select" class="li-selector dark f-065">Comercial</li>
              <li data-subkind="retention_discount" data-action="click->app--helpers--selector#select click->${this.controllerName}#setDescription" data-app--helpers--selector-target="select" class="li-selector dark f-065">Retenção</li>
              <li data-subkind="error_discount" data-action="click->app--helpers--selector#select click->${this.controllerName}#setDescription" data-app--helpers--selector-target="select" class="li-selector dark f-065">Erro Operacional</li>
              <li data-subkind="financial_discount" data-action="click->app--helpers--selector#select click->${this.controllerName}#setDescription" data-app--helpers--selector-target="select" class="li-selector dark f-065">Financeiro</li>`
    } else if (kind == `reversal`) {
      html += `<li data-subkind="reversal_amount" data-action="click->app--helpers--selector#select click->${this.controllerName}#setDescription" data-app--helpers--selector-target="select" class="li-selector dark f-065">Estorno</li>`
    } else if (kind == `advance`) {
      html += `<li data-subkind="accounting_amount" data-action="click->app--helpers--selector#select click->${this.controllerName}#setDescription" data-app--helpers--selector-target="select" class="li-selector dark f-065">Controle</li>`
      html += `<li data-subkind="biling_amount" data-action="click->app--helpers--selector#select click->${this.controllerName}#setDescription" data-app--helpers--selector-target="select" class="li-selector dark f-065">Faturamento</li>`
      html += `<li data-subkind="investment_amount" data-action="click->app--helpers--selector#select click->${this.controllerName}#setDescription" data-app--helpers--selector-target="select" class="li-selector dark f-065">Acumulação</li>`
      html += `<li data-subkind="protection_amount" data-action="click->app--helpers--selector#select click->${this.controllerName}#setDescription" data-app--helpers--selector-target="select" class="li-selector dark f-065">Proteção</li>`
      html += `<li data-subkind="return_amount" data-action="click->app--helpers--selector#select click->${this.controllerName}#setDescription" data-app--helpers--selector-target="select" class="li-selector dark f-065">Para Devolução</li>`
    }

    this.subkindListTarget.innerHTML = html
  }

  selectStatus(ev) {
    var status = ev.currentTarget.dataset.status

    if (status == `canceled`) {
      this.churnDescriptionDivTarget.classList.remove(`d-none`)
    } else {
      this.churnDescriptionDivTarget.classList.add(`d-none`)
    }
  }

  setDescription() {
    var kind = this.kindInputTarget.innerText
    var subkind = this.subkindInputTarget.innerText
    var plan = this.planInputTarget.innerText

    this.descriptionInputTarget.value = `${kind} | ${subkind} | ${plan}`

    this.getControllerByIdentifier("app--helpers--forms").floatingLabel()
  }

  currencyMask(ev) {
    this.getControllerByIdentifier("app--helpers--numbers").changeNumberToCurrency(ev)
  }

  dateMask(ev) {
    // this.getControllerByIdentifier("app--helpers--numbers").changeNumberToDate(ev)
    this.getControllerByIdentifier("app--helpers--numbers").changeNumberToMonthDate(ev)
  }

  dateValidateEv(ev) {
    var gridElement = ev.currentTarget
    var date = `01/${ev.target.value}`

    var cleanDate = this.getControllerByIdentifier("app--helpers--numbers").fromDateToNumber(`01/${ev.target.value}`)
    if ((ev.type == "keyup" && ev.shiftKey == false && cleanDate.length == 8) || ev.type == "blur" && cleanDate.length == 8) {
      this.dateValidate(date, gridElement)
    } else {
      gridElement.classList.add("f-normal")
      gridElement.classList.remove("f-danger")
    }
  }

  dateValidate(date, gridElement) {
    var checkFuture = this.getControllerByIdentifier("app--helpers--boolean").transformStringToBoolean(gridElement.dataset.checkFuture)
    this.checkDate = this.getControllerByIdentifier("app--helpers--date").checkDate(date, checkFuture)
    if (this.checkDate.valid) {
      gridElement.classList.add("f-normal")
      gridElement.classList.remove("f-danger")
    } else {
      this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", this.checkDate.message, 3000)
      this.errorMessageTarget.innerText = this.checkDate.message
      this.clearErrorMessage()

      gridElement.classList.add("f-danger")
      gridElement.classList.remove("f-normal")
    }
  }

  clearErrorMessage() {
    var controller = this
    setTimeout(() => {
      controller.errorMessageTarget.innerText = ``
    }, 3000);
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0

      if (controller.hasFormCardTarget) {


        if (controller.hasStartedAtInputTarget && controller.startedAtInputTarget.value == ``) {
          len += 1
        }

        if (controller.kindInputTarget.innerText == ``) {
          len += 1
        }

        if (controller.iuguIdInputTarget.value == ``) {
          len += 1
        }

        if (controller.iuguEmailInputTarget.value == ``) {
          len += 1
        }

        if (controller.subkindInputTarget.innerText == ``) {
          len += 1
        }

        if (controller.planInputTarget.innerText == ``) {
          len += 1
        }

        if (controller.setupCheckboxTarget.checked == false && controller.recurringCheckboxTarget.checked == false) {
          len += 1
        }
        
        if (!controller.checkDate.valid) {
          len += 1
        }

        if (controller.hasSaveBtnTarget) {
          if (len == 0) {
            controller.saveBtnTarget.disabled = false
          } else {
            controller.saveBtnTarget.disabled = true
          }
        }
      }
    }, 200);
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    })
    return results
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  randonNumber() {
    var number = Math.floor(Math.random() * 1000)
    return number
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
