import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["modal", "bottom", "top", "list", "view", "mainCard", "bodyTable", "footerTable", "body", "searchInput", "searchTable"]

  connect() {
    this.controllerName = `operations--ongoings--customers--index--clinic-modal`
    this.controllerNumber = this.getControllerByIdentifier(`app--helpers--numbers`)
    this.itemsOnPage = 500
    this.tableCols = 1
    this.pageNumber = 0
    this.numberPages = []
    this.sort = { mode: `asc`, field: `name` }
  }

  open() {
    document.body.classList.add("modal-open");
    this.element.setAttribute("style", "display: block;");
    this.element.classList.add("show");
    const html = `<div class="modal-backdrop fade show"></div>`
    // document.body.insertAdjacentHTML("beforeend", html)
    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.doClinicHtml()
    })
  }

  close() {
    document.body.classList.remove("modal-open");
    this.element.removeAttribute("style");
    this.element.classList.remove("show");
    document.getElementsByClassName("modal-backdrop")[0].remove();
    this.modalTarget.remove()
  }

  doClinicHtml() {
    var spaceRow = `<td colspan="10" style="height:2rem;padding-top:1rem;padding-bottom:1rem;" scope="col"></td>`

    var html = `<div class="card" style="width:100%;display:relative;" data-controller="app--helpers--search" data-${this.controllerName}-target="mainCard">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 card-title-table-list">${this.current_clinic.company_name}</h6>
                    <div class="card-actions ml-auto py-0 mc-tooltip">
                      <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-action="click->${this.controllerName}#close" type="button">
                        <span class="material-icons md-dark">clear</span>
                      </button>
                      <span class="mc-tooltiptext">Fechar</span>
                    </div>
                  </div>
                  <div class="card-body py-0" style="overflow:auto;">
                    <div class="row my-1">
                      <div class="col-12">
                        <table class="table table-sm table-borderless" style="font-size:80%;">
                          <tbody>
                            <tr>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                            </tr>
                            <tr>
                              <th colspan="4" class="f-065 align-middle px-0">
                                <strong>Razão Social</strong>
                              </th>
                              <td colspan="6" class="f-065 align-middle px-0 text-left">
                                ${this.current_clinic.company_trade_name}
                              </td>
                            </tr>
                            <tr>
                              <th colspan="4" class="f-065 align-middle px-0">
                                <strong>Nome Fantasia</strong>
                              </th>
                              <td colspan="6" class="f-065 align-middle px-0 text-left">
                                ${this.current_clinic.company_name}
                              </td>
                            </tr>
                            <tr>
                              <th colspan="4" class="f-065 align-middle px-0">
                                <strong>CNPJ</strong>
                              </th>
                              <td colspan="6" class="f-065 align-middle px-0 text-left">
                                ${this.current_clinic.company_cnpj_pretty}
                              </td>
                            </tr>
                            <tr>
                              <th colspan="4" class="f-065 align-middle px-0">
                                <strong>Data Início</strong>
                              </th>
                              <td colspan="6" class="f-065 align-middle px-0 text-left">
                                ${this.current_clinic.clinic_begin}
                              </td>
                            </tr>
                            <tr>
                              <th colspan="4" class="f-065 align-middle px-0">
                                <strong>Status</strong>
                              </th>
                              <td colspan="6" class="f-065 align-middle px-0 text-left">
                                ${this.current_clinic.clinic_status}
                              </td>
                            </tr>
                            <tr>
                              <th colspan="4" class="f-065 align-middle px-0">
                                <strong>Regime Tributário</strong>
                              </th>
                              <td colspan="6" class="f-065 align-middle px-0 text-left">
                                ${this.current_clinic.clinic_regime}
                              </td>
                            </tr>
                            <tr>
                              <th colspan="4" class="f-065 align-middle px-0">
                                <strong>Natureza Jurídica</strong>
                              </th>
                              <td colspan="6" class="f-065 align-middle px-0 text-left">
                                ${this.current_clinic.clinic_legal_nature}
                              </td>
                            </tr>
                            <tr>
                              <th colspan="4" class="f-065 align-middle px-0">
                                <strong>Cidade/UF:</strong>
                              </th>
                              <td colspan="6" class="f-065 align-middle px-0 text-left">
                                ${this.current_clinic.clinic_city}
                              </td>
                            </tr>
                            
                            
                            <tr>
                              ${spaceRow}
                            </tr>

                            
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.bodyTarget.innerHTML = html)
    }).then(() => {
      controller.mainCardTarget.style.height = ($(window).height() * 0.55) + "px"
      console.log(controller.current_clinic)
    })
  }


  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }
}