import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["viewPatientCard", "viewTitle", "viewPatientCardBody", "principalShow", "contactShow", "principalTab", "infoTab", "contactTab", "healthcareTab",
                    "viewPatientCardFooter", "infoShow"]

  connect() {
    this.controllerName = `operations--products--bookings--patients--show`
    this.doViewHtml()
  }

  doViewHtml() {
    var html = `<div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="viewPatientCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 py-3" style="font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="viewTitle">Nenhum Paciente Selecionado</h6>
                  </div>
                  <div class="card-body py-0 px-2" style="overflow:auto;" data-${this.controllerName}-target="viewPatientCardBody" id="cardContactBody">
                  </div>
                </div>`

    this.getControllerByIdentifier("operations--products--bookings--patients--dashboard").viewTarget.innerHTML = html
  }

  doShowHtml() {
    var html = `<ul class="nav nav-justified nav-tabs" role="tablist">
                  <li class="nav-item" data-${this.controllerName}-target="principalTab">
                    <a data-action="click->${this.controllerName}#showPrincipal" aria-controls="principal" aria-selected="true" class="f-065 nav-link nav-dark active" data-toggle="tab" href="#principal" id="principal-tab" role="tab">Principal</a>
                  </li>
                  <li class="nav-item" data-${this.controllerName}-target="infoTab">
                    <a data-action="click->${this.controllerName}#showInfo" aria-controls="info" aria-selected="true" class="f-065 nav-link nav-dark" data-toggle="tab" href="#info" id="info-tab" role="tab">Informações</a>
                  </li>
                  <li class="nav-item" data-${this.controllerName}-target="contactTab">
                    <a data-action="click->${this.controllerName}#showContact" aria-controls="contact" aria-selected="true" class="f-065 nav-link nav-dark" data-toggle="tab" href="#contact" id="contact-tab" role="tab">Contatos</a>
                  </li>
                  <li class="nav-item" data-${this.controllerName}-target="healthcareTab">
                    <a aria-controls="healthcare" aria-selected="true" class="f-065 nav-link nav-dark disabled" data-toggle="tab" href="#healthcare" id="healthcare-tab" role="tab"><s>Operadora</s></a>
                  </li>
                </ul>
                <div class="tab-content">
                  <div aria-labelledby="principal-tab" class="tab-pane fade show active" role="tabpanel" id="principal"><div class="list-group" data-${this.controllerName}-target="principalShow"></div></div>
                  <div aria-labelledby="info-tab" class="tab-pane fade" role="tabpanel" id="info"><div class="list-group" data-${this.controllerName}-target="infoShow"></div></div>
                  <div aria-labelledby="contact-tab" class="tab-pane fade" role="tabpanel" id="contact"><div class="list-group" data-${this.controllerName}-target="contactShow"></div></div>
                  <div aria-labelledby="healthcare-tab" class="tab-pane fade" role="tabpanel" id="healthcare"><div class="list-group" data-controller="users--profile--healthcare22" data-users--profile--healthcare-target="card"></div></div>
                </div>`

    this.viewTitleTarget.innerText = this.application.current_patient.account_name
    var controller = this
    new Promise(function (resolve) {
      resolve(controller.viewPatientCardBodyTarget.innerHTML = html)
    }).then(() => {
      controller.viewPatientCardTarget.style.height = ($(window).height() * 0.5) + "px"
      controller.showPrincipal()
      if (!controller.application.permissions.medbooking_patient_infos.can_index) {
        controller.infoTabTarget.children[0].classList.add("disabled")
        controller.infoTabTarget.children[0].innerHTML = `<s>Informações</s>`
      }
      if (!controller.application.permissions.medbooking_patient_contacts.can_index) {
        controller.contactTabTarget.children[0].classList.add("disabled")
        controller.contactTabTarget.children[0].innerHTML = `<s>Contatos</s>`
      }
      // if (!controller.application.permissions.medbooking_patient_healthcares.can_index) {
      //   controller.healthcareTabTarget.children[0].classList.add("disabled")
      //   controller.healthcareTabTarget.children[0].innerHTML = `<s>Operadora</s>`
      // }
    })
  }

  showPrincipal() {
    var html = `<div data-controller="operations--products--bookings--patients--principal" data-operations--products--bookings--patients--principal-target="main"></div>`
    $(".nav-tabs-indicator").addClass("nav-dark")
    this.principalShowTarget.innerHTML = html
  }

  showInfo() {
    var html = `<div data-controller="operations--products--bookings--patients--info" data-operations--products--bookings--patients--info-target="main"></div>`
    if (this.application.permissions.medbooking_patient_infos.can_index) {
      $(".nav-tabs-indicator").addClass("nav-dark")
      this.infoShowTarget.innerHTML = html
    }
  }

  showContact() {
    var html = `<div data-controller="operations--products--bookings--patients--contact" data-operations--products--bookings--patients--contact-target="main"></div>`
    if (this.application.permissions.medbooking_patient_contacts.can_index) {
      $(".nav-tabs-indicator").addClass("nav-dark")
      this.contactShowTarget.innerHTML = html
    }
  }


  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["viewPatientCard"]
    var identifier = "operations--products--bookings--patients--show"
    this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
