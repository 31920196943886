import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["formCard", "formTitle", "formCardBody", "saveBtn", "setupCheckbox", "recurringCheckbox", "onboardKind",
                    "statusDropdown", "statusDropdownBtn", "statusInput", "statusList",
                    "kindDropdown", "kindDropdownBtn", "kindInput", "kindList", "productAccrualDiv",
                    "productNameDropdown", "productNameDropdownBtn", "productNameInput", "productNameList",
                    "productKindDropdown", "productKindDropdownBtn", "productKindInput", "productKindList",
                    "productServiceDropdown", "productServiceDropdownBtn", "productServiceInput", "productServiceList",
                    "productAccrualDropdown", "productAccrualDropdownBtn", "productAccrualInput", "productAccrualList",
                    "productPlanDropdown", "productPlanDropdownBtn", "productPlanInput", "productPlanList", "productPlanDiv"]


  connect() {
    this.controllerName = `operations--onboards--entities--save`
  }

  disconnect() {
    this.stopRefreshing()
  }

  cancelSave() {
    this.stopRefreshing()
    this.getControllerByIdentifier("operations--onboards--entities--index").clearList()
  }

  saveOnboard() {
    this.send_data = { current_user: {}, onboard: {} }
    
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()
    this.saveBtnTarget.disabled = true
    this.stopRefreshing()

    if (this.actionMode == `edit`) {
      this.send_data.onboard.id = this.current_onboard.id
    }
    this.send_data.onboard.product_name = this.productNameInputTarget.dataset.productName
    this.send_data.onboard.product_service = this.productServiceInputTarget.dataset.productService
    this.send_data.onboard.product_kind = this.productKindInputTarget.dataset.productKind
    this.send_data.onboard.product_accrual = this.productAccrualInputTarget.dataset.productAccrual
    this.send_data.onboard.product_plan = this.productPlanInputTarget.dataset.productPlan
    this.send_data.onboard.kind = this.onboardKindTarget.dataset.kind

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = this.permission

    this.requestSave()
  }

  requestSave() {
    if (this.actionMode == `new`) {
      var url = "/operations/onboards/entities/create"
      var method = "POST"
    } else if (this.actionMode == `edit`) {
      var url = "/operations/onboards/entities/update"
      var method = "PUT"
    }

    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        var onboard = response.data.cln
        controller.application.onboards.forEach((element, i) => {
          if (element.id == onboard.id) {
            controller.application.onboards.splice(controller.application.onboards.indexOf(element), 1, onboard)
          }
        })

        controller.cancelSave()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  doFormHtml() {
    this.stopRefreshing()

    if (this.application.permissions[this.permission].can_create) {
      var saveBtnHtml = `<button disabled type="button" class="btn btn-primary btn-sm py-2 btn-table f-065" data-action="click->${this.controllerName}#saveOnboard" data-${this.controllerName}-target="saveBtn">Salvar</button>`
    } else {
      var saveBtnHtml = ``
    }

    var deleteBtnHtml = ``

    var partiesName = ``
    this.current_onboard.parties.forEach(party => {
      partiesName += `<p class="mb-0 f-075 f-bold" data-${this.controllerName}-target="onboardPartyName">${party.party_name}</p>`
    })

    var html = `<div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="formCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 py-3" style="font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="formTitle"></h6>
                  </div>

                  <div class="card-body py-0" style="overflow:auto;" data-${this.controllerName}-target="formCardBody">
  
                    <div class="row my-2 d-flex align-items-center">
                      <div class="col-3 px-2">
                        <span class="f-065">Partes: </span>
                      </div>
                      <div class="col-9 px-2">
                        ${partiesName}
                      </div>
                    </div>
                    <div class="row my-3 d-flex align-items-center">
                      <div class="col-6">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label>Produto</label>
                            <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="productNameDropdown">
                              <button disabled class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="productNameDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="productNameInput"></span></button>
                              <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                <ul class="ul-select" data-${this.controllerName}-target="productNameList" data-app--helpers--search-target="searchList">
                                  <li data-product-name="medclinic" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectProductNameEv" data-app--helpers--selector-target="select" class="li-selector dark f-065">PJ Médica</li>
                                  <li data-product-name="medbooking" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectProductNameEv" data-app--helpers--selector-target="select" class="li-selector dark f-065">Livro-Caixa</li>
                                  <li data-product-name="medfiling" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectProductNameEv" data-app--helpers--selector-target="select" class="li-selector dark f-065">Declaração IRPF</li>
                                  <li data-product-name="medreceivement" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectProductNameEv" data-app--helpers--selector-target="select" class="li-selector dark f-065">Gestão de Recebimentos</li>
                                  <li data-product-name="medbiling" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectProductNameEv" data-app--helpers--selector-target="select" class="li-selector dark f-065">Faturamento</li>
                                  <li data-product-name="medpayroll" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectProductNameEv" data-app--helpers--selector-target="select" class="li-selector dark f-065">Folha de Pagamento</li>
                                  <li data-product-name="medinsurance" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectProductNameEv" data-app--helpers--selector-target="select" class="li-selector dark f-065">Seguros</li>
                                  <li data-product-name="medprev" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectProductNameEv" data-app--helpers--selector-target="select" class="li-selector dark f-065">Previdência Privada</li>
                                  <li data-product-name="medloan" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectProductNameEv" data-app--helpers--selector-target="select" class="li-selector dark f-065">Crédito</li>
                                  <li data-product-name="medoutsourcing" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectProductNameEv" data-app--helpers--selector-target="select" class="li-selector dark f-065">BPO</li>
                                  <li data-product-name="medconsulting" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectProductNameEv" data-app--helpers--selector-target="select" class="li-selector dark f-065">Serviço Avulso</li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    
                    <div class="row">

                      <div class="col-6">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label>Tipo</label>
                            <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="productKindDropdown">
                              <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="productKindDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="productKindInput"></span></button>
                              <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                <ul class="ul-select" data-${this.controllerName}-target="productKindList" data-app--helpers--search-target="searchList"></ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label>Serviço</label>
                            <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="productServiceDropdown">
                              <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="productServiceDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="productServiceInput"></span></button>
                              <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                <ul class="ul-select" data-${this.controllerName}-target="productServiceList" data-app--helpers--search-target="searchList"></ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row my-2">
                      <div class="col-6 d-none" data-${this.controllerName}-target="productPlanDiv">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label>Plano</label>
                            <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="productPlanDropdown">
                              <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="productPlanDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="productPlanInput"></span></button>
                              <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                <ul class="ul-select" data-${this.controllerName}-target="productPlanList" data-app--helpers--search-target="searchList"></ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-6 d-none" data-${this.controllerName}-target="productAccrualDiv">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label>Competência</label>
                            <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="productAccrualDropdown">
                              <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="productAccrualDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="productAccrualInput"></span></button>
                              <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                <ul class="ul-select" data-${this.controllerName}-target="productAccrualList" data-app--helpers--search-target="searchList"></ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row my-3">
                      <div class="col-4 d-flex align-items-center">
                        <div class="custom-control custom-checkbox pr-0 pl-2 w-50 my-0 d-flex">
                          <input type="checkbox" class="custom-control-input" id="setupCheck" data-kind="setup" data-${this.controllerName}-target="setupCheckbox" data-action="click->${this.controllerName}#uniqKindEv">
                          <label class="custom-control-label f-065" for="setupCheck">Avulso</label>
                        </div>
                      </div>
                      <div class="col-4 d-flex align-items-center">
                        <div class="custom-control custom-checkbox pr-0 pl-2 w-50 my-0 d-flex">
                          <input type="checkbox" class="custom-control-input" id="recurringCheck" data-kind="recurring" data-${this.controllerName}-target="recurringCheckbox" data-action="click->${this.controllerName}#uniqKindEv">
                          <label class="custom-control-label f-065" for="recurringCheck">Recorrência</label>
                        </div>
                      </div>
                      <div class="col-3 d-flex align-items-center" data-${this.controllerName}-target="onboardKind" data-kind="none">
                      </div>
                    </div>
                  </div>

                  <div class="card-footer border-top text-right py-1">
                    <button type="button" class="btn btn-secondary btn-sm py-2 btn-table f-065" data-dismiss="modal" data-action="click->${this.controllerName}#cancelSave">Fechar</button>
                    ${deleteBtnHtml}
                    ${saveBtnHtml}
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("operations--onboards--entities--dashboard").viewTarget.innerHTML = html)
    }).then(() => {
      controller.formCardTarget.style.height = ($(window).height() * 0.6) + "px"


      if (controller.actionMode == `new`) {
        controller.formTitleTarget.innerText = `Novo Onboard`


      } else if (controller.actionMode == `edit`) {
        var onboard = this.current_onboard
        controller.product_name = onboard.product_name
        controller.selectProductName()

        controller.formTitleTarget.innerHTML = `Editando Onboard`

        controller.productNameDropdownTarget.value = onboard.product_name
        controller.productNameInputTarget.dataset.productName = onboard.product_name
        controller.productNameInputTarget.innerText = onboard.product_name_pretty

        controller.productKindDropdownTarget.value = onboard.product_kind
        controller.productKindInputTarget.dataset.productKind = onboard.product_kind
        controller.productKindInputTarget.innerText = onboard.product_kind_pretty

        controller.productServiceDropdownTarget.value = onboard.product_service
        controller.productServiceInputTarget.dataset.productService = onboard.product_service
        controller.productServiceInputTarget.innerText = onboard.product_service_pretty

        controller.uniqKind(onboard.kind)

        if (onboard.product_name == `medfiling`) {
          controller.productAccrualDropdownTarget.value = onboard.product_accrual
          controller.productAccrualInputTarget.dataset.productAccrual = onboard.product_accrual
          controller.productAccrualInputTarget.innerText = onboard.product_accrual

          controller.productPlanDropdownTarget.value = onboard.product_plan
          controller.productPlanInputTarget.dataset.productPlan = onboard.product_plan
          controller.productPlanInputTarget.innerText = onboard.product_plan_pretty

          controller.productAccrualDivTarget.classList.remove(`d-none`)
          controller.productPlanDivTarget.classList.remove(`d-none`)
          controller.setProductAccrualList()
        } else {
          controller.productAccrualDivTarget.classList.add(`d-none`)
          controller.productPlanDivTarget.classList.add(`d-none`)
        }
      }

      controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
      controller.refreshSaveBtn()
    })

  }

  selectProductNameEv(ev) {
    this.product_name = ev.currentTarget.dataset.productName

    this.selectProductName()
  }

  selectProductName() {
    if (this.product_name == `medfiling`) {
      this.productAccrualDivTarget.classList.remove(`d-none`)
      this.productPlanDivTarget.classList.remove(`d-none`)
      this.setProductAccrualList()
      this.setProductPlanList()
    } else {
      this.productAccrualDivTarget.classList.add(`d-none`)
      this.productPlanDivTarget.classList.add(`d-none`)
    }

    this.listProductKinds()
    this.listProductServices()
  }

  listProductKinds() {
    var html = ``

    if (this.product_name == `medreturn`) {
      this.productKindDropdownBtnTarget.disabled = true
      this.productKindDropdownTarget.value = `receivement`
      this.productKindInputTarget.dataset.productKind = `receivement`
      this.productKindInputTarget.innerText = `Recebimento`
      html += `<li data-product-kind="receivement" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Recebimento</li>`
    } else if (this.product_name == `medfiling`) {
      this.productKindDropdownBtnTarget.disabled = true
      this.productKindDropdownTarget.value = `receivement`
      this.productKindInputTarget.dataset.productKind = `receivement`
      this.productKindInputTarget.innerText = `Recebimento`

      // this.productKindDropdownBtnTarget.disabled = false
      // this.productKindDropdownTarget.value = ``
      // this.productKindInputTarget.dataset.productKind = ``
      // this.productKindInputTarget.innerText = ``
    } else if (this.product_name == `medbooking`) {
      this.productKindDropdownBtnTarget.disabled = true
      this.productKindDropdownTarget.value = `practice`
      this.productKindInputTarget.dataset.productKind = `practice`
      this.productKindInputTarget.innerText = `Consultório`
      html += `<li data-product-kind="practice" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Consultório</li>`
    } else if (this.product_name == `medclinic`) {
      this.productKindDropdownBtnTarget.disabled = false
      this.productKindDropdownTarget.value = ``
      this.productKindInputTarget.dataset.productKind = ``
      this.productKindInputTarget.innerText = ``
      html += `<li data-product-kind="receivement" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Recebimento</li>`
      html += `<li data-product-kind="practice" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Consultório</li>`
    } else if (this.product_name == `medreceivement`) {
      this.productKindDropdownBtnTarget.disabled = true
      this.productKindDropdownTarget.value = `receivement`
      this.productKindInputTarget.dataset.productKind = `receivement`
      this.productKindInputTarget.innerText = `Recebimento`
      html += `<li data-product-kind="receivement" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Recebimento</li>`
    } else if (this.product_name == `medbiling`) {
      this.productKindDropdownBtnTarget.disabled = true
      this.productKindDropdownTarget.value = `practice`
      this.productKindInputTarget.dataset.productKind = `practice`
      this.productKindInputTarget.innerText = `Consultório`
      html += `<li data-product-kind="practice" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Consultório</li>`
    } else if (this.product_name == `medemployee`) {
      this.productKindDropdownBtnTarget.disabled = false
      this.productKindDropdownTarget.value = ``
      this.productKindInputTarget.dataset.productKind = ``
      this.productKindInputTarget.innerText = ``
      html += `<li data-product-kind="home" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">eSocial</li>`
      html += `<li data-product-kind="employee" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Funcionário</li>`
    } else if (this.product_name == `medconsulting`) {
      this.productKindDropdownBtnTarget.disabled = true
      this.productKindDropdownTarget.value = `none`
      this.productKindInputTarget.dataset.productKind = `none`
      this.productKindInputTarget.innerText = `Nenhum`
      html += `<li data-product-kind="none" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Nenhum</li>`
    } else if (this.product_name == `medprev`) {
      this.productKindDropdownBtnTarget.disabled = false
      this.productKindDropdownTarget.value = ``
      this.productKindInputTarget.dataset.productKind = ``
      this.productKindInputTarget.innerText = ``
      html += `<li data-product-kind="pgbl_prev" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">PGBL</li>`
      html += `<li data-product-kind="vgbl_prev" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">VGBL</li>`
    } else if (this.product_name == `medloan`) {
      this.productKindDropdownBtnTarget.disabled = false
      this.productKindDropdownTarget.value = ``
      this.productKindInputTarget.dataset.productKind = ``
      this.productKindInputTarget.innerText = ``
      html += `<li data-product-kind="alimony" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Mesada</li>`
      html += `<li data-product-kind="debt_refinancing" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Financiamento</li>`
      html += `<li data-product-kind="prepayment" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Antecipação</li>`
    } else if (this.product_name == `medoutsourcing`) {
      this.productKindDropdownBtnTarget.disabled = false
      this.productKindDropdownTarget.value = ``
      this.productKindInputTarget.dataset.productKind = ``
      this.productKindInputTarget.innerText = ``
      html += `<li data-product-kind="finance_outsourcing" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Financeiro</li>`
      chtml += `<li data-product-kind="virtual_office" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Escritório Virtual</li>`
    } else if (this.product_name == `medinsurance`) {
      this.productKindDropdownBtnTarget.disabled = false
      this.productKindDropdownTarget.value = ``
      this.productKindInputTarget.dataset.productKind = ``
      this.productKindInputTarget.innerText = ``
      html += `<li data-product-kind="life_insurance" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Vida</li>`
      html += `<li data-product-kind="income_insurance" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">DIT</li>`
      html += `<li data-product-kind="responsability_insurance" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">RCP</li>`
      html += `<li data-product-kind="health_insurance" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Saúde</li>`
    }

    this.productKindListTarget.innerHTML = html
    this.getControllerByIdentifier("app--helpers--forms").floatingLabel()
  }

  listProductServices() {
    var html = ``

    if (this.product_name == `medreturn`) {
      this.productServiceDropdownBtnTarget.disabled = true
      this.productServiceDropdownTarget.value = `opening`
      this.productServiceInputTarget.dataset.productService = `opening`
      this.productServiceInputTarget.innerText = `Abertura Planner`
      html += `<li data-product-service="opening" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Abertura</li>`
    } else if (this.product_name == `medfiling`) {
      this.productServiceDropdownBtnTarget.disabled = true
      this.productServiceDropdownTarget.value = `opening`
      this.productServiceInputTarget.dataset.productService = `opening`
      this.productServiceInputTarget.innerText = `Abertura Declaração IRPF`
      html += `<li data-product-service="opening" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Abertura</li>`
    } else if (this.product_name == `medbooking`) {
      this.productServiceDropdownBtnTarget.disabled = false
      this.productServiceDropdownTarget.value = ``
      this.productServiceInputTarget.dataset.productService = ``
      this.productServiceInputTarget.innerText = ``
      html += `<li data-product-service="opening" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Abertura</li>`
      html += `<li data-product-service="migration" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Migração</li>`
    } else if (this.product_name == `medclinic`) {
      this.productServiceDropdownBtnTarget.disabled = false
      this.productServiceDropdownTarget.value = ``
      this.productServiceInputTarget.dataset.productService = ``
      this.productServiceInputTarget.innerText = ``
      html += `<li data-product-service="opening" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Abertura</li>`
      html += `<li data-product-service="pos_opening" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Pós-Abertura</li>`
      html += `<li data-product-service="migration" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Migração</li>`
      html += `<li data-product-service="amendment" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Alteração</li>`
      // html += `<li data-product-service="regularization" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Regularização</li>`
    } else if (this.product_name == `medreceivement`) {
      this.productServiceDropdownBtnTarget.disabled = true
      this.productServiceDropdownTarget.value = `opening`
      this.productServiceInputTarget.dataset.productService = `opening`
      this.productServiceInputTarget.innerText = `Abertura Gestão de Recebimentos`
      html += `<li data-product-service="opening" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Abertura</li>`
    } else if (this.product_name == `medbiling`) {
      this.productServiceDropdownBtnTarget.disabled = true
      this.productServiceDropdownTarget.value = `opening`
      this.productServiceInputTarget.dataset.productService = `opening`
      this.productServiceInputTarget.innerText = `Abertura Faturamento`
      html += `<li data-product-service="opening" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Abertura</li>`
    } else if (this.product_name == `medemployee`) {
      this.productServiceDropdownBtnTarget.disabled = false
      this.productServiceDropdownTarget.value = ``
      this.productServiceInputTarget.dataset.productService = ``
      this.productServiceInputTarget.innerText = ``
      html += `<li data-product-service="opening" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Abertura</li>`
      html += `<li data-product-service="migration" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Migração</li>`
    } else if (this.product_name == `medconsulting`) {
      this.productServiceDropdownBtnTarget.disabled = true
      this.productServiceDropdownTarget.value = `separate`
      this.productServiceInputTarget.dataset.productService = `separate`
      this.productServiceInputTarget.innerText = `Avulso`
      html += `<li data-product-service="separate" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Avulso</li>`
    } else if (this.product_name == `medprev`) {
      this.productServiceDropdownBtnTarget.disabled = true
      this.productServiceDropdownTarget.value = `opening`
      this.productServiceInputTarget.dataset.productService = `opening`
      this.productServiceInputTarget.innerText = `Abertura`
      html += `<li data-product-service="opening" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Abertura</li>`
    } else if (this.product_name == `medinsurance`) {
      this.productServiceDropdownBtnTarget.disabled = true
      this.productServiceDropdownTarget.value = `opening`
      this.productServiceInputTarget.dataset.productService = `opening`
      this.productServiceInputTarget.innerText = `Abertura`
      html += `<li data-product-service="opening" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Abertura</li>`
    } else if (this.product_name == `medloan`) {
      this.productServiceDropdownBtnTarget.disabled = true
      this.productServiceDropdownTarget.value = `opening`
      this.productServiceInputTarget.dataset.productService = `opening`
      this.productServiceInputTarget.innerText = `Abertura`
      html += `<li data-product-service="opening" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Abertura</li>`
    } else if (this.product_name == `medoutsourcing`) {
      this.productServiceDropdownBtnTarget.disabled = true
      this.productServiceDropdownTarget.value = `opening`
      this.productServiceInputTarget.dataset.productService = `opening`
      this.productServiceInputTarget.innerText = `Abertura`
      html += `<li data-product-service="opening" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Abertura</li>`
    }

    this.productServiceListTarget.innerHTML = html
    this.getControllerByIdentifier("app--helpers--forms").floatingLabel()
  }

  setProductAccrualList() {
    var html = ``

    html += `<li data-product-accrual="2023" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">2023</li>`
    html += `<li data-product-accrual="2022" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">2022</li>`
    html += `<li data-product-accrual="2021" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">2021</li>`
    html += `<li data-product-accrual="2020" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">2020</li>`
    html += `<li data-product-accrual="2019" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">2019</li>`
    html += `<li data-product-accrual="2018" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">2018</li>`

    this.productAccrualListTarget.innerHTML = html
  }

  setProductPlanList() {
    var html = ``

    html += `<li data-product-plan="light" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Light</li>`
    html += `<li data-product-plan="pro" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Pro</li>`
    html += `<li data-product-plan="premium" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Premium</li>`

    this.productPlanListTarget.innerHTML = html
  }

  uniqKindEv(ev) {
    var kind = ev.currentTarget.dataset.kind

    this.uniqKind(kind)  
  }

  uniqKind(kind) {
    if (kind == `setup`) {
      this.onboardKindTarget.dataset.kind = `setup`
      this.setupCheckboxTarget.checked = true
      this.recurringCheckboxTarget.checked = false
    } else if (kind == `recurring`) {
      this.onboardKindTarget.dataset.kind = `recurring`
      this.setupCheckboxTarget.checked = false
      this.recurringCheckboxTarget.checked = true
    }
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0

      if (controller.hasProductNameInputTarget && controller.productNameInputTarget.innerText == ``) {
        len += 1
      }

      if (controller.hasProductPlanInputTarget && controller.productKindInputTarget.innerText == ``) {
        len += 1
      }

      if (controller.hasProductServiceInputTarget && controller.productServiceInputTarget.innerText == ``) {
        len += 1
      }

      if (!controller.productAccrualDivTarget.classList.contains(`d-none`)) {
        if (controller.hasProductAccrualInputTarget && controller.productAccrualInputTarget.innerText == ``) {
          len += 1
        }
      }

      if (!controller.productPlanDivTarget.classList.contains(`d-none`)) {
        if (controller.hasProductPlanInputTarget && controller.productPlanInputTarget.innerText == ``) {
          len += 1
        }
      }


      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

}
