import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "viewTitle", "subtitle", "ticketsInProcess", "ticketsFlow", "ticketsTotal", "cardFilter", "search", "searchInput"]

  connect() {
    this.controllerName = `commercial--sales--opportunities--main--dashboard`
    this.application.permissions = {}
    this.application.opportunities = []
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.cardLoader = this.getControllerByIdentifier("app--helpers--loaders").cardLoader()
    this.bodyCardLoader = this.getControllerByIdentifier("app--helpers--loaders").bodyCardLoader()
    this.setPageLoader()
  }

  doDealFlowDashboard() {
    var html = `<div data-controller="commercial--sales--opportunities--dealflow--dashboard">
                  <div class="row mb-3 d-flex align-items-center">
                    <div class="col-6">
                      <div class="media">
                        <i class="material-icons md-lg md-primary mr-2">description</i>
                        <div class="media-body">
                          <h5 class="mt-2" style="margin-bottom:0px;">Pipeline de Negócios MedCapital</h5>
                          <span class="text-black-secondary" data-commercial--sales--opportunities--dealflow--dashboard-target="subtitle"></span>
                        </div>
                      </div>
                    </div>
                    <div class="col-2 mx-auto" data-commercial--sales--opportunities--dealflow--dashboard-target="team"></div>
                    <div class="col-3 mx-auto" data-commercial--sales--opportunities--dealflow--dashboard-target="search"></div>
                    <div class="col-1 text-right" data-controller="commercial--sales--opportunities--dealflow--submenu" data-commercial--sales--opportunities--dealflow--submenu-target="main"></div>
                  </div>
                  <div class="my-2" data-commercial--sales--opportunities--dealflow--dashboard-target="main"></div>
                </div>`

    this.mainTarget.innerHTML = html
  }

  doIndexDashboard() {
    var html = `<div data-controller="commercial--sales--opportunities--index--dashboard">
                  <div class="row mb-3 d-flex align-items-center">
                    <div class="col-4">
                      <div class="media">
                        <i class="material-icons md-lg md-primary mr-2">description</i>
                        <div class="media-body">
                          <h5 class="mt-2" style="margin-bottom:0px;">Gestão de Negócios MedCapital</h5>
                          <span class="text-black-secondary" data-commercial--sales--opportunities--index--dashboard-target="subtitle"></span>
                        </div>
                      </div>
                    </div>
                    <div class="col-2 mx-auto" data-commercial--sales--opportunities--index--dashboard-target="team"></div>
                    <div class="col-6 text-right" data-controller="commercial--sales--opportunities--index--submenu" data-commercial--sales--opportunities--index--submenu-target="main"></div>
                  </div>
                  <div class="my-2" data-commercial--sales--opportunities--index--dashboard-target="main"></div>
                </div>`

    this.mainTarget.innerHTML = html
  }

  setPageLoader() {
    if (window.location.pathname == `/a/fluxo-prospeccao` || window.location.pathname == `/a/fluxo-fechamento`) {
      this.doDealFlowDashboard()
    } else if (window.location.pathname == `/a/oportunidades`) {
      this.doIndexDashboard()
    }
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    });
    return results
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
