import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["formCard", "formTitle", "formCardBody", "productInput", "startedAtInput", "nextBtn", "linkInput",
                    "amountInput", "saveProductBtn", "kindDropdown", "kindDropdownBtn", "kindInput", "kindList"]

  connect() {
    this.company = {}
    this.controllerName = `operations--products--clinics--regularizations--add--product`
  }

  previousStep() {
    this.stopRefreshing()
    this.getControllerByIdentifier("operations--products--clinics--regularizations--add--contacts").doContactFormHtml()
    this.getControllerByIdentifier("operations--products--clinics--regularizations--add--dashboard").current_step = 1
    this.getControllerByIdentifier("operations--products--clinics--regularizations--add--dashboard").setStep()
  }

  saveProduct() {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()
    this.stopProductRefreshing()
    this.saveProductBtnTarget.disabled = true
    this.send_data = { current_user: {}, company: {}, product: {} }

    this.company = this.getControllerByIdentifier("operations--products--clinics--regularizations--add--dashboard").company

    this.send_data.company.company_id = this.company.id
    this.send_data.product.company_id = this.company.id
    this.send_data.product.name = `medclinic`
    this.send_data.product.kind = this.kindInputTarget.dataset.kind
    this.send_data.product.status = `onboard`
    this.send_data.product.started_at = this.getControllerByIdentifier("app--helpers--date").transformAllKindDate(this.startedAtInputTarget.value)
    this.send_data.product.link = this.linkInputTarget.value.trim()

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = `medclinic_regularizations`

    this.requestSave()
  }

  requestSave() {
    var url = "/operations/products/entities/create"
    var method = "POST"

    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          var product = response.data.cln
          controller.application.clinics[controller.application.clinics.length] = product
          controller.saveProductBtnTarget.innerText = `Confirmado`
          controller.saveProductBtnTarget.disabled = true
          controller.productConfirmed = true
          // controller.getControllerByIdentifier("operations--products--clinics--regularizations--index--dashboard").doMainDashboard()
          controller.getControllerByIdentifier("operations--products--clinics--regularizations--add--dashboard").current_product = product
        } else {
          controller.saveProductBtnTarget.innerText = `Salvar`
          controller.saveProductBtnTarget.disabled = false
          controller.productConfirmed = false
        }
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  cancelSave() {
    var r = confirm(`Tem certeza que deseja encerrar?`)
    if (r) {
      this.getControllerByIdentifier("operations--products--clinics--regularizations--index--dashboard").doMainGrid()
    }
  }

  nextStep() {
    this.stopRefreshing()
    this.stopProductRefreshing()
    this.nextBtnTarget.disabled = true

    this.getControllerByIdentifier("operations--products--clinics--regularizations--add--regime").doRegimeFormHtml()
    var controller = this.getControllerByIdentifier("operations--products--clinics--regularizations--add--dashboard")
    controller.changeStep(`regime`)
  }

  doProductFormHtml() {
    var html = this.stopRefreshing()

    var html1 = `<div class="form-group form-valid-group">
                    <div class="floating-label floating-label-sm">
                      <label for="startedAtForm">Início Em</label>
                      <input aria-describedby="startedAtFormHelp" class="form-control form-valid-control" data-${this.controllerName}-target="startedAtInput" placeholder="Início Em" type="text" required>
                    </div>
                  </div>`

    var html = `<div class="col-6 mx-auto">
                  <div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="formCard" data-action="resize@window->${this.controllerName}#layout">
                    <div class="card-header d-flex align-items-center card-header-table-list f-065">
                      <h6 class="card-title display-4" style="padding:1rem;font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="formTitle">Criando Produto PJ Médica</h6>
                    </div>
                    <div class="card-body py-0" style="overflow:auto;" data-${this.controllerName}-target="formCardBody">
                      <div class="row my-2">
                        <div class="col-4">
                          <div class="form-group form-valid-group">
                            <div class="floating-label floating-label-sm">
                              <label for="productForm">Produto</label>
                              <input disabled aria-describedby="productFormHelp" class="form-control form-valid-control" data-${this.controllerName}-target="productInput" placeholder="Produto" type="text" required>
                            </div>
                          </div>
                        </div>
                        <div class="col-4">
                          <div class="form-group">
                            <div class="floating-label floating-label-sm">
                              <label>Tipo de Empresa</label>
                              <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="kindDropdown">
                                <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="kindDropdownBtn">
                                  <span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="kindInput"></span>
                                </button>
                                <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                  <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                  <ul class="ul-select" data-${this.controllerName}-target="kindList" data-app--helpers--search-target="searchList">
                                    <li data-kind="receivement" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Recebimento</li>
                                    <li data-kind="practice" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Consultório</li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>                          
                        </div>
                        <div class="col-4">
                          <div class="form-group">
                            <div class="floating-label floating-label-sm">
                              <label for="startedAtForm">Início de Responsabilidade</label>
                              <input aria-describedby="startedAtFormHelp" class="form-control" id="startedAtForm" data-action="blur->${this.controllerName}#dateMask keypress->${this.controllerName}#dateMask keyup->${this.controllerName}#dateValidate blur->${this.controllerName}#dateValidate" data-${this.controllerName}-target="startedAtInput" placeholder="DD/MM/AAAA" type="text" required>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row mt-2 mb-4">
                        <div class="col-12">
                          <div class="form-group">
                            <div class="floating-label floating-label-sm">
                              <label for="linkForm">Link Google Drive</label>
                              <input aria-describedby="linkFormHelp" class="form-control" id="linkForm" data-${this.controllerName}-target="linkInput" placeholder="Link Google Drive" type="text" required>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row mt-2 mb-4">
                        <div class="col-12 px-1 text-right">
                          <button type="button" class="btn btn-primary btn-sm py-2 f-065" data-action="click->${this.controllerName}#saveProduct" data-${this.controllerName}-target="saveProductBtn">Salvar</button>
                        </div>
                      </div>              
                    </div>
                    <div class="card-footer border-top text-right py-1">
                      <button type="button" class="btn btn-secondary btn-sm py-2 f-065" data-action="click->${this.controllerName}#cancelSave">Fechar</button>
                      <button type="button" class="btn btn-primary btn-sm py-2 f-065" data-action="click->operations--products--clinics--regularizations--add--dashboard#changeStep click->${this.controllerName}#nextStep" data-next="3" data-${this.controllerName}-target="nextBtn">Próximo</button>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("operations--products--clinics--regularizations--add--dashboard").formTarget.innerHTML = html)
    }).then(() => {
      controller.refreshSaveBtn()
      // controller.getControllerByIdentifier("app--helpers--pickdate").pickNormal($(this.startedAtInputTarget), { max: true, months: true, years: 60 })
      controller.formCardTarget.style.height = ($(window).height() * 0.4) + "px"
      controller.productInputTarget.value = `PJ Médica`

      var product_data = controller.getControllerByIdentifier("operations--products--clinics--regularizations--add--dashboard").product_data

      if (product_data) {
        if (product_data.product.amount != undefined) { controller.amountInputTarget.value = controller.getControllerByIdentifier("app--helpers--numbers").currencyMask(product_data.product.amount) }
        if (product_data.product.started_at != undefined) { controller.startedAtInputTarget.value = product_data.product.started_at }
      }

      controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
    })
  }

  dateMask(ev) {
    this.getControllerByIdentifier("app--helpers--numbers").changeNumberToDate(ev)
  }

  dateValidate(ev) {
    var cleanDate = this.getControllerByIdentifier("app--helpers--numbers").fromDateToNumber(ev.target.value)
    if ((ev.type == "keyup" && ev.shiftKey == false && cleanDate.length == 8) || ev.type == "blur" && cleanDate.length == 8) {
      this.checkDate = this.getControllerByIdentifier("app--helpers--date").checkDate(ev.target.value)
      if (this.checkDate.valid) {
        this.startedAtInputTarget.classList.add("f-normal")
        this.startedAtInputTarget.classList.remove("f-danger")
      } else {
        this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", this.checkDate.message, 3000)
        this.startedAtInputTarget.classList.add("f-danger")
        this.startedAtInputTarget.classList.remove("f-normal")
      }
    } else {
      this.startedAtInputTarget.classList.add("f-normal")
      this.startedAtInputTarget.classList.remove("f-danger")
    }
  }

  currencyMask(ev) {
    this.getControllerByIdentifier("app--helpers--numbers").changeNumberToCurrency(ev)
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshProductTimer = setInterval(function () {
      var len = 0

      if (controller.startedAtInputTarget.value == ``) {
        len += 1
      }

      if (len == 0) {
        controller.saveProductBtnTarget.disabled = false
      } else {
        controller.saveProductBtnTarget.disabled = true
      }
    }, 200);

    this.refreshTimer = setInterval(function () {
      var len = 0

      if (!controller.productConfirmed) {
        len += 1
      }

      if (len == 0) {
        controller.nextBtnTarget.disabled = false
      } else {
        controller.nextBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  stopProductRefreshing() {
    if (this.refreshProductTimer) {
      clearInterval(this.refreshProductTimer)
    }
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
