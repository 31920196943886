import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["mainCard", "bodyTable", "footerTable", "dateStatus", "saveBtn", "deleteBtn", "saveCard", "titleCard",
    "amount", "description", "date"]

  connect() {
    this.controllerName = `operations--products--tax-returns--achievements--transaction`
    
    if (this.application.achievements) {
      this.application.achievements.forEach(element => {
        if (element.date_id == this.application.current_date.id) {
          this.current_achievement = element
        }
      });
    } else {
      this.current_achievement = { total_work_incomes: 0, saving_rate_goal: 0, saving_rate_real: 0, saving_goal: 0, saving_real: 0 }
    }

    this.doViewHtml()
  }

  saveTransaction() {
    this.transaction.tax_return_id = this.application.tax_return.id
    this.transaction.date_id = this.application.current_date.id
    this.transaction.achievement_id = this.current_achievement.id
    this.transaction.goal_id = this.goal.id
    this.transaction.amount = this.getControllerByIdentifier("app--helpers--numbers").fromCurrencyToNumber(this.amountTarget.value)
    this.transaction.date = this.getControllerByIdentifier("app--helpers--date").transformFullDate(this.dateTarget.value)
    this.transaction.description = this.descriptionTarget.value

    var data = { transaction: this.transaction, current_user: { current_user_id: this.application.current_user.id } }

    this.requestSave(data)
  }

  requestSave(data) {
    const token = $('meta[name=csrf-token]').attr('content');
    if (this.actionMode == "edit") {
      var url = "/operations/products/tax_returns/goal_transactions/update"
      var method = "PUT"
    } else if (this.actionMode == "new") {
      var url = "/operations/products/tax_returns/goal_transactions/create"
      var method = "POST"
    }

    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          this.stopRefreshing()
          this.cancelSave()
          var goal = response.data.cln
          this.application.goals.forEach((element, i) => {
            if (element.id == goal.id) {
              this.application.goals.splice(this.application.goals.indexOf(element), 1, goal)
            }
          })
          this.getControllerByIdentifier("operations--products--tax-returns--achievements--index").doDataTable()
        } else {
          this.saveBtnTarget.disabled = false
          if (this.hasDeleteBtnTarget) {
            this.deleteBtnTarget.disabled = true
          }
        }
        this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", this.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  doFormHtml() {
    this.stopRefreshing()

    if (this.application.medreturn_goal_transactions.can_create) {
      var saveBtnHtml = `<button type="button" class="btn btn-primary btn-sm" data-action="click->${this.controllerName}#saveTransaction" data-${this.controllerName}-target="saveBtn">Salvar</button>`
    } else {
      var saveBtnHtml = ``
    }

    var deleteBtnHtml = ``


    var html = `<div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="saveCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 card-title-table-list" data-${this.controllerName}-target="titleCard"></h6>
                  </div>
                  <div class="card-body py-0">
                    <div class="row my-2 d-flex align-items-center">
                      <div class="col-6 pl-0 pr-2">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="amountForm">Valor</label>
                            <input aria-describedby="amountFormHelp" class="form-control form-valid-control" id="amountForm" data-${this.controllerName}-target="amount" data-action="blur->${this.controllerName}#currencyMask keypress->${this.controllerName}#currencyMask keyup->${this.controllerName}#currencyMask" placeholder="Valor" type="tel" required>
                          </div>
                        </div>
                      </div>
                      <div class="col-6 pl-2 pr-0">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="dateForm">Data Aporte</label>
                            <input aria-describedby="dateFormHelp" class="form-control form-valid-control" id="dateForm" data-${this.controllerName}-target="date" placeholder="Data Recebimento" type="text" required>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row my-2">
                      <div class="col-12 pl-0 pr-0">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="descriptionForm">Descrição Aporte</label>
                            <input aria-describedby="descriptionFormHelp" class="form-control form-valid-control" id="descriptionForm" data-${this.controllerName}-target="description" placeholder="Descrição Aporte" type="text" required>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer border-top text-right py-1">
                    <button type="button" class="btn btn-secondary btn-sm" data-dismiss="modal" data-action="click->${this.controllerName}#cancelSave">Fechar</button>
                    ${deleteBtnHtml}
                    ${saveBtnHtml}
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("operations--products--tax-returns--achievements--dashboard").viewTarget.innerHTML = html)
    }).then(() => {
      controller.getControllerByIdentifier("app--helpers--elements").tooltip()
      controller.saveCardTarget.style.height = ($(window).height() * 0.5) + "px"
      pickDateLimit($('#dateForm'), "yearly", this.application.current_date.year);
      if (controller.actionMode == "new") {
        controller.titleCardTarget.innerText = `Novo Aporte`
        controller.amountTarget.value = "R$ 0,00"
      } else if (controller.actionMode == "edit") {
        controller.titleCardTarget.innerText = `Editando Aporte`
        controller.descriptionTarget.value = controller.goal.name
        controller.amountTarget.value = controller.getControllerByIdentifier("app--helpers--numbers").currencyMask(parseInt(controller.goal.amount * 100))
        controller.dateTarget.value = controller.transaction.date_pretty
      }
      // controller.refreshSaveBtn()
      controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()

    })
  }

  doViewHtml() {
    var html = `<div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="viewGoalCard">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4" style="padding:1rem;font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="viewTitle">Nenhum Objetivo Selecionado</h6>
                  </div>
                </div>`

    this.getControllerByIdentifier("operations--products--tax-returns--achievements--dashboard").viewTarget.innerHTML = html
  }



  cpfMask(ev) {
    this.getControllerByIdentifier("app--helpers--numbers").changeNumberToCpf(ev)
  }

  currencyMask(ev) {
    this.getControllerByIdentifier("app--helpers--numbers").changeNumberToCurrency(ev)
  }

  cancelSave() {
    this.stopRefreshing()
    this.doViewHtml()
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0
      controller.validGroupTargets.forEach(element => {
        if (this.fromStringToBoolean(element.dataset.valid) == false) {
          len += 1
        }
      });
      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
