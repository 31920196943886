import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["modal", "bottom", "top", "list", "view", "mainCard", "iuguIdInput", "footerTable", "body", "loader", "mainRow"]

  connect() {
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.cardLoader = this.getControllerByIdentifier("app--helpers--loaders").cardLoader()
    this.controllerName = `financials--books--receivables--conciliations--modal--invoice`
  }

  open() {
    document.body.classList.add("modal-open");
    this.element.setAttribute("style", "display: block;");
    this.element.classList.add("show");
    const html = `<div class="modal-backdrop fade show"></div>`
    // document.body.insertAdjacentHTML("beforeend", html)
    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.doHTML()
    })
  }

  close() {
    if (this.getControllerByIdentifier(`financials--books--receivables--billings--index`)) {
      this.getControllerByIdentifier(`financials--books--receivables--billings--index`).doDataTable()
    } else if (this.getControllerByIdentifier(`financials--books--receivables--conciliations--billings`)) {
      this.getControllerByIdentifier(`financials--books--receivables--conciliations--billings`).doDataTable()
    } else if (this.getControllerByIdentifier(`financials--books--receivables--dashboard--billings`)) {
      this.getControllerByIdentifier(`financials--books--receivables--dashboard--billings`).doDataTable()
    } else if (this.getControllerByIdentifier(`financials--books--receivables--liquidations--billings`)) {
      this.getControllerByIdentifier(`financials--books--receivables--liquidations--billings`).doDataTable()
    }

    document.body.classList.remove("modal-open");
    this.element.removeAttribute("style");
    this.element.classList.remove("show");
    document.getElementsByClassName("modal-backdrop")[0].remove();
    this.modalTarget.remove()
  }

  doHTML() {

    var html = `<div class="card" style="width:100%;display:relative;" data-controller="app--helpers--search" data-${this.controllerName}-target="mainCard">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 card-title-table-list">Buscar Fatura do Iugu</h6>
                    <div class="card-actions ml-auto py-0 mc-tooltip">
                      <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-action="click->${this.controllerName}#close" type="button">
                        <span class="material-icons md-dark">clear</span>
                      </button>
                      <span class="mc-tooltiptext">Fechar</span>
                    </div>
                  </div>
                  <div class="card-body py-0" style="overflow:auto;">
                    <div class="row d-flex align-items-center">
                      <div class="col-6 mt-1">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="iuguIdForm">Iugu ID</label>
                            <input aria-describedby="iuguIdFormHelp" class="form-control" id="iuguIdForm" data-${this.controllerName}-target="iuguIdInput" placeholder="Iugu ID" type="text" required>
                          </div>
                        </div>
                      </div>
                      <div class="col-4 mt-1">
                        Meds
                      </div>
                      <div class="col-2 mt-1">
                        <button type="button" class="btn btn-primary btn-sm py-2 btn-table f-065" data-action="click->${this.controllerName}#readInvoice" data-${this.controllerName}-target="saveBtn">Buscar Fatura</button>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-6 mt-1" data-${this.controllerBilling}-target="info" style="overflow:auto;"></div>
                      <div class="col-6 mt-1" data-${this.controllerBilling}-target="payment" style="overflow:auto;"></div>
                    </div>
                  </div>
                  <div class="card-footer py-0" data-${this.controllerName}-target="footerTable"></div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.bodyTarget.innerHTML = html)
    }).then(() => {
      controller.mainCardTarget.style.height = ($(window).height() * 0.8) + "px"
    })
  }

  readInvoice() {

    var iuguIdInput = this.iuguIdInputTarget.value

    if (iuguIdInput) {

      this.send_data = { billing: {}, current_user: {} }

      this.send_data.billing.invoice_id_iugu = iuguIdInput

      this.send_data.current_user.current_user_id = this.application.current_user.id
      this.send_data.current_user.feature = `financial_receivable_billings`

      var url = `/financials/integrations/bankings/iugu/billings/read_by_invoice_id_iugu`
      this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal({ submessage: `Carregando Fatura` })

      const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
      var controller = this
      fetch(url, init)
        .then(response => response.json())
        .then(response => {
          console.log(response)

          if (response.process) {

            controller.current_invoice = response.data.cln
            
          }
          
          controller.doInvoiceHtml()
          controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
        })
        .catch(error => {
          controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
        })
    } else {
      alert(`Favor inserir o ID da Fatura do Iugu`)
    }

  }

  doInvoiceHtml() {
    console.log(this.current_invoice)
  }


  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }
}