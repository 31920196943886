import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "content", "goalDashboard", "taxFilingDashboard", "financialYear", "calendarYear", "8888", "9999"]

  connect() {
    this.controllerName = `operations--products--tax-filings--entities--show`
    this.time_in = Date.now()
    this.application.current_date = {}
    this.application.permissions = {}
    this.application.domain = {}
    this.application.tax_filing_token = location.pathname.split("/").pop()
    this.application.tax_filing_calculations = []
    this.application.journeys = []
    this.application.tickets = []
    this.application.agents = []
    this.application.users = []
    this.application.files = []
    this.application.messages = []
    this.application.notes = []
    this.application.summaries = []

    this.doTaxFilingGrid()

    // getCurrentUserPermissions
    // getTaxFiling
    // getAccountProduct
    // getTaxFilingDates
    // getTaxFilingCalculations
    // getAgents
    // fetchAllUsers
    // getJourneys
    // getTickets
    // getMessages
    // getNotes
    
  }

  changeYear() {
    if (this.application.current_date.defined) {
      this.financialYearTarget.innerText = this.application.current_date.year + 1
      this.calendarYearTarget.innerText = this.application.current_date.year
    }
  }

  doTaxFilingGrid() {
    var html = `<div class="row">
                  <div class="col-12 px-0" data-controller="operations--products--tax-filings--entities--dashboard
                                                            operations--products--tax-filings--files--dashboard
                                                            operations--products--tax-filings--uploads--dashboard
                                                            operations--products--tax-filings--journey--dashboard
                                                            operations--products--tax-filings--tickets--dashboard
                                                            operations--products--tax-filings--process--dashboard
                                                            operations--products--tax-filings--settings--dashboard
                                                            operations--products--tax-filings--chat--dashboard
                                                            operations--products--tax-filings--notes--dashboard" data-${this.controllerName}-target="content"></div>
                </div>`

    var controller = this
    this.mainTarget.style.height = ""
    new Promise(function (resolve) {
      resolve(controller.mainTarget.innerHTML = html)
    }).then(() => {
      controller.getControllerByIdentifier("operations--products--tax-filings--entities--dashboard").doTaxFilingDashboard()
      controller.getCurrentUserPermissions()
    })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  getTaxFiling() {
    var data = { product: { token: this.application.tax_filing_token, name: "medfiling" }, current_user: { current_user_id: this.application.current_user_id } }
    const url = "/operations/products/entities/read"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.tax_filing = response.data.cln
          controller.application.domain_type = "Declaração de Ajuste"
          controller.application.domain.type = "Declaração de Ajuste"
          controller.application.domain.id = controller.application.tax_filing.id
          setTimeout(() => {
            controller.getControllerByIdentifier(`app--navigation--desktop--breadcrumb`).breadcrumbNameTarget.innerText = controller.application.tax_filing.account_name
          }, 500)
          controller.getAccountProduct()
        } else {
          controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
        }
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  getAccountProduct() {
    var data = { account: { account_id: this.application.tax_filing.account_id }, current_user: { current_user_id: this.application.current_user.id, feature: `operation_account_products` } }
    const url = "/operations/accounts/products/read_with_account"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.products = response.data.cln
          controller.getTaxFilingDates()
        } else {
          controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
        }
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  getTaxFilingDates() {
    var data = { date: { tax_filing_id: this.application.tax_filing.id }, current_user: { current_user_id: this.application.current_user_id } }
    const url = "/operations/products/tax_filings/dates/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.dates = response.data.cln
          if (controller.application.dates.length > 0) {
            controller.application.current_date = response.data.cln[0]
            if (controller.application.current_date.open == false) {
              controller.application.current_date.not_defined = true
              controller.application.current_date.message = `O Ano-Exercício ${controller.application.current_date.year + 1} está fechado.`
            }
          } else {
            controller.application.current_date = { open: false, defined: false, message: `Favor adicionar as Competências`, year: `Não Definido`, name: `Favor adicionar as Competências` }
            controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(`danger`,`Favor adicionar as Competências`, 2000)
          }
          controller.getControllerByIdentifier("operations--products--tax-filings--entities--dates").doCalendarHTML()
          controller.getTaxFilingCalculations()
        } else {
          controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
        }
        controller.getControllerByIdentifier("operations--products--tax-filings--entities--broadcast").getRoom()
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  getTaxFilingCalculations() {
    var data = { calculation: { tax_filing_id: this.application.tax_filing.id }, current_user: { current_user_id: this.application.current_user_id } }
    const url = "/operations/products/tax_filings/calculations/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.tax_filing_calculations = response.data.cln
          controller.getControllerByIdentifier("operations--products--tax-filings--entities--dashboard").setCurrentCalculation()
          // controller.getControllerByIdentifier("operations--products--tax-filings--entities--dashboard").doCalculationDashboardHtml()
        } else {
          controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
        }
        controller.getAgents()
        controller.getFiles()
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  getAgents() {
    var data = { agent: { tax_filing_id: this.application.tax_filing.id }, current_user: { current_user_id: this.application.current_user.id } }
    const url = "/operations/products/tax_filings/agents/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.agents = response.data.cln
          if (controller.application.current_date) {
            controller.application.agents.forEach(element => {
              if (element.date_id == controller.application.current_date.id) {
                controller.application.current_agent = element
              }
            })
          }
          controller.getJourneys()
          if (controller.application.permissions.medfiling.can_manage) {
            controller.getTaxFilingTeam()
          }
        } else {
          controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
        }
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  getJourneys() {
    var data = { journey: { tax_filing_id: this.application.tax_filing.id }, current_user: { current_user_id: this.application.current_user.id } }
    const url = "/operations/products/tax_filings/journeys/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.journeys = response.data.cln
        } else {
          controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
        }
        controller.getControllerByIdentifier("operations--products--tax-filings--entities--dashboard").doJourneyDashboardHtml()
        controller.getTickets()
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  getTickets() {
    var data = { ticket: { board_id: this.application.tax_filing.id, board_type: "operation_products" }, current_user: { current_user_id: this.application.current_user.id } }
    const url = "/operations/products/tax_filings/tickets/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.tickets = response.data.cln
        } else {
          controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
        }
        controller.getControllerByIdentifier("operations--products--tax-filings--entities--dashboard").doTicketDashboardHtml()
        controller.getMessages()
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  getFiles() {
    var data = { file: { tax_filing_id: this.application.tax_filing.id  }, current_user: { current_user_id: this.application.current_user.id } }
    const url = "/operations/products/tax_filings/files/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.files = response.data.cln
        } else {
          controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
        }
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  getSummaries() {
    var data = { summary: { tax_filing_id: this.application.tax_filing.id }, current_user: { current_user_id: this.application.current_user.id } }
    const url = "/operations/products/tax_filings/summaries/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.summaries = response.data.cln
        } else {
          controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
        }
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  getMessages() {
    var data = { room: { obj_id: this.application.tax_filing.id, obj_type: `operation_products`, room_type: `chat` }, current_user: { current_user_id: this.application.current_user.id } }
    const url = "/operations/products/tax_filings/messages/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.messages = response.data.cln
        } else {
          controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
        }
        controller.getNotes()
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  getNotes() {
    var data = { note: { domain_id: this.application.tax_filing.id, domain_type: `operation_products` }, current_user: { current_user_id: this.application.current_user.id } }
    const url = "/operations/products/tax_filings/notes/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.notes = response.data.cln
        } else {
          controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
        }
        controller.getSummaries()
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  fetchAllUsers() {
    const url = "/users/list_team"
    const data = { user: { account_kind: "team" }, current_user: { current_user_id: this.application.current_user.id } }
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.application.users = response.data.cln
      })
  }

  getTaxFilingTeam() {
    const data = { date: { date: new Date() }, current_user: { current_user_id: this.application.current_user.id } }
    const url = "/operations/products/config/teams/list_tax_filing_team"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.application.tax_filing_team = response.data.cln
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  getCurrentUserPermissions() {
    const featureNames = ["medfiling", "medfiling_dates", "medfiling_calculations", "medfiling_files", "medfiling_notes", "medfiling_journeys",
                          "medfiling_tickets", "medfiling_agents", "medfiling_messages", "medfiling_comments"]
    const data = { permission: { features: featureNames }, current_user: { current_user_id: this.application.current_user_id } }
    const url = "/users/permissions/list_by_features"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        response.data.cln.forEach(permission => {
          controller.application.permissions[permission.name] = permission.data
        });
        controller.getTaxFiling()
        controller.getControllerByIdentifier("operations--products--tax-filings--entities--submenu").doSubmenuHtml()
      })
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
