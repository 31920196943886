import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "content", "boardName", "boardDashboard", "printElement", "printCardElement", "7777", "8888", "9999"]

  connect() {
    this.controllerName = `users--works--meetings--boards--show`
    this.account_kind = this.application.current_user.account_kind
    this.time_in = Date.now()
    this.application.permissions = {}
    this.application.domain = {}
    this.application.board_slug = location.pathname.split("/").pop()
    this.application.board = {}
    this.application.weeklies = []
    this.application.topics = []
    this.application.tickets = []
    this.application.notes = []
    
    this.doBoardGrid()

    // getCurrentUserPermissions
    // getBoard
  }

  doBoardGrid() {
    var html = `<div class="row">
                  <div class="col-12 px-0" data-controller="users--works--meetings--boards--dashboard" data-${this.controllerName}-target="content"></div>
                </div>`

    var controller = this
    this.mainTarget.style.height = ""
    new Promise(function (resolve) {
      resolve(controller.mainTarget.innerHTML = html)
    }).then(() => {
      controller.getCurrentUserPermissions()
    })
  }

  finishLoadPage() {
    this.getControllerByIdentifier(`app--navigation--desktop--breadcrumb`).breadcrumbNameTarget.innerText = `${this.application.board.leader_name} | ${this.application.board.team_name}`
    this.getControllerByIdentifier(`users--works--meetings--boards--dashboard`).setPageTitle()
    this.getControllerByIdentifier(`users--works--meetings--boards--dashboard`).setWeeklies()
    this.getControllerByIdentifier(`users--works--meetings--boards--topics`).doTopicHtml()
    this.getControllerByIdentifier(`users--works--meetings--boards--notes`).setNotes()
    this.getControllerByIdentifier(`users--works--meetings--boards--tickets`).doDataTable()
    if (this.application.current_weekly) {
      this.application.current_weekly.data_loaded = true
    }


    var response = { type: `success`, message: `Página carregada com sucesso!` }
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).closeLoadModal()
    this.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
  }

  getNotes() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Anotações`, value: 90 })

    var data = { note: { domain_id: this.application.current_weekly.id, domain_type: `work_meetings` }, current_user: { current_user_id: this.application.current_user.id, feature: `work_meeting_notes` } }
    const url = "/users/accounts/notes/list_by_domain"

    // var data = { note: { domain_id: this.application.board.id, domain_type: "work_meetings" }, current_user: { current_user_id: this.application.current_user.id, feature: `work_meeting_boards` } }
    // const url = "/users/works/meetings/notes/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        response.data.cln.forEach(element => {
          controller.application.notes[controller.application.notes.length] = element
        })
        
        controller.finishLoadPage()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getTickets() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Tickets`, value: 75 })

    var data = { ticket: { board_id: this.application.current_weekly.id, board_type: "work_meetings" }, current_user: { current_user_id: this.application.current_user.id, feature: `work_meeting_tickets` } }
    const url = "/users/works/activities/tickets/list_by_board"

    // var data = { ticket: { board_id: this.application.board.id, board_type: "work_meetings" }, current_user: { current_user_id: this.application.current_user.id, feature: `work_meeting_boards` } }
    // const url = "/users/works/meetings/tickets/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        response.data.cln.forEach(element => {
          controller.application.tickets[controller.application.tickets.length] = element
        })
        
        controller.getNotes()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  loadDateData() {
    if (this.application.current_weekly && this.application.current_weekly.data_loaded == false) {
      if (!this.getControllerByIdentifier("app--helpers--page-load")) {
        this.getControllerByIdentifier(`app--helpers--pre-page-load`).openLoadModal({ submessage: `Carregando Informações do Semanal ${this.application.current_weekly.name}` })
      }
      this.getTickets()
    } else {
      this.finishLoadPage()
      // this.getControllerByIdentifier("operations--products--clinics--financials--show--dates").setCurrents()
      // this.getControllerByIdentifier("operations--products--clinics--financials--show--dates").runDashboardsHtml()
    }
  }

  getTopics() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Tópicos`, value: 65 })

    var data = { topic: { board_id: this.application.board.id }, current_user: { current_user_id: this.application.current_user.id, feature: `work_meeting_topics` } }
    const url = "/users/works/meetings/topics/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.topics = response.data.cln
        }
        
        controller.loadDateData()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getWeeklies() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Semanais`, value: 55 })

    var data = { weekly: { board_id: this.application.board.id }, current_user: { current_user_id: this.application.current_user.id, feature: `work_meeting_weeklies` } }
    const url = "/users/works/meetings/weeklies/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.weeklies = response.data.cln
          controller.getControllerByIdentifier(`users--works--meetings--boards--dashboard`).setCurrentWeekly()
        }
        
        controller.getTopics()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getRoom() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Canal de Transmissão`, value: 50 })
    var controllerBroadcast = this.getControllerByIdentifier(`users--works--meetings--boards--broadcast`)

    var data = { board: { id: this.application.board.id }, current_user: { current_user_id: this.application.current_user.id, feature: `work_meeting_boards` } }
    const url = "/users/works/meetings/boards/get_room"

    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.rooms[controller.application.rooms.length] = controllerBroadcast.identifier
          controllerBroadcast.update_room = response.data.cln
          controllerBroadcast.broadcastUpdateChannel()
        }
        
        controller.getWeeklies()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getBoard() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Quadro de Semanais`, value: 30 })

    var data = { board: { slug: this.application.board_slug }, current_user: { current_user_id: this.application.current_user.id, feature: `work_meeting_boards` } }
    const url = "/users/works/meetings/boards/read"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.board = response.data.cln
        }
        
        controller.getRoom()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getCurrentUserPermissions() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).openLoadModal({ submessage: `Carregando Permissões` })

    const data = { permissions: { subdomain: `work_meetings` }, current_user: { current_user_id: this.application.current_user.id, feature: `work_meeting_permissions`, account_kind: this.application.current_user.account_kind } }
    const url = "/users/permissions/policies/list_by_domain"

    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        response.data.cln.forEach(permission => {
          controller.application.permissions[permission.name] = permission.data
        })

        controller.getBoard()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
        window.location.reload()
      })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }
}