import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["list", "view", "mainCard", "bodyTable", "footerTable", "checkboxShow", "eventLeadName", "8888", "9999"]

  connect() {
    this.controllerName = `commercial--marketing--events--index--list`
    this.itemsOnPage = 100
    this.pageNumber = 0
    this.numberPages = []
    this.tableCols = 9
    this.sort = { mode: `desc`, field: `date_to_time` }
    this.hasShow = false
  }

  addEvent() {
    this.getControllerByIdentifier("commercial--marketing--events--index--add").actionMode = `new`
    this.getControllerByIdentifier("commercial--marketing--events--index--add").current_event = {}
    this.getControllerByIdentifier("commercial--marketing--events--index--add").doFormHtml()
  }

  editEvent(ev) {
    var id = ev.currentTarget.closest(".itemRow").dataset.id

    this.application.events.forEach(element => {
      if (element.id == id) {
        this.getControllerByIdentifier("commercial--marketing--events--index--add").actionMode = `edit`
        this.getControllerByIdentifier("commercial--marketing--events--index--add").current_event = element
        this.getControllerByIdentifier("commercial--marketing--events--index--add").doFormHtml()
      }
    })
  }

  showEvent(ev) {
    var slug = ev.currentTarget.closest(".itemRow").dataset.slug
    window.open(`/a/eventos/${slug}`, `_blank`)
  }

  sortTable(ev) {
    var mode = ev.currentTarget.dataset.mode
    var field = ev.currentTarget.dataset.field

    if (mode == `desc`) {
      ev.currentTarget.nextElementSibling.classList.remove("d-none")
      ev.currentTarget.classList.add("d-none")
    } else if (mode == `asc`) {
      ev.currentTarget.previousElementSibling.classList.remove("d-none")
      ev.currentTarget.classList.add("d-none")
    }

    this.sort.mode = mode
    this.sort.field = field
    this.doDataTable()
  }

  doIndexListHtml() {
    if (this.application.permissions[this.permission].can_create) {
      var addLine = `<span data-action="click->${this.controllerName}#addEvent" class="dropdown-item py-1 pointer dropdown-submenu-item">Adicionar Evento</span>`
    } else {
      var addLine = ``
    }

    var refreshLine = `<span data-action="click->${this.controllerName}#refreshEvents" class="dropdown-item py-1 pointer dropdown-submenu-item">Atualizar Eventos</span>`

    var clearBtnHtml = `<span class="mc-tooltip">
                          <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0 d-none" data-${this.controllerName}-target="clearListBtn" data-action="click->${this.controllerName}#clearList" type="button">
                            <span class="material-icons md-dark">clear</span>
                          </button>
                          <span class="mc-tooltiptext">Limpar</span>
                        </span>`

    var submenuHtml = `<div class="dropdown mc-tooltip" data-${this.controllerName}-target="optionBtn">
                        <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-toggle="dropdown" id="submenuMenuDrop" type="button"><span class="material-icons md-dark">more_vert</span></button>
                        <div aria-labelledby="submenuMenuDrop" class="dropdown-menu dropdown-menu-right menu" style="font-size:80%">
                          ${addLine}
                          ${refreshLine}
                        </div>
                        <span class="mc-tooltiptext">Opções</span>
                      </div>`

    var html = `<div class="card" style="width:100%;display:relative;" data-controller="app--helpers--search" data-${this.controllerName}-target="mainCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 card-title-table-list">Todos os Eventos</h6>
                    <input class="form-control f-070 w-50 mx-auto" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchTable" placeholder="Buscar ..." type="text">
                    <div class="card-actions ml-auto py-0">
                      ${clearBtnHtml}
                      ${submenuHtml}
                    </div>
                  </div>
                  <div class="card-body py-0" style="overflow:auto;">
                    <div class="row">
                      <div class="col p-0">
                        <table class="table table-sm table-hover" data-app--helpers--search-target="searchTable" style="font-size:80%;">
                          <thead>
                            <tr>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-25 align-middle">
                                Nome
                                <span class="material-icons md-sm md-dark" data-action="click->${this.controllerName}#sortTable" data-field="name" data-mode="desc">south</span>
                                <span class="material-icons md-sm md-dark d-none" data-action="click->${this.controllerName}#sortTable" data-field="name" data-mode="asc">north</span>
                              </th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-10 align-middle">
                                Data
                                <span class="material-icons md-sm md-dark" data-action="click->${this.controllerName}#sortTable" data-field="date_to_time" data-mode="desc">south</span>
                                <span class="material-icons md-sm md-dark d-none" data-action="click->${this.controllerName}#sortTable" data-field="date_to_time" data-mode="asc">north</span>
                              </th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-10 align-middle">Compartilhamento</th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-10 align-middle">Tipo</th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-15 align-middle" colspan="2">
                                Publicado
                                <span class="material-icons md-sm md-dark" data-action="click->${this.controllerName}#sortTable" data-field="published_at_to_time" data-mode="desc">south</span>
                                <span class="material-icons md-sm md-dark d-none" data-action="click->${this.controllerName}#sortTable" data-field="published_at_to_time" data-mode="asc">north</span>
                              </th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-10 align-middle text-center">Estágio</th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-10 align-middle text-center">Link Inscrições</th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-10 align-middle text-center">Link Ao Vivo</th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-5 align-middle text-center"></th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-5 align-middle text-center"></th>
                            </tr>
                          </thead>
                          <tbody data-${this.controllerName}-target="bodyTable">
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer py-0" data-${this.controllerName}-target="footerTable">
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("commercial--marketing--events--index--dashboard").contentTarget.innerHTML = html)
    }).then(() => {
      controller.mainCardTarget.style.height = ($(window).height() * 0.6) + "px"
      controller.bodyTableTarget.insertAdjacentHTML("beforeend", controller.getControllerByIdentifier("app--helpers--table").doTablePreloader(this.tableCols, 6))
      controller.doDataTable()
    })
  }

  doDataTable() {
    var current_events = this.application.events

    if (this.sort.mode == `asc`) {
      var events = this.getControllerByIdentifier("app--helpers--data").sortByKeyAsc(current_events, this.sort.field)
    } else if (this.sort.mode == `desc`) {
      var events = this.getControllerByIdentifier("app--helpers--data").sortByKeyDesc(current_events, this.sort.field)
    }

    if (events.length == 0) {
      var pagination = { itemsOnPage: 0, fromItem: 0, toItem: 0, totalItems: 0 }
      this.listData()
      this.doFooterTable(pagination)
    } else {
      var itemsOnPage = this.itemsOnPage
      var pageNumber = this.pageNumber
      var numberPages = this.numberPages
      var pages = []
      var itemLoop = Math.ceil(events.length / itemsOnPage)
      var x = 0
      var i = 1
      for (i; i < itemLoop + 1; i++) {
        var internPage = []
        for (x; x < itemsOnPage * i; x++) {
          if (events[x] !== undefined) {
            internPage[internPage.length] = events[x]
          }
        }
        pages[pages.length] = internPage
        numberPages[numberPages.length] = i - 1
      }

      var fromItem = itemsOnPage * pageNumber + 1
      var toItem = Math.min(itemsOnPage * (pageNumber + 1), (itemsOnPage * pageNumber) + pages[pageNumber].length)
      var pagination = { itemsOnPage: itemsOnPage, fromItem: fromItem, toItem: toItem, totalItems: events.length }
      this.listData(pages[pageNumber])
      this.doFooterTable(pagination)
    }
  }

  listData(data) {
    this.bodyTableTarget.innerHTML = ""
    if (data == undefined || data == [] || data.length == 0) {
      var noData = `</tr>
                      <td colspan="${this.tableCols}" class="p-3 align-middle text-center" style="font-size:100%;">
                        <span class="fa-stack fa-1x">
                          <i class="fas fa-list fa-stack-1x"></i>
                        </span>
                        <span class="f-075 align-middle">Não há Eventos</span>
                      </td>
                    </tr>`

      this.bodyTableTarget.innerHTML = noData
    } else {
      data.forEach(element => {
        this.bodyTableTarget.insertAdjacentHTML("beforeend", this.eventTablePartial(element, data.length))
      });
    }

    if (this.checked_id) {
      this.checkboxShowTargets.forEach(element => {
        if (element.id == `check-${this.checked_id}`) {
          element.checked = true
        }
      })
    }
  }

  eventTablePartial(element, length) {

    if (this.application.permissions.commercial_event_entities.can_update) {
      var edit = `<button data-action="click->${this.controllerName}#editEvent" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                    <span class="material-icons md-sm md-dark">edit</span>
                    <span class="mc-tooltiptext">Editar</span>
                  </button>`
    } else {
      var edit = ``
    }

    if (length == 1) {
      var tableRow = `<tr class="itemRow" data-id="${element.id}" data-slug="${element.slug}" style="height:50px;">`
    } else {
      var tableRow = `<tr class="itemRow" data-id="${element.id}" data-slug="${element.slug}">`
    }

    if (element.published) {
      var publishedText = `${element.published_at_full}`
      var publishedIcon = `<span class="mc-tooltip">
                            <span class="material-icons md-sm md-success default">done_all</span>
                            <span class="mc-tooltiptext">Publicado</span>
                          </span>`
    } else {
      var publishedText = `Evento não publicado`
      var publishedIcon = `<span class="mc-tooltip">
                            <span class="material-icons md-sm md-danger default">clear</span>
                            <span class="mc-tooltiptext">Não Publicado</span>
                          </span>`
    }

    var rowHtml = `${tableRow}
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle pointer" data-action="click->${this.controllerName}#showEvent">
                      <span class="mc-tooltip">
                        ${element.title}
                        <span class="mc-tooltiptext mc-tooltiptext-right">/${element.slug}</span>
                      </span>
                    </td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${element.date_pretty}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${element.sharing_pretty}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${element.kind_pretty}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${publishedIcon}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${publishedText}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${element.journey_pretty}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">
                      <p class="mb-0 f-065 mc-tooltip grab">
                        <span data-controller="app--helpers--copy" data-copy="${element.enrollment_link}" data-action="click->app--helpers--copy#copy"><span class="material-icons md-dark md-sm">ios_share</span></span>
                        <span class="mc-tooltiptext">Clique para Copiar</span>
                      </p>
                    </td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">
                      <p class="mb-0 f-065 mc-tooltip grab">
                        <span data-controller="app--helpers--copy" data-copy="${element.live_path}" data-action="click->app--helpers--copy#copy"><span class="material-icons md-dark md-sm">ios_share</span></span>
                        <span class="mc-tooltiptext">Clique para Copiar</span>
                      </p>
                    </td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${edit}</td>
                  </tr>`

    return rowHtml
  }

  itemsLength(ev) {
    this.itemsOnPage = ev.target.dataset.items
    this.pageNumber = 0
    this.numberPages = []
    this.doDataTable()
  }

  previousTablePage(ev) {
    if (this.numberPages.includes(this.pageNumber - 1)) {
      this.pageNumber -= 1
      this.doDataTable()
    }
  }

  nextTablePage(ev) {
    if (this.numberPages.includes(this.pageNumber + 1)) {
      this.pageNumber += 1
      this.doDataTable()
    }
  }

  doFooterTable(pagination) {
    var footerHtml = `<div class="card-actions align-items-center justify-content-end py-0 f-065">
                        <span class="align-self-center mb-1 mx-1 text-muted">Itens por Página:</span>
                        <div class="dropdown">
                          <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline dropdown-toggle f-065" data-toggle="dropdown" type="button">${pagination.itemsOnPage}</button>
                          <div class="dropdown-menu dropdown-menu-right menu">
                            <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="100">100</span>
                            <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="250">250</span>
                            <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="500">500</span>
                          </div>
                        </div>
                        <span class="align-self-center mb-1 mr-2 text-muted">${pagination.fromItem}-${pagination.toItem} de ${pagination.totalItems}</span>
                        <a class="btn btn-outline py-0" data-action="click->${this.controllerName}#previousTablePage"><i class="material-icons md-sm">chevron_left</i></a>
                        <a class="btn btn-outline py-0" data-action="click->${this.controllerName}#nextTablePage"><i class="material-icons md-sm">chevron_right</i></a>
                      </div>`

    this.footerTableTarget.innerHTML = footerHtml
  }

  cleanCheckBox() {
    this.checkboxShowTargets.forEach(element => {
      element.checked = false
    });
    this.checked_id = ``
  }

  refreshEvents() {
    this.getSquad()
  }

  getEvents() {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal({ message: `Em processamento, aguarde!` })

    const data = { event: { active: true, event_ids: this.application.event_ids }, current_user: { current_user_id: this.application.current_user.id, feature: `commercial_event_entities` } }
    const url = "/commercial/marketing/events/entities/list_by_ids"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.application.events = response.data.cln

        controller.doDataTable()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getSquad() {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal({ message: `Em processamento, aguarde!` })

    const data = { squad: { team_id: this.application.current_user.account_id }, current_user: { current_user_id: this.application.current_user.id, feature: `commercial_event_squads` } }
    const url = "/commercial/marketing/events/squads/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.squads = response.data.cln
          controller.application.event_ids = controller.mapElements(controller.application.squads, `event_id`)
          controller.getEvents()
        } else {
          controller.application.events = []
          controller.doDataTable()
        }

        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  doPreloaderHtml() {
    var html = `<div class="card" style="width:100%;display:relative;" data-controller="app--helpers--search" data-${this.controllerName}-target="mainCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 card-title-table-list">Todos os Eventos</h6>
                    <input class="form-control f-070 w-50 mx-auto" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchTable" placeholder="Buscar ..." type="text">
                  </div>
                  <div class="card-body py-0" style="overflow:auto;">
                    <div class="row">
                      <div class="col p-0">
                        <table class="table table-sm table-hover" data-app--helpers--search-target="searchTable" style="font-size:80%;">
                          <thead>
                            <tr>
                              <th colspan="${this.tableCols}" style="font-size:80%;" scope="col" class="pointer p-1 table-5 align-middle text-center"></th>
                            </tr>
                          </thead>
                          <tbody data-${this.controllerName}-target="bodyTable">
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer py-0" data-${this.controllerName}-target="footerTable">
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("commercial--marketing--events--index--dashboard").contentTarget.innerHTML = html)
    }).then(() => {
      controller.mainCardTarget.style.height = ($(window).height() * 0.6) + "px"
      controller.bodyTableTarget.insertAdjacentHTML("beforeend", controller.getControllerByIdentifier("app--helpers--table").doTablePreloader(this.tableCols, 6))
    })
  }

  // getEvents() {
  //   this.bodyTableTarget.innerHTML = this.getControllerByIdentifier("app--helpers--table").doTablePreloader(this.tableCols, 6)
  //   const data = { event: { active: true, event_ids: this.application.event_ids }, current_user: { current_user_id: this.application.current_user.id, feature: this.permission } }
  //   const url = "/commercial/marketing/events/entities/list_by_ids"
  //   const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
  //   var controller = this
  //   fetch(url, init)
  //     .then(response => response.json())
  //     .then(response => {
  //       controller.application.events = response.data.cln
  //       controller.doDataTable()
  //     })
  //     .catch(error => {
  //       controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
  //     })
  // }

  userCanUpdate(ticketId) {
    var ticket = {}
    this.application.in_process_tickets.forEach(element => {
      if (element.id == ticketId) {
        ticket = element
      }
    })
    var allowed = [ticket.owner_id, ticket.performer_id]
    if (allowed.includes(this.application.current_user.account_id) && this.stage != "done") {
      return true
    } else {
      return false
    }
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0
      controller.validGroupTargets.forEach(element => {
        if (this.fromStringToBoolean(element.dataset.valid) == false) {
          len += 1
        }
      });
      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  goToURL(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_self`)
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    })
    return results
  }

  // var controller = this
  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
