import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "objAttach"]

  connect() {
    this.controllerName = `operations--products--clinics--managements--emails--send-main-apuration`
    // this.setAttachments()
  }

  sendEmail() {
    this.send_data = { current_obj: {}, tracker: {}, current_user: {} }

    this.send_data.current_obj.id = this.current_obj.id
    this.send_data.current_obj.type = `clinic_fin_main_apurations`
    this.send_data.current_obj.message = `E-mail de Apuração com Guias de Impostos enviado para processamento!`
    this.send_data.current_obj.clinic_id = this.application.clinic.id
    
    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = `medclinic_financial_main_apurations`

    var receivers = []
    var objs = []
    this.current_receivers.forEach(receiver => {
      if (receiver.status && receiver.operational && this.sendController.nameTarget(`receiverCheckbox-${receiver.id}`).checked) {
        receivers[receivers.length] = receiver.id
      }
    })

    this.objAttachTargets.forEach(element => {
      if (element.checked) {
        objs[objs.length] = { obj_id: element.dataset.objId, obj_type: element.dataset.objType }
      }
    });

    this.send_data.current_obj.objs = objs
    this.send_data.current_obj.action = `send_main_apuration`

    this.send_data.tracker.receivers = receivers
    this.send_data.tracker.sender_id = this.application.current_user.account_id
    this.send_data.tracker.sender_name = `${this.application.current_user.name.split(" ").shift()} ${this.application.current_user.name.split(" ").pop()}`
    this.send_data.tracker.domain_id = this.current_clinic.id
    this.send_data.tracker.domain_type = `operation_products`

    var url = "/operations/products/clinics/managements/emails/send_apuration_emails"
    var method = "POST"

    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {

        controller.getControllerByIdentifier(`operations--products--clinics--managements--emails--pre-email`).closeEmailModal()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  setAttachments() {

    var current_taxes = []
    var attachsHtml = ``
    var taxesHtml = ``

    this.application.taxes.forEach(element => {
      if (element.date_id == this.application.current_date.id) {
        current_taxes[current_taxes.length] = element       
      }
    })

    var apuration = this.application.current_main_apuration
    if (apuration.has_main_pdf) {
      var attach = `<span class="mc-tooltip"><span class="material-icons md-sm md-success default help">done</span><span class="mc-tooltiptext">Tem Arquivo</span></span>`
    } else {
      var attach = `<span class="mc-tooltip"><span class="material-icons md-sm md-danger default help">clear</span><span class="mc-tooltiptext">Não Tem Arquivo</span></span>`
    }

    attachsHtml += `<div class="row mb-3 d-flex align-items-center">
                      <div class="col-10">
                        <div class="custom-control custom-checkbox custom-control-table pl-1 d-flex align-items-center">
                          <input type="checkbox" class="custom-control-input" data-obj-id="${apuration.id}" data-obj-type="clinic_fin_main_apurations" id="check-${apuration.id}" data-${this.controllerName}-target="apurationCheckbox-${apuration.id} objAttach">
                          <label class="custom-control-label f-065 pointer ml-3" for="check-${apuration.id}">Apuração da Empresa</label>
                        </div>
                      </div>
                      <div class="col-2 px-0">
                        ${attach}
                      </div>
                    </div>`

    current_taxes = this.getControllerByIdentifier("app--helpers--data").sortByKeyAsc(current_taxes, `kind_pretty`)
    current_taxes.forEach(tax => {

      var check = ``
      var attach = ``
      var tooltiptext = ``
      if (tax.has_bill) {
        tooltiptext += `Principal: ${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(tax.principal)} | `
        tooltiptext += `Multa: ${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(tax.interests)} | `
        tooltiptext += `Total: ${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(tax.total_amount)}`
        check += `<div class="custom-control custom-checkbox custom-control-table pl-1 d-flex align-items-center">
                    <input type="checkbox" class="custom-control-input" data-obj-id="${tax.id}" data-obj-type="clinic_fin_taxes" id="check-${tax.id}" data-${this.controllerName}-target="taxCheckbox-${tax.id} objAttach">
                    <label class="custom-control-label f-065 pointer ml-3" for="check-${tax.id}">
                      <span class="mc-tooltip pointer">
                        <span class="f-065">${tax.kind_pretty}</span>
                        <span class="mc-tooltiptext mc-tooltiptext-right">${tooltiptext}</span>
                      </span>
                    </label>
                  </div>`
        attach += `<span class="mc-tooltip"><span class="material-icons md-sm md-success default help">done</span><span class="mc-tooltiptext">Tem Arquivo</span></span>`
      } else {
        check += `<div class="custom-control custom-checkbox custom-control-table pl-1 d-flex align-items-center">
                    <input disabled type="checkbox" class="custom-control-input" data-obj-id="${tax.id}" data-obj-type="clinic_fin_taxes" id="check-${tax.id}" data-${this.controllerName}-target="taxCheckbox-${tax.id}">
                    <label class="custom-control-label f-065 pointer ml-3" for="check-${tax.id}">${tax.kind_pretty}</label>
                  </div>`
        attach += `<span class="mc-tooltip"><span class="material-icons md-sm md-danger default help">clear</span><span class="mc-tooltiptext">Não Tem Arquivo</span></span>`
      }

      taxesHtml += `<div class="row mb-3 d-flex align-items-center">
                      <div class="col-10 text-left">
                        ${check}
                      </div>
                      <div class="col-2 px-0">
                        ${attach}
                      </div>
                    </div>`
    })


    attachsHtml += taxesHtml

    this.mainTarget.innerHTML = attachsHtml
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

}