import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["formCard", "formTitle", "formCardBody", "nameInput",
                    "statusDropdown", "statusDropdownBtn", "statusInput", "statusList",
                    "positionDropdown", "positionDropdownBtn", "positionInput", "positionList",
                    "roleDropdown", "roleDropdownBtn", "roleInput", "roleList",
                    "kindDropdown", "kindDropdownBtn", "kindInput", "kindList",
                    "subkindDropdown", "subkindDropdownBtn", "subkindInput", "subkindList",
                    "medInput", "startedAtInput", "budgetInput", "medModal", "saveBtn", "nameModal",
                    "expensesInput", "compensationInput", "expensesDiv"]


  connect() {
    this.controllerName = `users--works--recruitments--vacancies--index--save`
    this.controllerNumber = this.getControllerByIdentifier(`app--helpers--numbers`)
  }

  disconnect() {
    this.stopRefreshing()
  }

  cancelSave() {
    this.stopRefreshing()
    this.getControllerByIdentifier(`users--works--recruitments--vacancies--index--list`).doIndexListHtml()
  }

  saveVacancy() {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal({ message: `Em processamento, aguarde!` })

    this.send_data = { current_user: {}, vacancy: {}, squad: {} }
    this.saveBtnTarget.disabled = true
    this.stopRefreshing()

    if (this.actionMode == `edit`) {
      this.send_data.vacancy.id = this.current_vacancy.id
    } else if (this.actionMode == `new`) {
      this.send_data.squad.team_id = this.application.current_user.account_id
      this.send_data.squad.team_name = this.application.current_user.name
      this.send_data.squad.role = `owner`
    }

    this.send_data.vacancy.name = this.nameInputTarget.value
    this.send_data.vacancy.started_at = this.startedAtInputTarget.value
    this.send_data.vacancy.med_id = this.medInputTarget.dataset.medId
    this.send_data.vacancy.med_name = this.medInputTarget.value
    this.send_data.vacancy.budget = this.controllerNumber.fromCurrencyToNumber(this.budgetInputTarget.value)
    this.send_data.vacancy.compensation = this.controllerNumber.fromCurrencyToNumber(this.compensationInputTarget.value)
    this.send_data.vacancy.expenses = this.controllerNumber.fromCurrencyToNumber(this.expensesInputTarget.value)
    this.send_data.vacancy.status = this.statusInputTarget.dataset.status
    this.send_data.vacancy.role = this.roleInputTarget.dataset.role
    this.send_data.vacancy.position = this.positionInputTarget.dataset.position
    this.send_data.vacancy.kind = this.kindInputTarget.dataset.kind
    this.send_data.vacancy.subkind = this.subkindInputTarget.dataset.subkind

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = this.permission

    this.requestSave()
  }

  destroyVacancy() {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal({ message: `Em processamento, aguarde!` })

    this.send_data = { current_user: {}, vacancy: {} }
    this.actionMode = `delete`

    this.send_data.vacancy.id = this.current_vacancy.id

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = this.permission

    var r = confirm(`Tem certeza que deseja apagar a Vaga?`)
    if (r) {
      this.requestSave()
    }
  }

  requestSave() {
    if (this.actionMode == `new`) {
      var url = "/users/works/recruitments/vacancies/create"
      var method = "POST"
    } else if (this.actionMode == `edit`) {
      var url = "/users/works/recruitments/vacancies/update"
      var method = "PUT"
    } else if (this.actionMode == `delete`) {
      var url = "/users/works/recruitments/vacancies/destroy"
      var method = "DELETE"
    }

    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.stopRefreshing()
        if (response.save) {
          var vacancy = response.data.cln

          if (controller.actionMode == `new`) {
            controller.application.vacancies[controller.application.vacancies.length] = vacancy
          } else if (controller.actionMode == `edit`) {
            controller.application.vacancies.forEach((element, i) => {
              if (element.id == vacancy.id) {
                controller.application.vacancies.splice(controller.application.vacancies.indexOf(element), 1, vacancy)
              }
            })
          } else if (controller.actionMode == `delete`) {
            controller.application.vacancies.forEach((element, i) => {
              if (element.id == vacancy.id) {
                controller.application.vacancies.splice(controller.application.vacancies.indexOf(element), 1)
              }
            })
          }
        }

        controller.cancelSave()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  doFormHtml() {
    this.stopRefreshing()


    if (this.application.permissions[this.permission].can_delete && this.actionMode == `edit`) {
      var deleteBtnHtml = `<button type="button" class="btn btn-flat-danger btn-sm py-2 btn-table f-065" data-action="click->${this.controllerName}#destroyVacancy" data-${this.controllerName}-target="deleteBtn">Apagar</button>`
    } else {
      var deleteBtnHtml = ``
    }

    if (this.application.permissions[this.permission].can_create) {
      var saveBtnHtml = `<button disabled type="button" class="btn btn-primary btn-sm py-2 btn-table f-065" data-action="click->${this.controllerName}#saveVacancy" data-${this.controllerName}-target="saveBtn">Salvar</button>`
    } else {
      var saveBtnHtml = ``
    }

    var html = `<div class="row">
                  <div class="col-6 offset-3">
                    <div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="formCard" data-action="resize@window->${this.controllerName}#layout">
                      <div class="card-header d-flex align-items-center card-header-table-list f-065">
                        <h6 class="card-title display-4 py-3" style="font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="formTitle"></h6>
                      </div>

                      <div class="card-body py-0" style="overflow:auto;" data-${this.controllerName}-target="formCardBody">
      
                        <div class="row my-2 d-flex align-items-center">
                          <div class="col-12">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="nameForm">Nome</label>
                                <input aria-describedby="nameFormHelp" class="form-control form-valid-control" data-${this.controllerName}-target="nameInput" placeholder="Nome" type="text" required>
                              </div>
                            </div>
                          </div>
                        </div>
                        
                        
                        <div class="row my-2 d-flex align-items-center">
                          <div class="col-3">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="startedAtForm">Início</label>
                                <input aria-describedby="startedAtFormHelp" class="form-control form-valid-control" id="startedAtForm" data-${this.controllerName}-target="startedAtInput" placeholder="Início" type="text" required>
                              </div>
                            </div>
                          </div>
                          <div class="col-1 text-right px-0">
                            <button data-action="click->${this.controllerName}#showMeds" data-channel-setter="true" data-element="medInput" data-${this.controllerName}-target="medModal" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                              <span class="material-icons md-sm md-dark">help_center</span>
                              <span class="mc-tooltiptext mc-tooltiptext-right">Clique para ver as Meds</span>
                            </button>
                          </div>
                          <div class="col-2">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="medForm">MedCapital</label>
                                <input readonly="readonly" class="form-control f-075 pt-1 pb-1" data-${this.controllerName}-target="medInput" type="text" placeholder="MedCapital" required>
                              </div>
                            </div>
                          </div>
                          <div class="col-3">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="budgetForm">Orçamento</label>
                                <input aria-describedby="budgetFormHelp" class="form-control f-065" id="budgetForm" data-${this.controllerName}-target="budgetInput" data-action="blur->${this.controllerName}#currencyMask keypress->${this.controllerName}#currencyMask keyup->${this.controllerName}#currencyMask" placeholder="Valor" type="text" required>
                              </div>
                            </div>
                          </div>
                          <div class="col-3 d-none" data-${this.controllerName}-target="expensesDiv">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="expensesForm">Despesas</label>
                                <input aria-describedby="expensesFormHelp" class="form-control f-065" id="expensesForm" data-${this.controllerName}-target="expensesInput" data-action="blur->${this.controllerName}#currencyMask keypress->${this.controllerName}#currencyMask keyup->${this.controllerName}#currencyMask" placeholder="Valor" type="text" required>
                              </div>
                            </div>
                          </div>
                          <div class="col-3">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="compensationForm">Remuneração</label>
                                <input aria-describedby="compensationFormHelp" class="form-control f-065" id="compensationForm" data-${this.controllerName}-target="compensationInput" data-action="blur->${this.controllerName}#currencyMask keypress->${this.controllerName}#currencyMask keyup->${this.controllerName}#currencyMask" placeholder="Valor" type="text" required>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row my-2">
                          <div class="col-4">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label>Status</label>
                                <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="statusDropdown">
                                  <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="statusDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="statusInput"></span></button>
                                  <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                    <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                    <ul class="ul-select" data-${this.controllerName}-target="statusList" data-app--helpers--search-target="searchList">
                                      <li data-status="in_process" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Em Processo</li>
                                      <li data-status="hired" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Contratado</li>
                                      <li data-status="closed" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Cancelado</li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-4">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label>Área</label>
                                <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="roleDropdown">
                                  <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="roleDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="roleInput"></span></button>
                                  <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                    <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                    <ul class="ul-select" data-${this.controllerName}-target="roleList" data-app--helpers--search-target="searchList">
                                      <li data-role="technology" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectRole" data-app--helpers--selector-target="select" class="li-selector dark f-065">Tecnologia</li>
                                      <li data-role="accounting" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectRole" data-app--helpers--selector-target="select" class="li-selector dark f-065">Controle</li>
                                      <li data-role="investment" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectRole" data-app--helpers--selector-target="select" class="li-selector dark f-065">Acumulação</li>
                                      <li data-role="protection" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectRole" data-app--helpers--selector-target="select" class="li-selector dark f-065">Proteção</li>
                                      <li data-role="legal" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectRole" data-app--helpers--selector-target="select" class="li-selector dark f-065">Jurídico</li>
                                      <li data-role="customer_success" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectRole" data-app--helpers--selector-target="select" class="li-selector dark f-065">Sucesso do Cliente</li>
                                      <li data-role="sales" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectRole" data-app--helpers--selector-target="select" class="li-selector dark f-065">Vendas</li>
                                      <li data-role="marketing" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectRole" data-app--helpers--selector-target="select" class="li-selector dark f-065">Marketing</li>
                                      <li data-role="performance" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectRole" data-app--helpers--selector-target="select" class="li-selector dark f-065">Performance</li>
                                      <li data-role="administrative" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectRole" data-app--helpers--selector-target="select" class="li-selector dark f-065">Adminstrativo</li>
                                      <li data-role="board" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectRole" data-app--helpers--selector-target="select" class="li-selector dark f-065">Diretoria</li>
                                      <li data-role="general" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectRole" data-app--helpers--selector-target="select" class="li-selector dark f-065">Serviços Gerais</li>
                                      <li data-role="biling" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectRole" data-app--helpers--selector-target="select" class="li-selector dark f-065">Faturamento</li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-4">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label>Posição</label>
                                <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="positionDropdown">
                                  <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="positionDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="positionInput"></span></button>
                                  <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                    <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                    <ul class="ul-select" data-${this.controllerName}-target="positionList" data-app--helpers--search-target="searchList">
                                      <li data-position="intern_job" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Estágio</li>
                                      <li data-position="assistant" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Assistente</li>
                                      <li data-position="analist" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Analista</li>
                                      <li data-position="manager" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Gestor</li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row my-2">
                          <div class="col-6">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label>Contratação</label>
                                <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="kindDropdown">
                                  <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="kindDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="kindInput"></span></button>
                                  <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                    <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                    <ul class="ul-select" data-${this.controllerName}-target="kindList" data-app--helpers--search-target="searchList">
                                      <li data-kind="intern" data-action="click->app--helpers--selector#select click->${this.controllerName}#setSubkind" data-app--helpers--selector-target="select" class="li-selector dark f-065">Estágio</li>
                                      <li data-kind="labor_law" data-action="click->app--helpers--selector#select click->${this.controllerName}#setSubkind" data-app--helpers--selector-target="select" class="li-selector dark f-065">CLT</li>
                                      <li data-kind="self_employed" data-action="click->app--helpers--selector#select click->${this.controllerName}#setSubkind" data-app--helpers--selector-target="select" class="li-selector dark f-065">Autônomo</li>
                                      <li data-kind="partner" data-action="click->app--helpers--selector#select click->${this.controllerName}#setSubkind" data-app--helpers--selector-target="select" class="li-selector dark f-065">Sócio</li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-6">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label>Tipo Pessoa</label>
                                <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="subkindDropdown">
                                  <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="subkindDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="subkindInput"></span></button>
                                  <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                    <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                    <ul class="ul-select" data-${this.controllerName}-target="subkindList" data-app--helpers--search-target="searchList"></ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row my-2">
                          
                        </div>
                      </div>
                      

                      <div class="card-footer border-top text-right py-1">
                        <button type="button" class="btn btn-secondary btn-sm py-2 btn-table f-065" data-dismiss="modal" data-action="click->${this.controllerName}#cancelSave">Fechar</button>
                        ${deleteBtnHtml}
                        ${saveBtnHtml}
                      </div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("users--works--recruitments--vacancies--index--dashboard").mainTarget.innerHTML = html)
    }).then(() => {
      controller.formCardTarget.style.height = ($(window).height() * 0.6) + "px"
      var date = new Date()
      controller.getControllerByIdentifier("app--helpers--pickdate").pickUntilDate($(controller.startedAtInputTarget), "monthly", date.getFullYear(), date.getMonth() + 1, date)


      if (controller.actionMode == `new`) {
        controller.formTitleTarget.innerText = `Nova Vaga`

        controller.statusDropdownBtnTarget.disabled = true
        controller.statusDropdownTarget.value = `in_process`
        controller.statusInputTarget.innerText = `Em Processo`
        controller.statusInputTarget.dataset.status = `in_process`
        controller.budgetInputTarget.value = controller.controllerNumber.currencyOptionMask(0)
        controller.compensationInputTarget.value = controller.controllerNumber.currencyOptionMask(0)
        controller.expensesInputTarget.value = controller.controllerNumber.currencyOptionMask(0)
        
        
      } else if (controller.actionMode == `edit`) {
        controller.formTitleTarget.innerText = `Editando Vaga`
        
        controller.statusDropdownTarget.value = controller.current_vacancy.status
        controller.statusInputTarget.innerText = controller.current_vacancy.status_pretty
        controller.statusInputTarget.dataset.status = controller.current_vacancy.status
        
        // controller.kindDropdownBtnTarget.disabled = true
        controller.kindDropdownTarget.value = controller.current_vacancy.kind
        controller.kindInputTarget.innerText = controller.current_vacancy.kind_pretty
        controller.kindInputTarget.dataset.kind = controller.current_vacancy.kind
        
        // controller.subkindDropdownBtnTarget.disabled = true
        controller.subkindDropdownTarget.value = controller.current_vacancy.subkind
        controller.subkindInputTarget.innerText = controller.current_vacancy.subkind_pretty
        controller.subkindInputTarget.dataset.subkind = controller.current_vacancy.subkind

        controller.positionDropdownTarget.value = controller.current_vacancy.position
        controller.positionInputTarget.innerText = controller.current_vacancy.position_pretty
        controller.positionInputTarget.dataset.position = controller.current_vacancy.position
        
        controller.roleDropdownBtnTarget.disabled = true
        controller.roleDropdownTarget.value = controller.current_vacancy.role
        controller.roleInputTarget.innerText = controller.current_vacancy.role_pretty
        controller.roleInputTarget.dataset.role = controller.current_vacancy.role
        controller.budgetInputTarget.value = controller.controllerNumber.currencyOptionMask(controller.current_vacancy.budget)
        controller.compensationInputTarget.value = controller.controllerNumber.currencyOptionMask(controller.current_vacancy.compensation)
        controller.expensesInputTarget.value = controller.controllerNumber.currencyOptionMask(controller.current_vacancy.expenses)

        controller.medModalTarget.disabled = true
        controller.medInputTarget.dataset.medId = controller.current_vacancy.med_id
        controller.medInputTarget.value = controller.current_vacancy.med_name
        controller.nameInputTarget.value = controller.current_vacancy.name
        controller.startedAtInputTarget.disabled = true
        controller.startedAtInputTarget.value = controller.current_vacancy.started_at_pretty

      }

      controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
      controller.refreshSaveBtn()
    })

  }

  setSubkind(ev) {
    var kind = ev.currentTarget.dataset.kind
    var html = ``

    if (kind == `intern` || kind == `labor_law` || kind == `partner`) {
      html = `<li data-subkind="individual_person" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Pessoa Física</li>`

      this.subkindDropdownBtnTarget.disabled = true
      this.subkindDropdownTarget.value = `individual_person`
      this.subkindInputTarget.dataset.subkind = `individual_person`
      this.subkindInputTarget.innerText = `Pessoa Física`
    } else if (kind == `self_employed`) {
      html = `<li data-subkind="individual_person" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Pessoa Física</li>
              <li data-subkind="legal_person" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Pessoa Jurídica</li>`

      this.subkindDropdownBtnTarget.disabled = false
      this.subkindDropdownTarget.value = ``
      this.subkindInputTarget.dataset.subkind = ``
      this.subkindInputTarget.innerText = ``
    }

    this.subkindListTarget.innerHTML = html
    this.getControllerByIdentifier("app--helpers--forms").floatingLabel()
  }

  selectRole() {

  }

  currencyMask(ev) {
    this.controllerNumber.changeNumberToCurrency(ev)
  }

  dateMask(ev) {
    this.controllerNumber.changeNumberToDate(ev)
  }

  showMeds(ev) {
    var element = ev.currentTarget.dataset.element
    var channelSetter = ev.currentTarget.dataset.channelSetter

    var html = `<div class="modal fade" data-controller="financials--books--dashboards--meds--modal" data-financials--books--dashboards--meds--modal-target="modal" id="connectionModal" tabindex="-1" role="dialog" aria-labelledby="connectionModalTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div class="modal-content">
                      <div class="modal-body p-0" data-financials--books--dashboards--meds--modal-target="body"></div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.getControllerByIdentifier(`financials--books--dashboards--meds--modal`).controllerForm = controller
      controller.getControllerByIdentifier(`financials--books--dashboards--meds--modal`).inputElement = this.nameTarget(element)
      controller.getControllerByIdentifier(`financials--books--dashboards--meds--modal`).channelSetter = channelSetter
      controller.getControllerByIdentifier(`financials--books--dashboards--meds--modal`).open()
    })
  }

  setNumber(ev) {
    var number = ev.currentTarget.dataset.number

    this.numberInputTarget.value = number
    this.getControllerByIdentifier("app--helpers--forms").floatingLabel()
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    })
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0

      if (controller.nameInputTarget.value == ``) {
        len += 1
      }

      if (controller.startedAtInputTarget.value == ``) {
        len += 1
      }

      if (controller.medInputTarget.value == ``) {
        len += 1
      }

      if (controller.budgetInputTarget.value == `` || controller.budgetInputTarget.value == `R$ 0,00`) {
        len += 1
      }

      if (controller.compensationInputTarget.value == `` || controller.compensationInputTarget.value == `R$ 0,00`) {
        len += 1
      }

      if (controller.statusInputTarget.innerText == ``) {
        len += 1
      }

      if (controller.roleInputTarget.innerText == ``) {
        len += 1
      }

      if (controller.positionInputTarget.innerText == ``) {
        len += 1
      }

      if (controller.kindInputTarget.innerText == ``) {
        len += 1
      }

      if (controller.subkindInputTarget.innerText == ``) {
        len += 1
      }



      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

}
