import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "bodyTable", "contracts", "receivables", "client", "sideBarReport", "addons",
                    "clearListBtn", "optionBtn", "viewClientCard", "content", "form"]

  connect() {
    this.controllerName = `financials--books--receivables--conciliations--modal--contracts`
    this.controllerMain = `financials--books--receivables--conciliations--modal--main`
    this.controllerClient = `financials--books--receivables--conciliations--modal--client`
    this.controllerBilling = `financials--books--receivables--conciliations--modal--billing`
    this.controllerReceivables = `financials--books--receivables--conciliations--modal--receivables`
    this.controllerNumber = this.getControllerByIdentifier(`app--helpers--numbers`)
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.cardLoader = this.getControllerByIdentifier("app--helpers--loaders").cardLoader()
    this.current_contract_addons = []
    this.contract_tickets = []
  }

  doContractList() {
    this.getControllerByIdentifier(this.controllerBilling).disableItemsCheck()
    var activeContracts = []

    this.current_client_contracts.forEach(contract => {
      if (contract.status == `active`) {
        activeContracts[activeContracts.length] = contract
      }
    });

    var html = ``
    var activeContractHtml = ``

    if (this.current_billing.conciliated) {
      activeContractHtml += `<div class="col-12 text-center mb-3">
                              <span class="f-065"><strong>Fatura já Conciliada</strong></span>
                            </div>`
    }

    if (activeContracts.length > 0) {
      activeContracts.forEach(contract => {
        activeContractHtml += `<div class="col-6">
                                <div class="card mt-1 pointer" style="width:100%;display:relative;" data-${this.controllerName}-target="contractCard" data-contract-id="${contract.id}" data-action="click->${this.controllerName}#showClientContracts">
                                  <div class="card-body p-0 f-065">
                                    <div class="row my-2 d-flex align-items-center">
                                      <div class="col-12 px-1">
                                        <p class="mb-0 f-065">Contrato: ${contract.name}</p>
                                        <p class="mb-0 f-065">Valor: ${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(contract.total_amount)}</p>
                                        <p class="mb-0 f-065">Início: ${contract.started_at_pretty}</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>`
      })
    } else {
      activeContractHtml += `<div class="col-12 text-center">
                              <span class="f-065"><strong>Não há Contratos Ativos</strong></span>
                            </div>`
    }

    html += `<div class="row mt-3 d-flex align-items-center">${activeContractHtml}</div>`

    this.getControllerByIdentifier(this.controllerClient).contractsTarget.innerHTML = html
    this.getControllerByIdentifier(this.controllerClient).receivablesTarget.innerHTML = ``
    this.getControllerByIdentifier(this.controllerReceivables).cleanConciliationProcess()
  }

  addContract() {
    this.controllerSaveContract = this.getControllerByIdentifier("financials--books--clients--contracts--save")

    this.controllerSaveContract.controllerDashboard = this
    this.controllerSaveContract.kindModel = this.kindModel
    this.controllerSaveContract.permission = `financial_receivable_contracts`
    this.controllerSaveContract.gridElement = this.getControllerByIdentifier(this.controllerClient).contractsTarget
    this.controllerSaveContract.current_client = this.current_client
    this.controllerSaveContract.current_contract = {}
    this.controllerSaveContract.current_billing = this.current_billing
    this.controllerSaveContract.actionMode = `new`
    this.controllerSaveContract.doFormHtml()
  }

  doContractShow() {

    var closeLine = `<span data-action="click->${this.controllerName}#doContractList" class="dropdown-item py-1 pointer dropdown-submenu-item">Fechar</span>`
    var receivablesLine = `<span data-action="click->${this.controllerName}#showReceivables" class="dropdown-item py-1 pointer dropdown-submenu-item">Listar Recebíveis</span>`
    var addonLine = `<span data-action="click->${this.controllerName}#addAddon" class="dropdown-item py-1 pointer dropdown-submenu-item">Adicionar Complemento</span>`
    var discountLine = `<span data-action="click->${this.controllerName}#addAddon" data-model="discount" class="dropdown-item py-1 pointer dropdown-submenu-item">Adicionar Complemento Desconto</span>`
    // var ticketLine = `<span data-action="click->${this.controllerName}#addTicket" class="dropdown-item py-1 pointer dropdown-submenu-item">Adicionar Ticket</span>`
    var ticketLine = `<span data-action="click->${this.controllerName}#showTickets" class="dropdown-item py-1 pointer dropdown-submenu-item">Ver Tickets</span>`

    var submenuHtml = `<div class="dropdown mc-tooltip" data-${this.controllerName}-target="optionBtn">
                        <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-toggle="dropdown" id="submenuMenuDrop" type="button"><span class="material-icons md-dark">more_vert</span></button>
                        <div aria-labelledby="submenuMenuDrop" class="dropdown-menu dropdown-menu-right menu" style="font-size:80%">
                          ${closeLine}
                          ${receivablesLine}
                          ${addonLine}
                          ${discountLine}
                          ${ticketLine}
                        </div>
                        <span class="mc-tooltiptext">Opções</span>
                      </div>`

    var html = ``
    var selectedContractHtml = `<div class="col-6">
                                  <div class="card mt-1 pointer bg-primary" style="width:100%;display:relative;" data-${this.controllerName}-target="contractCard" data-action="click->${this.controllerName}#showClientContracts">
                                    <div class="card-body p-0 f-065">
                                      <div class="row my-2 d-flex align-items-center">
                                        <div class="col-12 px-1">
                                          <p class="mb-0 f-065">Contrato: ${this.current_contract.name}</p>
                                          <p class="mb-0 f-065">Valor: ${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(this.current_contract.total_amount)}</p>
                                          <p class="mb-0 f-065">Início: ${this.current_contract.started_at_pretty}</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-6">
                                  <div class="row w-100">
                                    <div class="col-6 px-0 text-center"></div>
                                    <div class="col-3 px-0 text-center">
                                      <span class="mc-tooltip">
                                        <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-action="click->${this.controllerName}#addReceivable" type="button">
                                          <span class="material-icons md-dark">monetization_on</span>
                                        </button>
                                        <span class="mc-tooltiptext">Adicionar Recebível</span>
                                      </span>
                                    </div>
                                    
                                    
                                    <div class="col-3 px-0 text-center">
                                      ${submenuHtml}
                                    </div>
                                  </div>
                                </div>`

    html += `<div class="row mt-3 d-flex align-items-center">
              ${selectedContractHtml}
              <div class="col-12 px-0" data-${this.controllerName}-target="form"></div>
            </div>`

    // this.getControllerByIdentifier(this.controllerClient).contractsTarget.innerHTML = html
    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier(controller.controllerClient).contractsTarget.innerHTML = html)
    }).then(() => {
      controller.getControllerByIdentifier(controller.controllerReceivables).current_contract = controller.current_contract
      controller.getControllerByIdentifier(controller.controllerReceivables).conciliationProcess = false
      controller.getControllerByIdentifier(controller.controllerReceivables).getContractReceivables()
    })
  }

  doConciliatedContractShow() {

    var html = ``
    var selectedContractHtml = `<div class="col-6">
                                  <div class="card mt-1 pointer bg-primary" style="width:100%;display:relative;" data-${this.controllerName}-target="contractCard">
                                    <div class="card-body p-0 f-065">
                                      <div class="row my-2 d-flex align-items-center">
                                        <div class="col-12 px-1">
                                          <p class="mb-0 f-065">Contrato: ${this.current_contract.name}</p>
                                          <p class="mb-0 f-065">Valor: ${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(this.current_contract.total_amount)}</p>
                                          <p class="mb-0 f-065">Início: ${this.current_contract.started_at_pretty}</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-6">
                                  <div class="row w-100">
                                    <div class="col-3 px-0 text-center">
                                      <span class="mc-tooltip">
                                        <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-action="click->${this.controllerName}#showReceivables" type="button">
                                          <span class="material-icons md-dark">payments</span>
                                        </button>
                                        <span class="mc-tooltiptext">Listar Recebíveis</span>
                                      </span>
                                    </div>
                                    <div class="col-3 px-0 text-center">
                                      <span class="mc-tooltip">
                                        <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-action="click->${this.controllerName}#showTickets" type="button">
                                          <span class="material-icons md-dark">task</span>
                                        </button>
                                        <span class="mc-tooltiptext">Listar Tickets</span>
                                      </span>
                                    </div>
                                    <div class="col-3 px-0 text-center">
                                      <span class="mc-tooltip">
                                        <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-action="click->${this.controllerName}#doContractList" type="button">
                                          <span class="material-icons md-dark">clear</span>
                                        </button>
                                        <span class="mc-tooltiptext">Limpar</span>
                                      </span>
                                    </div>
                                  </div>
                                </div>`

    html += `<div class="row mt-3 d-flex align-items-center">
              ${selectedContractHtml}
              <div class="col-12 px-0" data-${this.controllerName}-target="form"></div>
            </div>`

    this.getControllerByIdentifier(this.controllerClient).contractsTarget.innerHTML = html
  }

  addReceivable(ev) {
    this.getControllerByIdentifier(this.controllerBilling).disableItemsCheck()
    this.formTarget.innerHTML = ``

    var html = `<div class="row mt-3">
                  <div class="col-12 px-0" data-${this.controllerReceivables}-target="receivables" data-controller="financials--books--receivables--entities--save-from-conciliation"></div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier(controller.controllerClient).receivablesTarget.innerHTML = html)
      // resolve(controller.formTarget.innerHTML = html)
    }).then(() => {
      
      controller.application.meds.forEach(element => {
        if (element.id == controller.current_billing.med_id) {
          controller.current_med = element
        }
      })
      
      if (controller.current_med.integration_company && controller.current_med.integration_company.fiscal_status == `active_fiscal`) {
        controller.getControllerByIdentifier(controller.controllerReceivables).current_billing = controller.current_billing
        controller.getControllerByIdentifier(controller.controllerReceivables).current_client = controller.current_client
        controller.getControllerByIdentifier(controller.controllerReceivables).current_contract = controller.current_contract
        controller.getControllerByIdentifier(controller.controllerReceivables).getContractAddons()
      } else {
        alert(`Favor atualizar a Integração para Emissão de NF da ${controller.current_med.name}`)
      }
    })
  }

  addAddon(ev) {
    this.getControllerByIdentifier(this.controllerBilling).enableItemsCheck()
    this.getControllerByIdentifier(this.controllerBilling).checkMode = `addon`
    this.getControllerByIdentifier(this.controllerBilling).billing_item_ids = []
    this.getControllerByIdentifier(this.controllerClient).receivablesTarget.innerHTML = ``

    var kindModel = ev.currentTarget.dataset.model
    
    var html = `<div class="row mt-3">
                  <div class="col-12 px-0" data-${this.controllerName}-target="addons" data-controller="financials--books--clients--addons--save"></div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.formTarget.innerHTML = html)
    }).then(() => {
      controller.controllerSaveAddon = controller.getControllerByIdentifier("financials--books--clients--addons--save")

      controller.controllerSaveAddon.controllerDashboard = controller
      controller.controllerSaveAddon.gridElement = controller.addonsTarget
      controller.controllerSaveAddon.permission = `financial_receivable_addons`
      controller.controllerSaveAddon.current_contract = controller.current_contract
      controller.controllerSaveAddon.current_billing = controller.current_billing
      controller.controllerSaveAddon.current_addon = {}
      controller.controllerSaveAddon.actionMode = `new`
      controller.controllerSaveAddon.kindModel = kindModel
      controller.controllerSaveAddon.doFormHtml()
    })
  }

  doAddonsList() {
    var activeAddons = []

    this.current_contract_addons.forEach(addon => {
      if (addon.status == `active`) {
        activeAddons[activeAddons.length] = addon
      }
    });

    var html = ``
    var activeAddonHtml = ``

    if (activeAddons.length > 0) {
      activeAddons.forEach(addon => {
        var cardShow = `<p class="mb-0 f-065">${addon.status_pretty}</p>
                        <p class="mb-0 f-065">${addon.plan_pretty}</p>
                        <p class="mb-0 f-065">${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(addon.amount)}</p>`

        activeAddonHtml += `<div class="card mt-1 pointer" style="width:100%;display:relative;" data-${this.controllerName}-target="addonCard-${addon.id}">
                                <div class="card-body p-0 f-065">
                                  <div class="row d-flex align-items-center">
                                    <div class="col-9 px-1">
                                      <div class="card-show-dropdown">
                                        <p class="mb-0 f-065">${addon.kind_pretty} | ${addon.subkind_pretty}</p>
                                        <p class="mb-0 f-065">${addon.description}</p>
                                        <div class="card-show-dropdown-content text-left">
                                          ${cardShow}
                                        </div>
                                      </div>
                                    </div>
                                    <div class="col-3 px-1">
                                      <span class="mc-tooltip">
                                        <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-id="${addon.id}" data-action="click->${this.controllerName}#" type="button">
                                          <span class="material-icons md-dark">monetization_on</span>
                                        </button>
                                        <span class="mc-tooltiptext">Criar Recebível</span>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>`
      })
    } else {
      activeAddonHtml += `<div class="row my-2 d-flex align-items-center">
                            <div class="col-12 text-center">
                              <span class="f-065"><strong>Não há Itens Ativos</strong></span>
                            </div>
                          </div>`
    }

    html += `<div class="row mt-3 d-flex align-items-center">${activeAddonHtml}</div>`

    this.addonsTarget.innerHTML = html
  }

  doContractLoader() {
    var html = `<div class="card-body py-0" style="overflow:auto;">
                <div class="row">
                  <div class="col-6">${this.loader}</div>
                  <div class="col-6">${this.loader}</div>
                </div>
                <div class="row">
                  <div class="col-6">${this.loader}</div>
                  <div class="col-6">${this.loader}</div>
                </div>
                <div class="row">
                  <div class="col-6">${this.loader}</div>
                  <div class="col-6">${this.loader}</div>
                </div>
              </div>`

    this.getControllerByIdentifier(this.controllerClient).contractsTarget.innerHTML = html
  }

  getClientContracts() {
    var submessage = `Carregando Contratos do Cliente`
    this.doContractLoader()
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).openLoadModal({ submessage: submessage })
    this.refreshLoadValue(submessage)
    this.getControllerByIdentifier(this.controllerMain).addLoader()

    const data = { contract: { active: true, client_id: this.current_client.id }, current_user: { current_user_id: this.application.current_user.id, feature: `financial_receivable_contracts` } }
    const url = "/financials/books/clients/contracts/list_by_client"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.stopRefreshing()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
        controller.getControllerByIdentifier(`app--helpers--pre-page-load`).closeLoadModal()

        controller.current_client_contracts = response.data.cln

        controller.doContractList()
        controller.getControllerByIdentifier(controller.controllerBilling).disableItemsCheck()
        controller.getControllerByIdentifier(controller.controllerMain).removeLoader()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  showReceivables() {

    var controllerModal = `financials--books--receivables--entities--list-modal`

    var html = `<div class="modal fade" data-controller="${controllerModal}" data-${controllerModal}-target="modal" id="connectionModal" tabindex="-1" role="dialog" aria-labelledby="connectionModalTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                  <div class="modal-dialog modal-dialog-centered modal-slg" role="document">
                    <div class="modal-content">
                      <div class="modal-body p-0" data-${controllerModal}-target="body"></div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.getControllerByIdentifier(controllerModal).obj_type = `contract`
      controller.getControllerByIdentifier(controllerModal).obj_id = controller.current_contract.id
      controller.getControllerByIdentifier(controllerModal).title = `${controller.current_contract.client_name} | ${controller.current_contract.name} | Início: ${controller.current_contract.started_at_pretty}`
      controller.getControllerByIdentifier(controllerModal).permission = `financial_receivable_contracts`
      controller.getControllerByIdentifier(controllerModal).open()
    })
  }

  showClientContracts(ev) {
    this.getControllerByIdentifier(this.controllerBilling).disableItemsCheck()
    var contractId = ev.currentTarget.dataset.contractId

    this.current_client_contracts.forEach(contract => {
      if (contract.id == contractId) {
        this.current_contract = contract
      }
    })

    if (this.current_billing.conciliated) {
      this.doConciliatedContractShow()
    } else {
      this.doContractShow()
    }
  }

  showTickets() {
    // if (this.contract_tickets.length > 0) {
    //   this.showTicketsBoard()
    // } else {
    // }
    this.getTickets()
  }

  getTickets() {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal({ message: `Em processamento, aguarde!` })
    this.contract_tickets = []

    var data = { ticket: { board_id: this.current_contract.id, board_type: `financial_contracts` }, current_user: { current_user_id: this.application.current_user.id, feature: `financial_receivable_tickets` } }
    const url = "/users/works/activities/tickets/list_by_board"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        response.data.cln.forEach(element => {
          controller.contract_tickets[controller.contract_tickets.length] = element
        })

        controller.showTicketsBoard()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  showTicketsBoard() {
    var controllerModal = `users--works--activities--shared--board-modal`

    var html = `<div class="modal fade" data-controller="${controllerModal}" data-${controllerModal}-target="modal" id="connectionModal" tabindex="-1" role="dialog" aria-labelledby="connectionModalTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                  <div class="modal-dialog modal-dialog-centered modal-slg" role="document">
                    <div class="modal-content">
                      <div class="modal-body p-0" data-${controllerModal}-target="body"></div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.getControllerByIdentifier(controllerModal).current_tickets = controller.contract_tickets
      controller.getControllerByIdentifier(controllerModal).obj_type = `contract`
      controller.getControllerByIdentifier(controllerModal).obj_id = controller.current_contract.id
      controller.getControllerByIdentifier(controllerModal).title = `${controller.current_contract.client_name} | ${controller.current_contract.name} | Início: ${controller.current_contract.started_at_pretty}`
      controller.getControllerByIdentifier(controllerModal).permission = `financial_receivable_tickets`
      controller.getControllerByIdentifier(controllerModal).ticket_count_controller = controller.getControllerByIdentifier(`financials--books--receivables--dashboard--submenu`)
      controller.getControllerByIdentifier(controllerModal).ticket_board = {
        board_id: controller.current_contract.id,
        board_type: `financial_contracts`,
        board_subtype: `financial_contracts`,
        board_path: `/a/contratos-financeiros`,
        board_name: `Contratos | ${controller.current_client.record_name} | ${controller.current_contract.name}`,
        date_id: controller.application.current_date.id,
        date_type: `financials_dates`
      }
      controller.getControllerByIdentifier(controllerModal).comment_board = {
        board_id: controller.current_contract.id,
        board_type: `financial_contracts`,
        date_id: controller.application.current_date.id,
        date_type: `financials_dates`
      }

      controller.getControllerByIdentifier(controllerModal).open()
    })
  }

  addTicket(ev) {
    
    var controllerModal = `users--works--activities--shared--modal`

    var html = `<div class="modal fade" data-controller="${controllerModal}" data-${controllerModal}-target="modal" id="connectionModal" tabindex="-1" role="dialog" aria-labelledby="connectionModalTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                  <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
                    <div class="modal-content">
                      <div class="modal-body p-0" data-${controllerModal}-target="body"></div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.getControllerByIdentifier(controllerModal).obj_type = `contract`
      controller.getControllerByIdentifier(controllerModal).obj_id = controller.current_contract.id
      controller.getControllerByIdentifier(controllerModal).title = `${controller.current_contract.name} | Início: ${controller.current_contract.started_at_pretty}`
      controller.getControllerByIdentifier(controllerModal).permission = `financial_receivable_tickets`
      controller.getControllerByIdentifier(controllerModal).ticket_count_controller = controller.getControllerByIdentifier(`financials--books--receivables--dashboard--submenu`)
      controller.getControllerByIdentifier(controllerModal).ticket_board = {
        board_id: controller.current_contract.id,
        board_type: `financial_contracts`,
        board_subtype: `financial_contracts`,
        board_path: `/a/contratos-financeiros`,
        board_name: `Contratos | ${controller.current_client.record_name} | ${controller.current_contract.name}`,
        date_id: controller.application.current_date.id,
        date_type: `financials_dates`
      }
      controller.getControllerByIdentifier(controllerModal).comment_board = {
        board_id: controller.current_contract.id,
        board_type: `financial_contracts`,
        date_id: controller.application.current_date.id,
        date_type: `financials_dates`
      }

      controller.getControllerByIdentifier(controllerModal).open()
    })
  }

  refreshLoadValue(submessage) {
    var controller = this
    var value = 1
    this.refreshLoad = setInterval(function () {
      controller.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: submessage, value: value })
      value += 1

      if (value == 99) {
        controller.stopRefreshing()

        var response = { type: `danger`, message: `Tivemos um problema para carregar os Contratos` }
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      }

    }, 200);
  }

  stopRefreshing() {
    if (this.refreshLoad) {
      clearInterval(this.refreshLoad)
    }
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  // `<div class="col-3 px-0 text-center">
//   <span class="mc-tooltip">
//     <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-action="click->${this.controllerName}#doContractList" type="button">
//       <span class="material-icons md-dark">clear</span>
//     </button>
//     <span class="mc-tooltiptext">Fechar</span>
//   </span>
// </div>
// <div class="col-3 px-0 text-center">
//   <span class="mc-tooltip">
//     <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-action="click->${this.controllerName}#addAddon" type="button">
//       <span class="material-icons md-dark">assignment_add</span>
//     </button>
//     <span class="mc-tooltiptext">Adicionar Complemento</span>
//   </span>
// </div>
// <div class="col-3 px-0 text-center">
//   <span class="mc-tooltip">
//     <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-action="click->${this.controllerName}#addReceivable" type="button">
//       <span class="material-icons md-dark">monetization_on</span>
//     </button>
//     <span class="mc-tooltiptext">Adicionar Recebível</span>
//   </span>
// </div>
// <div class="col-3 px-0 text-center">
//   <span class="mc-tooltip">
//     <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-action="click->${this.controllerName}#showReceivables" type="button">
//       <span class="material-icons md-dark">payments</span>
//     </button>
//     <span class="mc-tooltiptext">Listar Recebíveis</span>
//   </span>
// </div>`

}