import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "patientCheckbox", "sponsorCheckbox", "nameDiv", "nameInputDiv", "nameInput", "cpfDiv", "cpfInputDiv", "cpfInput",
                    "birthdateDiv", "birthdateInputDiv", "birthdateInput", "saveBtn", "sexDiv", "sexInputDiv", "sexDropdown", "sexDropdownBtn",
                    "sexInput", "sexList", "editBtn", "cancelBtn"]


  connect() {
    this.controllerName = `operations--products--bookings--patients--principal`
    this.send_data = { current_user: {}, patient: {}, account: {} }
    this.doPrincipalHtml()
  }

  editPatient(ev) {
    this.saveBtnTarget.classList.remove("d-none")
    this.refreshSaveBtn()

    if (ev.currentTarget.closest("tr").dataset) {
      this.field = ev.currentTarget.closest("tr").dataset.field
      ev.currentTarget.classList.add("d-none")
      ev.currentTarget.nextElementSibling.classList.remove("d-none")
    } else {
      this.field = "checkbox"
    }
    
    if (this.field == "name") {
      this.nameDivTarget.classList.add("d-none")
      this.nameInputDivTarget.classList.remove("d-none")
      this.nameInputTarget.value = this.nameDivTarget.innerText.trim()
      this.nameInputTarget.focus()
    } else if (this.field == "cpf") {
      this.cpfDivTarget.classList.add("d-none")
      this.cpfInputDivTarget.classList.remove("d-none")
      this.cpfInputTarget.value = this.cpfDivTarget.innerText.trim()
      this.cpfInputTarget.focus()
    } else if (this.field == "birthdate") {
      this.birthdateDivTarget.classList.add("d-none")
      this.birthdateInputDivTarget.classList.remove("d-none")
      this.birthdateInputTarget.value = this.birthdateDivTarget.innerText.trim()
      this.birthdateInputTarget.focus()
      this.getControllerByIdentifier("app--helpers--pickdate").pick($(this.birthdateInputTarget), { max: true, months: true, years: 60 })
    } else if (this.field == "sex") {
      this.sexDivTarget.classList.add("d-none")
      this.sexInputDivTarget.classList.remove("d-none")
      this.sexInputTarget.innerText = this.sexDivTarget.innerText.trim()
      this.sexInputTarget.focus()
    }

  }

  cancelInput(ev) {
    ev.currentTarget.classList.add("d-none")
    ev.currentTarget.previousElementSibling.classList.remove("d-none")

    if (this.field == "name") {
      this.nameDivTarget.classList.remove("d-none")
      this.nameInputDivTarget.classList.add("d-none")
      this.nameDivTarget.innerText = this.application.current_patient.account_name
    } else if (this.field == "cpf") {
      this.cpfDivTarget.classList.remove("d-none")
      this.cpfInputDivTarget.classList.add("d-none")
      this.cpfDivTarget.innerText = this.application.current_patient.account_cpf_pretty
    } else if (this.field == "birthdate") {
      this.birthdateDivTarget.classList.remove("d-none")
      this.birthdateInputDivTarget.classList.add("d-none")
      this.birthdateDivTarget.innerText = this.application.current_patient.account_birthdate_pretty
    } else if (this.field == "sex") {
      this.sexDivTarget.classList.remove("d-none")
      this.sexInputDivTarget.classList.add("d-none")
      this.sexDivTarget.innerText = this.application.current_patient.account_sex_pretty
    }
  }

  closeInput() {
    this.saveBtnTarget.classList.add("d-none")

    if (this.nameDivTarget.classList.contains("d-none")) {
      this.nameDivTarget.classList.remove("d-none")
      this.nameInputDivTarget.classList.add("d-none")
      this.nameDivTarget.innerText = this.nameInputTarget.value
    }

    if (this.cpfDivTarget.classList.contains("d-none")) {
      this.cpfDivTarget.classList.remove("d-none")
      this.cpfInputDivTarget.classList.add("d-none")
      this.cpfDivTarget.innerText = this.cpfInputTarget.value
    }

    if (this.birthdateDivTarget.classList.contains("d-none")) {
      this.birthdateDivTarget.classList.remove("d-none")
      this.birthdateInputDivTarget.classList.add("d-none")
      this.birthdateDivTarget.innerText = this.birthdateInputTarget.value
    }

    if (this.sexDivTarget.classList.contains("d-none")) {
      this.sexDivTarget.classList.remove("d-none")
      this.sexInputDivTarget.classList.add("d-none")
      this.sexDivTarget.innerText = this.sexInputTarget.innerText
    }

  }

  savePatient() {

    this.editBtnTargets.forEach(element => {
      element.classList.remove("d-none")  
    });

    this.cancelBtnTargets.forEach(element => {
      element.classList.add("d-none")  
    });

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.closeInput())
    }).then(() => {
      controller.send_data.current_user.current_user_id = this.application.current_user.id
      controller.send_data.patient.id = this.application.current_patient.id
      controller.send_data.patient.account_id = this.application.current_patient.account_id
      controller.send_data.patient.booking_id = this.application.current_patient.booking_id
      controller.send_data.patient.patient = controller.patientCheckboxTarget.checked
      controller.send_data.patient.sponsor = controller.sponsorCheckboxTarget.checked
      controller.send_data.account.id = this.application.current_patient.account_id
      controller.send_data.account.name = controller.nameDivTarget.innerText.trim()
      controller.send_data.account.cpf = controller.getControllerByIdentifier("app--helpers--numbers").fromCpfToNumber(controller.cpfDivTarget.innerText.trim())
      controller.send_data.account.birthdate = controller.getControllerByIdentifier("app--helpers--date").transformFullDate(controller.birthdateDivTarget.innerText.trim())
      controller.send_data.account.sex = controller.sexInputTarget.dataset.sex
      controller.stopRefreshing()
      controller.requestSave()
    }) 

  }

  requestSave() {
    var url = "/operations/products/bookings/patients/update"
    var method = "PUT"
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          this.stopRefreshing()
          var patient = response.data.cln
          this.application.current_patient = patient
          this.getControllerByIdentifier("operations--products--bookings--patients--show").viewTitleTarget.innerText = this.application.current_patient.account_name
          if (patient.active) {
            this.application.patients.forEach((element, i) => {
              if (element.id == patient.id) {
                this.application.patients.splice(this.application.patients.indexOf(element), 1, patient)
              }
            })
          } else {
            this.application.patients.forEach((element, i) => {
              if (element.id == patient.id) {
                this.application.patients.splice(this.application.patients.indexOf(element), 1)
              }
            })
          }
          this.getControllerByIdentifier("operations--products--bookings--patients--index").doDataTable()
        } else {
          this.saveBtnTarget.disabled = false
          if (this.hasDeleteBtnTarget) {
            this.deleteBtnTarget.disabled = true
          }
        }
        this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", this.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }


  doPrincipalHtml() {

    if (this.application.permissions.medbooking_patients.can_copy) {
      var copyPath = `<button type="button" class="btn btn-sm btn-table copy p-0 mc-tooltip">
                        <span class="material-icons md-sm md-light">file_copy</span>
                        <span class="mc-tooltiptext">Copiar</span>
                      </button>`
    } else {
      var copyPath = ``
    }

    if (this.application.permissions.medbooking_patients.can_update) {
      var edit = `<button data-${this.controllerName}-target="editBtn" data-action="click->${this.controllerName}#editPatient" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                    <span class="material-icons md-sm md-dark">edit</span>
                    <span class="mc-tooltiptext">Editar</span>
                  </button>`
      var cancel = `<button data-${this.controllerName}-target="cancelBtn" data-action="click->${this.controllerName}#cancelInput" type="button" class="btn btn-sm btn-table p-0 d-none mc-tooltip">
                      <span aria-hidden="true">&times;</span>
                      <span class="mc-tooltiptext">Cancelar</span>
                    </button>`
      var patientCheckbox = `<input type="checkbox" class="custom-control-input" id="patientCheck" data-${this.controllerName}-target="patientCheckbox" data-action="click->${this.controllerName}#editPatient">`
      var sponsorCheckbox = `<input type="checkbox" class="custom-control-input" id="sponsorCheck" data-${this.controllerName}-target="sponsorCheckbox" data-action="click->${this.controllerName}#editPatient">`
    } else {
      var edit = ``
      var sponsorCheckbox = `<input disabled type="checkbox" class="custom-control-input" id="sponsorCheck" data-${this.controllerName}-target="sponsorCheckbox">`
      var patientCheckbox = `<input disabled type="checkbox" class="custom-control-input" id="patientCheck" data-${this.controllerName}-target="patientCheckbox">`
    }

    var html = `<div class="row my-1">
                  <div class="col-12">
                    <table class="table table-sm table-search" style="font-size:80%;">
                      <tbody>
                        <tr data-field="name">
                          <th class="table-25 px-0 f-065 align-middle"><strong>Nome</strong></th>
                          <td class="table-45 f-065 align-middle">
                            <div data-${this.controllerName}-target="nameDiv">
                              ${this.application.current_patient.account_name}
                            </div>
                            <div class="d-none" data-${this.controllerName}-target="nameInputDiv">
                              <input data-${this.controllerName}-target="nameInput" class="form-control p-1 s-title-0p6rem" type="text" required>
                            </div>
                          </td>
                          <td class="align-middle text-center" data-copy="${this.application.current_patient.account_name}">${copyPath}</td>
                          <td class="align-middle text-center">
                            ${edit}
                            ${cancel}
                          </td>
                        </tr>
                        <tr data-field="cpf">
                          <th class="table-30 px-0 f-065 align-middle"><strong>CPF</strong></th>
                          <td class="table-45 f-065 align-middle">
                            <div data-${this.controllerName}-target="cpfDiv">
                              ${this.application.current_patient.account_cpf_pretty}
                            </div>
                            <div class="d-none" data-${this.controllerName}-target="cpfInputDiv">
                              <input data-${this.controllerName}-target="cpfInput" class="form-control p-1 s-title-0p6rem" type="tel" required data-action="blur->${this.controllerName}#cpfMask keypress->${this.controllerName}#cpfMask">
                            </div>
                          </td>
                          <td class="align-middle text-center" data-copy="${this.application.current_patient.account_cpf}">${copyPath}</td>
                          <td class="align-middle text-center">
                            ${edit} 
                            ${cancel} 
                          </td>
                        </tr>
                        <tr data-field="birthdate">
                          <th class="table-30 px-0 f-065 align-middle"><strong>DN</strong></th>
                          <td class="table-45 f-065 align-middle">
                            <div data-${this.controllerName}-target="birthdateDiv">
                              ${this.application.current_patient.account_birthdate_pretty}
                            </div>
                            <div class="d-none" data-${this.controllerName}-target="birthdateInputDiv">
                              <input data-${this.controllerName}-target="birthdateInput" class="form-control p-1 s-title-0p6rem" type="text" required>
                            </div>
                          </td>
                          <td class="align-middle text-center" data-copy="${this.application.current_patient.account_cpf}">${copyPath}</td>
                          <td class="align-middle text-center">
                            ${edit}
                            ${cancel}
                          </td>
                        </tr>
                        <tr data-field="sex">
                          <th class="table-30 px-0 f-065 align-middle"><strong>Sexo</strong></th>
                          <td class="table-45 f-065 align-middle">
                            <div data-${this.controllerName}-target="sexDiv">
                              ${this.application.current_patient.account_sex_pretty}
                            </div>
                            <div class="d-none" data-${this.controllerName}-target="sexInputDiv">
                              <div class="dropdown dropdown-selector" data-controller="app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="sexDropdown">
                                <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:25px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="sexDropdownBtn"><span class="mr-auto w-100 selected-item f-065" id="selected" data-app--helpers--selector-target="input" data-${this.controllerName}-target="sexInput"></span></button>
                                <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                  <input class="form-control form-control-selector dropdown-search-input f-065" type="text" placeholder="Buscar ...">
                                  <ul class="ul-select" data-${this.controllerName}-target="sexList">
                                    <li data-sex="male" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Masculino</li>
                                    <li data-sex="female" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Feminino</li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </td>
                          <td></td>
                          <td class="align-middle text-center">
                            ${edit}
                            ${cancel}
                          </td>
                        </tr>
                        <tr>
                          <th class="px-0 f-065 align-middle"><strong>Paciente</strong></th>
                          <td class="py-1-1 d-flex">
                            <div class="custom-control custom-checkbox pr-0 pl-2 w-50 my-0">
                              ${patientCheckbox}
                              <label class="custom-control-label" for="patientCheck"></label>
                            </div>
                          </td>
                          <td></td>
                          <td></td>
                        </tr>
                        <tr>
                          <th class="px-0 f-065 align-middle"><strong>Financeiro</strong></th>
                          <td class="py-1-1 d-flex">
                            <div class="custom-control custom-checkbox pr-0 pl-2 w-50 my-0">
                              ${sponsorCheckbox}
                              <label class="custom-control-label" for="sponsorCheck"></label>
                            </div>
                          </td>
                          <td></td>
                          <td></td>
                        </tr>
                        <tr>
                          <td colspan="4" class="text-right">
                            <button type="button" class="btn btn-primary btn-sm f-065 d-none" data-action="click->${this.controllerName}#savePatient" data-${this.controllerName}-target="saveBtn">Salvar</button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.mainTarget.innerHTML = html)
    }).then(() => {
      controller.sexInputTarget.dataset.sex = controller.application.current_patient.account_sex
      if (controller.application.current_patient.patient) {
        controller.patientCheckboxTarget.checked = true
      } else {
        controller.patientCheckboxTarget.checked = false
      }
      if (controller.application.current_patient.sponsor) {
        controller.sponsorCheckboxTarget.checked = true
      } else {
        controller.sponsorCheckboxTarget.checked = false
      }
    })
    
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  cpfMask(ev) {
    this.getControllerByIdentifier("app--helpers--numbers").changeNumberToCpf(ev)
    if (ev.type == "blur") {
      this.cpf_result = this.getControllerByIdentifier("app--helpers--tax-number").cpf_validate(ev.target.value)
      
      if (this.cpf_result.invalid) {
        this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", this.cpf_result.message, 3000)
        this.cpfInputTarget.classList.add("f-danger")
        this.cpfInputTarget.classList.remove("f-normal")
      } else {
        this.cpfInputTarget.classList.add("f-normal")
        this.cpfInputTarget.classList.remove("f-danger")
      }
    }
  }

  refreshSaveBtn() {
    this.stopRefreshing()
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0

      if (controller.nameDivTarget.classList.contains("d-none") && controller.nameInputTarget.value == "") {
        len += 1
      }
      if ((controller.cpfDivTarget.classList.contains("d-none") && controller.cpfInputTarget.value == "") || (controller.cpfDivTarget.classList.contains("d-none") && controller.cpf_result && controller.cpf_result.invalid)) {
        len += 1
      }
      if (controller.birthdateDivTarget.classList.contains("d-none") && controller.birthdateInputTarget.value == "") {
        len += 1
      }

      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
