import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["formCard", "formTitle", "formCardBody", "codeInput",
                    "statusDropdown", "statusDropdownBtn", "statusInput", "statusList",
                    "kindDropdown", "kindDropdownBtn", "kindInput", "kindList",
                    "numberInput", "codeInput", "descriptionInput", "saveBtn"]


  connect() {
    this.controllerName = `operations--products--clinics--regularizations--cnaes--save`
  }

  disconnect() {
    this.stopRefreshing()
  }

  cancelSave() {
    this.stopRefreshing()
    this.getControllerByIdentifier("operations--products--clinics--regularizations--cnaes--index").clearList()
  }

  saveCnae() {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal({ message: `Em processamento, aguarde!` })

    this.send_data = { current_user: {}, cnae: {} }
    this.saveBtnTarget.disabled = true
    this.stopRefreshing()

    if (this.actionMode == `edit`) {
      this.send_data.cnae.id = this.current_cnae.id
    }

    var fullCode = this.codeInputTarget.value.trim()
    var codeArray = fullCode.split("-")
    var shortCode = codeArray.pop()
    var code = codeArray.join("-")
    
    this.send_data.cnae.code = this.getControllerByIdentifier(`app--helpers--numbers`).fromCodeToNumber(code)
    this.send_data.cnae.full_code = this.getControllerByIdentifier(`app--helpers--numbers`).fromCodeToNumber(fullCode)
    this.send_data.cnae.short_code = this.getControllerByIdentifier(`app--helpers--numbers`).fromCodeToNumber(shortCode)
    this.send_data.cnae.description = this.descriptionInputTarget.value.trim()

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = this.permission

    this.requestSave()
  }

  requestSave() {
    if (this.actionMode == `new`) {
      var url = "/app/datas/cnaes/entities/create"
      var method = "POST"
    } else if (this.actionMode == `edit`) {
      var url = "/app/datas/cnaes/entities/update"
      var method = "PUT"
    } else if (this.actionMode == `delete`) {
      var url = "/app/datas/cnaes/entities/destroy"
      var method = "DELETE"
    }

    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.stopRefreshing()
        if (response.save) {
          var cnae = response.data.cln

          if (this.actionMode == `new`) {
            controller.application.cnaes[controller.application.cnaes.length] = cnae
          } else if (this.actionMode == `edit`) {
            controller.application.cnaes.forEach((element, i) => {
              if (element.id == cnae.id) {
                controller.application.cnaes.splice(controller.application.cnaes.indexOf(element), 1, cnae)
              }
            })
          } else if (this.actionMode == `delete`) {
            controller.application.cnaes.forEach((element, i) => {
              if (element.id == cnae.id) {
                controller.application.cnaes.splice(controller.application.cnaes.indexOf(element), 1)
              }
            })
          }
        }

        controller.cancelSave()
        controller.getControllerByIdentifier(`operations--products--clinics--regularizations--cnaes--index`).doDataTable()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  doFormHtml() {
    this.stopRefreshing()

    if (this.application.permissions[this.permission].can_create) {
      var saveBtnHtml = `<button disabled type="button" class="btn btn-primary btn-sm py-2 btn-table f-065" data-action="click->${this.controllerName}#saveCnae" data-${this.controllerName}-target="saveBtn">Salvar</button>`
    } else {
      var saveBtnHtml = ``
    }

    var deleteBtnHtml = ``

    var html = `<div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="formCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 py-3" style="font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="formTitle"></h6>
                  </div>

                  <div class="card-body py-0" style="overflow:auto;" data-${this.controllerName}-target="formCardBody">
  
                    <div class="row my-2">
                      <div class="col-4">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="codeForm">Código</label>
                            <input aria-describedby="codeFormHelp" class="form-control" id="codeForm" data-${this.controllerName}-target="codeInput" data-action="blur->${this.controllerName}#codeMask keypress->${this.controllerName}#codeMask" placeholder="Código" type="text" required>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row my-2">
                      <div class="col-12">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="descriptionForm">Descrição</label>
                            <input aria-describedby="descriptionFormHelp" class="form-control form-valid-control" id="descriptionForm" data-${this.controllerName}-target="descriptionInput" placeholder="Descrição" type="text" required>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="card-footer border-top text-right py-1">
                    <button type="button" class="btn btn-secondary btn-sm py-2 btn-table f-065" data-action="click->${this.controllerName}#cancelSave">Fechar</button>
                    ${deleteBtnHtml}
                    ${saveBtnHtml}
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("operations--products--clinics--regularizations--cnaes--dashboard").viewTarget.innerHTML = html)
    }).then(() => {
      controller.formCardTarget.style.height = ($(window).height() * 0.6) + "px"

      if (controller.actionMode == `new`) {
        controller.formTitleTarget.innerText = `Nova CNAE`

      } else if (controller.actionMode == `edit`) {
        controller.formTitleTarget.innerText = `Editando CNAE | ${controller.getControllerByIdentifier(`app--helpers--numbers`).codeMask(controller.current_cnae.full_code)}`

        controller.codeInputTarget.value = controller.getControllerByIdentifier(`app--helpers--numbers`).codeMask(controller.current_cnae.full_code)
        controller.descriptionInputTarget.value = controller.current_cnae.description

        // controller.kindDropdownTarget.value = controller.current_cnae.kind
        // controller.kindInputTarget.innerText = controller.current_cnae.kind_pretty
        // controller.kindInputTarget.dataset.kind = controller.current_cnae.kind

        controller.codeInputTarget.disabled = false
        controller.descriptionInputTarget.disabled = false

      }

      controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
      controller.refreshSaveBtn()
    })

  }

  setNumber(ev) {
    var number = ev.currentTarget.dataset.number

    this.numberInputTarget.value = number
    this.getControllerByIdentifier("app--helpers--forms").floatingLabel()
  }

  showCnaes() {
    var html = `<div class="modal fade" data-hour="bottom" data-controller="operations--products--clinics--regularizations--cnaes--modal" data-operations--products--clinics--regularizations--cnaes--modal-target="modal" id="connectionModal" tabindex="-1" role="dialog" aria-labelledby="connectionModalTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div class="modal-content">
                      <div class="modal-body p-3" data-operations--products--clinics--regularizations--cnaes--modal-target="body"></div>
                    </div>
                  </div>
                </div>`

    document.body.insertAdjacentHTML("beforeend", html)
  }

  codeMask(ev) {
    this.getControllerByIdentifier("app--helpers--numbers").changeNumberToCode(ev)
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0

      if (controller.codeInputTarget.value == ``) {
        len += 1
      }

      if (controller.descriptionInputTarget.value == ``) {
        len += 1
      }


      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

}
