import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["mainDashboard", "list", "view", "viewLoanCard", "previewCard", "5555", "previewTitle", "7777", "8888", "9999"]

  connect() {
    this.controllerName = `financials--books--payables--loans--show`
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.cardLoader = this.getControllerByIdentifier("app--helpers--loaders").cardLoader()
    this.closeLoan = false
    this.payLoan = false
  }

  doNoLoan() {
    var html = `<div class="card mt-3" style="width:100%;display:relative;" data-${this.controllerName}-target="viewLoanCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header py-0 f-065 text-center">
                    <h6 class="card-title py-2 f-card110"><strong>Nenhum Empréstimo/Parcelamento Selecionado</strong></h6>
                  </div>
                </div>`

    this.getControllerByIdentifier("financials--books--payables--loans--dashboard").showTarget.innerHTML = html
  }

  addInstallment() {
    if (this.application.current_date.open) {
      this.getControllerByIdentifier("financials--books--payables--installments--save").current_loan = this.current_loan
      this.getControllerByIdentifier("financials--books--payables--installments--save").current_installments = {}
      this.getControllerByIdentifier("financials--books--payables--installments--save").actionMode = "new"
      this.getControllerByIdentifier("financials--books--payables--installments--save").doFormHtml()
    } else {
      this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(`danger`, `A Competência está Fechada. Favor selecionar a Competência atual ou abrir a Competência.`, 5000)
    }
  }

  editLoan() {
    if (this.application.current_date.open) {
      if (this.application.permissions.financial_payable_loans.can_update && this.current_loan.status != `finished`) {
        this.getControllerByIdentifier("financials--books--payables--loans--save").current_loan = this.current_loan
        this.getControllerByIdentifier("financials--books--payables--loans--save").actionMode = "edit"
        this.getControllerByIdentifier("financials--books--payables--loans--save").doFormHtml()
      } else {
        this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(`danger`, `Essa conta está Inativa e não pode ser Editada`, 5000)
      }
    } else {
      this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(`danger`, `A Competência está Fechada. Favor selecionar a Competência atual ou abrir a Competência.`, 5000)
    }
  }

  deleteLoan() {
    if (this.application.current_date.open) {
      this.send_data = { current_user: {}, loan: {} }

      this.actionMode = `delete`
      this.send_data.loan.id = this.current_loan.id
      this.send_data.loan.active = false

      this.send_data.current_user.current_user_id = this.application.current_user.id
      this.send_data.current_user.feature = `financial_payable_loans`

      var r = confirm(`Tem certeza que deseja apagar o Empréstimo/Parcelamento?`)
      if (r) {
        this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()
        this.getControllerByIdentifier("financials--books--payables--loans--save").send_data = this.send_data
        this.getControllerByIdentifier("financials--books--payables--loans--save").actionMode = `delete`
        this.getControllerByIdentifier("financials--books--payables--loans--save").requestSave()
      }
    } else {
      this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(`danger`, `A Competência está Fechada. Favor selecionar a Competência atual ou abrir a Competência.`, 5000)
    }
  }

  showLoan() {
    this.getControllerByIdentifier(`financials--books--payables--loans--save`).stopRefreshing()
    var spaceRow = `<td colspan="10" style="height:2rem;padding-top:1rem;padding-bottom:1rem;" scope="col"></td>`

    var hasNoDateInstallment = true
    this.application.loan_installments.forEach(installment => {
      if (installment.date_id == this.application.current_date.id) {
        hasNoDateInstallment = false
      }
    })

    var copyAmortization = `<button data-action="click->${this.controllerName}#copyAmortization" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                            <span class="material-icons md-sm md-dark">copy_all</span>
                            <span class="mc-tooltiptext">Copiar Valor de Amortização</span>
                          </button>`

    if (this.application.permissions.financial_payable_loans.can_delete && this.current_loan.status != `finished`) {
      var deleteLine = `<span data-action="click->${this.controllerName}#deleteLoan" class="dropdown-item py-1 pointer dropdown-submenu-item">Apagar Empréstimo/Parcelamento</span>`
    } else {
      var deleteLine = ``
    }

    if (this.application.permissions.financial_payable_loans.can_update && this.current_loan.status != `finished`) {
      var editLine = `<span data-action="click->${this.controllerName}#editLoan" class="dropdown-item py-1 pointer dropdown-submenu-item">Editar Empréstimo/Parcelamento</span>`
    } else {
      var editLine = ``
    }

    if (this.application.permissions.financial_payable_installments.can_create && hasNoDateInstallment) {
      var addLine = `<span data-action="click->${this.controllerName}#addInstallment" class="dropdown-item py-1 pointer dropdown-submenu-item">Adicionar Parcela do Mês</span>`
    } else {
      var addLine = ``
    }

    var clearBtnHtml = `<span class="mc-tooltip">
                          <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0 d-none" data-${this.controllerName}-target="clearListBtn" data-action="click->${this.controllerName}#clearList" type="button">
                            <span class="material-icons md-dark">clear</span>
                          </button>
                          <span class="mc-tooltiptext">Limpar</span>
                        </span>`

    var submenuHtml = `<div class="dropdown mc-tooltip" data-${this.controllerName}-target="optionBtn">
                        <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-toggle="dropdown" id="submenuMenuDrop" type="button"><span class="material-icons md-dark">more_vert</span></button>
                        <div aria-labelledby="submenuMenuDrop" class="dropdown-menu dropdown-menu-right menu" style="font-size:80%">
                          ${addLine}
                          ${editLine}
                          ${deleteLine}
                        </div>
                        <span class="mc-tooltiptext">Opções</span>
                      </div>`

    var html = `<div class="card mt-3" style="width:100%;display:relative;" data-${this.controllerName}-target="previewCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4" style="padding:1rem;font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="previewTitle"></h6>
                    <div class="card-actions ml-auto py-0">
                      ${clearBtnHtml}
                      ${submenuHtml}
                    </div>
                  </div>
                  <div class="card-body py-0" style="overflow:auto;" data-${this.controllerName}-target="previewCardBody">
                    <div class="row my-1">
                      <div class="col-12">
                        <table class="table table-sm table-borderless" style="font-size:80%;">
                          <tbody>
                            <tr>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                            </tr>
                            <tr>
                              <th colspan="3" class="f-065 align-middle px-0">
                                <strong>Data Contratação</strong>
                              </th>
                              <td colspan="7" class="f-065 align-middle px-0 text-left">
                                ${this.current_loan.purchase_pretty}
                              </td>
                            </tr>
                            <tr>
                              <th colspan="3" class="f-065 align-middle px-0">
                                <strong>Valor Contratado</strong>
                              </th>
                              <td colspan="7" class="f-065 align-middle px-0 text-left">
                                ${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(this.current_loan.purchase_amount)}
                              </td>
                            </tr>
                            <tr>
                              <th colspan="3" class="f-065 align-middle px-0">
                                <strong>Valor Já Pago</strong>
                              </th>
                              <td colspan="7" class="f-065 align-middle px-0 text-left">
                                ${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(this.current_loan.paid_amount)}
                              </td>
                            </tr>
                            <tr>
                              <th colspan="3" class="f-065 align-middle px-0">
                                <strong>Valor Parcela</strong>
                              </th>
                              <td colspan="4" class="f-065 align-middle px-0 text-left">
                                ${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(this.current_loan.amortization_amount)}
                              </td>
                              <td colspan="3" class="f-065 align-middle px-0 text-left">
                                ${copyAmortization}
                              </td>
                            </tr>
                            <tr>
                              <th colspan="3" class="f-065 align-middle px-0">
                                <strong># Parcelas</strong>
                              </th>
                              <td colspan="7" class="f-065 align-middle px-0 text-left">
                                ${this.getControllerByIdentifier("app--helpers--numbers").numberMask(this.current_loan.installments)}
                              </td>
                            </tr>
                            <tr>
                              <th colspan="3" class="f-065 align-middle px-0">
                                <strong>Parcelas Já Pagas</strong>
                              </th>
                              <td colspan="7" class="f-065 align-middle px-0 text-left">
                                ${this.getControllerByIdentifier("app--helpers--numbers").numberMask(this.current_loan.paid_installments)}
                              </td>
                            </tr>
                            <tr>
                              <th colspan="3" class="f-065 align-middle px-0">
                                <strong>Dia Pagamento</strong>
                              </th>
                              <td colspan="7" class="f-065 align-middle px-0 text-left">
                                ${this.current_loan.due_day}
                              </td>
                            </tr>
                            <tr>
                              <th colspan="3" class="f-065 align-middle px-0">
                                <strong>Tipo</strong>
                              </th>
                              <td colspan="7" class="f-065 align-middle px-0 text-left">
                                ${this.current_loan.kind_pretty}
                              </td>
                            </tr>
                            <tr>
                              <th colspan="3" class="f-065 align-middle px-0">
                                <strong>Forma Pagamento</strong>
                              </th>
                              <td colspan="7" class="f-065 align-middle px-0 text-left">
                                ${this.current_loan.method_pretty}
                              </td>
                            </tr>
                            <tr>
                              <th colspan="3" class="f-065 align-middle px-0">
                                <strong>Canal Pagamento</strong>
                              </th>
                              <td colspan="7" class="f-065 align-middle px-0 text-left">
                                ${this.current_loan.channel_name}
                              </td>
                            </tr>
                            <tr>
                              <th colspan="3" class="f-065 align-middle px-0">
                                <strong>Fornecedor</strong>
                              </th>
                              <td colspan="7" class="f-065 align-middle px-0 text-left">
                                ${this.current_loan.provider_name}
                              </td>
                            </tr>

                            
                            <tr>
                              ${spaceRow}
                            </tr>

                            
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("financials--books--payables--loans--dashboard").showTarget.innerHTML = html)
    }).then(() => {
      controller.previewCardTarget.style.height = ($(window).height() * 0.6) + "px"
      controller.previewTitleTarget.innerText = `${controller.current_loan.description}`
      controller.getControllerByIdentifier("app--helpers--elements").tooltip()
    })
  }

  copyAmortization() {
    var saveController = this.getControllerByIdentifier(`financials--books--payables--installments--save`)
    if (saveController.hasAmortizationAmountInputTarget) {
      saveController.amortizationAmountInputTarget.value = this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(this.current_loan.amortization_amount)
      saveController.bindInterests()
    } else {
      alert(`Favor abrir o formulário de Edição!`)
    }
  }

  showLoanLoader() {
    var html = `<div class="card pointer mt-3">
                  <div class="card-header py-0 f-065 text-center">
                    <h6 class="card-title py-1 f-card110">${this.loader}</h6>
                  </div>
                  <div class="card-body py-2 px-2 text-center" style="overflow:auto;">
                    ${this.loader}
                  </div>
                </div>`

    this.getControllerByIdentifier("financials--books--payables--loans--dashboard").showTarget.innerHTML = html
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = this.controllerName
    this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // })

}
