import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["modal", "bottom", "top", "list", "view", "mainCard", "cardBody", "cardFooter", "cardTitle",
                    "body", "loader", "mainRow", "chartAccounts", "resume", "economy",
                    "totalTaxableIncome", "totalExemptAndNonTaxableIncome", "totalIncomeExclusiveTaxation", "totalIncome",
                    "officialSocialContribution", "supplementaryPension", "dependents", "instructionExpense", "medicalExpenses", "alimony", "booking",
                    "totalIncome", "totalDeductions", "calculationBasis", "taxDue", "incentiveDeduction", "totalWithholdingIncomeTax", "taxDueRra",
                    "adjustedIncomeTaxAmount", "finalAdjusted", "finalAdjustedLabel", "saveBtn", "journeyCol", "totalWithholdingIncomeTax", "taxDueRra",
                    "simplifiedCheckbox", "completeCheckbox"]

  connect() {
    this.controllerName = `operations--products--tax-filings--summaries--save`
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.cardLoader = this.getControllerByIdentifier("app--helpers--loaders").cardLoader()
    this.numberController = this.getControllerByIdentifier(`app--helpers--numbers`)
  }

  open() {
    document.body.classList.add("modal-open");
    this.element.setAttribute("style", "display: block;");
    this.element.classList.add("show");
    var html = `<div class="modal-backdrop fade show"></div>`
    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.doModalHtml()
    })
  }

  close() {
    this.stopRefreshing()
    document.body.classList.remove("modal-open");
    this.element.removeAttribute("style");
    this.element.classList.remove("show");
    document.getElementsByClassName("modal-backdrop")[0].remove();
    this.modalTarget.remove()
  }

  doModalHtml() {
    var html = `<div class="card" style="width:100%;display:relative;" data-controller="app--helpers--search" data-${this.controllerName}-target="mainCard">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 card-title-table-list" data-${this.controllerName}-target="cardTitle">Editando Resumo IRPF ${this.application.current_date.financial_year}</h6>
                    <div class="card-actions ml-auto py-0 mc-tooltip">
                      <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-action="click->${this.controllerName}#close" type="button">
                        <span class="material-icons md-dark">clear</span>
                      </button>
                      <span class="mc-tooltiptext">Fechar</span>
                    </div>
                  </div>
                  <div class="card-body py-0" style="overflow:auto;" data-${this.controllerName}-target="cardBody"></div>
                  <div class="card-footer py-2 text-right" data-${this.controllerName}-target="cardFooter"></div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.bodyTarget.innerHTML = html)
    }).then(() => {
      controller.mainCardTarget.style.height = ($(window).height() * 0.8) + "px"
      if (controller.title) {
        controller.cardTitleTarget.innerText = controller.title
      }
      controller.doBodyHtml()
      controller.doFooterHtml()
    })
  }

  doBodyHtml() {
    var html = `<div class="row my-3">
                  <div class="col-3">
                    <div class="row">
                      <div class="col-12 px-0">
                        <p class="f-075 f-bold mb-0">Rendimentos</p>
                        <hr class="mt-1">
                      </div>
                      <div class="col-12 px-0">
                        <div class="form-group form-valid-group">
                          <div class="floating-label floating-label-sm">
                            <label for="totalTaxableIncome">Total Rendimento Tributável</label>
                            <input aria-describedby="totalTaxableIncomeHelp" class="form-control" id="totalTaxableIncome" data-${this.controllerName}-target="totalTaxableIncome" placeholder="" type="text" data-action="blur->${this.controllerName}#currencyMask keypress->${this.controllerName}#currencyMask keyup->${this.controllerName}#currencyMask blur->${this.controllerName}#bindTax">
                          </div>
                        </div>
                      </div>
                      <div class="col-12 px-0">
                        <div class="form-group form-valid-group">
                          <div class="floating-label floating-label-sm">
                            <label for="totalExemptAndNonTaxableIncome">Total Rendimento Isento e Não-Tributável</label>
                            <input aria-describedby="totalExemptAndNonTaxableIncomeHelp" class="form-control" id="totalExemptAndNonTaxableIncome" data-${this.controllerName}-target="totalExemptAndNonTaxableIncome" placeholder="" type="text" data-action="blur->${this.controllerName}#currencyMask keypress->${this.controllerName}#currencyMask keyup->${this.controllerName}#currencyMask blur->${this.controllerName}#bindTax">
                          </div>
                        </div>
                      </div>
                      <div class="col-12 px-0">
                        <div class="form-group form-valid-group">
                          <div class="floating-label floating-label-sm">
                            <label for="totalIncomeExclusiveTaxation">Total Rendimento Tributação Exclusiva</label>
                            <input aria-describedby="totalIncomeExclusiveTaxationHelp" class="form-control" id="totalIncomeExclusiveTaxation" data-${this.controllerName}-target="totalIncomeExclusiveTaxation" placeholder="" type="text" data-action="blur->${this.controllerName}#currencyMask keypress->${this.controllerName}#currencyMask keyup->${this.controllerName}#currencyMask blur->${this.controllerName}#bindTax">
                          </div>
                        </div>
                      </div>
                      <div class="col-12 px-0">
                        <div class="form-group form-valid-group">
                          <div class="floating-label floating-label-sm">
                            <label for="totalIncome">Total Rendimentos</label>
                            <input aria-describedby="totalIncomeHelp" class="form-control" id="totalIncome" data-${this.controllerName}-target="totalIncome" placeholder="" type="text" data-action="blur->${this.controllerName}#currencyMask keypress->${this.controllerName}#currencyMask keyup->${this.controllerName}#currencyMask blur->${this.controllerName}#bindTax" readonly>
                          </div>
                        </div>
                      </div>
                      <div class="col-6 mt-5">
                        <div class="custom-control custom-checkbox pr-0 pl-2 w-100 my-0 d-flex">
                          <input type="checkbox" class="custom-control-input" id="simplifiedCheck" data-${this.controllerName}-target="simplifiedCheckbox" data-taxation="simplified" data-action="click->${this.controllerName}#typeCheck">
                          <label class="custom-control-label f-065 pointer" for="simplifiedCheck">Simplicada</label>
                        </div>
                      </div>
                      <div class="col-6 mt-5">
                        <div class="custom-control custom-checkbox pr-0 pl-2 w-100 my-0 d-flex">
                          <input type="checkbox" class="custom-control-input" id="completeCheck" data-${this.controllerName}-target="completeCheckbox" data-taxation="complete" data-action="click->${this.controllerName}#typeCheck">
                          <label class="custom-control-label f-065 pointer" for="completeCheck">Completa</label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-3">
                    <div class="row">
                      <div class="col-12 px-0">
                        <p class="f-075 f-bold mb-0">Deduções</p>
                        <hr class="mt-1">
                      </div>
                      <div class="col-12 px-0">
                        <div class="form-group form-valid-group">
                          <div class="floating-label floating-label-sm">
                            <label for="officialSocialContribution">Previdência Oficial Pública</label>
                            <input aria-describedby="officialSocialContributionHelp" class="form-control" id="officialSocialContribution" data-${this.controllerName}-target="officialSocialContribution" placeholder="" type="text" data-action="blur->${this.controllerName}#currencyMask keypress->${this.controllerName}#currencyMask keyup->${this.controllerName}#currencyMask blur->${this.controllerName}#bindTax">
                          </div>
                        </div>
                      </div>
                      <div class="col-12 px-0">
                        <div class="form-group form-valid-group">
                          <div class="floating-label floating-label-sm">
                            <label for="supplementaryPension">Previdência Privada</label>
                            <input aria-describedby="supplementaryPensionHelp" class="form-control" id="supplementaryPension" data-${this.controllerName}-target="supplementaryPension" placeholder="" type="text" data-action="blur->${this.controllerName}#currencyMask keypress->${this.controllerName}#currencyMask keyup->${this.controllerName}#currencyMask blur->${this.controllerName}#bindTax">
                          </div>
                        </div>
                      </div>
                      <div class="col-12 px-0">
                        <div class="form-group form-valid-group">
                          <div class="floating-label floating-label-sm">
                            <label for="dependents">Dedução Dependentes</label>
                            <input aria-describedby="dependentsHelp" class="form-control" id="dependents" data-${this.controllerName}-target="dependents" placeholder="" type="text" data-action="blur->${this.controllerName}#currencyMask keypress->${this.controllerName}#currencyMask keyup->${this.controllerName}#currencyMask blur->${this.controllerName}#bindTax">
                          </div>
                        </div>
                      </div>
                      <div class="col-12 px-0">
                        <div class="form-group form-valid-group">
                          <div class="floating-label floating-label-sm">
                            <label for="instructionExpense">Dedução com Instrução</label>
                            <input aria-describedby="instructionExpenseHelp" class="form-control" id="instructionExpense" data-${this.controllerName}-target="instructionExpense" placeholder="" type="text" data-action="blur->${this.controllerName}#currencyMask keypress->${this.controllerName}#currencyMask keyup->${this.controllerName}#currencyMask blur->${this.controllerName}#bindTax">
                          </div>
                        </div>
                      </div>
                      <div class="col-12 px-0">
                        <div class="form-group form-valid-group">
                          <div class="floating-label floating-label-sm">
                            <label for="medicalExpenses">Dedução com Saúde</label>
                            <input aria-describedby="medicalExpensesHelp" class="form-control" id="medicalExpenses" data-${this.controllerName}-target="medicalExpenses" placeholder="" type="text" data-action="blur->${this.controllerName}#currencyMask keypress->${this.controllerName}#currencyMask keyup->${this.controllerName}#currencyMask blur->${this.controllerName}#bindTax">
                          </div>
                        </div>
                      </div>
                      <div class="col-12 px-0">
                        <div class="form-group form-valid-group">
                          <div class="floating-label floating-label-sm">
                            <label for="alimony">Dedução com Pensão Alimentícia</label>
                            <input aria-describedby="alimonyHelp" class="form-control" id="alimony" data-${this.controllerName}-target="alimony" placeholder="" type="text" data-action="blur->${this.controllerName}#currencyMask keypress->${this.controllerName}#currencyMask keyup->${this.controllerName}#currencyMask blur->${this.controllerName}#bindTax">
                          </div>
                        </div>
                      </div>
                      <div class="col-12 px-0">
                        <div class="form-group form-valid-group">
                          <div class="floating-label floating-label-sm">
                            <label for="booking">Dedução Despesas Livro-Caixa</label>
                            <input aria-describedby="bookingHelp" class="form-control" id="booking" data-${this.controllerName}-target="booking" placeholder="" type="text" data-action="blur->${this.controllerName}#currencyMask keypress->${this.controllerName}#currencyMask keyup->${this.controllerName}#currencyMask blur->${this.controllerName}#bindTax">
                          </div>
                        </div>
                      </div>
                      <div class="col-12 px-0">
                        <div class="form-group form-valid-group">
                          <div class="floating-label floating-label-sm">
                            <label for="totalDeductions">Total Deduções</label>
                            <input aria-describedby="totalDeductionsHelp" class="form-control" id="totalDeductions" data-${this.controllerName}-target="totalDeductions" placeholder="" type="text" data-action="blur->${this.controllerName}#currencyMask keypress->${this.controllerName}#currencyMask keyup->${this.controllerName}#currencyMask blur->${this.controllerName}#bindTax" readonly>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-3">
                    <div class="row">
                      <div class="col-12 px-0">
                        <p class="f-075 f-bold mb-0">Cálculo Imposto</p>
                        <hr class="mt-1">
                      </div>
                      <div class="col-12 px-0">
                        <div class="form-group form-valid-group">
                          <div class="floating-label floating-label-sm">
                            <label for="calculationBasis">Base de Cálculo</label>
                            <input aria-describedby="calculationBasisHelp" class="form-control" id="calculationBasis" data-${this.controllerName}-target="calculationBasis" placeholder="" type="text" data-action="blur->${this.controllerName}#currencyMask keypress->${this.controllerName}#currencyMask keyup->${this.controllerName}#currencyMask blur->${this.controllerName}#bindTax" readonly>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 px-0">
                        <div class="form-group form-valid-group">
                          <div class="floating-label floating-label-sm">
                            <label for="taxDue">Imposto Total Devido</label>
                            <input aria-describedby="taxDueHelp" class="form-control" id="taxDue" data-${this.controllerName}-target="taxDue" placeholder="" type="text" data-action="blur->${this.controllerName}#currencyMask keypress->${this.controllerName}#currencyMask keyup->${this.controllerName}#currencyMask blur->${this.controllerName}#bindTax" readonly>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 px-0">
                        <div class="form-group form-valid-group">
                          <div class="floating-label floating-label-sm">
                            <label for="totalWithholdingIncomeTax">Imposto Total Retido</label>
                            <input aria-describedby="totalWithholdingIncomeTaxHelp" class="form-control" id="totalWithholdingIncomeTax" data-${this.controllerName}-target="totalWithholdingIncomeTax" placeholder="" type="text" data-action="blur->${this.controllerName}#currencyMask keypress->${this.controllerName}#currencyMask keyup->${this.controllerName}#currencyMask blur->${this.controllerName}#bindTax">
                          </div>
                        </div>
                      </div>
                      <div class="col-12 px-0">
                        <div class="form-group form-valid-group">
                          <div class="floating-label floating-label-sm">
                            <label for="incentiveDeduction">Dedução Incentivo</label>
                            <input aria-describedby="incentiveDeductionHelp" class="form-control" id="incentiveDeduction" data-${this.controllerName}-target="incentiveDeduction" placeholder="" type="text" data-action="blur->${this.controllerName}#currencyMask keypress->${this.controllerName}#currencyMask keyup->${this.controllerName}#currencyMask blur->${this.controllerName}#bindTax">
                          </div>
                        </div>
                      </div>
                      <div class="col-12 px-0">
                        <div class="form-group form-valid-group">
                          <div class="floating-label floating-label-sm">
                            <label for="taxDueRra">Imposto Devido RRA</label>
                            <input aria-describedby="taxDueRraHelp" class="form-control" id="taxDueRra" data-${this.controllerName}-target="taxDueRra" placeholder="" type="text" data-action="blur->${this.controllerName}#currencyMask keypress->${this.controllerName}#currencyMask keyup->${this.controllerName}#currencyMask blur->${this.controllerName}#bindTax">
                          </div>
                        </div>
                      </div>
                      <div class="col-12 px-0">
                        <div class="form-group form-valid-group">
                          <div class="floating-label floating-label-sm">
                            <label for="adjustedIncomeTaxAmount">Imposto Total Ajustado</label>
                            <input aria-describedby="adjustedIncomeTaxAmountHelp" class="form-control" id="adjustedIncomeTaxAmount" data-${this.controllerName}-target="adjustedIncomeTaxAmount" placeholder="" type="text" data-action="blur->${this.controllerName}#currencyMask keypress->${this.controllerName}#currencyMask keyup->${this.controllerName}#currencyMask blur->${this.controllerName}#bindTax" readonly>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 px-0">
                        <p class="f-075 f-bold mb-0">Ajuste Final</p>
                        <hr class="mt-1">
                      </div>
                      <div class="col-12 px-0">
                        <div class="form-group form-valid-group">
                          <div class="floating-label floating-label-sm">
                            <label for="finalAdjusted" data-${this.controllerName}-target="finalAdjustedLabel"></label>
                            <input aria-describedby="finalAdjustedHelp" class="form-control" id="finalAdjusted" data-${this.controllerName}-target="finalAdjusted" placeholder="" type="text" data-action="blur->${this.controllerName}#currencyMask keypress->${this.controllerName}#currencyMask keyup->${this.controllerName}#currencyMask blur->${this.controllerName}#bindTax" readonly>
                          </div>
                        </div>
                      </div>
                      
                    </div>
                  </div>
                  <div class="col-3" data-${this.controllerName}-target="journeyCol" data-operations--products--tax-filings--journey--upload-target="uploadArea" data-controller="operations--products--tax-filings--journey--upload">
                  </div>
                  
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.cardBodyTarget.innerHTML = html)
    }).then(() => {
      controller.setFieldValues()
      controller.refreshSaveBtn()
      
      if (controller.getControllerByIdentifier(`operations--products--tax-filings--journey--save`)) {
        controller.getControllerByIdentifier(`operations--products--tax-filings--journey--upload`).journey_status = controller.journey_status
        controller.getControllerByIdentifier(`operations--products--tax-filings--journey--upload`).doUploadHtml()
      }
      
      setTimeout(() => {
        controller.bindTax()
      }, 500)
      controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
    })
  }

  setFieldValues() {
    this.totalTaxableIncomeTarget.value = this.numberController.currencyOptionMask(this.application.current_summary.total_taxable_income)
    this.totalExemptAndNonTaxableIncomeTarget.value = this.numberController.currencyOptionMask(this.application.current_summary.total_exempt_and_non_taxable_income)
    this.totalIncomeExclusiveTaxationTarget.value = this.numberController.currencyOptionMask(this.application.current_summary.total_income_exclusive_taxation)
    this.officialSocialContributionTarget.value = this.numberController.currencyOptionMask(this.application.current_summary.official_social_contribution)
    this.supplementaryPensionTarget.value = this.numberController.currencyOptionMask(this.application.current_summary.supplementary_pension)
    this.dependentsTarget.value = this.numberController.currencyOptionMask(this.application.current_summary.dependents)
    this.instructionExpenseTarget.value = this.numberController.currencyOptionMask(this.application.current_summary.instruction_expense)
    this.medicalExpensesTarget.value = this.numberController.currencyOptionMask(this.application.current_summary.medical_expenses)
    this.alimonyTarget.value = this.numberController.currencyOptionMask(this.application.current_summary.alimony)
    this.bookingTarget.value = this.numberController.currencyOptionMask(this.application.current_summary.booking)
    this.totalIncomeTarget.value = this.numberController.currencyOptionMask(this.application.current_summary.total_income)
    this.totalDeductionsTarget.value = this.numberController.currencyOptionMask(this.application.current_summary.total_deductions)
    this.taxDueTarget.value = this.numberController.currencyOptionMask(this.application.current_summary.tax_due)
    this.calculationBasisTarget.value = this.numberController.currencyOptionMask(this.application.current_summary.calculation_basis)
    this.incentiveDeductionTarget.value = this.numberController.currencyOptionMask(this.application.current_summary.incentive_deduction)
    this.totalWithholdingIncomeTaxTarget.value = this.numberController.currencyOptionMask(this.application.current_summary.total_withholding_income_tax)
    this.taxDueRraTarget.value = this.numberController.currencyOptionMask(this.application.current_summary.tax_due_rra)
    this.adjustedIncomeTaxAmountTarget.value = this.numberController.currencyOptionMask(this.application.current_summary.adjusted_income_tax_amount)

    if (this.application.current_summary.taxation == `simplified`) {
      this.simplifiedCheckboxTarget.checked = true
    } else if (this.application.current_summary.taxation == `complete`) {
      this.completeCheckboxTarget.checked = true
    }

    if (this.application.current_user.account_kind == `doctor`) {
      this.totalTaxableIncomeTarget.disabled = true
      this.totalExemptAndNonTaxableIncomeTarget.disabled = true
      this.totalIncomeExclusiveTaxationTarget.disabled = true
      this.officialSocialContributionTarget.disabled = true
      this.supplementaryPensionTarget.disabled = true
      this.dependentsTarget.disabled = true
      this.instructionExpenseTarget.disabled = true
      this.medicalExpensesTarget.disabled = true
      this.alimonyTarget.disabled = true
      this.bookingTarget.disabled = true
      this.totalIncomeTarget.disabled = true
      this.totalDeductionsTarget.disabled = true
      this.taxDueTarget.disabled = true
      this.calculationBasisTarget.disabled = true
      this.incentiveDeductionTarget.disabled = true
      this.totalWithholdingIncomeTaxTarget.disabled = true
      this.taxDueRraTarget.disabled = true
      this.adjustedIncomeTaxAmountTarget.disabled = true
    }
  }

  bindTax() {
    var totalTaxableIncome = this.numberController.fromCurrencyToNumber(this.totalTaxableIncomeTarget.value)
    var totalExemptAndNonTaxableIncome = this.numberController.fromCurrencyToNumber(this.totalExemptAndNonTaxableIncomeTarget.value)
    var totalIncomeExclusiveTaxation = this.numberController.fromCurrencyToNumber(this.totalIncomeExclusiveTaxationTarget.value)
    
    var totalIncome = Number(totalTaxableIncome) + Number(totalExemptAndNonTaxableIncome) + Number(totalIncomeExclusiveTaxation)
    this.totalIncomeTarget.value = this.numberController.currencyOptionMask(totalIncome)

    var officialSocialContribution = this.numberController.fromCurrencyToNumber(this.officialSocialContributionTarget.value)
    var supplementaryPension = this.numberController.fromCurrencyToNumber(this.supplementaryPensionTarget.value)
    var dependents = this.numberController.fromCurrencyToNumber(this.dependentsTarget.value)
    var instructionExpense = this.numberController.fromCurrencyToNumber(this.instructionExpenseTarget.value)
    var medicalExpenses = this.numberController.fromCurrencyToNumber(this.medicalExpensesTarget.value)
    var alimony = this.numberController.fromCurrencyToNumber(this.alimonyTarget.value)
    var booking = this.numberController.fromCurrencyToNumber(this.bookingTarget.value)
    
    var totalDeductions = Number(officialSocialContribution) + Number(supplementaryPension) + Number(dependents) + Number(instructionExpense) + Number(medicalExpenses) + Number(alimony) + Number(booking)
    this.totalDeductionsTarget.value = this.numberController.currencyOptionMask(totalDeductions)
    
    var calculationBasis = Number(totalTaxableIncome) - Number(totalDeductions)
    this.calculationBasisTarget.value = this.numberController.currencyOptionMask(calculationBasis)
    
    var taxDue = this.setTaxDue(calculationBasis)

    var incentiveDeduction = this.numberController.fromCurrencyToNumber(this.incentiveDeductionTarget.value)
    var totalWithholdingIncomeTax = this.numberController.fromCurrencyToNumber(this.totalWithholdingIncomeTaxTarget.value)
    var taxDueRra = this.numberController.fromCurrencyToNumber(this.taxDueRraTarget.value)

    var adjustedIncomeTaxAmount = Number(taxDue) - Number(incentiveDeduction) - Number(totalWithholdingIncomeTax) - Number(taxDueRra)
    this.adjustedIncomeTaxAmountTarget.value = this.numberController.currencyOptionMask(adjustedIncomeTaxAmount)
    
    
    
    var finalAdjusted = Math.abs(adjustedIncomeTaxAmount)
    this.finalAdjustedTarget.value = this.numberController.currencyOptionMask(finalAdjusted)
    if (adjustedIncomeTaxAmount > 0) {
      var finalAdjustedLabelText = `Saldo de Imposto à Pagar`
      var finalAdjustedLabelStatement = `to_pay`
    } else if (adjustedIncomeTaxAmount < 0) {
      var finalAdjustedLabelText = `Imposto à Restituir`
      var finalAdjustedLabelStatement = `to_refund`
    } else if (adjustedIncomeTaxAmount == 0) {
      var finalAdjustedLabelText = `Sem Ajuste`
      var finalAdjustedLabelStatement = `do_nothing`
    }
    // this.finalAdjustedLabelTarget.innerText = finalAdjustedLabelText
    // this.finalAdjustedLabelTarget.dataset.statement = finalAdjustedLabelStatement
    // if (this.getControllerByIdentifier(`operations--products--tax-filings--journey--save`)) {
    //   this.getControllerByIdentifier(`operations--products--tax-filings--journey--upload`).finalAdjustedTarget.value = finalAdjustedLabelText
    //   this.getControllerByIdentifier(`operations--products--tax-filings--journey--upload`).finalAdjustedTarget.dataset.statement = finalAdjustedLabelStatement
    // }

    this.finalAdjustedLabelTarget.innerText = finalAdjustedLabelText
    this.finalAdjustedLabelTarget.dataset.statement = finalAdjustedLabelStatement
    
    if (this.getControllerByIdentifier(`operations--products--tax-filings--journey--save`)) {
      this.getControllerByIdentifier(`operations--products--tax-filings--journey--upload`).finalAdjustedInputTarget.innerText = finalAdjustedLabelText
      this.getControllerByIdentifier(`operations--products--tax-filings--journey--upload`).finalAdjustedInputTarget.dataset.statement = finalAdjustedLabelStatement
      this.getControllerByIdentifier(`operations--products--tax-filings--journey--upload`).finalAdjustedDropdownTarget.value = finalAdjustedLabelStatement
    }
    
    this.getControllerByIdentifier("app--helpers--forms").floatingLabel()
  }
  
  setTaxDue(base) {

    var due = 0

    // Until 2023
    // 
    // if (base > Number(1903.98 * 12) && base <= Number(2826.65 * 12)) {
    //   due = Number(0.075 * base) - Number(142.80 * 12)
    // } else if (base > Number(2826.65 * 12) && base <= Number(3751.05 * 12)) {
    //   due = Number(0.15 * base) - Number(354.80 * 12)
    // } else if (base > Number(3751.05 * 12) && base <= Number(4664.68 * 12)) {
    //   due = Number(0.225 * base) - Number(636.13 * 12)
    // } else if (base > Number(4664.68 * 12)) {
    //   due = Number(0.275 * base) - Number(869.36 *12)
    // } else if (base <= Number(1903.98 * 12)) {
    //   due = 0
    // }

    // From 2024
    
    if (base > Number(2042.66 * 12) && base <= Number(2826.65 * 12)) {
      due = Number(0.075 * base) - Number(153.19950 * 12)
    } else if (base > Number(2826.65 * 12) && base <= Number(3751.05 * 12)) {
      due = Number(0.15 * base) - Number(365.19825 * 12)
    } else if (base > Number(3751.05 * 12) && base <= Number(4664.68 * 12)) {
      due = Number(0.225 * base) - Number(646.52700 * 12)
    } else if (base > Number(4664.68 * 12)) {
      due = Number(0.275 * base) - Number(879.76100 *12)
    } else if (base <= Number(2042.66 * 12)) {
      due = 0
    }
    
    this.taxDueTarget.value = this.numberController.currencyOptionMask(due)

    return due
  }

  typeCheck(ev) {
    var taxation = ev.currentTarget.dataset.taxation

    if (taxation == `simplified`) {
      this.simplifiedCheckboxTarget.checked = true
      this.completeCheckboxTarget.checked = false
    } else if (taxation == `complete`) {
      this.simplifiedCheckboxTarget.checked = false
      this.completeCheckboxTarget.checked = true
    }
  }

  doFooterHtml() {
    if (this.application.permissions[this.application.summary_permission].can_create) {
      var saveBtnHtml = `<button type="button" class="btn btn-primary btn-sm py-2 btn-table f-065" data-action="click->${this.controllerName}#saveSummary" data-${this.controllerName}-target="saveBtn">Salvar</button>`
    } else {
      var saveBtnHtml = ``
    }

    this.cardFooterTarget.innerHTML = saveBtnHtml
  }

  saveSummary() {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal({ message: `Em processamento, aguarde!` })
    this.saveBtnTarget.disabled = true
    this.stopRefreshing()

    this.send_data = { current_user: {}, summary: {}, tracker: { manual: true } }

    this.send_data.summary.id = this.application.current_summary.id
    this.send_data.summary.total_taxable_income = this.numberController.fromCurrencyToNumber(this.totalTaxableIncomeTarget.value)
    this.send_data.summary.total_exempt_and_non_taxable_income = this.numberController.fromCurrencyToNumber(this.totalExemptAndNonTaxableIncomeTarget.value)
    this.send_data.summary.total_income_exclusive_taxation = this.numberController.fromCurrencyToNumber(this.totalIncomeExclusiveTaxationTarget.value)
    this.send_data.summary.official_social_contribution = this.numberController.fromCurrencyToNumber(this.officialSocialContributionTarget.value)
    this.send_data.summary.supplementary_pension = this.numberController.fromCurrencyToNumber(this.supplementaryPensionTarget.value)
    this.send_data.summary.dependents = this.numberController.fromCurrencyToNumber(this.dependentsTarget.value)
    this.send_data.summary.instruction_expense = this.numberController.fromCurrencyToNumber(this.instructionExpenseTarget.value)
    this.send_data.summary.medical_expenses = this.numberController.fromCurrencyToNumber(this.medicalExpensesTarget.value)
    this.send_data.summary.alimony = this.numberController.fromCurrencyToNumber(this.alimonyTarget.value)
    this.send_data.summary.booking = this.numberController.fromCurrencyToNumber(this.bookingTarget.value)
    this.send_data.summary.total_income = this.numberController.fromCurrencyToNumber(this.totalIncomeTarget.value)
    this.send_data.summary.total_deductions = this.numberController.fromCurrencyToNumber(this.totalDeductionsTarget.value)
    this.send_data.summary.tax_due = this.numberController.fromCurrencyToNumber(this.taxDueTarget.value)
    this.send_data.summary.calculation_basis = this.numberController.fromCurrencyToNumber(this.calculationBasisTarget.value)
    this.send_data.summary.incentive_deduction = this.numberController.fromCurrencyToNumber(this.incentiveDeductionTarget.value)
    this.send_data.summary.total_withholding_income_tax = this.numberController.fromCurrencyToNumber(this.totalWithholdingIncomeTaxTarget.value)
    this.send_data.summary.tax_due_rra = this.numberController.fromCurrencyToNumber(this.taxDueRraTarget.value)
    this.send_data.summary.adjusted_income_tax_amount = this.numberController.fromCurrencyToNumber(this.adjustedIncomeTaxAmountTarget.value)
    this.send_data.summary.statement = this.finalAdjustedLabelTarget.dataset.statement

    if (this.simplifiedCheckboxTarget.checked) {
      this.send_data.summary.taxation = `simplified`
    } else if (this.completeCheckboxTarget.checked) {
      this.send_data.summary.taxation = `complete`      
    }

    this.send_data.tracker.record_id = this.application.tax_filing.id
    this.send_data.tracker.record_type = `operation_products`
    this.send_data.tracker.obj_id = this.application.current_date.id
    this.send_data.tracker.obj_type = `operation_dates`
    this.send_data.tracker.action = `updated`
    this.send_data.tracker.observations = `Atualizou o Resumo do IRPF ${this.application.current_date.financial_year}`

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = this.application.summary_permission

    if (this.getControllerByIdentifier(`operations--products--tax-filings--journey--save`)) {
      this.getControllerByIdentifier(`operations--products--tax-filings--journey--upload`).prepareToRequestSave()
    } else {
      this.requestSave()
    }

  }

  requestSave() {
    var url = "/operations/products/tax_filings/summaries/update"
    var method = "PUT"

    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.stopRefreshing()
        if (response.save) {
          var summary = response.data.cln

          controller.application.summaries.forEach((element, i) => {
            if (element.id == summary.id) {
              controller.application.summaries.splice(controller.application.summaries.indexOf(element), 1, summary)
            }
          })

          controller.application.current_summary = summary
        }

        controller.close()
        
        if (controller.getControllerByIdentifier(`operations--products--tax-filings--summaries--show`)) {
          controller.getControllerByIdentifier(`operations--products--tax-filings--summaries--show`).doShowHtml()
        }
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  currencyMask(ev) {
    this.numberController.changeNumberToCurrency(ev)
  }

  refreshSaveBtn() {
    var controller = this
    if (this.hasSaveBtnTarget) {
      this.refreshTimer = setInterval(function () {
        var len = 0
        
        if (controller.finalAdjustedTarget.value == ``) {
          len += 1
        }

        if (controller.getControllerByIdentifier(`operations--products--tax-filings--journey--save`)) {
          if (controller.journey_status == `draft_sent`) {
            if (controller.getControllerByIdentifier(`operations--products--tax-filings--journey--upload`).fileDraftNameTarget.innerText == ``) {
              len += 1
            }
          } else if (controller.journey_status == `transmitted`) {
            if (controller.getControllerByIdentifier(`operations--products--tax-filings--journey--upload`).fileFilingNameTarget.innerText == ``) {
              len += 1
            }
            if (controller.getControllerByIdentifier(`operations--products--tax-filings--journey--upload`).fileReceiptNameTarget.innerText == ``) {
              len += 1
            }
          } else if (controller.journey_status == `draft_sent`) {
          }
        }
        
        if (len == 0) {
          controller.saveBtnTarget.disabled = false
        } else {
          controller.saveBtnTarget.disabled = true
        }
      }, 200);
    }
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }
}