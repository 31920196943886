import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["formCard", "formTitle", "formCardBody", "emailInput", "nameInput", "dddInput", "saveBtn",
                    "phoneInput", "checkboxAcademic", "checkboxDoctor", "checkboxNotDoctor", "doctorRow", "crmInput",
                    "crmStateDropdown", "crmStateDropdownBtn", "crmStateInput", "academicRow", "medschoolInput", "graduationInput",
                    "crmStateList", "searchBtn", "uniqPhoneInput", "uniqCrmInput", "uniqGraduationInput"]


  connect() {
    this.controllerName = `commercial--marketing--landings--conversions--save`
    this.send_data = { current_user: {}, event: {} }
  }

  cancelSave() {
    this.stopRefreshing()
    this.getControllerByIdentifier(`commercial--marketing--landings--conversions--show`).doViewHtml()
    this.getControllerByIdentifier(`commercial--marketing--landings--conversions--list`).doDataTable()
  }

  saveConversion() {
    this.send_data = { current_user: {}, conversion: {}, lead: {} }

    this.send_data.conversion.id = this.current_conversion.id
    this.send_data.lead.id = this.current_conversion.lead_id
    

    this.send_data.lead.name = this.nameInputTarget.value.trim()
    this.send_data.lead.email = this.emailInputTarget.value.trim()
    this.send_data.lead.ddd = this.dddInputTarget.value.trim()
    this.send_data.lead.number = this.getControllerByIdentifier("app--helpers--numbers").fromPhoneToNumber(this.phoneInputTarget.value.trim())
    if (this.kind == `academic`) {
      this.send_data.lead.uniq_crm = `${this.medschoolInputTarget.value.trim()} | ${this.graduationInputTarget.value.trim()}`
    } else if (this.kind == `doctor`) {
      this.send_data.lead.crm = this.getControllerByIdentifier("app--helpers--numbers").fromCrmToNumber(this.crmInputTarget.value.trim())
      this.send_data.lead.crm_state = this.crmStateInputTarget.innerText
      this.send_data.lead.uniq_crm = `${this.crmStateInputTarget.innerText}${this.crmInputTarget.value.trim()}`
    }
    this.send_data.lead.kind = this.kind

    this.send_data.current_user.current_user_id = this.application.current_user.id

    this.requestSave()

  }

  requestSave() {
    this.stopRefreshing()
    var url = "/commercial/marketing/landings/conversions/update"
    var method = "PUT"
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          controller.stopRefreshing()
          var conversion = response.data.cln
          if (this.actionMode == `new`) {
            controller.application.conversions[controller.application.conversions.length] = conversion
          } else if (this.actionMode == `edit`) {
            controller.application.conversions.forEach((element, i) => {
              if (element.id == conversion.id) {
                controller.application.conversions.splice(controller.application.conversions.indexOf(element), 1, conversion)
              }
            })
          }
          controller.cancelSave()
        } else {
          controller.saveBtnTarget.disabled = false
        }
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 3000)
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  doFormHtml() {
    this.stopRefreshing()

    if (this.application.permissions.landing_conversions.can_update) {
      var saveBtnHtml = `<button disabled type="button" class="btn btn-primary btn-sm py-2 btn-table f-065" data-action="click->${this.controllerName}#saveConversion" data-${this.controllerName}-target="saveBtn">Salvar</button>`
    } else {
      var saveBtnHtml = ``
    }

    var html = `<div class="row">
                  <div class="col-12 px-0">
                    <div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="formCard" data-action="resize@window->${this.controllerName}#layout">
                      <div class="card-header d-flex align-items-center card-header-table-list f-065">
                        <h6 class="card-title display-4 py-3" style="font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="formTitle"></h6>
                      </div>
                      <div class="card-body py-3 px-0" style="overflow:auto;" data-${this.controllerName}-target="formCardBody">
                        <div class="row d-flex align-items-center">
                          <div class="col-6 pr-2">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="">Nome</label>
                                <input class="form-control f-075" data-${this.controllerName}-target="nameInput" type="text" placeholder="Nome" required>
                              </div>
                            </div>
                          </div>
                          <div class="col-6 pr-2">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="">E-mail</label>
                                <input class="form-control f-075" data-${this.controllerName}-target="emailInput" type="text" placeholder="E-mail" required>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-6 pr-2">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="">Telefone Informado</label>
                                <input disabled class="form-control f-075" data-${this.controllerName}-target="uniqPhoneInput" type="text" placeholder="Telefone" required>
                              </div>
                            </div>
                          </div>
                          <div class="col-2 px-2">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="">DDD</label>
                                <input class="form-control form-valid-control" data-${this.controllerName}-target="dddInput" data-action="blur->${this.controllerName}#prefixMask keyup->${this.controllerName}#prefixMask keypress->${this.controllerName}#prefixMask" placeholder="DDD" type="text" required>
                              </div>
                            </div>
                          </div>
                          <div class="col-4 px-2">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="">Telefone</label>
                                <input class="form-control form-valid-control" data-${this.controllerName}-target="phoneInput" data-action="blur->${this.controllerName}#phoneMask keyup->${this.controllerName}#phoneMask keypress->${this.controllerName}#phoneMask" placeholder="Telefone" type="text" required>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-12 my-3 d-flex justify-content-between">
                            <div class="form-group form-check d-flex align-items-center mr-3 mb-0">
                              <input disabled type="checkbox" class="form-check-input" name="academicConversion" data-${this.controllerName}-target="checkboxAcademic" data-kind="academic" data-action="click->${this.controllerName}#checkKind">
                              <label class="form-check-label ml-1" for="academicConversion" style="margin-left:1rem;">Acadêmico</label>
                            </div>
                            <div class="form-group form-check d-flex align-items-center mr-3 mb-0">
                              <input disabled type="checkbox" class="form-check-input" name="doctorConversion" data-${this.controllerName}-target="checkboxDoctor" data-kind="doctor" data-action="click->${this.controllerName}#checkKind">
                              <label class="form-check-label ml-1" for="doctorConversion" style="margin-left:1rem;">Médico</label>
                            </div>
                            <div class="form-group form-check d-flex align-items-center mr-3 mb-0">
                              <input disabled type="checkbox" class="form-check-input" name="notDoctorConversion" data-${this.controllerName}-target="checkboxNotDoctor" data-kind="not_doctor" data-action="click->${this.controllerName}#checkKind">
                              <label class="form-check-label ml-1" for="notDoctorConversion" style="margin-left:1rem;">Outro</label>
                            </div>
                          </div>
                        </div>
                        <div class="row d-none" data-${this.controllerName}-target="academicRow">
                          <div class="col-6 pr-2">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="">Informado</label>
                                <input class="form-control f-075" data-${this.controllerName}-target="uniqGraduationInput" type="text" placeholder="Informado" required>
                              </div>
                            </div>
                          </div>
                          <div class="col-3 px-2">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="">Faculdade</label>
                                <input class="form-control f-075" data-${this.controllerName}-target="medschoolInput" type="text" placeholder="Faculdade" required>
                              </div>
                            </div>
                          </div>
                          <div class="col-3 pr-2">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="">Formatura</label>
                                <input class="form-control f-075" data-${this.controllerName}-target="graduationInput" type="text" placeholder="Formatura" required>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row d-none" data-${this.controllerName}-target="doctorRow">
                          <div class="col-4 pr-2">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="">CRM Informado</label>
                                <input disabled class="form-control f-075" data-${this.controllerName}-target="uniqCrmInput" type="text" data-action="blur->${this.controllerName}#crmMask  keypress->${this.controllerName}#crmMask keyup->${this.controllerName}#crmMask" placeholder="CRM" required>
                              </div>
                            </div>
                          </div>
                          <div class="col-4 pr-2">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label>UF</label>
                                <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="crmStateDropdown">
                                  <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="crmStateDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="crmStateInput"></span></button>
                                  <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                    <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                    <ul class="ul-select" data-${this.controllerName}-target="crmStateList" data-app--helpers--search-target="searchList">
                                      <li data-crm-state="AC" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark">AC</li>
                                      <li data-crm-state="AL" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark">AL</li>
                                      <li data-crm-state="AP" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark">AP</li>
                                      <li data-crm-state="AM" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark">AM</li>
                                      <li data-crm-state="BA" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark">BA</li>
                                      <li data-crm-state="CE" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark">CE</li>
                                      <li data-crm-state="DF" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark">DF</li>
                                      <li data-crm-state="ES" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark">ES</li>
                                      <li data-crm-state="GO" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark">GO</li>
                                      <li data-crm-state="MA" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark">MA</li>
                                      <li data-crm-state="MT" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark">MT</li>
                                      <li data-crm-state="MS" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark">MS</li>
                                      <li data-crm-state="MG" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark">MG</li>
                                      <li data-crm-state="PA" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark">PA</li>
                                      <li data-crm-state="PB" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark">PB</li>
                                      <li data-crm-state="PR" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark">PR</li>
                                      <li data-crm-state="PE" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark">PE</li>
                                      <li data-crm-state="PI" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark">PI</li>
                                      <li data-crm-state="RJ" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark">RJ</li>
                                      <li data-crm-state="RN" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark">RN</li>
                                      <li data-crm-state="RS" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark">RS</li>
                                      <li data-crm-state="RO" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark">RO</li>
                                      <li data-crm-state="RR" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark">RR</li>
                                      <li data-crm-state="SC" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark">SC</li>
                                      <li data-crm-state="SP" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark">SP</li>
                                      <li data-crm-state="SE" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark">SE</li>
                                      <li data-crm-state="TO" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark">TO</li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-4 pr-2">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="">CRM</label>
                                <input class="form-control f-075" data-${this.controllerName}-target="crmInput" type="text" data-action="blur->${this.controllerName}#crmMask  keypress->${this.controllerName}#crmMask keyup->${this.controllerName}#crmMask" placeholder="CRM" required>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          
                        </div>
                      </div>
                      <div class="card-footer border-top text-right py-1">
                        <button type="button" class="btn btn-secondary btn-sm py-2 btn-table f-065" data-dismiss="modal" data-action="click->${this.controllerName}#cancelSave">Fechar</button>
                        ${saveBtnHtml}
                      </div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("commercial--marketing--landings--conversions--dashboard").viewTarget.innerHTML = html)
    }).then(() => {
      controller.formCardTarget.style.height = ($(window).height() * 0.6) + "px"
      controller.formTitleTarget.innerText = `Editando Lead | ${controller.current_conversion.name}`
      controller.nameInputTarget.value = controller.current_conversion.name
      controller.emailInputTarget.value = controller.current_conversion.email
      controller.uniqPhoneInputTarget.value = controller.current_conversion.uniq_number
      controller.dddInputTarget.value = controller.current_conversion.ddd
      controller.phoneInputTarget.value = controller.current_conversion.number_pretty

      if (controller.current_conversion.kind == `academic`) {
        controller.kind = `academic`
        controller.checkboxAcademicTarget.checked = true
        controller.academicRowTarget.classList.remove("d-none")
        controller.uniqGraduationInputTarget.value = controller.current_conversion.crm_input
      } else if (controller.current_conversion.kind == `doctor`) {
        controller.kind = `doctor`
        controller.checkboxDoctorTarget.checked = true
        controller.doctorRowTarget.classList.remove("d-none")
        controller.crmStateDropdownTarget.value = controller.current_conversion.crm_state
        controller.crmStateInputTarget.innerText = controller.current_conversion.crm_state
        controller.crmStateInputTarget.dataset.state = controller.current_conversion.crm_state
        controller.crmInputTarget.value = controller.current_conversion.crm
        controller.uniqCrmInputTarget.value = controller.current_conversion.crm_input
      } else {
        controller.kind = `not_doctor`
        controller.checkboxNotDoctorTarget.checked = true
      }

      controller.refreshSaveBtn()
      controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
    })
  }

  crmMask(ev) {
    this.getControllerByIdentifier("app--helpers--numbers").changeNumberToCrm(ev)
  }

  prefixMask(ev) {
    this.getControllerByIdentifier("app--helpers--numbers").changeNumberToDDD(ev)
  }

  phoneMask(ev) {
    this.getControllerByIdentifier("app--helpers--numbers").changeNumberToPhone(ev)
  }

  checkKind(ev) {
    this.kind = ev.currentTarget.dataset.kind

    if (this.kind == `academic`) {
      this.checkboxAcademicTarget.checked = true
      this.checkboxDoctorTarget.checked = false
      this.checkboxNotDoctorTarget.checked = false

      this.academicRowTargets.forEach(element => {
        element.classList.remove("d-none")
      })
      this.doctorRowTarget.classList.add("d-none")

    } else if (this.kind == `doctor`) {
      this.checkboxAcademicTarget.checked = false
      this.checkboxDoctorTarget.checked = true
      this.checkboxNotDoctorTarget.checked = false

      this.academicRowTargets.forEach(element => {
        element.classList.add("d-none")
      })
      this.doctorRowTarget.classList.remove("d-none")

    } else if (this.kind == `not_doctor`) {
      this.checkboxAcademicTarget.checked = false
      this.checkboxDoctorTarget.checked = false
      this.checkboxNotDoctorTarget.checked = true

      this.academicRowTargets.forEach(element => {
        element.classList.add("d-none")
      })
      this.doctorRowTarget.classList.add("d-none")
    }
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    });
    return results
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0

      if (controller.emailInputTarget.value == ``) {
        len += 1
      }

      if (controller.nameInputTarget.value == ``) {
        len += 1
      }

      if (controller.dddInputTarget.value == ``) {
        len += 1
      }

      if (controller.phoneInputTarget.value == ``) {
        len += 1
      }

      if (controller.kind == `academic`) {
      } else if (controller.kind == `doctor`) {
        if (controller.crmInputTarget.value == ``) {
          len += 1
        }
        if (controller.crmStateInputTarget.innerText == ``) {
          len += 1
        }
      } else if (controller.kind == `not_doctor`) {
      }

      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
