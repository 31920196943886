import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "list", "bodyTable", "addressInput", "kindDropdown", "kindDropdownBtn", "kindInput",
                    "kindList", "mainCheckbox", "form", "cancelBtn", "saveBtn", "errorMessage"]

  connect() {
    this.controllerName = `users--accounts--contacts--email`
    this.current_email = {}
    this.send_data = { current_user: {}, record: {}, email: {} }
    this.doTableHtml()
  }

  cancelForm() {
    this.doTableHtml()
    this.stopRefreshing()
    this.getControllerByIdentifier("app--helpers--elements").untooltip()
  }

  edit(ev) {
    this.email_list.forEach(element => {
      if (element.id == ev.currentTarget.closest(".itemRow").dataset.id) {
        this.current_email = element
      }
    });
    this.actionMode = "edit"
    this.formHtml()
  }

  save() {

    if (this.actionMode == "edit") { this.send_data.email.id = this.current_email.id }
    this.send_data.email.address = this.addressInputTarget.value.trim()
    this.send_data.email.kind = this.kindInputTarget.dataset.kind
    this.send_data.email.is_main = this.mainCheckboxTarget.checked
    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = this.permissions.feature_name
    this.send_data.record.account_id = this.application.account_contact.account_id
    this.send_data.record.company_id = this.application.account_contact.company_id
    this.send_data.record.record_type = this.application.account_contact.record_type

    this.stopRefreshing()
    this.saveBtnTarget.disabled = true
    
    this.requestSave()
  }

  requestSave() {
    if (this.actionMode == "new") {
      var url = "/users/accounts/emails/create"
      var method = "POST"
    } else if (this.actionMode == "edit") {
      var url = "/users/accounts/emails/update"
      var method = "PUT"
    }
    var controller = this
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.stopRefreshing()
        if (response.save) {
          var email = response.data.cln
          if (controller.actionMode == "new") {
            controller.email_list[controller.email_list.length] = email
          } else if (controller.actionMode == "edit") {
            if (email.active) {
              controller.email_list.forEach((element, i) => {
                if (element.id == email.id) {
                  controller.email_list.splice(controller.email_list.indexOf(element), 1, email)
                }
              })
            } else {
              controller.email_list.forEach((element, i) => {
                if (element.id == email.id) {
                  controller.email_list.splice(controller.email_list.indexOf(element), 1)
                }
              })
            }
          }
          controller.doTableHtml()
        } else {
          if (controller.hasSaveBtnTarget) {
            controller.saveBtnTarget.disabled = false
          }
          if (controller.hasDeleteBtnTarget) {
            controller.deleteBtnTarget.disabled = true
          }
        }
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  formHtml() {
    this.stopRefreshing()
    var html = `<table class="table table-sm table-borderless my-0" style="font-size:80%;">
                    <tbody>
                      <tr class="itemRow" data-${this.controllerName}-target="form">
                        <td style="font-size:80%;" scope="col" class="table-50 p-1 align-middle">
                          <div class="floating-label floating-label-sm">
                            <label for="addressInput">E-mail</label>
                            <input id="addressInput" data-${this.controllerName}-target="addressInput" class="form-control p-1 s-title-0p6rem" type="email" data-action="blur->${this.controllerName}#validateEmail keypress->${this.controllerName}#validateEmail" required>
                          </div>
                        </td>
                        <td style="font-size:80%;" scope="col" class="table-30 p-1 align-middle">
                          <div class="floating-label floating-label-sm">
                            <label>Tipo</label>
                            <div class="dropdown dropdown-selector" data-controller="app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="kindDropdown">
                              <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:29px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="kindDropdownBtn">
                                <span class="mr-auto w-100 selected-item f-065" id="selected" data-app--helpers--selector-target="input" data-${this.controllerName}-target="kindInput"></span>
                              </button>
                              <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                <input class="form-control form-control-selector dropdown-search-input f-065" type="text" placeholder="Buscar ...">
                                <ul class="ul-select" data-${this.controllerName}-target="kindList">
                                  <li data-kind="personal" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Pessoal</li>
                                  <li data-kind="commercial" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Comercial</li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td style="font-size:80%;" scope="col" class="table-10 p-1 align-middle">
                          <div class="custom-control custom-checkbox pr-0 pl-2 w-50 my-0 d-flex align-items-center mc-tooltip">
                            <input type="checkbox" class="custom-control-input" id="checkEmailMain" data-${this.controllerName}-target="mainCheckbox">
                            <label class="custom-control-label f-065 pointer" for="checkEmailMain"></label>
                            <span class="mc-tooltiptext">É o email principal?</span>
                          </div>
                        </td>
                        <td style="font-size:80%;" scope="col" class="table-10 p-1 align-middle">
                          <button data-${this.controllerName}-target="cancelBtn" data-action="click->${this.controllerName}#cancelForm" type="button" class="btn btn-sm btn-table p-0" data-toggle="tooltip" data-placement="top" title data-original-title="Cancelar"><span aria-hidden="true">&times;</span></button>
                        </td>
                      </tr>
                      <tr>
                        <td colspan="4" class="text-right px-0 pt-0">
                          <span class="mr-auto text-left f-danger my-auto" data-${this.controllerName}-target="errorMessage"></span>
                          <button disabled type="button" class="btn btn-primary btn-sm f-065 py-2" data-action="click->${this.controllerName}#save" data-${this.controllerName}-target="saveBtn">Salvar</button>
                        </td>
                      </tr>
                    </tbody>
                  </table>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.listTarget.innerHTML = html)
    }).then(() => {
      controller.refreshSaveBtn()
      if (controller.actionMode == "new") {

      } else if (controller.actionMode == "edit") {
        controller.addressInputTarget.value = controller.current_email.address
        controller.kindInputTarget.dataset.kind = controller.current_email.kind
        controller.kindInputTarget.innerText = controller.current_email.kind_pretty
        controller.kindDropdownTarget.value = controller.current_email.kind_pretty
        controller.mainCheckboxTarget.checked = controller.current_email.is_main
      }
      controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
    })
  }


  doTableHtml() {
    var html = `<div class="table-responsive">
                  <table class="table table-sm table-borderless my-0" style="font-size:80%;">
                    <tbody data-${this.controllerName}-target="bodyTable">
                    </tbody>
                  </table>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.listTarget.innerHTML = html)
    }).then(() => {
      controller.listData(controller.email_list)
    })
  }

  listData(data) {
    this.bodyTableTarget.innerHTML = ""
    if (data == undefined || data == [] || data.length == 0) {
      var noData = `<td colspan="9" class="px-0 align-middle text-left" style="font-size:80%;">
                      <span class="fa-stack fa-1x">
                        <i class="fas fa-list fa-stack-1x"></i>
                      </span>
                      <span class="f-065">Sem e-mail cadastrado</span>
                    </td>`

      this.bodyTableTarget.innerHTML = noData
    } else {
      data.forEach(element => {
        this.bodyTableTarget.insertAdjacentHTML("beforeend", this.rowTablePartial(element, data.length))
      });
    }
    this.getControllerByIdentifier("app--helpers--elements").tooltip()
  }

  rowTablePartial(element, length) {
    if (this.permissions.can_copy) {
      var copyPath = `<button type="button" class="btn btn-sm btn-table copy p-0" data-toggle="tooltip" data-placement="right" title data-original-title="Copiar"><i class="material-icons md-sm md-light">file_copy</i></button>`
    } else {
      var copyPath = ``
    }

    if (this.permissions.can_update) {
      var edit = `<button data-action="click->${this.controllerName}#edit" type="button" class="btn btn-sm btn-table p-0" data-toggle="tooltip" data-placement="left" title data-original-title="Editar"><span class="material-icons md-sm md-dark">edit</span></button>`
    } else {
      var edit = ``
    }

    if (length == 1) {
      var tableRow = `<tr class="itemRow" data-id="${element.id}" style="height:50px;">`
    } else {
      var tableRow = `<tr class="itemRow" data-id="${element.id}">`
    }

    if (element.is_main) {
      var mainStatus = `<span style="color:#26C485;"><i class="fas fa-check-double" data-toggle="tooltip" data-placement="left" title data-original-title="E-mail Principal"></i></span>`
    } else {
      var mainStatus = ``
    }

    var rowHtml = `<tr>
                    <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                    <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                    <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                    <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                    <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                    <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                    <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>



                  </tr>
                  <tr class="itemRow" data-id="${element.id}">
                    <td colspan="4" style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="p-1 align-middle">
                      <p class="mb-0 f-065 mc-tooltip grab">
                        <span data-controller="app--helpers--copy" data-copy="${element.address}" data-action="click->app--helpers--copy#copy">${element.address}</span>
                        <span class="mc-tooltiptext">Clique para Copiar</span>
                      </p>
                    </td>
                    <td colspan="1" style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="p-1 align-middle" data-copy="${element.address}">${copyPath}</td>
                    <td colspan="1" style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="p-1 align-middle">${mainStatus}</td>
                    <td colspan="1" style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="p-1 align-middle">${edit}</td>
                  </tr>
                  <tr>
                    <td colspan="7" style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                  </tr>`

    return rowHtml
    // `<td style="font-size:60%;" scope="col" class="p-1 align-middle pointer">
      //   <p class="mb-0 f-065 mc-tooltip">
      //     <span data-controller="app--helpers--copy" data-copy="${element.token}" data-action="click->app--helpers--copy#copy">${element.token}</span>
      //     <span class="mc-tooltiptext">Clique para Copiar</span>
      //   </p>
      // </td>`
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0
      if (controller.addressInputTarget.value == "") {
        len += 1
      }
      if (controller.kindInputTarget.innerText == "") {
        len += 1
      }

      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  validateEmail(ev) {
    var mail = ev.currentTarget.value

    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail) || mail == ``) {
      this.invalidEmail = false
    } else {
      this.setErrorMessage(`O e-mail digitado é inválido!`)
      this.invalidEmail = true
    }
  }

  setErrorMessage(message) {
    this.errorMessageTarget.innerText = message
    var controller = this
    setTimeout(() => {
      if (controller.hasErrorMessageTarget) {
        controller.errorMessageTarget.innerText = ``
      }
    }, 3000);
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
